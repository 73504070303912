import React from "react";
import { Modal, Spinner, Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles(() => ({
  modalBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    fontSize: "1.1em"
  },
  spinnerBorder: {
    width: "3rem",
    height: "3rem"
  }
}));

export default function Loading(props) {
  function handleClose() {
    return false;
  }

  const classes = useStyles();

  const handleDownloadPercent = (currentPage, lastPage) => {
    return `${((currentPage * 100) / lastPage).toFixed(0)}%`;
  };

  return (
    <>
      {props.isLoading ? (
        <Modal
          show={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loading-modal"
          onHide={() => handleClose()}
        >
          <Modal.Body className={classes.modalBody}>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <h2>{props.msg ? props.msg : "Carregando..."}</h2>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                {props.lastPage && props.currentPage ? (
                  <p className={classes.message}>
                    Terminando de montar seu relatório{" "}
                    {handleDownloadPercent(props.currentPage, props.lastPage)}
                  </p>
                ) : (
                  <p className={classes.message}>
                    Aguarde um momento por favor
                  </p>
                )}
              </Col>
            </Row>
            <Row className="show-grid">
              <Spinner
                animation="border"
                className={classes.spinnerBorder}
                size="xs"
              />
            </Row>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
