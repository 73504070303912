import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getReceiptWayById = async (receiptwayId) => {
  return await api.makeHttpRequest({
    url: `/receiptway/${receiptwayId}`,
    method: "GET"
  });
}

export const getReceiptWays = async (filter) => {
  return await api.makeHttpRequest({
    url: "/receiptway",
    method: "GET",
    params: filter
  });
}

export const createReceiptWay = async (receiptway) => {
  return await api.makeHttpRequest({
    url: "/receiptway",
    method: "POST",
    data: receiptway
  });
}

export const updateReceiptWay = async (id, receiptway) => {
  return await api.makeHttpRequest({
    url: `/receiptway/${id}`,
    method: "PUT",
    data: receiptway
  });
}

export const deleteReceiptWay = async (receiptwayId) => {
  return await api.makeHttpRequest({
    url: `/receiptway/${receiptwayId}`,
    method: "DELETE"
  });
}
