import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getFinancialMovementById = async (financialMovementId) => {
  return await api.makeHttpRequest({
    url: `/finances/${financialMovementId}`,
    method: "GET"
  });
}

export const getFinancialMovements = async (filter) => {
  return await api.makeHttpRequest({
    url: "/finances",
    method: "GET",
    params: filter
  });
}

export const createFinancialMovement = async (financialMovement) => {
  return await api.makeHttpRequest({
    url: "/finances",
    method: "POST",
    data: financialMovement
  });
}

export const updateFinancialMovement = async (id, financialMovement) => {
  return await api.makeHttpRequest({
    url: `/finances/${id}`,
    method: "PUT",
    data: financialMovement
  });
}

export const deleteFinancialMovement = async (financialMovementId) => {
  return await api.makeHttpRequest({
    url: `/finances/${financialMovementId}`,
    method: "DELETE"
  });
}
