import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";
import globalColors from "../../../../../_metronic/_assets/sass/custom/globalColors.scss";

export const TableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: "100%";
  overflow-x: auto;
  border-radius: "4px";
  box-shadow: ${props =>
    props.removeShadow
      ? "none"
      : "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"};

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #d1d1d1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #026fbb;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #014d82;
  }
`;

//linhas tas tabela
export const StyledTableRow = styled(TableRow)`
  background-color: ${props => props.bg || "initial"};

  &:first-child {
    border-right: 1px solid #6F6F6F36 !important
  }

  &:nth-of-type(odd) {
    background-color: ${props => props.bg || "#fff"};
  }
`;

export const StyledTableHeader = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;

    padding: 8px;
    color: #ffffff;
    background: #026fbb;
  }

  &.MuiTableCell-head,
  &.MuiTableSortLabel-active {
    svg {
      color: ${globalColors.primaryColor2} !important;
    }
  }

  &.MuiTableCell-head:first-child {
    border-top-left-radius: 4px;
    padding-left: 16px;
    background: #2992db;
  }

  &.MuiTableCell-head:last-child {
    border-top-right-radius: 4px;
    background: #01416d;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${globalColors.textColor2};
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 4px 7px;

    background: "transparent";
    max-width: 250px;

    &:first-child {
      padding-left: 16px;
    }
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  .link {
    text-decoration: underline;
  }
`;

export const StyledTableHeaderAnalisys = styled(StyledTableHeader)`
  &.MuiTableCell-head {
    border-right: 1px solid #6f6f6f36;
  }

  &.MuiTableCell-head:first-child {
    background: #026fbb;
  }

  &.MuiTableCell-head:last-child {
    background: #026fbb;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;

    padding: 8px;
    color: #52525b;
    background: "#f9f7f6";
  }

  &.MuiTableCell-head,
  &.MuiTableSortLabel-active {
    svg {
      color: ${globalColors.primaryColor2} !important;
    }
  }

  &.MuiTableCell-head:first-child {
    border-top-left-radius: 4px;
    padding-left: 16px;
  }

  &.MuiTableCell-head:last-child {
    border-top-right-radius: 4px;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${globalColors.textColor2};
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 4px 7px;

    background: "transparent";
    max-width: 324px;

    &:first-child {
      padding-left: 16px;
    }
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  .link {
    text-decoration: underline;
  }
`;

export const StyledTableContent = styled.div`
  background-color: #f9f7f6;
  padding: 16px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: calc(100vh - 41px);
`;

export const OpenContainerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${globalColors.primaryColor2};

  position: relative;
  right: 15px;

  top: 50%;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;

  padding: 0px;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  transition: filter 0.2s;
  transition: width 0.8s;

  .arrow {
    width: 24px;
    height: 20px;
    color: #fff;
  }

  &:hover {
    filter: brightness(0.9);
  }

  background-color: "red" !important;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  width: ${({ showMap }) => (showMap ? "84vw" : "0vw")};
  background: #fff;

  margin-left: 4px;

  transition: width 0.8s;

  img {
    display: ${({ showMap }) => (!showMap ? "none" : "flex")};
    margin: 0 auto;

    position: relative;
    top: 24%;
  }
`;

export const Status = styled.div`
  color: ${({ status }) =>
    status === "Disponível"
      ? globalColors.primaryColor2
      : status === "Ocupado"
        ? globalColors.primaryColor4
        : status === "Inadimplente"
          ? globalColors.secondaryColor6
          : status === "Manutenção"
            ? globalColors.secondaryColor8
            : globalColors.textColor2};
`;

export const InputDate = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #e2e5ec;
  color: #495057;

  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(61%) sepia(24%) saturate(751%) hue-rotate(4deg)
      brightness(105%) contrast(93%);
    //width: 20%;

    /* :not(.has-value):before{
  color: lightgray;
  content: attr(placeholder);
  } */

    :required:invalid::-webkit-datetime-edit {
      color: transparent !important;
    }
    :focus::-webkit-datetime-edit {
      color: black !important;
    }
  }
`;
