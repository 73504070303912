import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";

import discountIcon from "../../../../assets/icons/discount.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { deleteDiscount } from "../../../../services/modules/DiscountsApiService";
import { getEffectiveValue } from "../../../../utils/getEffectiveValue";
import { getLastAlteration, getCreationDate } from "../../../../utils/getEffectivePercentual";
import * as moment from 'moment';

const propsDatatable = {
  title: "Descontos",
  endpoint: "/discount",
  className: "h-100",
  placeholderSearch: "Busque descontos pela descrição",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

const DiscountListing = props => {
  window.setPageTitle("Listagem de descontos - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.DISCOUNT_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.DISCOUNT_WRITE.ENGLISH
  );

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    { label: "Ações" },
    { column: null, label: "Descrição do desconto" },
    { column: null, label: "Percentual do desconto" },
    { column: null, label: "Data de criação" },
    { column: null, label: "Última alteração" }
  ];

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteDiscount(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Desconto deletado com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar desconto.", "error");
    } finally {
      setLoading(false);
    }
  };

  const formatRow = r => {
    let crudActions = ["delete", "edit"];

    return (
      <StyledTableRow
        key={r.id}
        hover
        tabIndex={-1}
      >
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            disableActions={false}
            actions={crudActions}
            module="descontos"
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Desconto"}
            addMessage={"Você tem certeza que deseja excluir este desconto? Essa ação não poderá ser desfeita"}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.description}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {getEffectiveValue(r?.alterationHistoric)}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {moment(getCreationDate(r?.alterationHistoric)).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {moment(getLastAlteration(r?.alterationHistoric)).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={discountIcon} alt={"Icone de desconto"} />}
        className="h-100"
        alert={"Você só pode realizar lançamentos usando um desconto após a data da criação dele no sistema. Caso registre o desconto hoje e tente lançar uma movimentação de ontem o desconto será zerado já que ele não existia ontem."}
        headRows={headRows}
        formatRow={formatRow}
        addButtonUrl={userHasAccess ? "/admin/descontos/adicionar" : ""}
        rows={[]}
        reload={reload}
        orderBy="id"
        orderDirection="desc"
        width={200}
        hiddenReload
        hiddenKanbanContent
      />
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(DiscountListing);
