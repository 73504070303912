import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import truck from "../../../../assets/icons/truck.svg";
import FormActions from "../../components/FormActions";
import { connect } from "react-redux";

import { Formik } from "formik";

import { schema } from "./validator";
import { FormikInput } from "../../../../components/FormikInput/Input";
import Swal from "sweetalert2";

import {
  getSupplierById,
  createSupplier,
  updateSupplier
} from "../../../../services/modules/SupplierApiService";

import { FormAlert } from "../../../../components/Alerts/alert";

const rootPage = "/admin/fornecedores";

const SupplierForm = ({ idCompany }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.SUPPLIERS_WRITE.ENGLISH);

  const { supplierId } = useParams();
  const history = useHistory();

  window.setPageTitle(
    `${!!supplierId ? "Cadastro" : "Edição"} de fornecedores`
  );

  const [initialValues, setInitialValues] = useState({
    name: undefined,
    responsable: undefined,
    phone: undefined,
    email: undefined,
    totalBought: 0,
    discountValue: "0%",
    address: '',
    obs: ''
  });

  const get = async id => {
    try {
      const result = await getSupplierById(id);
      let discount = "";

      if (result.discount && result?.discount?.alterationHistoric.length) {
        result.discount.alterationHistoric.sort((a, b) => {
          return new Date(b.effectiveDate) - new Date(a.effectiveDate);
        });

        const history = result?.discount?.alterationHistoric[0];
        discount = `${Number(history.value).toFixed(2)}%`;
      }

      setInitialValues({
        ...result,
        discountValue: discount
      });
    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  useEffect(() => {
    if (supplierId) {
      get(supplierId);
    }
  }, [supplierId]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async values => {
    setLoading(true);
    const discountValue = Number(values.discountValue.replace("%", "")).toFixed(6)

    try {
      if (!!supplierId) {
        await updateSupplier(supplierId, {
          ...values,
          discountValue: Number(discountValue) ?? 0
        });
      } else {
        await createSupplier({
          ...values,
          ...(idCompany && { idCompany }),
          discountValue: Number(discountValue) ?? 0
        });
      }

      setLoading(false);
      await Swal.fire({
        title: "Sucesso",
        text: `Fornecedor ${!!supplierId ? "atualizado" : "criado"
          } com sucesso`,
        icon: "success"
      });

      history.push(rootPage);
    } catch (err) {
      const message = "Erro ao salvar fornecedor";
      Swal.fire({
        title: "Erro",
        text: message,
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const isInvalidInput = (event, key) => {
    return alreadyHasComma(event, key) || insertingCommaWithoutValue(event, key)
  }

  const alreadyHasComma = (event, key) => {
    return event.discountValue?.includes(".") && (key === "," || key === ".")
  }

  const insertingCommaWithoutValue = (event, key) => {
    return (event.discountValue === undefined || event.discountValue?.length === 0) && (key === "," || key === ".")
  }

  const thereIsNoValue = fields => {
    return fields.discountValue.length === 2 && fields.discountValue[1] === "%"
  }

  const handleValueKeyDown = (event, fields, setFieldValue) => {
    if (isInvalidInput(fields, event.key)) {
      event.preventDefault();
      return;
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      fields?.discountValue?.length > 0
    ) {
      if (thereIsNoValue(fields)) {
        setFieldValue("discountValue", "");
        return;
      }
      setFieldValue("discountValue", `${fields?.discountValue.slice(0, -2)}%`);
    }
  };

  const toFixedMask = value => {
    return `${value.split(".")[0]}.${value.split(".")[1].slice(0, 5)}`;
  }

  const alreadyHasFiveDecimalPlaces = element => {
    return element.split(".")[1]?.length >= 5;
  }

  const removeInvalidCharactersFromNumber = element => {
    return element.replace("%", "").replace(/[^0-9,.]/g, "").replace(",", ".");
  }

  const formatMask = element => {
    let valueToReturn = 0.00;

    const value = removeInvalidCharactersFromNumber(element)

    if (alreadyHasFiveDecimalPlaces(element)) {
      valueToReturn = toFixedMask(value)
    } else {
      valueToReturn = value;
    }

    return `${valueToReturn > 100 ? 100 : valueToReturn}%`;
  }

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        {" "}
        <img src={truck} alt={"Fornecedores"} />
        Fornecedores
      </h3>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        enableReinitialize
        isInitialValid={!!supplierId}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, handleSubmit, setFieldTouched, validateField, isValid, values, errors, touched }) => (
          <>
            <form
              className={styles.form}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="name"
                  placeholder="Digite o nome da empresa"
                  label="Empresa"
                  setFieldTouched={setFieldTouched}
                  error={!!touched.name ? errors.name : undefined}
                  required
                />
                <FormikInput
                  type="text"
                  name="responsable"
                  setFieldTouched={setFieldTouched}
                  error={!!touched.responsable ? errors.responsable : undefined}
                  placeholder="Digite o nome do Responsável"
                  label="Responsável"
                  required
                />
              </div>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="phone"
                  placeholder="Digite o telefone"
                  setFieldTouched={setFieldTouched}
                  onChange={() => {
                    setFieldTouched('phone');
                    validateField('phone');
                  }}
                  error={!!touched.phone ? errors.phone : undefined}
                  mask="(##) #####-####"
                  label="Telefone"
                />
                <FormikInput
                  type="text"
                  name="email"
                  setFieldTouched={setFieldTouched}
                  placeholder="Digite o e-mail"
                  error={!!touched.email ? errors.email : undefined}
                  label="E-mail"
                />
              </div>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="totalBought"
                  placeholder="0"
                  label="Total Comprado"
                  isDisabled

                />
                <FormikInput
                  type="text"
                  name="discountValue"
                  error={!!touched.discountValue ? errors.discountValue : undefined}
                  placeholder="Digite a porcentagem do desconto"
                  label="Desconto"
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onKeyDown={event =>
                    handleValueKeyDown(event, values, setFieldValue)
                  }
                  formatter={e => {
                    return formatMask(e);
                  }}
                />
                <FormikInput
                  type="text"
                  name="obs"
                  placeholder="Digite as observações"
                  label="Observações"
                />

                <FormikInput
                  type="text"
                  name="address"
                  setFieldTouched={setFieldTouched}
                  error={!!touched.address ? errors.address : undefined}
                  placeholder="Digite o endereço"
                  label="Endereço"
                />
              </div>
              <FormAlert />
            </form>
            <FormActions
              module={"fornecedores"}
              formIsValid={isValid}
              onSubmit={handleSubmit}
              hideCancel={true}
              messagem
            />
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ company }) => ({
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(SupplierForm);
