import { getEffectivePercentual } from "./getEffectivePercentual";
import * as moment from 'moment-timezone';

export const getEffectiveValue = (alterationHistoric, effectiveDate = null) => {
  const value = getEffectivePercentual(
    alterationHistoric,
    effectiveDate ?
      moment(effectiveDate).startOf('day')
      : moment().startOf('day'),
  )
  return `${value}%`;
};
