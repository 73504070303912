import React, { useEffect, useState } from "react";
import { iconButtonStyle, StyledTableCell, StyledTableRow } from "../../../../partials/datatable/styles";
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { DeleteIcon } from "../../../../components/IconsSVG";
import CrudActions from "../../../../partials/datatable/CrudActions";

export const SimpleTable = ({
  headers,
  rows,
  onConfirmDelete,
  deleteTitle,
  deleteMessage,
  emptyMessage,
  cellStyle
}) => {

  const defaultCellStyle = {
    padding: "5px 10px",
  };

  const generateRow = (row, i) => (
    <StyledTableRow
      hover
      key={i}
    >
      {onConfirmDelete && (
        <StyledTableCell
          style={cellStyle ?? defaultCellStyle}
          align="left"
        >
          <CrudActions
            disableActions={false}
            actions={['delete']}
            onConfirmDelete={onConfirmDelete}
            id={row?.id}
            obj={row}
            titleMessageDelete={deleteTitle}
            addMessage={deleteMessage}
          />
        </StyledTableCell>
      )}
      {headers.map(header => (
        <StyledTableCell
          style={cellStyle ?? defaultCellStyle}
          key={header.value}
        >
          {row[header.value]}
        </StyledTableCell>
      ))
      }
    </StyledTableRow>
  );

  const emptyRow = (
    <StyledTableRow
      hover
    >
      <StyledTableCell
        scope="row"
        style={cellStyle ?? defaultCellStyle}
        colSpan={headers.length + (onConfirmDelete ? 1 : 0)}
      >
        {emptyMessage ?? "N/A"}
      </StyledTableCell>
    </StyledTableRow>
  );

  const headersRow = (
    <StyledTableRow>
      {onConfirmDelete && (
        <StyledTableCell
          style={cellStyle ?? defaultCellStyle}
          align="left"
          width="100px"
        >
          Ações
        </StyledTableCell>
      )}
      {headers.map(header => (
        <StyledTableCell style={cellStyle ?? defaultCellStyle} key={header.label}>
          {header.label}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );

  return (
    <Table>
      <TableHead>
        {headersRow}
      </TableHead>
      <TableBody>
        {(!rows || rows?.length === 0) ? emptyRow : rows.map(generateRow)}
      </TableBody>
    </Table>
  )
};
