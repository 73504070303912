import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";

//linhas tas tabela
export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fff;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;

    padding: 8px;
    color: #52525b;
    background: #f9f7f6;
  }

  &.MuiTableCell-head,
  &.MuiTableSortLabel-active {
    svg {
      color: ${globalColors.primaryColor1} !important;
    }
  }

  &.MuiTableCell-head:first-child {
    border-top-left-radius: 4px;
    padding-left: 0px;
  }

  &.MuiTableCell-head:last-child {
    border-top-right-radius: 4px;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Cera Pro Regular";
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: ${globalColors.textColor2};
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 4px 7px;

    //background: #FFFFFF;
    max-width: 250px;

    &:first-child {
      padding-left: 0px;
    }
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  .link {
    text-decoration: underline;
  }
`;

export const StyledTableContent = styled.div`
  background-color: #f9f7f6;
  padding: 0px;
  width: 100%;
  max-height: calc(100vh - 41px);
`;

export const Status = styled.div`
  color: ${({ status }) =>
    status === "Disponível"
      ? globalColors.primaryColor2
      : status === "Ocupado"
      ? globalColors.primaryColor4
      : status === "Inadimplente"
      ? globalColors.secondaryColor6
      : status === "Manutenção"
      ? globalColors.secondaryColor8
      : globalColors.textColor2};
`;
