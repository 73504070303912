import { persistReducer } from "redux-persist";
import { put, takeLatest } from "redux-saga/effects";
import { AppAPIService } from "../../services/AppAPIService";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SelectCompany: "[SelectCompany] Action",
  SetCompanies: "[SetCompanies] Action",
  Forget: "[Forget] Action",
};

const initialState = {
  idCompany: undefined,
  companies: []
};

export const reducer = persistReducer(
  { storage, key: "company:ohana", whitelist: ["idCompany"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetCompanies: {
        return { ...state, companies: action.payload }
      }

      case actionTypes.SelectCompany: {
        const { idCompany } = action.payload;
        return { ...state, idCompany };
      }

      case actionTypes.Forget: {
        localStorage.clear();
        return initialState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setCompanies: (companies) => ({
    type: actionTypes.SetCompanies,
    payload: companies
  }),
  selectCompany: (idCompany) => ({
    type: actionTypes.SelectCompany,
    payload: { idCompany }
  }),
  forget: () => ({
    type: actionTypes.Forget,
    payload: {}
  })
};

export const getCompanies = async () => {
  const api = AppAPIService.getInstance();

  const companies = await api.makeHttpRequest({
    method: "GET",
    url: 'company?page=1&perPage=1000000'
  })

  return companies.data.map(company => {
    return {
      value: company.id,
      label: company.name
    }
  })
}

export function* saga() {
  yield takeLatest(actionTypes.SelectCompany, function* setCompanies() {
    const data = yield getCompanies();
    yield put(actions.setCompanies(data));
  });
}
