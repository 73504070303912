/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loading from "../../pages/home/components/Loading";
import { Portlet, PortletBody } from "../content/Portlet";
import { AppAPIService } from "../../services/AppAPIService";
import { StyledTableCell, StyledTableContent, StyledTableRow } from "./styles";
import "./styles.css";
import { Box, TableSortLabel } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const EnhancedTableHead = props => {
  const { headRows, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const generateRow = headRows => (
    <TableRow>
      {(headRows || []).map((row, i) => (
        <TableCell
          key={row.column || i}
          align={row.align ? row.align : "left"}
          padding={row.disablePadding ? "none" : "default"}
          size={row.size || null}
          width={row.width || undefined}
          variant="head"
          component={StyledTableCell}
          sortDirection={orderBy === row.column ? order : false}
        >
          {row.column ? (
            <TableSortLabel
              active={orderBy === row.column}
              direction={order}
              onClick={createSortHandler(row.column)}
            >
              {row.label}
            </TableSortLabel>
          ) : (
            row.label
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <TableHead>
      {Array.isArray(headRows[0]) &&
        headRows.map(row => {
          return generateRow(row);
        })}
      {!Array.isArray(headRows[0]) && generateRow(headRows)}
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired
};

const api = AppAPIService.getInstance();

const DatatableForm = props => {
  const { widthTable, idCompany, user } = props;
  const useStyles = makeStyles(() => {
    return {
      root: {
        //tabela e pesquisa
        maxHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        boxShadow: "none"
      },
      paper: {
        width: "100%",
        flex: "1"
      },
      table: {
        minWidth: widthTable ? widthTable : 400,
        overflowX: "scroll",
        background: "#F9F7F6"
      },
      tableWrapper: {
        // TODO: remover esse overflow
        //overflow: "hidden !important",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        overflowX: "auto",
        borderRadius: "4px"
      },
      rows: {
        padding: "0 !important",
        margin: "0 !important"
      }
    };
  });

  const classes = useStyles();
  const currentState = loadPaginationState();

  const [rows, setRows] = useState(props.rows);

  const [order, setOrder] = useState(currentState.order || "desc");
  const [orderBy, setOrderBy] = useState(
    currentState.orderBy || props.orderBy || props.headRows[0].column || "id"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage || currentState.perPage || 5
  );
  const [count, setCount] = useState(rows?.length);
  const [isLoading, setIsLoading] = useState("");
  const [isLoadingExport, setIsLoadingExport] = useState("");
  const [reload, setReload] = useState(false);
  const pageCurrentState = currentState.page;

  const loadRecords = useCallback(
    async (config = {}) => {
      if ((!props.farmId && props.name === "areaPool") || !props.endpoint) {
        return;
      }

      setIsLoading(true);

      let params = {
        page: (config.page !== undefined ? config.page : page) + 1,
        perPage: rowsPerPage || config.perPage,
        orderBy: config.orderBy || orderBy,
        orderByDirection: config.orderByDirection || order,
        idCompany
      };

      try {
        let response = {};

        response = await api.makeHttpRequest({
          url: props.endpoint,
          params
        });

        setRows(response?.data || response || []);
        setCount(response?.meta?.total || response?.data?.length || 0);

        setPage(
          params.page < response?.meta?.lastPage
            ? response?.meta?.currentPage - 1 > 0
              ? response?.meta?.currentPage - 1
              : 0
            : response?.meta?.lastPage - 1 > 0
              ? response?.meta?.lastPage - 1
              : 0
        );

        const paramsReport = {
          endpoint: props.endpoint,
          params,
          lastPage: response?.meta?.lastPage
        };

        if (props.module === "reports" && !response?.data?.length) {
          Swal.fire({
            title: "Busca sem resultados",
            text:
              "Os filtros selecionados não encontraram resultados. Ajuste os filtros para nova consulta.",
            icon: "warning"
          });
          props.setFetchReport(false);
          props.setAbleButton(false);
        }
        if (props.setParamsReport) {
          props.setParamsReport(paramsReport);
        }
      } catch (e) {
        Swal.fire(
          "Erro!",
          "Erro ao carregar os dados, tente novamente mais tarde.",
          "error"
        );
      } finally {
        setIsLoading(false);
        setReload(false);
      }
    },
    [
      order,
      orderBy,
      page,
      props.endpoint,
      props.farmId,
      props.name,
      rowsPerPage,
      props.setParamsReport,
      props.setFetchReport,
      props.setAbleButton
    ]
  );

  useEffect(() => {
    if (!props.cancelReload) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page
      });
    }
  }, [
    reload,
    props.reload,
    rowsPerPage,
    props.cancelReload,
    props.skipPaginationState,
    loadRecords,
    pageCurrentState,
    page
  ]);

  useEffect(() => {
    if (Boolean(props.dateRange)) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange]);

  function loadPaginationState() {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (paginationState[props.endpoint]) {
      return paginationState[props.endpoint];
    }

    return {};
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(+event.target.value);
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    const direction = isDesc ? "asc" : "desc";

    setOrder(direction);
    setOrderBy(property);
    loadRecords({
      orderByDirection: direction,
      orderBy: property
      //orderBy: property === "farm" ? "farm.label" : property,
    });
  };

  function refreshAction() {
    loadRecords({
      page: pageCurrentState || page
    });
  }

  if (reload) {
    refreshAction();
  }

  useEffect(() => {
    if (props.name === "areaPool") {
      if (props.rows?.length > 0) {
        setRows(props.rows);
        setCount(1);
        return;
      } else {
        setRows([]);
        setCount(1);
      }
    }
  }, [props.name, props.rows]);

  return (
    <>
      <Loading isLoading={isLoading || isLoadingExport} />
      <StyledTableContent style={{ maxHeight: "fit-content" }}>
        <Portlet
          fluidHeight={true}
          style={{
            backgroundColor: "#F9F7F6",
            boxShadow: "none",
            margin: "0px"
          }}
        >
          <PortletBody fit={true} fluid={true}>
            <div className={classes.root}>
              {props.formView ? props.formView : null}

              <div className={classes.tableContainer}>
                <Paper className={classes.paper}>
                  <div className={classes.tableWrapper}>
                    <Table
                      className={classes.table}
                      style={{ borderRadius: "4px 4px 0px 0px" }}
                    >
                      <EnhancedTableHead
                        rowCount={rows?.length}
                        headRows={props.headRows}
                        styledTableCell={props.styledTableCell}
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                      />
                      <TableBody style={{ height: "32px" }}>
                        {isLoading ? (
                          <TableRow component={StyledTableRow}>
                            <TableCell
                              colSpan={props.headRows?.length}
                              className="text-center"
                              component={StyledTableCell}
                            >
                              Aguarde...
                            </TableCell>
                          </TableRow>
                        ) : count > 0 ? (
                          //rows.sort(getComparator(order, orderBy)).map(row => props.formatRow(row))
                          rows.map(row => props.formatRow(row))
                        ) : (
                          <TableRow component={StyledTableRow}>
                            <TableCell
                              colSpan={props.headRows.length}
                              className="text-center"
                              component={StyledTableCell}
                            >
                              {(!user.idCompany && !idCompany) ?
                                "Nenhum registro encontrado, selecione uma empresa para visualisar, caso já tenha selecionado, crie um desconto na formulário acima"
                                :
                                "Nenhuma informação para ser exibida, crie um desconto na formulário acima"
                              }
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
                {props.name === "areaPool" ? null : (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20]}
                          colSpan={5}
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          id="MuiTable-root-custom"
                          labelRowsPerPage="Registros por página"
                          backIconButtonProps={{
                            "aria-label": "Anterior"
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima"
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </StyledTableContent>
    </>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  idCompany: company.idCompany,
  user: auth.user
});

export default withRouter(
  connect(mapStateToProps)(DatatableForm)
)
