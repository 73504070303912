import { HttpService } from "./HttpService";
import axios from "axios";
import store from "../store/store";
import moment from "moment";
import * as authDuck from "../store/ducks/auth.duck";

export class AppAPIService extends HttpService {
  static _instance;

  constructor() {
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_HOST}/api`
    });

    super(instance);
  }

  static getInstance() {
    return this._instance || (this._instance = new this());
  }

  async makeHttpRequest(config) {
    const authData = this.getAuthData();
    if (authData && authData.accessToken) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${authData.accessToken}`,
        "Access-Control-Expose-Headers": "Content-Disposition"
      };
    }

    return super.makeHttpRequest(config);
  }

  getAuthData() {
    const {
      auth: { authData }
    } = store.getState();

    return authData;
  }

  getAuthUser() {
    const {
      auth: { user },
    } = store.getState();
    return user;
  }

  async handleRequestError(e, config) {
    if (e.response.status !== 401) {
      return super.handleRequestError(e, config);
    }

    const authData = this.getAuthData();

    if (authData && authData.refreshToken) {
      const response = await super.makeHttpRequest({
        ...config,
        url: 'auth/refresh',
        method: "GET",
        headers: {
          Authorization: `Bearer ${authData.refreshToken}`
        }
      });

      if (response) {
        store.dispatch(authDuck.actions.refresh({
          ...authData,
          accessToken: response.accessToken,
          refreshToken: response.refreshToken
        }));

        return this.makeHttpRequest(config);
      }
    }
  }

  async download(reqConfig, type) {
    const response = await this.makeHttpRequest({
      ...reqConfig,
      responseType: "blob"
    });

    const blob = new Blob([response.data], { type });

    const extensions = {
      "application/pdf": "pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx"
    };

    const contentDisposition = response.headers["content-disposition"];
    let fileName = `${moment().format("YYYY-MM-DD-HH-mm-ss")}.${extensions[type]
      }`;
    if (contentDisposition && contentDisposition.split("filename=").length) {
      fileName = contentDisposition.split("filename=")[1];
      if (fileName) {
        fileName = fileName.trim().substr(0, fileName.indexOf(";"));
      }
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  }
}
