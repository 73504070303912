import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./styles.scss";

const TabsComponent = ({ titles, disabled, children, index, setIndex }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (index !== null) {
      setTabIndex(index);
      setIndex(index);
    }
  }, [index]);

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => {
        setTabIndex(index);
        setIndex(index);
      }}
    >
      {
        <TabList>
          {titles.map(title => {
            return (
              <Tab
                disabled={disabled ?? false}
                key={title}
              >
                {title}
              </Tab>
            );
          })}
        </TabList>
      }
      {children?.map(child => {
        return <TabPanel style={{ backgroundColor: "#FFF" }} key={child.props.id}>{child}</TabPanel>;
      })}
    </Tabs>
  );
};

export default TabsComponent;
