import React from "react";
import { connect } from "react-redux";
import AdminCompany from "../../../app/partials/layout/AdminCompany";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";

const Topbar = ({ user }) => {
  return (
    <div className="kt-header__topbar">

      {/* <UserNotifications
          skin="light"
          iconType=""
          icon="flaticon2-bell-alarm-symbol"
          type="primary"
          dot="false"
        /> */}

      <LanguageSelector iconType="" />

      {(user?.profile?.name.includes("Administrador")) && <AdminCompany />}
      <UserProfile showBadge={true} />
    </div>
  );
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(Topbar)
