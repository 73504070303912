import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "../Users/styles.module.scss";
import Loading from "../../../home/components/Loading";

import FormActions from "../../components/FormActions";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import discount from "../../../../assets/icons/discount.svg";
import { connect } from "react-redux";
import { Formik } from "formik";
import { createDiscount, getDiscountsById, updateDiscount } from "../../../../services/modules/DiscountsApiService";
import { schema } from './validator';
import { FormikInput } from "../../../../components/FormikInput/Input";
import { getEffectiveValue } from "../../../../utils/getEffectiveValue";
import { FormAlert } from "../../../../components/Alerts/alert";

const DiscountForm = ({ idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.DISCOUNT_WRITE.ENGLISH);
  const rootPage = "/admin/descontos";
  const { discountId } = useParams();

  window.setPageTitle(
    `${!!discountId ? "Cadastro" : "Edição"} de descontos`
  );

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    description: undefined,
    value: undefined
  });

  const get = async id => {
    try {
      const discount = await getDiscountsById(id);

      setInitialValues({
        description: discount.description,
        value: getEffectiveValue(discount.alterationHistoric)
      });
    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  useEffect(() => {
    if (discountId) {
      get(discountId);
    }
  }, [discountId]);

  const handleSubmit = async values => {
    setLoading(true);

    const payload = {
      idCompany: user.idCompany ?? idCompany,
      description: values.description,
      isPercentual: true,
      value: Number(Number(values.value.replace("%", "")).toFixed(6))
    }

    try {
      if (!!discountId) {
        await updateDiscount(discountId, payload);
      } else {
        await createDiscount(payload);
      }

      setLoading(false);

      await Swal.fire({
        title: "Sucesso",
        text: `Desconto ${!!discountId ? "atualizado" : "criado"
          } com sucesso`,
        icon: "success"
      });

      history.push(rootPage);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  const toFixedMask = value => {
    return `${value.split(".")[0]}.${value.split(".")[1].slice(0, 5)}`;
  }

  const alreadyHasFiveDecimalPlaces = element => {
    return element.split(".")[1]?.length >= 5;
  }

  const removeInvalidCharactersFromNumber = element => {
    return element.replace("%", "").replace(/[^0-9,.]/g, "").replace(",", ".");
  }

  const formatMask = element => {
    let valueToReturn = 0.00;

    const value = removeInvalidCharactersFromNumber(element)

    if (alreadyHasFiveDecimalPlaces(element)) {
      valueToReturn = toFixedMask(value)
    } else {
      valueToReturn = value;
    }

    return `${valueToReturn > 100 ? 100 : valueToReturn}%`;
  }

  const alreadyHasComma = (event, key) => {
    if (!event || !event.value) return false
    return event?.value?.includes(".") && (key === "," || key === ".")
  }

  const isInvalidInput = (event, key) => {
    return alreadyHasComma(event, key) || insertingCommaWithoutValue(event, key)
  }

  const insertingCommaWithoutValue = (event, key) => {
    return (event.value === undefined || event.value?.length === 0) && (key === "," || key === ".")
  }

  const thereIsNoValue = fields => {
    return fields.value.length === 2 && fields.value[1] === "%"
  }

  const handleValueKeyDown = (event, fields, setFieldValue) => {
    if (isInvalidInput(fields, event.key)) {
      event.preventDefault();
      return;
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      fields?.value?.length > 0
    ) {
      if (thereIsNoValue(fields)) {
        setFieldValue("value", "");
        return;
      }
      setFieldValue("value", `${fields?.value.slice(0, -2)}%`);
    }
  };

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={discount} alt={"Descontos"} />
        Descontos
      </h3>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        isInitialValid={!!discountId}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, handleSubmit, isValid, values }) => (
          <>
            <form
              className={styles.form}
              autoComplete="off"
              onSubmit={handleSubmit}
              id="discounts"
            >
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="description"
                  placeholder="Digite a descrição do desconto"
                  setFieldValue={setFieldValue}
                  label="Descrição do Desconto"
                  required
                />
                <FormikInput
                  type="text"
                  name="value"
                  onKeyDown={event =>
                    handleValueKeyDown(event, values, setFieldValue)
                  }
                  setFieldValue={setFieldValue}
                  formatter={e => {
                    return formatMask(e);
                  }}
                  placeholder="Digite o percentural do desconto"
                  label="Percentual do desconto"
                  required
                />
              </div>

              <FormAlert />
              <FormActions
                module={"descontos"}
                formIsValid={isValid}
                onSubmit={handleSubmit}
                hideCancel={true}
                messagem
              />
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany,
  user: auth.user
});

export default connect(mapStateToProps)(DiscountForm);
