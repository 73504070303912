import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import moment from "moment";
import { USER_TYPES } from "../../../../enum/UserTypes";
import vehicle from "../../../../assets/icons/vehicle.svg";
import carWarning from "../../../../assets/icons/car.svg";
import carDanger from "../../../../assets/icons/carDanger.svg";
import documentWarning from "../../../../assets/icons/documentWarning.svg";
import documentDanger from "../../../../assets/icons/documentDanger.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import {
  deleteVehicle,
  toggleVehicleStatus
} from "../../../../services/modules/VehicleApiService";
import { Overlay } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import RememberDialog from "../../../../partials/datatable/RememberDialog";

const propsDatatable = {
  title: "Veiculos",
  endpoint: "/vehicle",
  className: "h-100",
  placeholderSearch: "Busque por cliente, placa ou modelo",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    fontWeight: 700,
    padding: "10px 30px",
    color: "#333333",
    border: "1px solid #e3e3e3",
    lineHeight: "9.5px",
    boxShadow: "rgba(0, 0, 0, .5)",
    fontSize: 10
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFF",
    "&:before": {
      border: "1px solid #e3e3e3"
    }
  }
}));

const VehicleListing = props => {
  window.setPageTitle("Listagem de veiculos - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.VEHICLES_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.VEHICLES_WRITE.ENGLISH
  );
  const [showRemember, setShowRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    ...(userHasAccess ? [{ label: "Ações" }] : []),
    { column: "name", label: "Lembretes" },
    { column: "client.name", label: "Cliente" },
    { column: "licensePlate", label: "Placa" },
    { column: "brand", label: "Marca" },
    { column: "model", label: "Modelo" },
    { column: "color", label: "Cor" },
    { column: "year", label: "Ano" },
    { column: "milage", label: "Km Atual" },
    { column: "soldAt", label: "Data da Venda" }
  ];

  const handleConfirmChangeStatus = async obj => {
    try {
      setLoading(true);
      await toggleVehicleStatus(obj.id, !obj.active);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Veículo ${!obj.active ? "ativado" : "desativado"} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteVehicle(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Veículo deletado com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar veículo.", "error");
    } finally {
      setLoading(false);
    }
  };

  const checkRemember = r => {
    if (
      moment(r?.nextOilChangeDate).isBetween(
        moment(),
        moment().add(10, "days")
      ) ||
      moment(r?.nextOilChangeDate).isBefore(moment()) ||
      moment(r?.licenseExpiresAt).isBetween(
        moment(),
        moment().add(10, "days")
      ) ||
      moment(r?.licenseExpiresAt).isBefore(moment())
    ) {
      console.log(showRemember);
      if (!showRemember) setShowRemember(true);
    }
  };

  const formatRow = r => (
    <StyledTableRow key={r.id} bg="rgba(245, 238, 53, 0.2)" hover tabIndex={-1}>
      {userHasAccess ? (
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            actions={["delete", "edit", "toggle"]}
            module="vehicle"
            path="veiculos"
            onConfirmChangeStatus={handleConfirmChangeStatus}
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Veículo"}
            addMessage={
              "Você tem certeza que deseja excluir este veículo? Essa ação não poderá ser desfeita"
            }
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {moment(r?.nextOilChangeDate).isBetween(
          moment(),
          moment().add(10, "days")
        ) && (
          <CustomTooltip title="Próximo a troca de óleo" arrow placement="top">
            <img
              style={{ margin: "0 5px" }}
              src={carWarning}
              alt="Próximo a troca de óleo"
            />
          </CustomTooltip>
        )}

        {moment(r?.nextOilChangeDate).isBefore(moment()) && (
          <CustomTooltip title="Troca de óleo vencida" arrow placement="top">
            <img src={carDanger} alt="Troca de óleo vencida" />
          </CustomTooltip>
        )}

        {moment(r?.licenseExpiresAt).isBetween(
          moment(),
          moment().add(10, "days")
        ) && (
          <CustomTooltip
            title="Licenciamento próximo a data de expiração"
            arrow
            placement="top"
          >
            <img src={documentWarning} alt="Próximo a data de expiração" />
          </CustomTooltip>
        )}

        {moment(r?.licenseExpiresAt).isBefore(moment()) && (
          <CustomTooltip title="Licenciamento expirado" arrow placement="top">
            <img src={documentDanger} alt="Licenciamento expirado" />
          </CustomTooltip>
        )}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.client?.fullname}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.licensePlate}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.brand}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.model}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.color}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.year}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.milage.toLocaleString()}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {moment.utc(r?.soldAt).format("DD/MM/YYYY")}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <RememberDialog
        open={showRemember}
        closeDialog={() => setShowRemember(false)}
      />
      <Datatable
        {...propsDatatable}
        icon={<img src={vehicle} alt={"Icone Veículo"} />}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        reload={reload}
        addButtonUrl={userHasAccess ? "/admin/veiculos/adicionar" : ""}
        checkRemember={checkRemember}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(VehicleListing);
