import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles/";
import Icon from "@material-ui/core/Icon/";
import IconButton from "@material-ui/core/IconButton/";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import ConfirmChangeStatusDialog from "./ConfirmChangeStatusDialog";
import ConfirmChangeKinship from "./ConfirmChangeKinship";
import ConfirmChangeInputs from "./ConfirmChangeInputs";
import AddServices from "./AddServices";
import toggleOnIcon from "../../assets/icons/icon-toggle-on.svg";
import toggleOffIcon from "../../assets/icons/icon-toggle-off.svg";
import { Refresh, Backspace, PictureAsPdf, ImportantDevices } from "@material-ui/icons";
import { BiLinkExternal } from "react-icons/bi";
import { MdMoneyOff } from "react-icons/md";
import { MdOutlineEditOff } from 'react-icons/md';
import { TbTrashOff } from 'react-icons/tb';
import { useHistory } from "react-router-dom";
import {
  DeleteIcon,
  EditIcon,
  MailIcon,
  PlayIcon,
  StopIcon
} from "../../components/IconsSVG";
import { ReactComponent as AddIcon } from "../../assets/icons/Add.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/Download.svg";
import { ReactComponent as PrintIcon } from "../../assets/icons/Print.svg";
import {
  TooltipBox,
  TooltipCard,
  TooltipText
} from "../../pages/admin/components/AdminTitle/styles";
import * as categoryDuck from "../../store/ducks/category.duck";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";
import { TailSpin } from "react-loader-spinner";
import store from "../../store/store";
import { deleteCategory } from "../../services/modules/CategoryApiService";

//
const useStyles = makeStyles(() => ({
  button: {
    margin: "0 2px",
    "& svg": {
      fill: "#807e80"
    },
    "& .fail svg": {
      fill: `${globalColors.alertColor} !important`
    },
    "& .success svg": {
      fill: `${globalColors.successColor} !important`
    },
    "&.Mui-disabled": {
      "& svg": {
        fill: `#A1A1A1 !important`
      }
    },
    "&:hover": {
      "& svg": {
        fill: globalColors.primaryColor2
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
}));

const CrudActions = ({
  actions,
  onConfirmDelete,
  onConfirmClear,
  onConfirmRevert,
  onConfirmChangeStatus,
  onConfirmChangeKinship,
  onEditDiscount,
  module,
  path,
  id,
  uId,
  obj,
  onLink,
  onViewDetails,
  onViewCertified,
  onConfirmResendEmail,
  onConfirmPlay,
  onConfirmStop,
  onConfirmAddServices,
  onConfirmRefund,
  handleEdit,
  pageOrigin,
  setReload,
  onConfirmPrint,
  onConfirmDownload,
  onConfirmChangeAttorney,
  handleDeleteAttorney,
  addMessage,
  titleMessageDelete,
  handleModalEditPower,
  handleModalEditMaintenance,
  handleModalEditRefuelling,
  handleModalEditAreaPool,
  disableActions = false,
  setLoading = () => {},
  reload
}) => {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmChangeKinship, setConfirmChangeKinship] = useState(false);
  const [confirmClear, setConfirmClear] = useState(false);
  const [confirmRevert, setConfirmRevert] = useState(false);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const [confirmPlay, setConfirmPlay] = useState(false);
  const [confirmRefund, setConfirmRefund] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [confirmPrint, setConfirmPrint] = useState(false);
  const [confirmChangeAttorney, setConfirmChangeAttorney] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");

  const history = useHistory();

  function toggleConfirmDialog(state) {
    setConfirmDelete(state);
  }

  function toogleChangeKinship(state) {
    setConfirmChangeKinship(state);
  }

  function toogleChangeAttorney(state) {
    setConfirmChangeAttorney(state);
  }

  function toggleConfirmRevertDialog(state) {
    setConfirmRevert(state);
  }

  function toggleConfirmClearDialog(state) {
    setConfirmClear(state);
  }

  function toggleChangeStatusDialog(state) {
    setConfirmChangeStatus(state);
  }

  function togglePlayDialog(state) {
    setConfirmPlay(state);
  }

  function toggleResendDialog(state) {
    setConfirmResend(state);
  }

  function toggleAddDialog(state) {
    setConfirmAdd(state);
  }

  function toggleRefundDialog(state) {
    setConfirmRefund(state);
  }

  function toggleDownloadDialog(state) {
    setConfirmDownload(state);
  }

  function togglePrintDialog(state) {
    setConfirmPrint(state);
  }

  function handleConfirmDelete(id, { quantity }) {
    toggleConfirmDialog(false);

    onConfirmDelete(id, quantity);
  }

  async function handleConfirmDeleteCategory(obj) {
    setLoading(true)
    await deleteCategory({
      category: obj.category.toLowerCase(),
      subcategory: obj.subcategory.toLowerCase(),
      classification: obj.type.toLowerCase(),
      detail: obj.description.toLowerCase(),
      idCompany: obj.idCompany
    })

    if (reload) reload()
  }

  function handleChangeKinship(obj) {
    toogleChangeKinship(false);
    onConfirmChangeKinship(obj);
  }

  function handleChangeAttorney(obj) {
    toogleChangeAttorney(false);
    onConfirmChangeAttorney(obj);
  }

  function handleConfirmClear(id) {
    toggleConfirmClearDialog(false);
    onConfirmClear(id);
  }

  function handleConfirmRevert(id) {
    toggleConfirmRevertDialog(false);
    onConfirmRevert(id);
  }

  function handleChangeStatus(id) {
    toggleChangeStatusDialog(false);
    onConfirmChangeStatus(id);
  }

  function handleRefund(obj) {
    toggleRefundDialog(false);
    onConfirmRefund(obj);
  }

  function handlePlay(obj) {
    togglePlayDialog(false);
    onConfirmPlay(obj);
  }

  function handleViewDetails(obj) {
    onViewDetails(obj);
  }

  function handleEditObj(obj) {
    handleEdit(obj);
  }

  function handleViewCertified(obj) {
    onViewCertified(obj);
  }

  function handleLink(obj) {
    onLink(obj);
  }

  function handleResendMail(obj) {
    toggleResendDialog(false);
    setSendingEmail(true)
    onConfirmResendEmail(obj)
      .then(() => {
        setSendingEmail(false)
        setEmailStatus('success')
      })
      .catch(() => {
        setSendingEmail(false)
        setEmailStatus('fail')
      })
  }

  function handleAddServices(obj) {
    toggleAddDialog(false);
    onConfirmAddServices(obj);
  }

  function handleDownloadFile(obj) {
    toggleDownloadDialog(false);
    onConfirmDownload(obj);
  }

  function handlePrintFile(obj) {
    togglePrintDialog(false);
    onConfirmPrint(obj);
  }

  function handleEditCategory(obj) {
    store.dispatch(categoryDuck.actions.setCategory(obj));
    history.push(`/admin/${path ?? module}/editar${pageOrigin ? "?origin=" + pageOrigin : ""}`)
  }

  function handleEditSubcategory(obj) {
    store.dispatch(categoryDuck.actions.setCategory(obj));
    history.push(`/admin/${path ?? module}/editar${pageOrigin ? "?origin=" + pageOrigin : ""}`)
  }

  return (
    <div
      className={classes.div}
      style={{
        justifyContent:
          onConfirmChangeKinship || onConfirmDownload ? "right" : ""
      }}
    >
      {actions && actions.includes("deleteCategory") ? (
        <>
          <ConfirmDeleteDialog
            open={confirmDelete}
            closeDialog={() => toggleConfirmDialog(false)}
            message={addMessage}
            title={titleMessageDelete}
            confirm={async () => {
              toggleConfirmDialog(false)
              await handleConfirmDeleteCategory(obj);
            }}
          />

          <IconButton
            size="small"
            disabled={disableActions}
            className={classes.button}
            title="Excluir registro"
            onClick={() => toggleConfirmDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : actions && actions.includes("delete") && (
        <>
          <ConfirmDeleteDialog
            open={confirmDelete}
            closeDialog={() => toggleConfirmDialog(false)}
            message={addMessage}
            title={titleMessageDelete}
            confirm={() => {
              handleConfirmDelete(id || uId, obj);
            }}
          />

          <IconButton
            size="small"
            disabled={disableActions}
            className={classes.button}
            title="Excluir registro"
            onClick={() => toggleConfirmDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("editSubcategory") ? (
        <IconButton
          onClick={() => handleEditSubcategory(obj)}
          size="small"
          className={classes.button}
          title="Editar registro"
        >
          <EditIcon />
        </IconButton>
      ) : actions && actions.includes("editCategory") ? (
        <IconButton
          onClick={() => handleEditCategory(obj)}
          size="small"
          className={classes.button}
          title="Editar registro"
        >
          <EditIcon />
        </IconButton>
      ) : actions && actions.includes("editDiscount") ? (
        id ? (
          <IconButton
            onClick={() => onEditDiscount(obj)}
            size="small"
            className={classes.button}
            title="Editar desconto"
          >
            <EditIcon />
          </IconButton>
        ) : null
      ) : actions && actions.includes("edit") && (
        <IconButton
          size="small"
          className={classes.button}
          disabled={disableActions}
          title="Editar registro"
        >
          <Link to={`/admin/${path ?? module}/${id}/editar${pageOrigin ? "?origin=" + pageOrigin : ""}`}>
            <EditIcon />
          </Link>
        </IconButton>
      )}

      {actions && actions.includes("resend") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmResend}
            closeDialog={() => setConfirmResend(false)}
            confirm={() => handleResendMail(obj)}
            message="Deseja reenviar um email de ativação para o usuário selecionado?"
            title="Confirmação"
          />
          <IconButton
            size="small"
            color="inherit"
            className={classes.button}
            title="Reenviar email de confirmação"
            onClick={() => {
              toggleResendDialog(true)
            }}
          >
            {sendingEmail && (
              <TailSpin
                height="15"
                width="15"
                color={globalColors.primaryColor1}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            )}
            {!sendingEmail && (
              <div className={emailStatus}>
                <MailIcon />
              </div>
            )}
          </IconButton>
        </>
      )}

      {actions && actions.includes("toggle") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleChangeStatus(obj)}
            message={`Deseja ${obj && obj.active ? "inativar" : "ativar"
              } o registro selecionado?`}
            title="Confirmação"
          />

          {obj && obj.active ? (
            <IconButton
              size="small"
              color="secondary"
              className={classes.button}
              title="Inativar"
              onClick={() => {
                toggleChangeStatusDialog(true)
              }}
            >
              <img src={toggleOnIcon} alt="Inativar" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              className={classes.button}
              title="Ativar"
              onClick={() => toggleChangeStatusDialog(true)}
            >
              <img src={toggleOffIcon} alt="Ativar" />
            </IconButton>
          )}
        </>
      )}

      {actions && actions.includes("download") && (
        <>
          <IconButton
            size="small"
            className={classes.button}
            title="Baixar arquivo"
            onClick={() => toggleDownloadDialog(true)}
          >
            <DownloadIcon style={{ fill: "transparent" }} />
          </IconButton>
        </>
      )}

      {actions && actions.includes("print") && (
        <>
          <IconButton
            size="small"
            className={classes.button}
            title="Imprimir arquivo"
            onClick={() => togglePrintDialog(true)}
          >
            <PrintIcon style={{ fill: "transparent" }} />
          </IconButton>
        </>
      )}

      {actions && actions.includes("edit-beneficiary") && (
        <>
          <ConfirmChangeKinship
            open={confirmChangeKinship}
            data={obj}
            title={"Deseja editar parentesco?"}
            closeDialog={() => toogleChangeKinship(false)}
            confirm={() => handleChangeKinship(obj)}
            setReload={setReload}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Editar registro"
            onClick={() => toogleChangeKinship(true)}
          >
            <EditIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("edit-contract-graveyard") && (
        <>
          <ConfirmChangeInputs
            open={confirmChangeAttorney}
            data={obj}
            title={"Edição de produrador"}
            closeDialog={() => toogleChangeAttorney(false)}
            confirm={() => handleChangeAttorney(true)}
            setReload={setReload}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Editar procuradores"
            onClick={() => toogleChangeAttorney(true)}
          >
            <EditIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("revert") && (
        <>
          <ConfirmDeleteDialog
            open={confirmRevert}
            title="Confirmação"
            message="Deseja realmente reverter a linha de registro?"
            closeDialog={() => toggleConfirmRevertDialog(false)}
            confirm={() => handleConfirmRevert(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Reverter registro original da linha"
            onClick={() => toggleConfirmRevertDialog(true)}
          >
            <Refresh fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("clear") && (
        <>
          <ConfirmDeleteDialog
            open={confirmClear}
            title="Confirmação"
            message="Deseja realmente limpar a linha de registro?"
            closeDialog={() => toggleConfirmClearDialog(false)}
            confirm={() => handleConfirmClear(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Limpar registros da linha"
            onClick={() => toggleConfirmClearDialog(true)}
          >
            <Backspace fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("play") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmPlay}
            confirm={() => handlePlay(obj)}
            closeDialog={() => togglePlayDialog(false)}
            message="Deseja iniciar um novo timer, contendo as mesmas informações de Cliente, Projeto, Atividade e Demanda?"
            hideTitle
          />
          <IconButton
            color="primary"
            size="small"
            title="Iniciar timer"
            className={classes.button}
            onClick={() => togglePlayDialog(true)}
          >
            <PlayIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("stop") && (
        <IconButton
          color="primary"
          size="small"
          title="Iniciar timer"
          className={classes.button}
          onClick={() => onConfirmStop(obj)}
        >
          <StopIcon />
        </IconButton>
      )}

      {actions && actions.includes("edit-form") && (
        <IconButton
          onClick={() => handleEditObj(obj)}
          size="small"
          className={classes.button}
          title="Editar registro"
        >
          <EditIcon />
        </IconButton>
      )}

      {actions && actions.includes("delete-beneficiary") && (
        <>
          <ConfirmDeleteDialog
            open={confirmDelete}
            closeDialog={() => toggleConfirmDialog(false)}
            confirm={() => handleConfirmDelete(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Excluir registro"
            onClick={() => toggleConfirmDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("add") && (
        // <TooltipCard>
        //   <TooltipText>
        <>
          <AddServices
            open={confirmAdd}
            closeDialog={() => setConfirmAdd(false)}
            confirm={() => handleAddServices(obj)}
            message="Os serviços adicionais será adicionado em breve..."
            title="Selecione para adicionar serviços extras:"
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Adicionar registro"
            onClick={() => toggleAddDialog(true)}
          >
            <AddIcon style={{ fill: "#fff" }} />
          </IconButton>
        </>
        //   </TooltipText>

        //   <TooltipBox>
        //     <div>Incluir serviços adicionais</div>
        //   </TooltipBox>
        // </TooltipCard>
      )}

      {actions && actions.includes("show") && (
        <IconButton
          // color='primary'
          onClick={() => handleViewDetails(obj)}
          size="small"
          className={classes.button}
          title="Visualizar detalhes"
        >
          <Icon fontSize="small">visibility</Icon>
        </IconButton>
      )}

      {actions && actions.includes("certified") && (
        <IconButton
          onClick={() => handleViewCertified(obj)}
          size="small"
          className={classes.button}
          title="Baixar certificado em PDF"
        >
          <PictureAsPdf fontSize="small" />
        </IconButton>
      )}

      {actions && actions.includes("link-campaign") && (
        <IconButton
          onClick={() => handleLink(obj)}
          size="small"
          className={classes.button}
          title="Ir para campanha"
        >
          <BiLinkExternal size="20px" />
        </IconButton>
      )}

      {actions && actions.includes("refund") && (
        <>
          <ConfirmDeleteDialog
            open={confirmRefund}
            title="Confirmação"
            message="Deseja realmente realizar o estorno desta doação?"
            closeDialog={() => toggleRefundDialog(false)}
            confirm={() => handleRefund(obj)}
          />

          <IconButton
            onClick={() => toggleRefundDialog(true)}
            size="small"
            className={classes.button}
            title="Estornar doação"
          >
            <MdMoneyOff size="20px" />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default CrudActions;
