import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import icon from "../../../../assets/icons/streamline_money.svg";
import Loading from "../../../home/components/Loading";
import styles from "./styles.module.scss";
import FormActions from "../../components/FormActions";
import Button from "../../../../components/Button";
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";

import { iconButtonStyle, StyledTableCell, StyledTableRow } from "../../../../partials/datatable/styles";
import { connect } from "react-redux";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { FieldArray, Formik } from "formik";
import { FormikInput } from "../../../../components/FormikInput/Input";
import { USER_TYPES } from "../../../../enum/UserTypes";
import { useHistory, useParams } from "react-router-dom";
import { createReceiptWay, getReceiptWayById, updateReceiptWay } from "../../../../services/modules/ReceiptWayApiService";
import { DeleteIcon } from "../../../../components/IconsSVG";
import { schema } from './validator';
import { FormAlert } from "../../../../components/Alerts/alert";


const PaymentWaysTable = ({ rows, remove }) => {
  const classes = iconButtonStyle();

  const cellStyle = {
    padding: "5px 10px",
  };
  const generateRow = (row, i) => (
    <StyledTableRow
      hover
    >
      <StyledTableCell
        style={cellStyle}
        align="left"
      >
        <IconButton
          size="small"
          title="Excluir registro"
          onClick={() => remove(i)}
          className={classes.button}
        >
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell
        style={cellStyle}
      >
        {row.name}
      </StyledTableCell>
    </StyledTableRow>
  );

  const emptyRow = (
    <StyledTableRow
      hover
    >
      <StyledTableCell
        scope="row"
        style={cellStyle}
        colSpan={2}
      >
        Nenhum tipo de pagamento registrado
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <Table>
      <TableHead>
        <StyledTableRow
          variant="head"
        >
          <StyledTableCell
            style={cellStyle}
            align="left"
            width="100px"
          >
            Ações
          </StyledTableCell>
          <StyledTableCell
            style={cellStyle}
          >
            Tipos de pagamento
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {rows.length === 0 ? emptyRow : rows.map(generateRow)}
      </TableBody>
    </Table>
  )
}

const ReceiptWayForm = ({ idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.RECEIPTWAY_WRITE.ENGLISH);
  const rootPage = "/admin/formas-recebimento";
  const { receiptWayId } = useParams();

  window.setPageTitle(
    `${!!receiptWayId ? "Edição" : "Cadastro"} de formas de recebimento`
  );

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    paymentWays: [],
    tempPaymentName: ""
  });

  const get = async id => {
    try {
      const receiptWay = await getReceiptWayById(id);

      setInitialValues({
        name: receiptWay.name,
        paymentWays: receiptWay.paymentWays,
        tempPaymentName: ""
      });
    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  }

  const addPaymentWay = (setFieldValue, push, tempPaymentName) => {
    if(tempPaymentName.trim()) {
      push({ name: tempPaymentName});
      setFieldValue('tempPaymentName', "");
    }
  }

  const handleSubmit = async values => {
    setLoading(true);

    const payload = {
      idCompany: user.idCompany ?? idCompany,
      name: values.name,
      oldName: initialValues.name,
      paymentWays: values.paymentWays
    };

    try {
      if (!!receiptWayId) {
        await updateReceiptWay(receiptWayId, payload);
      } else {
        await createReceiptWay(payload);
      }

      setLoading(false);

      await Swal.fire({
        title: "Sucesso",
        text: `Forma de pagamento ${!!receiptWayId ? "atualizada" : "criada"
          } com sucesso`,
        icon: "success"
      });

      history.push(rootPage);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        title: "Erro",
        text: err?.response?.data?.messages[0],
        icon: "error"
      });
    }
  };

  const handlePaymentKeyDown = (event, setFieldValue, push, tempPaymentName) => {
    if(event.key === 'Enter') {
      addPaymentWay(setFieldValue, push, tempPaymentName);
    }
  }

  useEffect(() => {
    if(receiptWayId) {
      get(receiptWayId);
    }
  }, [receiptWayId]);

  const handleRemoveItem = (validateForm, remove, index) => {
    remove(index);
    setTimeout(() => {
      validateForm();
    }, 200);
  }

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={icon} alt={"Formas de recebimento"} />
        Formas de Recebimento
      </h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        isInitialValid={!!receiptWayId}
        validationSchema={schema}
      >
        {({ setFieldValue, handleSubmit, validateForm, isValid, values }) => (
          <>
          <form
            className={styles.form}
            autoComplete="off"
            onSubmit={handleSubmit}
            id="receiptWays"
          >
            <div className={styles.containerInputs}>
              <FormikInput
                type="text"
                name="name"
                placeholder="Digite o nome da forma de recebimento"
                setFieldValue={setFieldValue}
                label="Nome"
                required
              />
            </div>
            <FieldArray name="paymentWays">
              {({ insert, remove, push }) => (
                <>
                  <div className={styles.paymentWaysTable}>
                    <div className={styles.containerPaymentInputs}>
                      <div className={styles.paymentName}>
                        <FormikInput
                          type="text"
                          name="tempPaymentName"
                          placeholder="Digite o novo tipo de pagamento"
                          label="Tipo de pagamento"
                          onKeyDown={e => handlePaymentKeyDown(e, setFieldValue, push, values.tempPaymentName)}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className={styles.addButton}>
                        <Button
                          type="button"
                          borderRadius="4px"
                          text="ADICIONAR"
                          color="white"
                          width="150px"
                          fontSize="15px"
                          onClick={() => addPaymentWay(setFieldValue, push, values.tempPaymentName)}
                        />
                      </div>
                    </div>
                    <PaymentWaysTable
                      remove={(i) => handleRemoveItem(validateForm, remove, i)}
                      rows={values.paymentWays}
                    />
                  </div>
                </>
              )}
            </FieldArray>
            <FormAlert />
          </form>
          <FormActions
            module={"formas-recebimento"}
            hideCancel={true}
            formIsValid={isValid}
            onSubmit={handleSubmit}
          />
          </>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany,
  user: auth.user
});

export default connect(mapStateToProps)(ReceiptWayForm);
