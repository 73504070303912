import React from "react";

import { useField } from "formik";
import { applyMask } from "../../utils/applyMask";

import InputForm from "../InputForm";

export const FormikInput = ({
  name,
  label,
  onBlur,
  menuIsOpen,
  formatter,
  value,
  formatterOptions,
  onChange,
  isCreatable,
  isMulti,
  isSelect,
  type,
  placeholder,
  mask,
  required,
  onKeyDown,
  ref,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const role = touched && error ? "notOk" : "";

  const handleChangeMasked = event => {
    if (isSelect || isCreatable) {
      try {
        props.setFieldValue(name, event);
        if (!!onChange) onChange(event);
      } catch (error) {}
      return;
    }

    if (formatter && !mask) {
      event.target.value = formatter(event.target.value, formatterOptions);
    }

    if (!formatter && mask) {
      event.target.value = applyMask(event.target.value, mask);
    }

    if (!!onChange) onChange(event);
    field.onChange(event);
  };

  return (
    <InputForm
      id={name}
      type={type}
      name={name}
      placeholder={placeholder}
      error={touched && error ? error : ""}
      required={required}
      role={role}
      label={label}
      defaultEvent={true}
      isSelect={isSelect}
      isCreateable={isCreatable}
      isMulti={isMulti}
      {...props}
      {...field}
      onBlur={(event) => {
        if (Object.keys(props).find(key => key === 'setFieldTouched')) {
          props.setFieldTouched(name);
        }
        if (onBlur) {
          onBlur(event)
        }
      }}
      onKeyDown={onKeyDown}
      onChange={handleChangeMasked}
      ref={ref}
    />
  );
};
