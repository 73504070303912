import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { AppAPIService } from "../../../../services/AppAPIService";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import shoppingCart from "../../../../assets/icons/ShoppingCart.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";

const ListingProducts = props => {
  let api = AppAPIService.getInstance();

  UseProtectedPage.accessToFormOrListing(USER_TYPES.PRODUCTS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.PRODUCTS_WRITE.ENGLISH
  );

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  window.setPageTitle("Listagem de produtos - Admin");

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        method: "DELETE",
        url: `/product/${id}`
      });

      setReload(!reload);

      Swal.fire({
        title: "Sucesso!",
        text: "Produto deletado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar produto.", "error");
    }

    setLoading(false);
  };

  const handleConfirmChangeStatus = async obj => {
    try {
      await api.makeHttpRequest({
        method: "PATCH",
        url: `/product/${obj.id}/state`,
        data: {
          status: !obj.active
        }
      });

      setReload(!reload);

      const userStatus = !obj.active ? "ativado" : "desativado";

      Swal.fire({
        title: "Sucesso!",
        text: `Produto ${userStatus} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    }
  };

  const headRows = [
    { column: "code", label: "Código do Produto" },
    { column: "name", label: "Nome do Produto" },
    { column: "measure.name", label: "Unidade de Medida" },
    { column: "minimumQuantity", label: "Estoque Mínimo" },
    { column: "inventory", label: "Estoque Inicial" },
    { column: "obs", label: "Observações" }
  ];

  if (userHasAccess) {
    headRows.unshift({ label: "Ações" });
  }

  const formatRow = r => {
    let crudActions = ["delete", "edit", "toggle"];

    return (
      <StyledTableRow key={r.id} hover tabIndex={-1}>
        {userHasAccess ? (
          <StyledTableCell
            scope="row"
            style={{ padding: "0px 10px", width: "48px" }}
          >
            <CrudActions
              actions={crudActions}
              module="produtos"
              onConfirmChangeStatus={handleConfirmChangeStatus}
              onConfirmDelete={handleConfirmDelete}
              id={r.id}
              obj={r}
              titleMessageDelete={"Excluir Produto"}
              addMessage={"Você tem certeza que deseja excluir este produto? Essa ação não poderá ser desfeita"}
            />
          </StyledTableCell>
        ) : null}
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.code}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.measure.name}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.minimumQuantity}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.inventory}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.obs}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        title="Produtos"
        icon={<img src={shoppingCart} alt={"Produto"} />}
        endpoint="/product"
        className="h-100"
        headRows={headRows}
        formatRow={formatRow}
        rows={[]}
        reload={reload}
        placeholderSearch="Busque por nome ou código"
        width={props.width}
        tooltip="Cadastrar nova pessoa"
        hiddenReload
        addButtonUrl={userHasAccess ? "/admin/produtos/adicionar" : ""}
        hiddenKanbanContent
        titleButon={"Novo cadastro"}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(ListingProducts);
