import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";

import { Formik } from "formik";

import { schema } from "./discounts.validator";
import { FormikInput } from "../../../../components/FormikInput/Input";
import Swal from "sweetalert2";

import { connect } from "react-redux";
import { useMutation } from "react-query";
import { Button } from "react-bootstrap";
import DatatableForm from "../../../../partials/dataTableForm/DatatableForm";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import CrudActions from "../../../../partials/datatable/CrudActions";
import {
  createFee,
  updateFee,
  deleteFee
} from "../../../../services/modules/FeeApiService";
import { getEffectiveValue } from "../../../../utils/getEffectiveValue";

const FeeForm = ({ idCompany, user, setLoading, ...props }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.DISCOUNT_WRITE.ENGLISH);

  window.setPageTitle(`Taxas`);

  const [feeId, setFeeId] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (feeId) {
      /// Get fee
    }
  }, [feeId]);

  const [initialValues, setInitialValues] = useState({
    description: "",
    value: undefined
  });

  const handleSubmit = async (values, { setFieldValue, resetForm }) => {
    const payload = {
      idCompany: user.idCompany ?? idCompany,
      description: values.description,
      isPercentual: true,
      value: Number(Number(values.value.replace("%", "")).toFixed(6))
    };

    setLoading(true);

    try {
      if (!!feeId) {
        await updateFee(feeId, payload);
      } else {
        await createFee(payload);
      }

      await Swal.fire({
        title: "Sucesso",
        text: `Taxa ${!!feeId ? "atualizado" : "criado"} com sucesso`,
        icon: "success"
      });
    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    } finally {
      setFeeId(0);
      setFieldValue("value", "");
      setFieldValue("description", "");
      setInitialValues({
        description: "",
        value: undefined
      });
      resetForm();

      setReload(!reload);
      setLoading(false);
    }
  };

  const { mutate: confirmFeeDelete } = useMutation(async (id, quantity) => {
    try {
      setLoading(true);
      await deleteFee(id);

      setReload(!reload);

      Swal.fire({
        title: "Sucesso!",
        text: "Taxa removida com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Erro ao remover taxa. Tente novamente em alguns instantes.",
        icon: "error",
        cancelButtonText: "sair"
      });
    } finally {
      setLoading(false);
    }
  });

  const handleEditFee = fee => {
    setFeeId(fee.id);

    const feeValue = getEffectiveValue(fee.alterationHistoric);

    setInitialValues({
      description: fee.description,
      value: feeValue,
      isPercentual: true,
      idCompany: user.idCompany ?? idCompany
    });
  };

  const rowsFormat = r => {
    let crudActions = ["delete", "editDiscount"];

    return (
      <StyledTableRow
        key={r.id}
        bg={r.id === feeId ? "rgba(245, 238, 53, 0.2)" : "transparent"}
        hover
        tabIndex={-1}
      >
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            disableActions={false}
            actions={crudActions}
            module="fee"
            onConfirmDelete={confirmFeeDelete}
            onEditDiscount={handleEditFee}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Taxa"}
            addMessage={"Você tem certeza que deseja excluir essa taxa? Essa ação não poderá ser desfeita"}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.description}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {getEffectiveValue(r?.alterationHistoric)}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const formatMask = element => {
    let valueToReturn = 0.00;

    const value = removeInvalidCharactersFromNumber(element)

    if (alreadyHasFiveDecimalPlaces(element)) {
      valueToReturn = toFixedMask(value)
    } else {
      valueToReturn = value;
    }

    return `${valueToReturn > 100 ? 100 : valueToReturn}%`;
  }

  const toFixedMask = value => {
    return `${value.split(".")[0]}.${value.split(".")[1].slice(0, 5)}`;
  }

  const alreadyHasFiveDecimalPlaces = element => {
    return element.split(".")[1]?.length >= 5;
  }

  const removeInvalidCharactersFromNumber = element => {
    return element.replace("%", "").replace(/[^0-9,.]/g, "").replace(",", ".");
  }

  const isInvalidInput = (event, key) => {
    return alreadyHasComma(event, key) || insertingCommaWithoutValue(event, key)
  }

  const alreadyHasComma = (event, key) => {
    return event.value?.includes(".") && (key === "," || key === ".")
  }

  const insertingCommaWithoutValue = (event, key) => {
    return (event.value === undefined || event.value?.length === 0) && (key === "," || key === ".")
  }

  const thereIsNoValue = fields => {
    return fields.value.length === 2 && fields.value[1] === "%"
  }

  const handleValueKeyDown = (event, fields, setFieldValue) => {
    if (isInvalidInput(fields, event.key)) {
      event.preventDefault();
      return;
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      fields?.value?.length > 0
    ) {
      if (thereIsNoValue(fields)) {
        setFieldValue("value", "");
        return;
      }
      setFieldValue("value", `${fields?.value.slice(0, -2)}%`);
    }
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        isInitialValid={!!feeId}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, handleSubmit, isValid, values }) => (
          <form
            className={styles.form}
            autoComplete="off"
            onSubmit={handleSubmit}
            id="discounts"
          >
            <div className={styles.containerInputs}>
              <FormikInput
                type="text"
                name="description"
                placeholder="Digite a descrição da taxa"
                setFieldValue={setFieldValue}
                label="Descrição da Taxa"
                required
              />
              <FormikInput
                type="text"
                name="value"
                onKeyDown={event =>
                  handleValueKeyDown(event, values, setFieldValue)
                }
                setFieldValue={setFieldValue}
                formatter={e => {
                  return formatMask(e);
                }}
                placeholder="Digite o percentural da taxa"
                label="Percentual da Taxa"
                required
              />

              <Button
                type="submit"
                className={
                  isValid ? styles.discountButtonValid : styles.discountButton
                }
              >
                Salvar Taxa
              </Button>
            </div>

            <DatatableForm
              title="Abastecimento"
              endpoint={`/fee`}
              className="h-100"
              headRows={feeLabels}
              formatRow={rowsFormat}
              rows={[]}
              reload={reload}
              orderBy="id"
              orderDirection="desc"
              width={200}
              hiddenReload
              module="maquinario"
              hiddenKanbanContent
            />
          </form>
        )}
      </Formik>
    </>
  );
};

const feeLabels = [
  { label: "Ações" },
  { column: null, label: "Descrição da taxa" },
  { column: null, label: "Percentual da taxa" }
];

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(FeeForm);
