
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions/';
import DialogContent from '@material-ui/core/DialogContent/';
import DialogContentText from '@material-ui/core/DialogContentText/';
import DialogTitle from '@material-ui/core/DialogTitle/';
import { Button, StyledDialog } from './components/Dialog/style';
import bellRing from "../../assets/icons/bellRing.svg";
import closeIcon from "../../assets/icons/icon-close.svg";
import { IconButton } from '@material-ui/core';


export default function RememberDialog({ open, closeDialog, confirm }) {
  return (
    <div>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "20px 20px 0 0", display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={closeDialog}>
            <img src={closeIcon} alt="Lembrete" width={12} />
          </IconButton>
        </div>

        <DialogTitle id="alert-dialog-title" style={{ margin: 0, padding: 0, fontWeight: 700 }}>
          <img src={bellRing} alt="Lembrete" width={25} />
          <br />

          <span style={{ fontWeight: 'bold !important' }}>
            Novos Lembretes
          </span>
        </DialogTitle>
        <DialogContent style={{ margin: "0 20px 20px" }}>
          <DialogContentText id="alert-dialog-description">
            Atenção, você possui novos lembretes. Confira quais são eles <b>na coluna de lembretes. Para mais detalhes passe o mouse sobre o icone da coluna.</b>
          </DialogContentText>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
