import * as yup from "yup";
import { pt } from "yup-locale-pt";

yup.setLocale(pt);

export const schema = yup.object().shape({
  name: yup
    .string()
    .label("Empresa")
    .min(3)
    .max(100)
    .required(),
  responsable: yup
    .string()
    .label("Responsável")
    .required()
    .min(3)
    .max(100),
  phone: yup
    .string()
    .label("Telefone")
    .optional()
    .nullable()
    .matches(/^\([1-9]{2}\) 9?[0-9]{4}\-[0-9]{4}$/, {
      message: "Telefone inválido",
      excludeEmptyString: true
    }),
  email: yup
    .string()
    .label("E-mail")
    .optional()
    .nullable()
    .email(),
  totalBought: yup
    .number()
    .label("Total Comprado")
    .typeError("Total Comprado deve ser um número")
    .required(),
  discountValue: yup
    .string()
    .label("Desconto")
    .typeError("Desconto deve ser um número")
    .test(
      "validate-discount-range",
      "Desconto maior que 100%",
      (value) => value ? Number(value.replace("%", "").replace(/[^0-9.]/, "")) <= 100 : true
    ),
  address: yup
    .string()
    .label("Endereço")
    .optional()
    .nullable(),
  obs: yup
    .string()
    .label("Observações")
    .optional()
});
