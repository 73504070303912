import React, { useCallback, useState } from "react";
import Select from "react-select";
import styles from "./styles.module.scss";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import Creatable from "react-select/creatable";


const InputForm = ({
  id,
  type,
  ref,
  name,
  placeholder,
  onChange,
  value,
  label,
  error,
  role,
  isSelect,
  options,
  isDisabled,
  defaultValue,
  isMulti,
  isReadonly,
  addProductSelect,
  isCreateable,
  required,
  newOption,
  onBlur,
  onInputChange,
  onKeyDown,
  menuIsOpen,
  components,
  defaultEvent = false,
  isClearable,
  isHeaderButton,
  ...props
}) => {
  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      ...(props.height && {
        height: props.height
      }),
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Cera Pro Regular",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      backgroundColor: isSelected ? `${globalColors.primaryColor2}` : "#fff",

      "&:hover": {
        backgroundColor: `${globalColors.primaryColor2}`,
        color: "#fff",
        cursor: "pointer"
      }
    }),
    singleValue: styles => ({
      ...styles,
      color: "black",
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Cera Pro Regular"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontWeight: 400,
      color: "rgba(193, 193, 193, 1)",
      whiteSpace: "nowrap",
      fontFamily: "Cera Pro Regular"
    }),
    multiValue: (styles, data) => ({
      ...styles,
      backgroundColor: "#F9F7F6",
      color: "#52525B",
      borderRadius: "4px",
      padding: "4px",
      fontSize: "14px",
      alignItems: "center"
    }),
    multiValueRemove: styles => ({
      ...styles,
      height: "10%",
      color: "#026FBB",
      border: "1px solid #026FBB",
      ":hover": {
        backgroundColor: "#026FBB",
        color: "white"
      }
    }),
    menuList: styles => ({
      ...styles,
      height: addProductSelect ? "auto" : "",
      maxHeight: addProductSelect ? "100px" : "300px"
    })
  };

  const [showPassword, setShowPassword] = useState(false);

  const renderEyeIcon = useCallback(
    show => {
      return show ? (
        <span onClick={() => setShowPassword(!showPassword)}>
          <AiOutlineEye />
        </span>
      ) : (
        <span onClick={() => setShowPassword(!showPassword)}>
          <AiOutlineEyeInvisible />
        </span>
      );
    },
    [showPassword]
  );

  const handleSpaceFirstChar = (value, callBack) => {
    const spaceFirst = /^( )/.test(value);

    if (spaceFirst) return;

    return callBack(value, name);
  };

  return (
    <div className={`${isHeaderButton ? styles.headerButton : styles.inputContainer}`}>
      {isSelect ? (
        <div
          className={`
          ${styles.divInput} ${isDisabled ? styles.isDisabled : ""} `}
          role={role}
        >
          <label htmlFor={name} style={{ display: "flex", alignItems: "center" }}>
            {label}{required && <span>&nbsp; *</span>}
          </label>
          <Select
            id={id}
            ref={ref}
            defaultEvent
            isClearable={isClearable ?? true}
            isDisabled={isDisabled}
            styles={selectStyle}
            options={options}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            isMulti={isMulti}
            name={name}
            noOptionsMessage={({ value }) =>
              !value && "Resultados não encontrados"
            }
          />
        </div>
      ) : isCreateable ? (
        <div className={`${styles.inputContainer} `}>
          <div
            className={`
          ${styles.divInput} ${isDisabled ? styles.isDisabled : ""} `}
            role={role}
          >
            <label htmlFor={name} style={{ display: "flex", alignItems: "center" }}>
              {label}{required && <span>&nbsp; *</span>}
            </label>
            {
              isMulti ?
                <Creatable
                  id={id}
                  ref={ref}
                  isClearable={isClearable ?? true}
                  isMulti={isMulti}
                  isDisabled={isDisabled}
                  styles={selectStyle}
                  options={options}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  menuIsOpen={menuIsOpen}
                  defaultValue={defaultValue}
                  inputValue={value}
                  value={options}
                  onChange={onChange}
                  onInputChange={onInputChange}
                  onKeyDown={onKeyDown}
                  name={name}
                  components={components}
                  formatCreateLabel={value => `${newOption}: ${value}`}
                  noOptionsMessage={({ value }) =>
                    !value && "Resultados não encontrados"
                  }
                />
                :
                <Creatable
                  id={id}
                  isClearable={isClearable ?? true}
                  ref={ref}
                  isMulti={isMulti}
                  isDisabled={isDisabled}
                  styles={selectStyle}
                  options={options}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  defaultValue={defaultValue}
                  value={value}
                  onChange={onChange}
                  name={name}
                  formatCreateLabel={value => `${newOption}: ${value}`}
                  noOptionsMessage={({ value }) =>
                    !value && "Resultados não encontrados"
                  }
                />
            }
          </div>
        </div>
      ) : (
        <div
          className={`${styles.divInput} ${isDisabled ? styles.isDisabled : ""
            }`}
          role={role}
        >
          <label htmlFor={name} style={{ display: "flex", alignItems: "center" }}>
            {label}{required && <span>&nbsp; *</span>}
          </label>

          <input
            id={id}
            ref={ref}
            onKeyDown={onKeyDown}
            type={
              type !== "password" ? type : showPassword ? type ?? "text" : "password"
            }
            placeholder={placeholder}
            value={value}
            onChange={
              defaultEvent ?
                onChange
                :
                ({ target: { name, value } }) => handleSpaceFirstChar(value, () => onChange(value, name))
            }
            role={error}
            className={styles.input}
            disabled={isDisabled}
            autoComplete="off"
            name={name}
            style={{
              letterSpacing:
                !showPassword && label?.includes("Senha") && value !== ""
                  ? "4px"
                  : "0"
            }}
            readOnly={isReadonly}
            onBlur={onBlur}
          />

          {label?.includes("Senha") && (
            <span className={styles.eyeIcon}>
              {renderEyeIcon(showPassword)}
            </span>
          )}
        </div>
      )}
      {error && <span className={styles.textError}>{error}</span>}
    </div>
  );
};
export default InputForm;
