import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";

import categoryIcon from "../../../../assets/icons/category.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import * as categoryDuck from "../../../../store/ducks/category.duck";
import store from "../../../../store/store";
import ClassificationList from "./ClassificationList";
import { makeStyles } from "@material-ui/core/styles/";
import { StyledTableContent } from "./styles";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import AdminTitle from "../../components/AdminTitle";
import { getCategories } from "../../../../services/modules/CategoryApiService";
import { getFinancialFlowAnalysis } from "../../../../services/modules/CashFlowAnalysisApiService";
import { RiSearchLine } from "react-icons/ri";
import { Col, Form } from "react-bootstrap";
import moment from "moment";
import globalColors from "../../../../../_metronic/_assets/sass/custom/globalColors.scss";

export const enumerateFinancialFlow = (_financialFlowCategories) => {
  if (!_financialFlowCategories)
    return {};
  const categoriesEnumeration = {};
  _financialFlowCategories.forEach((category, categoryIndex) => {
    const categoryNumber = `${categoryIndex + 1}`;
    categoriesEnumeration[`${category.name}`] = categoryNumber

    category.subcategories.forEach((subcategory, subcategoryIndex) => {
      const subcategoryNumber = `${categoryIndex + 1}.${subcategoryIndex + 1}`;
      categoriesEnumeration[`${category.name}.${subcategory.name}`] = subcategoryNumber

      subcategory.groups.forEach((group, groupIndex) => {
        const groupNumber = `${categoryIndex + 1}.${subcategoryIndex + 1}.${groupIndex + 1}`;
        categoriesEnumeration[`${category.name}.${subcategory.name}.${group.name || group}`] = groupNumber;
      });
    });
  });

  return categoriesEnumeration;
}

const CategoryContainer = ({
  user,
  userHasAccess,
  receipts,
  expenses,
  investments,
  idCompany,
  reload,
  filter,
  setFilter,
  categoriesEnumeration,
  setLoading
}) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const useStyles = makeStyles(() => {
    return {
      root: {
        maxHeight: "fit-content",
        display: "flex",
        flexDirection: windowSize.current[0] > 900 ? "row" : "column",
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        justifyContent: "center"
      },
      rows: {
        padding: "0 !important",
        margin: "0 !important"
      },
    };
  });

  const classes = useStyles();
  return (
    <>
      <div
        className={`col-xl-12 h-100`}
        style={{
          minheight: "max-content",
          margintop: "0px",
          padding: "0 0 0 0",
          display: "flex",
          justifycontent: "center"
        }}
      >
        <StyledTableContent style={{ maxHeight: "fit-content" }}>
          <Portlet
            fluidHeight={true}
            style={{ backgroundColor: "#F9F7F6", boxShadow: "none" }}
          >
            <div style={{ "paddingBottom": "10px" }}>
              <AdminTitle
                icon={<img src={categoryIcon} alt={"Icone das categorias"} />}
                title="Plano de Contas"
                width={200}
                addButtonUrl={"/admin/categorias/adicionar"}
                disabled={user?.idProfile !== 1}
                tooltip="Novo Cadastro"
                titleButon="Novo Cadastro"
              />
            </div>
            <PortletBody fit={true} fluid={true}>
              <Form.Row className={classes.rows}>
                <Form.Group
                  as={Col}
                  lg={"12"}
                  xs={"12"}
                  className={
                    "rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile icon-search input-custom"
                  }
                >
                  <Form.Control
                    type="text"
                    name="search"
                    onChange={e => {
                      setFilter(e.target.value);
                    }}
                    value={filter}
                    className="input-custom"
                    placeholder="Busque por categoria ou descrição"
                  />
                  <button type="button">
                    <RiSearchLine size={20} />
                  </button>
                </Form.Group>
              </Form.Row>
              <div className={classes.root}>
                <ClassificationList
                  user={user}
                  title="Receitas"
                  categories={receipts}
                  type="receita"
                  key="receita"
                  idCompany={idCompany}
                  reload={reload}
                  categoriesEnumeration={categoriesEnumeration}
                  titleBackgroundColor={globalColors.primaryColor1}
                  setLoading={setLoading}
                />
                <ClassificationList
                  user={user}
                  title="Despesas"
                  categories={expenses.map(category => ({
                    ...category,
                    subcategories: category.subcategories.filter(
                      subcategory => !subcategory.name.toLowerCase().includes("custos financeiros")
                    )
                  }))}
                  type="despesa"
                  key="despesa"
                  idCompany={idCompany}
                  reload={reload}
                  categoriesEnumeration={categoriesEnumeration}
                  titleBackgroundColor={globalColors.primaryColor2}
                  setLoading={setLoading}
                />
                <ClassificationList
                  user={user}
                  title="Investimentos"
                  categories={investments}
                  type="investimento"
                  key="investimento"
                  idCompany={idCompany}
                  reload={reload}
                  categoriesEnumeration={categoriesEnumeration}
                  titleBackgroundColor={globalColors.primaryColor4}
                  setLoading={setLoading}
                />
              </div>
            </PortletBody>
          </Portlet>
        </StyledTableContent>
      </div>
    </>
  )
}

const CategoryListing = props => {
  window.setPageTitle("Plano de contas - Admin");

  store.dispatch(categoryDuck.actions.setCategory({}));

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filter, setFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoriesEnumeration, setCategoriesEnumeration] = useState({});

  useEffect(() => {
    fetchCategories();
  }, [props.idCompany, reload]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const [_categories, _financialFlow] = await Promise.all([
        getCategories({
          idCompany: props.idCompany,
          perPage: 10,
          page: 1,
        }),
        getFinancialFlowAnalysis({
          idCompany: props.idCompany,
          page: 1,
          perPage: 10,
          orderBy: 'id',
          orderByDirection: 'desc',
          year: moment().year()
        })
      ]);

      const _categoriesEnumeration = enumerateFinancialFlow(_categories);

      setCategoriesEnumeration(_categoriesEnumeration);
      setCategories(_categories);
      setReceipts(_categories.filter(c => c.type === "receita"));
      setExpenses(_categories.filter(c => c.type === "despesa"));
      setInvestments(_categories.filter(c => c.type === "investimento"));
    } catch (e) {
      console.log(e);
      Swal.fire("Erro!", "Erro ao recuperar Plano de Contas.", "error");
    }
    setLoading(false);
  }

  const filterData = () => {
    if (filter.length > 1) {
      const new_cat = categories.map(c => ({
        ...c,
        subcategories: c.subcategories.map(subc => ({
          ...subc,
          groups: subc.groups.filter(g => g.search(filter) != -1)
        }))
      }));
      setReceipts(new_cat.filter(c => c.type === "receita"));
      setExpenses(new_cat.filter(c => c.type === "despesa"));
      setInvestments(new_cat.filter(c => c.type === "investimento"));
    } else {
      setReceipts(categories.filter(c => c.type === "receita"));
      setExpenses(categories.filter(c => c.type === "despesa"));
      setInvestments(categories.filter(c => c.type === "investimento"));
    }
  }

  useEffect(() => {
    filterData();
  }, [filter])


  return (
    <>
      <Loading isLoading={loading} />
      <CategoryContainer
        user={props?.user}
        receipts={receipts}
        investments={investments}
        expenses={expenses}
        idCompany={props.idCompany}
        reload={() => setReload(!reload)}
        filter={filter}
        setFilter={setFilter}
        categoriesEnumeration={categoriesEnumeration}
        setLoading={setLoading}
      />
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(CategoryListing);
