import { pt } from "yup-locale-pt";
import * as yup from 'yup';

yup.setLocale(pt)

export const schema = yup.object().shape({
  description: yup.string()
    .label("Descrição")
    .required(),
  value: yup.string()
    .label("Valor")
    .test(value => Number(value?.replace("%", '').replace(",", ".")))
    .required(),
});
