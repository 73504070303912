import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const AdminRoute = props => {
  const [returnedRoute, setReturnedRoute] = useState("");
  useEffect(() => {
    switch (props.permissionLevel) {
      case "ADMIN":
        return setReturnedRoute(
          props.roleType === "ADMIN" ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );
      case "COORDINATOR":
        return setReturnedRoute(
          props.roleType === "COORDINATOR" || props.roleType === "ADMIN" ? (
            <Route {...props} />
          ) : (
            <Redirect to="/admin/error" />
          )
        );
      case "EMPLOYEE":
      default:
        return setReturnedRoute(<Route {...props} />);
    }
  }, [props, props.roleType]);
  return <>{returnedRoute}</>;
};

const mapStateToProps = ({ auth }) => ({
  roleType: auth.role?.name
});

export default connect(mapStateToProps, null)(AdminRoute);
