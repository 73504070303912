import styled from "styled-components";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";

export const Container = styled.button`
 display: flex;
 height: ${({ height }) => (height ? height : "100%")};
 width: ${({ width }) => (width ? width : "100%")};
 background: ${({ background }) =>
    background ? background : globalColors.secondaryColor1};
 border: 1px solid ${globalColors.primaryColor1};
 color: ${({ color }) => (color ? color : globalColors.secondaryColor1)};
 font-family: "Cera Pro Regular"
 font-weight: 600;
 font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
 font-weight: 600;
 align-items: center;
 justify-content: center;
 margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
 padding: ${({ padding }) => (padding ? padding : 0)};
 border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 0)};

 svg {
	transform: rotate(90deg);
 }

 :hover {
   transition: ${({ isLoading }) => (isLoading ? 0 : "0.3s")};
   opacity: ${({ isLoading }) => (isLoading ? 1 : "0.8")};
   border: 1px solid #ffff;
   border: ${({ isLoading }) => isLoading && 0};
   color: #fff;
   background: ${globalColors.primaryColor2};
  }
`;
