import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatISO, parseISO } from "date-fns";
import styles from "../Users/styles.module.scss";
import Loading from "../../../home/components/Loading";

import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import InputForm from "../../../../components/InputForm";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import verifyCpfCnpj, {
  formatCPF
} from "../../../../utils/validators/IsValidCpfCnpj";
import FormActions from "../../components/FormActions";
import { AppAPIService } from "../../../../services/AppAPIService";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import IsValidName from "../../../../utils/validators/IsValidName";
import message from "../../../../assets/icons/message.svg";
import formatCEP from "../../../../utils/FormatCep";
import axios from "axios";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import validateCep from "../../../../utils/validators/IsValidCep";
import { connect } from "react-redux";
import moment from "moment";
import { FormAlert } from "../../../../components/Alerts/alert";
import { isNotNull, validateIfHasAnyNull } from "../../../../utils/validators/IsNotNull";
import { applyMask } from "../../../../utils/applyMask";

const ClienteForm = ({ companies, selectCompany, idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.CLIENTS_WRITE.ENGLISH);

  const { clientId } = useParams();

  let api = AppAPIService.getInstance();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailRole, setEmailRole] = useState("");

  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [fullnameRole, setFullnameRole] = useState("");

  const [phones, setPhones] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneRole, setPhoneRole] = useState("");

  const [mobiles, setMobiles] = useState([]);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [mobileRole, setMobileRole] = useState("");

  const [expirationDates, setExpirationDates] = useState([]);
  const [expirationDate, setExpirationDate] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [expirationDateRole, setExpirationDateRole] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [_, setBirthDateRole] = useState("");

  const [document, setCpfOrCnpj] = useState("");
  const [documentError, setCpfOrCnpjError] = useState("");
  const [documentRole, setCpfOrCnpjRole] = useState("");

  const [streetRole, setStreetRole] = useState("");
  const [streetError, setStreetError] = useState("");
  const [street, setStreet] = useState("");

  const [districtRole, setDistrictRole] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [district, setDistrict] = useState("");

  const [addressComplementRole, setAddressComplementRole] = useState("");
  const [addressComplementError, setAddressComplementError] = useState("");
  const [addressComplement, setAddressComplement] = useState("");

  const [postalCodeRole, setPostalCodeRole] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [cityRole, setCityRole] = useState("");
  const [cityError, setCityError] = useState("");
  const [city, setCity] = useState("");

  const [stateRole, setStateRole] = useState("");
  const [stateError, setStateError] = useState("");
  const [state, setState] = useState("");

  const [addressNumberRole, setAddressNumberRole] = useState("");
  const [addressNumberError, setAddressNumberError] = useState("");
  const [addressNumber, setAddressNumber] = useState("");

  const [obs, setObs] = useState("");

  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);

  const [userInfo, setUserInfo] = useState({});

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberError("");
      setAddressNumberRole("ok");
    } else {
      setAddressNumberError("Campo obrigatório.");
      setAddressNumberRole("notOk");
    }
  };

  const handleInputAddressNumber = e => {
    if (e.length > 6) return;

    if (e !== "" && !Number(e)) {
      return;
    }

    setAddressNumber(e);
    handleValidationAddressNumber(e);
  };

  const handleValidationAddressComplement = value => {
    if (value.length < 2 && value.length > 0) {
      setAddressComplementError("Complemento deve ter pelo menos 2 caracteres");
      setAddressComplementRole("notOk");
    } else if (value.length >= 2) {
      setAddressComplementRole("ok");
      setAddressComplementError("");
    } else {
      setAddressComplementError("");
      setAddressComplementRole("");
    }
  };

  const handleInputAddressComplement = e => {
    if (e.length > 20) return;

    let value = e;
    setAddressComplement(value);
    handleValidationAddressComplement(value);
  };

  const handleNeighborhoodValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setDistrictError("Bairro deve ter pelo menos 2 caracteres");
      setDistrictRole("notOk");
    } else if (value.length >= 2) {
      setDistrictRole("ok");
      setDistrictError("");
    } else {
      setDistrictError("");
      setDistrictRole("");
    }
  };

  const handleInputAddressNeighborhood = e => {
    handleNeighborhoodValidation(e);
    setDistrict(e);
  };

  const handleAddressValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setStreetError("Endereço deve ter pelo menos 2 caracteres");
      setStreetRole("notOk");
    } else if (value.length >= 2) {
      setStreetRole("ok");
      setStreetError("");
    } else {
      setStreetError("");
      setStreetRole("");
    }
  };

  const handleInputAddress = e => {
    if (e.length > 50) return;
    setStreet(e);
    handleAddressValidation(e);
  };

  const handleFetchUfs = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.sigla,
        label: resp.nome
      }));
      setUfs(ufOpts);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleFetchCities = useCallback(async () => {
    try {
      const ufSelected = ufs.find(ufOpt => ufOpt.value === state?.value);
      const response = await (
        await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected?.value}/municipios`
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.nome,
        label: resp.nome
      }));
      setCities(ufOpts);
    } catch (error) {
      console.log(error);
    }
  }, [state.value, ufs]);

  const handleSetUf = e => {
    if (e === null) {
      setStateError("Campo obrigatório.");
      setStateRole("notOk");
      setState("");
    } else {
      setState(e ? e : {});
      setStateRole("ok");
      setStateError("");
    }
  };

  const handleSetCity = e => {
    if (e === null) {
      setCityError("Campo obrigatório.");
      setCityRole("notOk");
      setCity("");
    } else {
      setCity(e ? e : {});
      setCityRole("ok");
      setCityError("");
    }
  };

  useEffect(() => {
    if (postalCode === "") {
      setState("");
      setCity("");
      setStateError("");
      setStateRole("");
      setCityRole("");
      setCityError("");
      setStreet("");
      setStreetRole("");
      setStreetError("");
      setDistrictRole("");
      setDistrictError("");
      setDistrict("");
      setAddressNumber("");
      setAddressNumberRole("");
      setAddressNumberError("");
      setAddressComplement("");
      setAddressComplementRole("");
      setAddressComplementError("");
    }
  }, [postalCode]);

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await (
        await axios.get(`https://viacep.com.br/ws/${cep}/json`, {
          headers: {}
        })
      ).data;

      if (response?.erro) {
        setPostalCodeRole("ok");
        setPostalCodeError("");

        setAddressNumber("");
        setAddressNumberRole("notOk");
        setAddressNumberError("Campo obrigatório.");

        setStreet("");
        setStreetRole("notOk");
        setStreetError("Campo obrigatório.");

        setDistrict("");
        setDistrictRole("notOk");
        setDistrictError("Campo obrigatório.");

        setAddressComplement("");
        setAddressComplementRole("");
        setAddressComplementError("");

        setState("");
        setStateRole("notOk");
        setStateError("Campo obrigatório.");

        setCity("");
        setCityRole("notOk");
        setCityError("Campo obrigatório.");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setPostalCodeRole("ok");
      setPostalCodeError("");

      setStreet(response.logradouro);
      setStreetRole("ok");
      setStreetError("");

      if (!response.logradouro) {
        setStreet("");
        setStreetRole("notOk");
      }

      if (!response.bairro) {
        setDistrictRole("notOk");
        setDistrictError("Bairro não encontrado");
      } else {
        setDistrictError("");
        setDistrict(response.bairro);
        setDistrictRole("ok");
      }

      setState({
        value: response.uf,
        label: response.uf
      });
      setStateRole("ok");
      setStateError("");

      setCity({
        value: response.localidade,
        label: response.localidade
      });
      setCityRole("ok");
      setCityError("");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido."
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleInputCep = useCallback(
    async zipcode => {
      if (zipcode.length > 11) {
        return;
      }

      const { error, cepFormat, cep, role } = validateCep(zipcode);

      if (zipcode.length < 1) {
        setPostalCode(zipcode);
        setPostalCodeError("");
        setPostalCodeRole("");
        return;
      }

      if (cep === "") {
        setPostalCodeError("Apenas números.");
        setPostalCodeRole("notOk");
        return;
      }

      if (zipcode.length > 10) {
        return;
      }

      setPostalCode(cepFormat);
      setPostalCodeError(error);
      setPostalCodeRole(role);

      if (cep.length === 8) {
        await handleCepConsult(cep);
      }
    },
    [handleCepConsult]
  );

  useEffect(() => {
    handleFetchUfs();
  }, [handleFetchUfs]);

  useEffect(() => {
    handleFetchCities();
  }, [ufs, state.value, handleFetchCities]);

  const handleInputEmail = async e => {
    if (e.length > 50) {
      return;
    }
    if (e.length < 1) {
      setEmail(e);
      setEmailError("Campo obrigatório");
      setEmailRole("notOk");
    } else if (!IsValidEmail(e)) {
      setEmail(e);
      setEmailError("Não é um E-mail válido.");
      setEmailRole("notOk");
    } else {
      setEmail(e);
      setEmailError("");
      setEmailRole("ok");
    }
  };

  const checkEmail = async e => {
    if (e !== "" && e !== userInfo?.document) {
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: "client/email/avaiability",
          data: {
            email: e
          }
        });
        setEmailError("");
        setEmailRole("ok");
      } catch (e) {
        setEmailError("Este email já está em uso");
        setEmailRole("notOk");
        return;
      }
    }
  };

  const checkDocument = async e => {
    if (e !== "" && e !== userInfo?.email) {
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: "client/document/avaiability",
          data: {
            document: e,
            idCompany: user.idCompany ?? idCompany
          }
        });
        setCpfOrCnpjError("");
        setCpfOrCnpjRole("ok");
      } catch (e) {
        setCpfOrCnpjError("Este documento já está em uso");
        setCpfOrCnpjRole("notOk");
        return;
      }
    }
  }

  const handleInputFullname = e => {
    if (e.length > 250) {
      return;
    }
    if (e.length < 3) {
      setFullname(e);
      setFullnameError("Campo obrigatório");
      setFullnameRole("notOk");
    } else if (!IsValidName(e)) {
      setFullname(e);
      setFullnameError("Mínimo de 3 caracteres.");
      setFullnameRole("notOk");
    } else {
      setFullname(e);
      setFullnameError("");
      setFullnameRole("ok");
    }
  };

  const handleInputPhoneChange = e => {
    setPhones(e || []);
  };

  const handleInputMobileChange = e => {
    setMobiles(e || []);
  };

  const handleInputPhone = e => {
    if (phones.length === 2) return;

    if (e === "") {
      setPhone("")
      return;
    }

    let value = e.slice(0, 10);

    const phoneNumbers = value.replace(/\D/g, "");

    if (value !== "" && !Number(phoneNumbers)) {
      setPhoneError("Apenas números");
      setPhoneRole("notOk");
      return;
    }

    if (!IsValidPhone(phoneNumbers)) {
      setPhoneError("Não é um telefone válido.");
      setPhoneRole("notOk");
    } else if (IsValidPhone(phoneNumbers) === "") {
      setPhoneError("");
      setPhoneRole("");
    } else {
      if (phoneNumbers.length === 10) {
        setPhones(prev => {
          setPhone("");
          return [...prev, { label: applyMask(e), value: applyMask(e) }];
        });
        setPhoneError("");
        setPhoneRole("ok");
        return;
      }
      setPhoneError("");
      setPhoneRole("ok");
    }

    setPhone(phoneNumbers);
  };

  const handleInputMobile = e => {
    if (mobiles.length === 2) return;

    if (e === "") {
      setMobile("")
      return;
    }

    let value = e.slice(0, 11);

    const phoneNumbers = value.replace(/\D/g, "");

    if (value !== "" && !Number(phoneNumbers)) {
      setMobileError("Apenas números");
      setMobileRole("notOk");
      return;
    }

    if (!IsValidPhone(phoneNumbers)) {
      setMobileError("Não é um telefone válido.");
      setMobileRole("notOk");
    } else if (IsValidPhone(phoneNumbers) === "") {
      setMobileError("");
      setMobileRole("");
    } else {
      if (phoneNumbers.length === 11) {
        setMobiles(prev => {
          setMobile("");
          return [...prev, { label: applyMask(e), value: applyMask(e) }];
        });
        setMobileError("");
        setMobileRole("ok");
        return;
      }
      setMobileError("");
      setMobileRole("ok");
    }

    setMobile(phoneNumbers);
  };

  const handleExpirationDateChange = e => {
    setExpirationDate(e);
    setExpirationDateRole("ok")
  };

  const handleBirthDateChange = e => {
    setBirthDate(e);
    setBirthDateRole("ok")
  };

  const handleInputDocument = doc => {
    const docNumbers = doc.replace(/\D/g, "");

    const { isValid, error, cpfCnpjFormat, cpfCnpj } = verifyCpfCnpj(
      docNumbers
    );

    if (doc.length < 1) {
      setCpfOrCnpj(doc);
      setCpfOrCnpjError("");
      setCpfOrCnpjRole("");
      return;
    }

    if (cpfCnpjFormat === "") {
      setCpfOrCnpjError("Apenas números.");
      setCpfOrCnpjRole("notOk");
      return;
    }

    if (doc.length > 18) {
      return;
    }

    setCpfOrCnpj(cpfCnpjFormat);
    setCpfOrCnpjError(error);
    setCpfOrCnpjRole(isValid ? "ok" : "notOk");
  };

  const preSubmit = async (event) => {
    event.preventDefault();

    try {

      const valuesInput = [
        fullname,
        email,
        document,
        expirationDate,
        birthDate,
        mobiles,
        user.idCompany ?? idCompany,
        addressComplement,
        addressNumber,
        state.label,
        city.label,
        postalCode,
        district,
        street,
      ]

      const hasAnyOptionalNotFilled = validateIfHasAnyNull(valuesInput);

      if (hasAnyOptionalNotFilled) {
        await Swal.fire({
          title: "Confirmação",
          text: `Alguns campos opcionais são importantes para a emissão da nota fiscal`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Desejo continuar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          console.log(result)
          if (result.value) {

            await submit()
          } else {
            return;
          }
        }, () => {
          setLoading(false)
        })
      } else {
        await submit();
      }
    } catch (error) {
      console.log('Erro validando so campos ', error)
      setLoading(false);
    }
  }

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      await api.makeHttpRequest({
        url: clientId ? `/client/${clientId}` : "/client",
        method: clientId ? "PUT" : "POST",
        data: {
          fullname: isNotNull(fullname) ? fullname : null,
          email: isNotNull(email) ? email : null,
          document: isNotNull(document) ? document : null,
          drivePermissionExpirationDate: isNotNull(expirationDate) ? moment(expirationDate).toISOString() : null,
          birthDate: isNotNull(birthDate) ? moment(birthDate).toISOString() : null,
          obs,
          phones: [...phones.map(p => `${p.value}`), ...mobiles.map(p => `${p.value}`)] ?? null,
          idCompany: user.idCompany ?? idCompany,
          address: isNotNull(
            [state.label, city.label, postalCode, district, street]
          ) ? {
            complement: addressComplement ?? "",
            number: parseInt(addressNumber),
            state: state.label,
            city: city.label,
            postalCode,
            district,
            street
          } : null,
        }
      });

      setLoading(false);

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: `${clientId ? "Editado" : "Cadastrado"} com sucesso.`
      }).then(() => {
        history.push("/admin/clientes");
      });
    } catch (error) {
      if (
        error.response?.data?.errors
          ?.toLowerCase()
          .includes("cliente já existe no sistema")
      ) {
        setEmailError("E-mail já cadastrado em nosso banco de dados");
        setEmailRole("notOk");

        Swal.fire({
          title: "Erro!",
          text: "E-mail já cadastrado em nosso banco de dados",
          icon: "error"
        });
      } else {
        Swal.fire({
          title: "Erro!",
          text:
            "Erro tentar cadastrar novo cliente, tente novamente mais tarde!",
          icon: "error"
        }).then(() => {
          history.push("/admin/clientes");
        });
      }
    } finally {
      setLoading(false);
    }
  },
    [
      api,
      phones,
      mobiles,
      obs,
      clientId,
      fullname,
      idCompany,
      user.idCompany,
      email,
      document,
      history,
      addressComplement,
      expirationDate,
      birthDate,
      addressNumber,
      postalCode,
      district,
      street,
      state,
      city
    ]
  );

  const formIsValid = useCallback(() => {
    let isValid = false;

    isValid =
      fullname !== "" &&
      fullnameRole === "ok" &&
      document !== "" &&
      documentRole === "ok";

    return isValid;
  }, [fullname, fullnameRole, document, documentRole]);

  const onCleanForm = async () => {
    setFullname("");
    setEmail("");
    setPhone("");
    setCpfOrCnpj("");
  };

  const fetchClientInfo = useCallback(
    async id => {
      try {
        setLoading(true);

        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/client/${id}`
        });

        setUserInfo(response);

        setFullname(response.fullname);
        setFullnameRole("ok");

        setEmail(response.email);
        setEmailRole("ok");

        if (response.document) {
          setCpfOrCnpj(formatCPF(response.document.replace(/\D/g, "")));
          setCpfOrCnpjRole("ok");
        }

        if (response.drivePermissionExpirationDate) {
          setExpirationDate(
            formatISO(parseISO(response.drivePermissionExpirationDate), {
              representation: "date"
            })
          );
          setExpirationDateRole("ok");
        }

        if (response.birthDate) {
          setBirthDate(
            formatISO(parseISO(response.birthDate), { representation: "date" })
          );
          setBirthDateRole("ok");
        }

        if (response.phones) {
          let phones = [];
          let mobiles = [];

          response.phones.map(p => {
            const phoneNumber = p.number.replace(/\D/g, "");
            const masked = applyMask(phoneNumber);

            if (phoneNumber.length === 10)
              phones.push({
                label: masked,
                value: masked
              });

            if (phoneNumber.length === 11)
              mobiles.push({
                label: masked,
                value: masked
              });
          });

          setMobiles(mobiles);
          setPhones(phones);
        }

        if (response.address.postalCode) {
          setPostalCode(formatCEP(response.address.postalCode));
          setPostalCodeRole("ok");
        }

        if (response.address.street) {
          setStreet(response.address.street);
          setStreetRole("ok");
        }

        if (response.address.district) {
          setDistrict(response.address.district);
          setDistrictRole("ok");
        }

        if (response.address.number) {
          setAddressNumber(response.address.number);
          setAddressNumberRole("ok");
        }

        if (response.address.complement) {
          setAddressComplement(response.address.complement);
          setAddressComplementRole("ok");
        }

        if (response.address.state) {
          setState({
            label: response.address.state,
            value: response.address.state
          });
          setStateRole("ok");
        }

        if (response.address.city) {
          setCity({
            label: response.address.city,
            value: response.address.city
          });
          setCityRole("ok");
        }

        setObs(response.obs || "");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (!clientId) {
      return;
    }

    fetchClientInfo(clientId);
  }, [fetchClientInfo, clientId]);

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={message} alt={"Clientes"} />
        Clientes
      </h3>

      <form className={styles.form} autoComplete="off">
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            name="name"
            placeholder="Digite o nome"
            value={fullname}
            error={fullnameError}
            onChange={handleInputFullname}
            role={fullnameRole}
            label="Nome"
            required
          />

          <InputForm
            type="text"
            name="cpfOrCnpj"
            placeholder="000.000.000-00"
            value={document}
            error={documentError}
            onChange={handleInputDocument}
            onBlur={() => checkDocument(document)}
            role={documentRole}
            label={"CPF/CNPJ"}
            required
          />

          <InputForm
            type="text"
            name="sold"
            value={0}
            error={""}
            role={"ok"}
            label="Total Vendido"
            isDisabled
          />
        </div>

        <div className={styles.containerInputs}>
          <InputForm
            type="date"
            name="expirationDate"
            value={expirationDate}
            onChange={handleExpirationDateChange}
            label="Vencimento da CNH"
          />
          <InputForm
            type="date"
            name="BirthDate"
            value={birthDate}
            onChange={handleBirthDateChange}
            label="Data de Nascimento"
          />
          <InputForm
            type="text"
            name="email"
            placeholder="Digite o e-mail"
            value={email}
            error={emailError}
            onBlur={() => checkEmail(email)}
            onChange={handleInputEmail}
            role={emailRole}
            label="E-mail"
          />
        </div>

        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            name="mobile"
            placeholder="(00) 00000-0000"
            value={mobile}
            error={mobileError}
            components={{
              DropdownIndicator: null
            }}
            onChange={handleInputMobileChange}
            role={mobileRole}
            label="Telefone celular"
            onInputChange={handleInputMobile}
            menuIsOpen={false}
            isClearable
            isMulti
            isCreateable
            options={mobiles}
          />

          <InputForm
            type="text"
            name="phone"
            placeholder="(00) 0000-0000"
            value={phone}
            error={phoneError}
            components={{
              DropdownIndicator: null
            }}
            onChange={handleInputPhoneChange}
            role={phoneRole}
            label="Telefone fixo"
            onInputChange={handleInputPhone}
            menuIsOpen={false}
            isClearable
            isMulti
            isCreateable
            options={phones}
          />

          <div className={styles.cepContainer}>
            <InputForm
              type="tel"
              placeholder="Digite o CEP"
              onChange={handleInputCep}
              value={postalCode}
              name="cep"
              error={postalCodeError}
              label="CEP"
              role={postalCodeRole}
            />
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.cep}
            >
              Não sei meu CEP
              <AiOutlineQuestionCircle fontSize={"20px"} />
            </a>
          </div>
        </div>
        <div className={styles.containerInputs}>
          <InputForm
            isSelect
            placeholder="Estado"
            label="Estado"
            role={stateRole}
            value={state}
            options={ufs}
            onChange={handleSetUf}
            error={stateError}
          />
          <InputForm
            type="text"
            placeholder="Digite o bairro"
            onChange={handleInputAddressNeighborhood}
            value={district}
            name="neighborhood"
            error={districtError}
            label="Bairro"
            role={districtRole}
          />
          <InputForm
            isSelect
            placeholder="Cidade"
            label="Cidade"
            role={cityRole}
            value={city}
            options={cities}
            onChange={handleSetCity}
            error={cityError}
          />

        </div>
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            placeholder="Digite o nome da rua"
            onChange={handleInputAddress}
            value={street}
            name="street"
            error={streetError}
            label="Logradouro"
            role={streetRole}
          />
          <InputForm
            type="text"
            placeholder="Digite o número"
            onChange={handleInputAddressNumber}
            value={addressNumber}
            name="address"
            error={addressNumberError}
            label="Número"
            role={addressNumberRole}
          />

          <InputForm
            type="text"
            placeholder="Digite o complemento"
            onChange={handleInputAddressComplement}
            value={addressComplement}
            name="complement"
            error={addressComplementError}
            label="Complemento"
            role={addressComplementRole}
          />
        </div>
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            placeholder="Observações"
            onChange={setObs}
            value={obs}
            name="Observações"
            label="Observações"
          />
        </div>
        <FormAlert />
      </form>

      <FormActions
        module={"clientes"}
        formIsValid={formIsValid()}
        onCleanForm={onCleanForm}
        onSubmit={preSubmit}
        hideCancel={true}
        messagem
      />
    </div>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany,
  user: auth.user
});

export default connect(mapStateToProps)(ClienteForm);
