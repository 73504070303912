import React, { Suspense, useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import ErrorPage from "../errors/ErrorPage";
import AdminRoute from "./components/AdminRoute";
import {
  UserListing,
  UserForm,
  ListingProduct,
  FormProduct,
  WorkerForm,
  SupplierForm,
  SupplierListing
} from "./pages";
import ClienteForm from "./pages/Clientes/ClienteForm";
import ClientesListing from "../admin/pages/Clientes/ClienteListing";
import { USER_TYPES } from "../../enum/UserTypes";

import WorkerListing from "./pages/Workers/WorkerListing";
import { VehiclesListing } from "./pages/Vehicles";
import VehicleForm from "./pages/Vehicles/VehiclesForm";
import { FinancesListing, FinancesTabs } from "./pages/Finances";
import { FlowListing } from "./pages/Cash Flow";
import { AnalisysFlowListing } from "./pages/CashFlowAnalisys";
import DiscountListing from "./pages/Discounts/DiscountListing";
import DiscountForm from "./pages/Discounts/DiscountForm";
import FeeForm from "./pages/Fees/FeeForm";
import FeeListing from "./pages/Fees/FeeListing";
import FinancesForm from "./pages/Finances/FinancesForm";
import CategoryListing from "./pages/Categories/CategoryListing";
import CategoryForm from "./pages/Categories/CategoryForm";
import SubcategoryForm from "./pages/Categories/SubcategoryForm";
import ReceiptWayListing from "./pages/ReceiptWay/ReceiptWayListing";
import ReceiptWayForm from "./pages/ReceiptWay/ReceiptWayForm";

export default function AdminPage() {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeEvent = () => {
    if (window.innerWidth !== width) {
      setWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeEvent);

    return () => window.removeEventListener("resize", resizeEvent);
  });

  const getAuthData = () => {
    if (window.localStorage.getItem("persist:auth:ohana")) {
      return JSON.parse(window.localStorage.getItem("persist:auth:ohana"))
        ?.authData;
    }

    return { user: { roles: [] } };
  };

  return (
    <Suspense>
      <Switch>
        <Redirect
          exact
          from="/admin"
          to={
            getAuthData().user?.roles?.some(
              item => item === USER_TYPES.USERS_READ.ENGLISH
            )
              ? "/admin/usuarios"
              : "/admin/produtos"
          }
        />

        {/* USUÁRIOS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/usuarios"
          component={() => <UserListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/usuarios/:clientId/editar"
          component={UserForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/usuarios/adicionar"
          component={UserForm}
        />

        {/* PRODUTOS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/produtos"
          component={() => <ListingProduct width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/produtos/adicionar"
          component={FormProduct}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/produtos/:productId/editar"
          component={FormProduct}
        />

        {/* FUNCIONÁRIOS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/profissionais"
          component={() => <WorkerListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/profissionais/:workerId/editar"
          component={WorkerForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/profissionais/adicionar"
          component={WorkerForm}
        />

        {/* FORNECEDORES */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/fornecedores"
          component={() => <SupplierListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/fornecedores/:supplierId/editar"
          component={SupplierForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/fornecedores/adicionar"
          component={SupplierForm}
        />

        {/* CLIENTES */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/clientes"
          component={() => <ClientesListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/clientes/:clientId/editar"
          component={ClienteForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/clientes/adicionar"
          component={ClienteForm}
        />

        {/* VEÍCULOS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/veiculos"
          component={() => <VehiclesListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/veiculos/:vehicleId/editar"
          component={VehicleForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/veiculos/adicionar"
          component={VehicleForm}
        />

        {/* FINANÇAS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/lancamentos"
          component={() => <FinancesListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/lancamentos/:movementId/editar"
          component={FinancesForm}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/lancamentos/adicionar"
          component={FinancesForm}
        />

        {/* FLUXO DE CAIXA */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/fluxo-caixa"
          component={() => <FlowListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/analise-fluxo-caixa"
          component={() => <AnalisysFlowListing width={width} />}
        />

        {/* DESCONTOS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/descontos"
          component={() => <DiscountListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/descontos/adicionar"
          component={() => <DiscountForm width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/descontos/:discountId/editar"
          component={() => <DiscountForm width={width} />}
        />

        {/* TAXAS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/taxas"
          component={() => <FeeListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/taxas/adicionar"
          component={() => <FeeForm width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/taxas/:receiptWayId/editar"
          component={() => <FeeForm width={width} />}
        />

        {/* CATEGORIAS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/categorias"
          component={() => <CategoryListing width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/categorias/adicionar"
          component={() => <CategoryForm width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/categorias/editar"
          component={() => <CategoryForm width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/categorias/subcategoria/editar"
          component={() => <SubcategoryForm width={width} />}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/formas-recebimento"
          component={() => <ReceiptWayListing width={width} />}
        />
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/formas-recebimento/:receiptWayId/editar"
          component={() => <ReceiptWayForm width={width} />}
        />
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/formas-recebimento/adicionar"
          component={() => <ReceiptWayForm width={width} />}
        />

      </Switch>
    </Suspense>
  );
}
