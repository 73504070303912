import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";

import feeIcon from "../../../../assets/icons/fee.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { deleteFee } from "../../../../services/modules/FeeApiService";
import * as moment from 'moment';

const propsDatatable = {
  title: "Taxas",
  endpoint: "/receiptWay",
  className: "h-100",
  placeholderSearch: "Busque taxas pela descrição",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

const FeeListing = props => {
  window.setPageTitle("Listagem de taxas - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.FEE_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.FEE_WRITE.ENGLISH
  );

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    { label: "Ações" },
    { column: null, label: "Forma de recebimento" },
    { column: null, label: "Data de criação" },
  ];

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteFee(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Taxa deletada com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar taxa", "error");
    } finally {
      setLoading(false);
    }
  };

  const formatRow = r => {
    let crudActions = ["edit"];

    return (
      <StyledTableRow
        key={r.id}
        hover
        tabIndex={-1}
      >
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            disableActions={false}
            actions={crudActions}
            module="taxas"
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Taxa"}
            addMessage={"Você tem certeza que deseja excluir esta taxa? Essa ação não poderá ser desfeita"}
          />
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.name}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {moment(r?.createdAt).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={feeIcon} alt={"Icone de taxa"} />}
        alert={"Para criar taxas é necessária antes a criação de formas de recebimento. Você só pode realizar lançamentos usando uma taxa após a data da criação dela no sistema. Caso registre a taxa hoje e tente lançar uma movimentação de ontem a taxa será zerada já que ela não existia ontem."}
        className="h-100"
        headRows={headRows}
        formatRow={formatRow}
        rows={[]}
        reload={reload}
        orderBy="id"
        orderDirection="desc"
        width={200}
        hiddenReload
        hiddenKanbanContent
      />
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(FeeListing);
