import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import FormActions from "../../components/FormActions";
import { SimpleTable } from "../../components/SimpleTable";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import fee from "../../../../assets/icons/fee.svg";
import { connect } from "react-redux";
import { saveManyFees } from "../../../../services/modules/FeeApiService";
import { FormAlert } from "../../../../components/Alerts/alert";
import Button from "../../../../components/Button";
import { getReceiptWayById } from "../../../../services/modules/ReceiptWayApiService";
import moment from "moment";
import InputForm from "../../../../components/InputForm";

const tableHeaders = [
  { label: "Tipo de pagamento", value: "paymentWayName" },
  { label: "Data de início", value: "effectiveDate" },
  { label: "Valor", value: "value" }
]

const FeeForm = ({ idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.FEE_WRITE.ENGLISH);
  const rootPage = "/admin/taxas";
  const { receiptWayId } = useParams();

  window.setPageTitle(
    `${!!receiptWayId ? "Edição" : "Cadastro"} de taxas`
  );

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [receiptWays, setReceiptWays] = useState([]);
  const [paymentWays, setPaymentWays] = useState([]);


  const [receiptWay, setReceiptWay] = useState();

  const [paymentWay, setPaymentWay] = useState();
  const [paymentWayError, setPaymentWayError] = useState("");

  const [effectiveDate, setEffectiveDate] = useState();
  const [effectiveDateError, setEffectiveDateError] = useState("");

  const [value, setValue] = useState(0);
  const [valueError, setValueError] = useState("");

  const [addingAlterationHistoric, setAddingAlterationHistoric] = useState([]);
  const [alterationHistoric, setAlterationHistoric] = useState([]);

  useEffect(() => {
    if (receiptWayId) {
      get(receiptWayId);
    }
  }, [receiptWayId]);

  const get = async id => {
    try {
      setLoading(true);

      const _receiptWay = await getReceiptWayById(id);
      setReceiptWay({
        ..._receiptWay,
        label: _receiptWay.name,
        value: _receiptWay.id,
      })

      const _alterationHistoric = reduceAlterationHistoric(_receiptWay);
      setAlterationHistoric(_alterationHistoric);

      setPaymentWays(_receiptWay.paymentWays.map(
        (paymentWay) => ({
          ...paymentWay,
          label: paymentWay.name,
          value: paymentWay.id,
        })
      ))


    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err?.response?.data?.messages[0] ?? err.message,
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  }

  const reduceAlterationHistoric = _receiptWay => {
    const _alterationHistoric = []
    _receiptWay.Fee.forEach(fee => {
      _alterationHistoric.push(...fee.alterationHistoric.map(
        (historic) => ({
          ...historic,
          paymentWayName: fee.paymentWay.name,
          value: `${historic.value}%`,
          effectiveDate: moment.utc(historic.effectiveDate).format("DD/MM/YYYY"),
          idCompany: fee.idCompany,
          idReceiptWay: fee.idReceiptWay,
          idPaymentWay: fee.paymentWay.id
        })
      ));
    });
    return _alterationHistoric;
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const newAlterationHistoric = concatAndParseAlterationHistoric();
      const fees = prepareFeesPayload(newAlterationHistoric);

      const payload = {
        idCompany: user.idCompany ?? idCompany,
        idReceiptWay: Number(receiptWayId),
        data: fees
      };

      await saveManyFees(payload);

      Swal.fire({
        title: "Sucesso",
        text: "Taxas salvas com sucesso!",
        icon: "success"
      })
      history.push(rootPage);
    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err?.response?.data?.messages[0] ?? err.message,
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  }

  const concatAndParseAlterationHistoric = () => {
    const concatenadedAlterationHistoric = [
      ...alterationHistoric.map(a => ({
        ...a,
        value: Number(removeInvalidCharactersFromNumber(a.value)),
        effectiveDate: moment.utc(a.effectiveDate, "DD/MM/YYYY")
      })),
      ...addingAlterationHistoric.map(a => ({
        idCompany: a.idCompany,
        idPaymentWay: a.idPaymentWay,
        idReceiptWay: a.idReceiptWay,
        effectiveDate: moment.utc(a.effectiveDate, "DD/MM/YYYY"),
        value: Number(removeInvalidCharactersFromNumber(a.value)),
        paymentWayName: a.paymentWayName,
      }))];
    return concatenadedAlterationHistoric;
  }

  const prepareFeesPayload = (newAlterationHistoric) => {
    const fees = [...receiptWay.Fee];
    fees.forEach(fee => {
      fee.alterationHistoric = newAlterationHistoric.filter(
        historic => historic.paymentWayName === fee.paymentWay.name)
    });

    paymentWays.forEach(p => {
      if (fees.find(fee => fee.idPaymentWay === p.id) === undefined) {
        fees.push({
          idPaymentWay: p.id,
          isPercentual: true,
          alterationHistoric: newAlterationHistoric.filter(
            historic => historic.paymentWayName === p.name
          )
        });
      }
    })

    return cleanFeesPayload(fees);
  }

  const cleanFeesPayload = (fees) => {
    return fees.map(fee => ({
      ...(fee.id ? { id: fee.id } : {}),
      isPercentual: true,
      idPaymentWay: fee.idPaymentWay,
      alterationHistoric: fee.alterationHistoric.map(
        historic => ({
          ...(historic.id ? { id: historic.id } : {}),
          value: historic.value,
          effectiveDate: historic.effectiveDate
        })
      )
    }))
  }

  const toFixedMask = value => {
    return `${value.split(".")[0]}.${value.split(".")[1].slice(0, 5)}`;
  }

  const alreadyHasFiveDecimalPlaces = element => {
    return element.split(".")[1]?.length >= 5;
  }

  const removeInvalidCharactersFromNumber = element => {
    return element.replace("%", "").replace(/[^0-9,.]/g, "").replace(",", ".");
  }

  const formatMask = element => {
    let valueToReturn = 0.00;

    const value = removeInvalidCharactersFromNumber(element)

    if (alreadyHasFiveDecimalPlaces(element)) {
      valueToReturn = toFixedMask(value)
    } else {
      valueToReturn = value;
    }

    return `${valueToReturn > 100 ? 100 : valueToReturn}%`;
  }

  const alreadyHasComma = (event, key) => {
    if (!event || !event.value) return false
    return event?.value?.includes(".") && (key === "," || key === ".")
  }

  const isInvalidInput = (event, key) => {
    return alreadyHasComma(event, key) || insertingCommaWithoutValue(event, key)
  }

  const insertingCommaWithoutValue = (event, key) => {
    return (event.value === undefined || event.value?.length === 0) && (key === "," || key === ".")
  }

  const thereIsNoValue = fields => {
    return fields.value.length === 2 && fields.value[1] === "%"
  }

  const handleValueKeyDown = (event, fields) => {
    if (isInvalidInput(fields, event.key)) {
      event.preventDefault();
      return;
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      fields?.value?.length > 0
    ) {
      if (thereIsNoValue(fields)) {
        setValue("")
        return;
      }
      setValue(`${fields?.value.slice(0, -2)}%`);
    }
  }

  const handleConfirmDelete = async id => {
    setAlterationHistoric(alterationHistoric.filter(historic => historic.id !== id));
    setAddingAlterationHistoric(addingAlterationHistoric.filter(historic => historic.id !== id));
  }

  const hasDuplicatedHistoricWithPaymentAndDate = (historic, idPaymentWay, _effectiveDate) => {
    return historic.some(
      hist => hist.idPaymentWay === idPaymentWay && hist.effectiveDate === _effectiveDate
    );
  }

  const feeAlreadyExists = fee => {
    return hasDuplicatedHistoricWithPaymentAndDate(alterationHistoric, fee.idPaymentWay, fee.effectiveDate) ||
      hasDuplicatedHistoricWithPaymentAndDate(addingAlterationHistoric, fee.idPaymentWay, fee.effectiveDate);
  }

  const handleAddFee = () => {
    if (!paymentWay) {
      setPaymentWayError("Campo obrigatório");
      return;
    }
    if (!effectiveDate) {
      setEffectiveDateError("Campo obrigatório");
      return;
    }
    if (!value) {
      setValueError("Taxa deve ser maior que 0");
      return;
    }

    const payload = {
      idCompany,
      idPaymentWay: paymentWay.id,
      idReceiptWay: receiptWay.id,
      effectiveDate: moment.utc(effectiveDate).format('DD/MM/YYYY'),
      value: value,
      paymentWayName: paymentWay.name,
      id: paymentWay.name + effectiveDate // Temporary ID
    };

    if (feeAlreadyExists(payload)) {
      Swal.fire({
        title: "Taxa duplicada",
        text: `Já existe uma taxa com o tipo de pagamento "${paymentWay.name}" na data ${payload.effectiveDate}`,
        icon: 'error'
      });
      return;
    }

    setAddingAlterationHistoric([payload, ...addingAlterationHistoric]);

    setPaymentWay(null);
    setEffectiveDate(moment(`01-01-${moment().year()}`, 'DD-MM-YYYY').format('DD-MM-YYYY'));
    setValue(0);
    setPaymentWayError("");
    setEffectiveDateError("");
    setValueError("");
  }

  const handleSelectReceiptWay = async (selectedReceiptWay) => {
    if (selectedReceiptWay) {
      get(selectedReceiptWay.id);
    } else {
      setReceiptWay(null);
    }
  }

  const handleSelectPaymentWay = async (selectedPaymentWay) => {
    setPaymentWay(selectedPaymentWay);
  }

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={fee} alt={"Taxas"} />
        Taxas
      </h3>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={handleSubmit}
        id="fees"
      >
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            name="receiptWay"
            placeholder="Selecione a forma de recebimento"
            label="Forma de recebimento"
            options={receiptWays}
            isSelect
            isDisabled={!!receiptWayId}
            value={receiptWay}
            onChange={handleSelectReceiptWay}
            required
          />
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.containerFeeInputs}>
            <InputForm
              type="text"
              name="paymentWay"
              placeholder="Selectione o novo tipo de pagamento"
              label="Tipo de pagamento"
              options={paymentWays}
              value={paymentWay}
              onChange={handleSelectPaymentWay}
              error={paymentWayError}
              isSelect
            />
            <InputForm
              type="date"
              name="effectiveDate"
              placeholder="Data de inicio"
              label="Data de inicio"
              value={effectiveDate ?? moment(`01-01-${moment().year()}`, 'DD-MM-YYYY').format('DD/MM/YYYY')}
              onChange={setEffectiveDate}
              error={effectiveDateError}
            />
            <InputForm
              type="text"
              name="value"
              placeholder="Digite o percentual da taxa"
              label="Percentual da taxa"
              onKeyDown={event => handleValueKeyDown(event, { value: value })}
              onChange={e => {
                setValue(formatMask(e));
              }}
              value={value}
              error={valueError}
            />
            <div className={styles.addButton}>
              <Button
                type="button"
                borderRadius="4px"
                text="ADICIONAR"
                color="white"
                width="150px"
                fontSize="15px"
                onClick={handleAddFee}
              />
            </div>
          </div>
          <SimpleTable
            headers={tableHeaders}
            rows={[...addingAlterationHistoric, ...alterationHistoric]}
            onConfirmDelete={handleConfirmDelete}
            emptyMessage="Nenhum registro de taxas"
            deleteTitle="Excluir taxa"
            deleteMessage="Você tem certeza que deseja excluir esta taxa? Essa ação não poderá ser desfeita"
          />
        </div>
        <FormAlert />
      </form>
      <FormActions
        module={"taxas"}
        formIsValid={true}
        onSubmit={handleSubmit}
        hideCancel
        messagem
      />
    </div>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany,
  user: auth.user
});

export default connect(mapStateToProps)(FeeForm);
