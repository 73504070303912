import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getFinancialFlowAnalysis = async (config) => {
  return await api.makeHttpRequest({
    url: `/financialflowanalysis`,
    method: "GET",
    params: config,
  });
}
