import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import InputForm from "../../../../components/InputForm";
import FormActions from "../../components/FormActions";
import { AppAPIService } from "../../../../services/AppAPIService";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import { useQuery, useQueryClient } from "react-query";
import { fetchDetails } from "../utils/fetchDetails";
import shoppingCart from "../../../../assets/icons/ShoppingCart.svg";
import { parseInt } from "lodash";
import { connect } from "react-redux";
import { FormAlert } from "../../../../components/Alerts/alert";

const FormProduct = (props) => {
  const { user, idCompany } = props;
  UseProtectedPage.accessToFormOrListing(USER_TYPES.PRODUCTS_WRITE.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.PRODUCTS_WRITE.ENGLISH
  );

  const { productId } = useParams();
  const formTitle = productId ? "Editar produto" : "Cadastrar novo produto";

  let api = AppAPIService.getInstance();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeRole, setCodeRole] = useState("");

  const [inventory, setInventory] = useState("");
  const [inventoryError, setInventoryError] = useState("");
  const [inventoryRole, setInventoryRole] = useState("");

  const [idMeasure, setMeasure] = useState("");
  const [measureError, setMeasureError] = useState("");
  const [measureRole, setMeasureRole] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameRole, setNameRole] = useState("");

  const [supplier, setSupplier] = useState("");
  const [supplierError, setSupplierError] = useState("");
  const [supplierRole, setSupplierRole] = useState("");

  const [minimumQuantity, setMinimumQuantity] = useState("");
  const [minimumQuantityError, setMinimumQuantityError] = useState("");
  const [minimumQuantityRole, setMinimumQuantityRole] = useState("");

  const [obs, setObs] = useState("");

  const [productInfo, setProductInfo] = useState({});

  const handleInputObs = e => {
    const value = e;
    setObs(value);
  };

  const handleInputcode = async event => {
    const value = event.toUpperCase();
    if (value !== "") {
      setCode(value);
      setCodeError("");
      setCodeRole("ok");
    } else {
      setCode(value);
      setCodeError("Campo obrigatório.");
      setCodeRole("notOk");
    }
  };

  const checkCode = async (e) => {
    if (e !== "" && e !== productInfo?.code) {
      try {
        await api.makeHttpRequest({
          method: 'POST',
          url: "product/code/avaiability",
          data: {
            code: e,
            idCompany: user.idCompany ?? idCompany
          }
        })
        setCodeError("");
        setCodeRole("ok");
      } catch (e) {
        setCodeError("Este código já está em uso");
        setCodeRole("notOk");
        return
      }
    }
  }

  const checkName = async (e) => {
    if (e !== "" && e !== productInfo?.name) {
      try {
        await api.makeHttpRequest({
          method: 'POST',
          url: "product/name/avaiability",
          data: {
            name: e,
            idCompany: user.idCompany ?? idCompany
          }
        })
        setNameError("");
        setNameRole("ok");
      } catch (e) {
        setNameError("Já existe um produto cadastrado com esse nome");
        setNameRole("notOk");
        return
      }
    }
  }

  const handleInputminimumQuantity = e => {
    if (e !== "") {
      const value = e.replace(/\D/g, "");
      setMinimumQuantity(value);
      setMinimumQuantityError("");
      setMinimumQuantityRole("ok");
    } else {
      const value = e.replace(/\D/g, "");
      setMinimumQuantity(value);
      setMinimumQuantityError("Campo obrigatório.");
      setMinimumQuantityRole("ok");
    }
  };

  const handleInputinventory = e => {
    if (e !== "") {
      const value = e.replace(/\D/g, "");
      setInventory(value);
      setInventoryError("");
      setInventoryRole("ok");
    } else {
      const value = e.replace(/\D/g, "");
      setInventory(value);
      setInventoryError("Campo obrigatório.");
      setInventoryRole("ok");
    }
  };

  const queryClient = useQueryClient();

  const { data: measures } = useQuery(
    "measures",
    () => fetchDetails(`/measure?idCompany=${user.idCompany ?? idCompany}`, setLoading),
    {
      select: measures =>
        measures.data?.map(i => ({ value: i.id, label: i.name }))
    }
  );

  const { data: suppliers } = useQuery(
    "suppliers",
    () => fetchDetails(`/supplier?idCompany=${user.idCompany ?? idCompany}&page=1&perPage=10000`, setLoading),
    {
      select: suppliers =>
        suppliers.data?.map(i => ({ value: i.id, label: i.name }))
    }
  )


  const handleInputmeasure = async e => {
    if (e === null || "") {
      setMeasure("notOk");
      setMeasureError("Campo obrigatório.");
      setMeasureRole("");
      return;
    } else {
      setMeasure("notOk");
      setMeasureError("");
      setMeasureRole("");
    }

    if (measures.find(m => m.label === e.label)) {
      setMeasure(e);
      setMeasureRole("ok");
    } else {
      const newMeasure = await api.makeHttpRequest({
        url: `/measure`,
        method: "POST",
        data: {
          name: e.value,
          idCompany: user.idCompany ?? idCompany
        }
      });

      queryClient.invalidateQueries("measures");
      setMeasure({ value: newMeasure.id, label: newMeasure.name });
      setMeasureError("");
      setMeasureRole("ok");
    }
  };

  const handleInputname = e => {
    if (e !== "") {
      setName(e.toUpperCase());
      setNameError("");
      setNameRole("ok");
    } else {
      setName(e);
      setNameError("Campo obrigatório.");
      setNameRole("ok");
    }
  };

  const handleInputsupplier = e => {
    console.log(e)
    if (e !== null || "") {
      setSupplier(e);
      setSupplierError("");
      setSupplierRole("ok");
    } else {
      setSupplier(e);
      setSupplierError("Campo obrigatório.");
      setSupplierRole("notOk");
    }
  };

  const fetchProductInfo = useCallback(
    async id => {
      try {
        setLoading(true);

        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/product/${id}`
        });

        setProductInfo(response)

        setCode(response.code);
        setCodeRole("ok");

        setName(response.name);
        setNameRole("ok");

        if (response.idMeasure) {
          setMeasure({
            label: response.measure.name,
            value: response.measure.id
          });
          setMeasureRole("ok");
        }
        setMinimumQuantity(response.minimumQuantity);
        setMinimumQuantityRole("ok");

        setInventory(response.inventory);
        setInventoryRole("ok");

        if (response.idSupplier) {
          setSupplier({
            label: response.supplier.name,
            value: response.supplier.id
          });
          setSupplierRole("ok");
        }

        setObs(response.obs);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (!productId) {
      return;
    }

    fetchProductInfo(productId);
  }, [fetchProductInfo, productId]);

  const submit = useCallback(
    async e => {
      e.preventDefault();

      try {
        setLoading(true);
        await api.makeHttpRequest({
          url: productId ? `/product/${productId}` : "/product",
          method: productId ? "PUT" : "POST",
          data: {
            code: code,
            name: name,
            minimumQuantity: parseInt(minimumQuantity),
            inventory: parseInt(inventory),
            obs: obs,
            idSupplier: supplier.value,
            idMeasure: idMeasure.value,
            idCompany: user.idCompany ?? idCompany
          }
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Produto ${productId ? "editado" : "cadastrado"} com sucesso.`
        }).then(() => {
          history.push("/admin/produtos");
        });
      } catch (error) {
        if (
          error.response?.data?.errors
            ?.toLowerCase()
            .includes("produto já existe no sistema")
        ) {
          setCodeError("Código já cadastrado em nosso banco de dados");
          setCodeRole("notOk");
          console.log(error.response?.data?.errors);
          Swal.fire({
            title: "Erro!",
            text: "Produto já cadastrado em nosso banco de dados",
            icon: "error"
          });
        } else {
          Swal.fire({
            title: "Erro!",
            text:
              "Erro tentar cadastrar novo produto, tente novamente mais tarde!",
            icon: "error"
          }).then(() => {
            history.push("/admin/produtos");
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      api,
      inventory,
      minimumQuantity,
      idCompany,
      user.idCompany,
      productId,
      code,
      name,
      idMeasure,
      history,
      supplier,
      obs
    ]
  );

  const formIsValid = useCallback(() => {
    let isValid = false;

    isValid =
      inventory !== "" &&
      inventoryRole === "ok" &&
      code !== "" &&
      codeRole === "ok" &&
      idMeasure !== "" &&
      measureRole === "ok" &&
      minimumQuantity !== "" &&
      minimumQuantityRole === "ok" &&
      name !== "" &&
      nameRole === "ok" &&
      supplierRole !== "notOk";

    return isValid;
  }, [
    minimumQuantity,
    minimumQuantityRole,
    code,
    codeRole,
    idMeasure,
    measureRole,
    inventory,
    inventoryRole,
    nameRole,
    name,
    supplier
  ]);

  const onCleanForm = async () => {};

  return (
    userHasAccess && (
      <div className={styles.container}>
        <Loading isLoading={loading} />
        <h3 className={styles.formTitle}>
          {" "}
          <img src={shoppingCart} alt={"Produto"} />
          {formTitle}
        </h3>

        <form className={styles.form} autoComplete="off">
          <div className={styles.containerInputs}>
            <InputForm
              type="text"
              name="Código do Produto"
              placeholder="Código do Produto"
              value={code}
              error={codeError}
              onBlur={() => checkCode(code)}
              onChange={handleInputcode}
              role={codeRole}
              label="Código do Produto"
              required
            />

            <InputForm
              type="text"
              name="Nome do Produto"
              placeholder="Nome do Produto"
              value={name}
              error={nameError}
              onBlur={() => checkName(name)}
              onChange={handleInputname}
              role={nameRole}
              label={"Nome do Produto"}
              required
            />

            <InputForm
              name="Fornecedor"
              placeholder="Fornecedor"
              value={supplier}
              error={supplierError}
              onChange={handleInputsupplier}
              role={supplierRole}
              required
              label={"Fornecedor"}
              options={suppliers}
              isSelect
            />
          </div>

          <div className={styles.containerInputs}>
            <InputForm
              name="Unidade de Medida"
              placeholder="Digite a unidade de medida"
              value={idMeasure}
              error={measureError}
              onChange={handleInputmeasure}
              role={measureRole}
              label="Unidade de medida"
              required
              options={measures}
              isCreateable
              addProductSelect
              newOption="Criar unidade de Medida"
            />

            <InputForm
              type="text"
              name="Estoque Mínimo"
              placeholder="Digite a quantidade"
              value={minimumQuantity}
              error={minimumQuantityError}
              onChange={handleInputminimumQuantity}
              role={minimumQuantityRole}
              label="Estoque Mínimo"
              required
            />

            <InputForm
              type="text"
              name="Estoque Inicial"
              placeholder="Digite a quantidade"
              value={inventory}
              error={inventoryError}
              onChange={handleInputinventory}
              role={inventoryRole}
              label="Estoque Inicial"
              required
            />
          </div>

          <div className={styles.containerInputs}>
            <InputForm
              type="text"
              placeholder="Observações"
              onChange={handleInputObs}
              value={obs}
              name="Observações"
              label="Observações"
            />
          </div>
          <FormAlert />
        </form>

        <FormActions
          module={"produtos"}
          //isEdit={!!data.data.match.params.id}
          formIsValid={formIsValid()}
          onCleanForm={onCleanForm}
          onSubmit={submit}
          hideCancel={true}
          messagem
        />
      </div>
    )
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(FormProduct)
