import React from "react";
import { FormattedMessage } from "react-intl";
import farm from "../../../app/assets/icons/fazendinha.svg";
import dashboard from "../../../app/assets/icons/dashboard.svg";
import profile from "../../../app/assets/icons/usuarios-perfis.svg";
import users from "../../../app/assets/icons/users.svg";
import tractor from "../../../app/assets/icons/trator.svg";
import clients from "../../../app/assets/icons/clientes.svg";
import shoppingCart from "../../../app/assets/icons/ShoppingCart.svg";
import briefcase from "../../../app/assets/icons/briefcase.svg";
import production from "../../../app/assets/icons/production.svg";
import supply from "../../../app/assets/icons/supply.svg";
import truck from "../../../app/assets/icons/truck.svg";
import fuelSupply from "../../../app/assets/icons/fuel-supply.svg";
import chore from "../../../app/assets/icons/chore.svg";
import maintenance from "../../../app/assets/icons/maintenance.svg";
import reports from "../../../app/assets/icons/reports.svg";
import clienteIcon from "../../../app/assets/icons/message.svg";
import money from "../../../app/assets/icons/money.svg";
import vehicle from "../../../app/assets/icons/vehicle.svg";
import streamline from "../../../app/assets/icons/streamline.svg";
import streamline_money from "../../../app/assets/icons/streamline_money.svg";
import discount from '../../../app/assets/icons/discount.svg';
import fee from '../../../app/assets/icons/fee.svg';
import category from '../../../app/assets/icons/category.svg';

export default class MenuItemText extends React.Component {
  render() {
    const icons = {
      farm,
      dashboard,
      users,
      profile,
      tractor,
      clients,
      shoppingCart,
      briefcase,
      production,
      supply,
      maintenance,
      chore,
      fuelSupply,
      reports,
      truck,
      clienteIcon,
      money,
      vehicle,
      streamline,
      discount,
      fee,
      category,
      streamline_money
    };
    const { item, parentItem } = this.props;

    return (
      <>
        {item.icon && (
          <div className="kt-menu__link-icon">
            <img src={icons[item.icon]} className="imagem-menu" alt="" />
          </div>
        )}

        {item.translate ? (
          <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          </span>
        ) : (
          <span
            className="kt-menu__link-text"
            style={{ whiteSpace: "nowrap" }}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        )}

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {/*{item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}*/}
      </>
    );
  }
}
