import { createCipheriv, createDecipheriv } from "crypto";

export const encrypt = data => {
  const cipher = createCipheriv(
    "aes-256-cbc",
    process.env.REACT_APP_ENCRYPT_KEY || "",
    process.env.REACT_APP_ENCRYPT_IV || ""
  );

  let dataEncrypted = cipher.update(JSON.stringify(data), "utf-8", "hex");

  dataEncrypted += cipher.final("hex");

  return dataEncrypted;
};

export const decrypt = data => {
  const decipher = createDecipheriv(
    "aes-256-cbc",
    process.env.REACT_APP_ENCRYPT_KEY || "",
    process.env.REACT_APP_ENCRYPT_IV || ""
  );

  let dataDecrypted = decipher.update(data, "hex", "utf-8");

  dataDecrypted += decipher.final("utf-8");

  return JSON.parse(dataDecrypted);
};
