import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getCategories = async config => {
  return await api.makeHttpRequest({
    url: "/categories",
    method: "GET",
    params: config
  })
}

export const createCategory = async category => {
  return await api.makeHttpRequest({
    url: "/categories",
    method: "POST",
    data: category
  });
};

export const updateCategory = async category => {
  return await api.makeHttpRequest({
    url: "/categories",
    method: "PUT",
    data: category
  });
};

export const updateSubcategory = async category => {
  return await api.makeHttpRequest({
    url: "/categories/subcategories",
    method: "PUT",
    data: category
  });
};

export const deleteCategory = async category => {
  return await api.makeHttpRequest({
    url: "/categories",
    method: "DELETE",
    data: category
  });
};
