export const FinancialMoveStatus = {
  DONE: "Realizado",
  PENDING: "Pendente"
};

export const FinancialMoveTo = {
  CLIENT: "Cliente",
  SUPPLIER: "Fornecedor",
  WORKER: "Funcionario",
  OTHERS: "Outros"
};

export const FinancialMoveType = {
  INVESTIMENT: "Investimento",
  RECEIPT: "Receita",
  EXPENSE: "Despesa"
};

export const FinancialMoveMethods = {
  CHEQUE: "Cheque",
  CREDITO: "Crédito",
  DEBITO: "Débito",
  DINHEIRO: "Dinheiro",
  TRANSFERENCIA: "Transferência",
  PIX: "Pix",
  CREDITO_COMP: "Crédito Comp.",
  DEBITO_COMP: "Débito Comp."
};
