
import React, { useState, useEffect } from 'react';
import DialogActions from '@material-ui/core/DialogActions/';
import DialogContent from '@material-ui/core/DialogContent/';
import DialogContentText from '@material-ui/core/DialogContentText/';
import DialogTitle from '@material-ui/core/DialogTitle/';
import { Form, Col } from "react-bootstrap";
import Swal from "sweetalert2";

import verifyCpfCnpj, { formatCPF } from "../../utils/validators/IsValidCpfCnpj";
import formatTel from "../../utils/FormatTel";
import formatCel from "../../utils/FormatCel";
import formatRg from "../../utils/FormatRg";
import IsValidName from "../../utils/validators/IsValidName";
import IsValidEmail from "../../utils/validators/IsValidEmail";
import { InputField } from "../../pages/admin/components/InputField";
import { AppAPIService } from "../../services/AppAPIService";
import { Button, StyledDialog } from './components/Dialog/style';

export default function ConfirmChangeInputs({ open, closeDialog, title, hideTitle, data, setReload, handleAttorneyUpdate }) {

  const api = AppAPIService.getInstance();

  const id = data.id;
  const [name, setName] = useState(data.name);
  const [cpf, setCpf] = useState(data.cpf);
  const [rg, setRg] = useState(data.rg);
  const [email, setEmail] = useState(data.email);
  const [mobileNumber, setMobileNumber] = useState(data.mobileNumber);
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);

  async function handleChangeAttorney(id) {
    try {

      const rawCPF = cpf && cpf.replace(/\D/g, "");
      const rawRG = rg && rg.replace(/\D/g, "");
      const rawMobile = mobileNumber && mobileNumber.replace(/\D/g, "");
      const rawPhone = phoneNumber && phoneNumber.replace(/\D/g, "");

      await api.makeHttpRequest({
        method: "PATCH",
        url: `/attorney/${id}`,
        data: {
          name: name ?? "",
          cpf: rawCPF ?? "",
          rg: rawRG ?? "",
          email: email ?? "",
          mobileNumber: rawMobile ?? "",
          phoneNumber: rawPhone ?? ""
        }
      });

      closeDialog(false);
      Swal.fire({
        title: "Sucesso!",
        text: "Procurador alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });

      setReload(c => { return !c });
    } catch (e) {
      closeDialog(false);
      Swal.fire("Erro!", "Erro ao alterar procurador.", "error");
    }
  }

  function formValid() {
    const validFirst =
      !!name &&
      IsValidName(name) &&

      !!rg &&
      rg.replace(/\D/g, "").length === 9 &&
      formatRg(rg) &&

      !!cpf &&
      cpf.replace(/\D/g, "").length === 11 &&
      verifyCpfCnpj(cpf).isValid &&

      !!email &&
      IsValidEmail(email) &&

      !!mobileNumber &&
      formatCel(mobileNumber) &&

      !!phoneNumber &&
      formatTel(phoneNumber);
    return validFirst;
  }

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hideTitle && <DialogTitle id="alert-dialog-title">{title && title !== '' ? title : "Deseja editar parentesco?"}</DialogTitle>}
        <DialogContent>
          <Form.Row>
            <Form.Group as={Col} xl>
              <InputField
                name="name"
                type="text"
                label='Nome'
                placeholder="Digite o nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={name && !IsValidName(name)}
                required
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6">
              <InputField
                name="cpf"
                type="text"
                label='CPF'
                placeholder="Digite o CPF"
                value={formatCPF(cpf)}
                onChange={(e) => setCpf(e.target.value)}
                maxLength={14}
                isInvalid={
                  cpf &&
                  (!verifyCpfCnpj(cpf).isValid ||
                    cpf.replace(/\D/g, "").length !== 11)
                }
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg="6">
              <InputField
                name="rg"
                type="text"
                label='RG'
                placeholder="Digite o RG"
                value={formatRg(rg)}
                onChange={(e) => setRg(e.target.value)}
                maxLength={12}
                isInvalid={
                  rg &&
                    rg.replace(/\D/g, "").length !== 9
                }
                required

              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xl>
              <InputField
                name="email"
                type="text"
                label='E-mail'
                placeholder="Digite o e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={email && !IsValidEmail(email)}
                required
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                name="mobileNumber"
                type="text"
                label='Celular'
                placeholder="Digite o celular com DDD"
                value={formatCel(mobileNumber)}
                onChange={(e) => setMobileNumber(formatCel(e.target.value))}
                isInvalid={mobileNumber && mobileNumber.replace(/\D/g, "").length !== 11}
                maxLength={15}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                name="attorneyPhoneNumber"
                type="text"
                label='Telefone'
                placeholder="Digite o telefone com DDD"
                value={formatTel(phoneNumber)}
                onChange={(e) => setPhoneNumber(formatTel(e.target.value))}
                isInvalid={phoneNumber && phoneNumber.replace(/\D/g, "").length !== 10}
                maxLength={14}
                required
              />
            </Form.Group>
          </Form.Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancelar
          </Button>
          <Button
            onClick={() => handleChangeAttorney(id)}
            color="primary"
            disabled={!formValid()}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
