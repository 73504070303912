import React, { useState } from "react";
import { connect } from "react-redux";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";

import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import streamline_money from "../../../../assets/icons/streamline_money.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { FormatMoney } from "../../../../utils/FormatMoney";
import './custom-styles.css';
import CustomDataTable from "./CustomDataTable";
import { flowRight, indexOf } from "lodash";

const propsDatatable = {
  title: "Análise do Fluxo de Caixa",
  endpoint: "/financialflowanalysis",
  className: "h-100 analystic",
  placeholderSearchYear: "Selecione o ano",
  customAnalisysHeader: true
};

const AnalisysFlowListing = props => {
  window.setPageTitle("Análise do Fluxo de Caixa - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.FINANCIALFLOWANALYSIS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.FINANCIALFLOWANALYSIS_READ.ENGLISH
  );

  const [loading, setLoading] = useState(false);

  const months = [
    { width: "317px", label: "JANEIRO", value: "jan", align: "left" },
    { width: "317px", label: "FEVEREIRO", value: "fev", align: "left" },
    { width: "317px", label: "MARÇO", value: "mar", align: "left" },
    { width: "317px", label: "ABRIL", value: "abr", align: "left" },
    { width: "317px", label: "MAIO", value: "mai", align: "left" },
    { width: "317px", label: "JUNHO", value: "jun", align: "left" },
    { width: "317px", label: "JULHO", value: "jul", align: "left" },
    { width: "317px", label: "AGOSTO", value: "ago", align: "left" },
    { width: "317px", label: "SETEMBRO", value: "set", align: "left" },
    { width: "317px", label: "OUTUBRO", value: "out", align: "left" },
    { width: "317px", label: "NOVEMBRO", value: "nov", align: "left" },
    { width: "317px", label: "DEZEMBRO", value: "dez", align: "left" }
  ];

  const getVerticalAnalysis = (receiptTotal, itemTotal) => {
    const result = (itemTotal / receiptTotal) * 100;
    if (isNaN(result) || result === Infinity || result === -Infinity) return "0.00%"
    return `${result.toFixed(2)}%`;
  }

  const calculateHorizontalAnalysis = (flowAnalisys) => {
    flowAnalisys.categories.map((category) => {
      category.subcategories.map((subcategory) => {
        subcategory.groups.map((group) => {
          let lastSerie = null;
          group.series.map((serie) => {
            let result = ((serie.total / (lastSerie?.total ?? 0)) - 1) * 100;
            if (isNaN(result) || result === Infinity || result === -Infinity)
              serie.ah = "0.00";
            else
              serie.ah = result.toFixed(2);
            lastSerie = serie;
          });
        });
      });
    });
  }

  const getFinancialCoastsTotal = (flowAnalisys) => {
    const aggregation = {};

    months.map(month => {
      aggregation[month.value] = 0;
    })

    flowAnalisys.categories.map((category) => {
      if (category.name.toLowerCase() === "custos variáveis") {
        category.subcategories.map((subcategory) => {
          if (subcategory.name.toLowerCase() === "custos financeiros") {
            subcategory.groups.map((group) => {
              group.series.map((serie) => {
                aggregation[serie.referencialMonth] += Number(serie.total);
              });
            });
          }
        });
      }
    });

    return aggregation;
  }

  const headRows = [
    { width: "317px", label: "CATEGORIA" },
    ...months
  ];

  const getBackgroudRow = value => {
    let color;

    const code = value.split(" ");
    const validCode = new RegExp("^[0-9].$").test(code[0]);

    if (validCode) {
      return "#2992DB";
    }

    switch (String(value).toLowerCase()) {
      case "total":
        color = "#FFFDBD";
        break;

      default:
        color = "transparent";
        break;
    }

    return color;
  };

  const toDecimal = value => {
    return Number(value).toFixed(2);
  };

  const getCategoryAggregationTotalPerMonth = (flowAnalisys, categoryType) => {

    if (flowAnalisys?.categories) {
      const aggregations = [];
      for (let category of flowAnalisys.categories) {
        for (const subcategory of category.subcategories) {
          if (
            (subcategory.type === 'receita' && subcategory.name === 'entrada não operacional') ||
            (subcategory.type === 'despesa' && subcategory.name === 'saída não operacional')
          ) {
            // "Entrada e/ou saída não operacional ignorada..."
            continue;
          }

          if (subcategory.type === categoryType) {
            aggregations.push(subcategory.total)
          }
        }
      };

      const aggregated = {};
      for (let aggregation of aggregations) {
        for (let key of Object.keys(aggregation)) {
          if (!Object.keys(aggregated).includes(key)) {
            aggregated[key] = aggregation[key];
          } else {
            aggregated[key] += aggregation[key];
          }
        }
      }

      return aggregated;
    }
  }

  const getContributionMargin = (flow) => {
    const receiptTotal = getCategoryAggregationTotalPerMonth(flow, 'receita')
    const contributionMargin = {}

    months.map((month) => {
      contributionMargin[month.value] = {
        value: 0.00,
        ah: 0.00
      };
    });

    const variableCosts = flow?.categories?.find(category => category.name.toUpperCase().includes('CUSTOS VARIÁVEIS'))

    months.map((month) => {
      const value = receiptTotal[month.value] - (variableCosts?.total[month.value] ?? 0)
      contributionMargin[month.value] = {
        value,
        av: getVerticalAnalysis(receiptTotal[month.value], value)
      }
    })

    return contributionMargin
  }

  const getOperatingProfitBeforeInvestiments = (flow) => {
    const receiptTotal = getCategoryAggregationTotalPerMonth(flow, 'receita')
    const contributionMargin = getContributionMargin(flow)

    const operatingProfitBeforeInvestiments = {}

    months.map((month) => {
      operatingProfitBeforeInvestiments[month.value] = {
        value: 0.00,
        ah: 0.00
      };
    });

    const fixedCostsCategory = flow?.categories?.find(category => category.name.toUpperCase().includes('DESPESAS FIXAS'))
    if (!fixedCostsCategory) return operatingProfitBeforeInvestiments;

    const fixedCostsAggregation = {}

    months.map((month) => {
      fixedCostsAggregation[month.value] = 0;
    });

    const includedSubcategories = [
      'despesas administrativas',
      'despesa com alimentação',
      'despesa com pessoal'
    ]

    for (const subcategory of fixedCostsCategory.subcategories) {
      if (includedSubcategories.includes(subcategory.name.toLowerCase())) {
        months.map((month) => {
          fixedCostsAggregation[month.value] += subcategory.total[month.value];
        });
      }
    }

    months.map((month) => {
      const value = contributionMargin[month.value].value - fixedCostsAggregation[month.value]
      operatingProfitBeforeInvestiments[month.value] = {
        value,
        av: getVerticalAnalysis(receiptTotal[month.value], value)
      }
    })

    return operatingProfitBeforeInvestiments
  }

  const getOperatingProfit = (flow) => {

    const receiptTotal = getCategoryAggregationTotalPerMonth(flow, 'receita')

    const includedSubcategories = [
      'investimento em marketing',
      'investimento em bens materiais',
      'investimento em desenvolvimento'
    ]

    const investimentsAggregations = {}

    months.map((month) => {
      investimentsAggregations[month.value] = 0;
    });

    for (const category of flow.categories) {
      for (const subcategory of category.subcategories) {
        if (includedSubcategories.includes(subcategory.name.toLowerCase())) {
          months.map((month) => {
            investimentsAggregations[month.value] += subcategory.total[month.value];
          });
        }
      }
    }

    const operatingProfitBeforeInvestiments = getOperatingProfitBeforeInvestiments(flow)
    const operatingProfit = {}

    months.map((month) => {
      operatingProfit[month.value] = {
        value: 0.00,
        ah: 0.00
      };
    });

    months.map((month) => {
      const value = operatingProfitBeforeInvestiments[month.value].value - investimentsAggregations[month.value]
      operatingProfit[month.value] = {
        value,
        av: getVerticalAnalysis(receiptTotal[month.value], value)
      }
    })

    return operatingProfit
  }

  const getLiquidProfit = (flow) => {
    const receiptTotal = getCategoryAggregationTotalPerMonth(flow, 'receita')

    const liquidProfit = {}

    months.map((month) => {
      liquidProfit[month.value] = {
        value: 0.00,
        ah: 0.00
      };
    });

    const operatingProfit = getOperatingProfit(flow);

    let nonOperationalInput = { total: [] };
    months.map((month) => {
      nonOperationalInput.total[month.value] = 0.00;
    });

    let nonOperationalOutput = { total: [] };
    months.map((month) => {
      nonOperationalOutput.total[month.value] = 0.00;
    });

    for (const category of flow.categories) {
      for (const sub of category.subcategories) {
        if (sub.name.trim() === "entrada não operacional")
          nonOperationalInput = sub;
        if (sub.name.trim() === "saída não operacional")
          nonOperationalOutput = sub;
      }
    }

    months.map((month) => {
      const value = operatingProfit[month.value].value + nonOperationalInput.total[month.value] - nonOperationalOutput.total[month.value]
      liquidProfit[month.value] = {
        value,
        av: getVerticalAnalysis(receiptTotal[month.value], value)
      }
    })

    return liquidProfit
  }

  const flowOrder = {
    "receita e faturamento": [
      "receita de vendas"
    ],
    "custos variáveis": [
      "custo tributário",
      "custo fornecedor",
      "custos financeiros"
    ],
    "despesas fixas": [
      "despesas administrativas",
      "despesa com alimentação",
      "despesa com pessoal"
    ],
    "investimento em marketing": [
      "investimento em marketing"
    ],
    "investimento em bens materiais": [
      "investimento em bens materiais"
    ],
    "investimento em desenvolvimento": [
      "investimento em desenvolvimento"
    ],
    "investimentos": [
      "investimento oportunidade/emergência",
    ]
  };


  const orderFlow = (flow) => {
    const sortedFlow = [];
    const flowOrderCategoryKeys = Object.keys(flowOrder);
    const filteredCategories = flow.categories.filter(cat => flowOrderCategoryKeys.includes(cat.name.trim()));

    for (const category of flowOrderCategoryKeys) {
      const foundCategory = filteredCategories.find(c => c.name.trim() === category.trim());
      if (!foundCategory) continue;
      const filteredSubcategories = foundCategory.subcategories.filter(sub => flowOrder[category].includes(sub.name.trim()));
      const nonFilteredSubcategories = foundCategory.subcategories.filter(sub => !flowOrder[category].includes(sub.name.trim()));
      foundCategory.subcategories = [...filteredSubcategories, ...nonFilteredSubcategories];
      sortedFlow.push(foundCategory);
    }

    let nonOperationalOutput = null;
    let nonOperationalInput = null;

    for (const category of flow.categories) {
      for (const sub of category.subcategories) {
        if (sub.name.trim() === "entrada não operacional")
          nonOperationalInput = sub;
        if (sub.name.trim() === "saída não operacional")
          nonOperationalOutput = sub;
      }
    }

    if (nonOperationalInput || nonOperationalOutput) {
      sortedFlow.push({
        id: 0,
        name: "Movimentação não operacional",
        subcategories: [nonOperationalInput, nonOperationalOutput]
      })
    }

    const nonFilteredCategories = flow.categories.filter(cat => {
      return (
        !flowOrderCategoryKeys.includes(cat.name.trim()) &&
        !cat.name.toLowerCase().includes("movimentação não operacional")
      )
    });

    flow.categories = [...sortedFlow, ...nonFilteredCategories];
  }


  const formatRow = r => {
    calculateHorizontalAnalysis(r);
    const contributionMargin = getContributionMargin(r);
    const operatingProfitBeforeInvestiments = getOperatingProfitBeforeInvestiments(r);
    const operatingProfit = getOperatingProfit(r);
    const liquidProfit = getLiquidProfit(r);

    const receiptTotal = getCategoryAggregationTotalPerMonth(r, 'receita');
    orderFlow(r);

    return (
      <>
        {r.categories?.map((category, categoryIndex) => (
          <>
            <StyledTableRow key={category.id} tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
              {[...Array(12)].map((x, i) => (
                <StyledTableCell
                  scope="row"
                  style={{
                    background: "#2992DB",
                    width: "100%"
                  }}
                />
              ))}
            </StyledTableRow>

            {category.subcategories?.map(sub => (
              <>
                <StyledTableRow
                  key={sub.id}
                  bg={getBackgroudRow(sub.name)}
                  tabIndex={-1}
                  style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}
                >
                  {[...Array(12)].map((x, i) => (
                    <StyledTableCell
                      scope="row"
                      style={{
                        fontWeight: "600",
                        color: "#A1A1AA",
                        textTransform: "capitalize",
                        textAlign: "center",
                        display: "flex",
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        AV
                      </td>
                      <td
                        style={{
                          width: "100%",
                        }}
                      >
                        AH
                      </td>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>

                {sub.groups?.map(group => (
                  <>
                    <StyledTableRow key={group.id} hover tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                      {group.series.map(serie => (
                        <StyledTableCell
                          scope="row"
                          style={{
                            color: "#333333 !important",
                            display: "flex",
                            width: "100%",
                            borderRight: "1px solid rgba(224, 224, 224, 1)"
                          }}
                        >
                          <td
                            style={{
                              width: "100%",
                              borderRight: "1px solid rgba(224, 224, 224, 1)"
                            }}
                          >
                            {FormatMoney(toDecimal(serie.total))}
                          </td>
                          <td
                            style={{
                              width: "100%",
                              borderRight: "1px solid rgba(224, 224, 224, 1)"
                            }}
                          >
                            {getVerticalAnalysis(receiptTotal[serie.referencialMonth], serie.total)}
                          </td>
                          <td
                            style={{
                              width: "100%",
                            }}
                          >
                            {`${serie.ah}%`}
                          </td>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </>
                ))}

                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                  {months.map((month) => (
                    <StyledTableCell
                      scope="row"
                      style={{
                        background: "#FFFDBD",
                        display: "flex",
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {FormatMoney(sub.total[month.value].toFixed(2))}
                      </td>
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {getVerticalAnalysis(receiptTotal[month.value], sub.total[month.value])}
                      </td>
                      <td
                        style={{
                          width: "100%",
                        }}
                      >
                        --
                      </td>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </>
            ))}

            {category.name.toUpperCase().includes("CUSTOS VARIÁVEIS") ? (
              <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                {months.map((month) => (
                  <StyledTableCell
                    scope="row"
                    style={{
                      background: "#F1F388",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    <td
                      style={{
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      {FormatMoney(toDecimal(contributionMargin[month.value].value))}
                    </td>
                    <td
                      style={{
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      {contributionMargin[month.value].av}
                    </td>
                    <td
                      style={{
                        width: "100%",
                      }}
                    />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ) : null}

            {category.name.toUpperCase().includes("DESPESAS FIXAS") ? (
              <>
                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                  {months.map(month => (
                    <StyledTableCell
                      scope="row"
                      style={{
                        background: "#F1F37F",
                        display: "flex",
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {FormatMoney(operatingProfitBeforeInvestiments[month.value].value.toFixed(2))}
                      </td>
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {operatingProfitBeforeInvestiments[month.value].av}
                      </td>
                      <td
                        style={{
                          width: "100%",
                        }}
                      >
                        --
                      </td>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                  {months.map(month => (
                    <StyledTableCell
                      scope="row"
                      style={{
                        background: "#F9D2B1",
                        display: "flex",
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      <td
                        style={{
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {FormatMoney(operatingProfit[month.value].value.toFixed(2))}
                      </td>
                      <td
                        style={{
                          width: "100%",
                        }}
                      >
                        {operatingProfit[month.value].av}
                      </td>
                      <td
                        style={{
                          width: "100%",
                        }}
                      >
                        --
                      </td>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </>
            ) : null}

            {categoryIndex + 1 === r.categories?.length ? (
              <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row", padding: 0 }}>
                {months.map(month => (
                  <StyledTableCell
                    scope="row"
                    style={{
                      background: "#E8EA76",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    <td
                      style={{
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      {FormatMoney(liquidProfit[month.value].value.toFixed(2))}
                    </td>
                    <td
                      style={{
                        width: "100%",
                        borderRight: "1px solid rgba(224, 224, 224, 1)"
                      }}
                    >
                      {liquidProfit[month.value].av}
                    </td>
                    <td
                      style={{
                        width: "100%",
                      }}
                    >
                      --
                    </td>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ) : null}
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <CustomDataTable
        {...propsDatatable}
        icon={
          <img src={streamline_money} alt={"Icone Análise Fluxo de Caixa"} />
        }
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        paginator={false}
        dateFilter
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(AnalisysFlowListing);
