import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUser } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Refresh: "[Refresh] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  // user: { name: "Admin", role: { type: "ADMIN" } },
  user: undefined,
  authData: undefined
};

export const reducer = persistReducer(
  { storage, key: "auth:ohana", whitelist: ["user", "authData", "roles"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authData } = action.payload;
        return { authData, user: undefined };
      }

      case actionTypes.Refresh: {
        const { authData } = action.payload;
        return { ...state, authData };
      }

      case actionTypes.Register: {
        const { authData } = action.payload;
        return { authData, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        localStorage.clear();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user, roles: user.roles };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authData, user) => ({
    type: actionTypes.Login,
    payload: { authData, user }
  }),
  refresh: authData => ({
    type: actionTypes.Refresh,
    payload: { authData }
  }),
  register: authData => ({
    type: actionTypes.Register,
    payload: { authData }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Refresh, function* refreshSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const data = yield getUser();
    yield put(actions.fulfillUser(data));
  });
}
