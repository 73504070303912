import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as IconCircleLeft } from "../../../assets/icons/akar-icons_circle_dark.svg";
import { ReactComponent as IconCircleRight } from "../../../assets/icons/icon_circle-chevron-right-fill.svg";
import globalColors from "../../../../_metronic/_assets/sass/custom/globalColors.scss";

const Button = styled.button`
  position: relative;
  width: ${({ submitText }) => (submitText ? "200px" : "150px")};
  height: 48px;

  font-family: "Cera Pro Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;

  padding: 0;

  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  border-radius: 4px;
  transition: filter 0.4s;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: ${({ variant }) =>
    variant === "cancel" ? "underline" : ""};
  }

  &:hover {
    filter: brightness(90%);
    cursor: pointer;
  }

  &:disabled {
    background: ${globalColors.textColor3};
    cursor: not-allowed;
    border-color: ${({ color }) =>
    color ? globalColors.textColor3 : "#4D4C4D88"};
  }

  ${({ variant }) =>
    variant === "primary"
      ? `
    background: ${globalColors.primaryColor1};
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `
      : variant === "cancel" &&
      `
      background: transparent;
      color: ${globalColors.primaryColor1};
      border-color: transparent
    `};
`;

const FormActions = ({
  module,
  onSubmit,
  formIsValid,
  submitText,
  hideCancel,
  backButton,
  showOs
}) => {
  return (
    <div
      className="text-right"
      style={{
        marginTop: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "right"
      }}
    >
      {hideCancel ? (
        <Link to={`/admin/${module}`}>
          <Button
            variant="cancel"
            color={globalColors.primaryColor1}
            type="button"
          >
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconCircleLeft /> Cancelar
            </span>
          </Button>
        </Link>
      ) : null}

      {backButton && !hideCancel ? (
        <Button
          variant="secondary"
          color={globalColors.primaryColor1}
          type="button"
          onClick={backButton}
        >
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconCircleLeft /> Cancelar
          </span>
        </Button>
      ) : null}

      {/* {
        showOs ? (
          <Button
            type="submit"
            variant="primary"
            color={globalColors.primaryColor2}
            onClick={onCompleteOS}
            title="Concluir OS"
            style={{ marginRight: "8px" }}
          >
            <span className="text-light">
              Concluir OS
            </span>
          </Button>
        ) : null
      } */}

      <Button
        variant="primary"
        type="submit"
        color={globalColors.primaryColor1}
        background={globalColors.primaryColor1}
        onClick={onSubmit}
        disabled={!formIsValid}
        title={formIsValid ? "Salvar dados" : "Verifique os campos"}
        submitText={submitText}
      >
        <span className="text-light" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {submitText ?? "Salvar"} <IconCircleRight />
        </span>
      </Button>
    </div>
  );
};

export default FormActions;
