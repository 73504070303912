export const CountryList = [
  {
    id : "Afeganistão",
    label : "Afeganistão"
  },
  {
    id : "África do Sul",
    label : "África do Sul"
  },
  {
    id : "Albânia",
    label : "Albânia"
  },
  {
    id : "Alemanha",
    label : "Alemanha"
  },
  {
    id : "Andorra",
    label : "Andorra"
  },
  {
    id : "Angola",
    label : "Angola"
  },
  {
    id : "Anguilla",
    label : "Anguilla"
  },
  {
    id : "Antártida",
    label : "Antártida"
  },
  {
    id : "Antígua e Barbuda",
    label : "Antígua e Barbuda"
  },
  {
    id : "Arábia Saudita",
    label : "Arábia Saudita"
  },
  {
    id : "Argélia",
    label : "Argélia"
  },
  {
    id : "Argentina",
    label : "Argentina"
  },
  {
    id : "Armênia",
    label : "Armênia"
  },
  {
    id : "Aruba",
    label : "Aruba"
  },
  {
    id : "Austrália",
    label : "Austrália"
  },
  {
    id : "Áustria",
    label : "Áustria"
  },
  {
    id : "Azerbaijão",
    label : "Azerbaijão"
  },
  {
    id : "Bahamas",
    label : "Bahamas"
  },
  {
    id : "Bahrein",
    label : "Bahrein"
  },
  {
    id : "Bangladesh",
    label : "Bangladesh"
  },
  {
    id : "Barbados",
    label : "Barbados"
  },
  {
    id : "Belarus",
    label : "Belarus"
  },
  {
    id : "Bélgica",
    label : "Bélgica"
  },
  {
    id : "Belize",
    label : "Belize"
  },
  {
    id : "Benin",
    label : "Benin"
  },
  {
    id : "Bermudas",
    label : "Bermudas"
  },
  {
    id : "Bolívia",
    label : "Bolívia"
  },
  {
    id : "Bósnia-Herzegóvina",
    label : "Bósnia-Herzegóvina"
  },
  {
    id : "Botsuana",
    label : "Botsuana"
  },
  {
    id : "Brasil",
    label : "Brasil"
  },
  {
    id : "Brunei",
    label : "Brunei"
  },
  {
    id : "Bulgária",
    label : "Bulgária"
  },
  {
    id : "Burkina Fasso",
    label : "Burkina Fasso"
  },
  {
    id : "Burundi",
    label : "Burundi"
  },
  {
    id : "Butão",
    label : "Butão"
  },
  {
    id : "Cabo Verde",
    label : "Cabo Verde"
  },
  {
    id : "Camarões",
    label : "Camarões"
  },
  {
    id : "Camboja",
    label : "Camboja"
  },
  {
    id : "Canadá",
    label : "Canadá"
  },
  {
    id : "Canárias",
    label : "Canárias"
  },
  {
    id : "Cazaquistão",
    label : "Cazaquistão"
  },
  {
    id : "Ceuta e Melilla",
    label : "Ceuta e Melilla"
  },
  {
    id : "Chade",
    label : "Chade"
  },
  {
    id : "Chile",
    label : "Chile"
  },
  {
    id : "China",
    label : "China"
  },
  {
    id : "Chipre",
    label : "Chipre"
  },
  {
    id : "Cingapura",
    label : "Cingapura"
  },
  {
    id : "Colômbia",
    label : "Colômbia"
  },
  {
    id : "Comores",
    label : "Comores"
  },
  {
    id : "Congo",
    label : "Congo"
  },
  {
    id : "Coréia do Norte",
    label : "Coréia do Norte"
  },
  {
    id : "Coréia do Sul",
    label : "Coréia do Sul"
  },
  {
    id : "Costa do Marfim",
    label : "Costa do Marfim"
  },
  {
    id : "Costa Rica",
    label : "Costa Rica"
  },
  {
    id : "Croácia",
    label : "Croácia"
  },
  {
    id : "Cuba",
    label : "Cuba"
  },
  {
    id : "Curaçao",
    label : "Curaçao"
  },
  {
    id : "Diego Garcia",
    label : "Diego Garcia"
  },
  {
    id : "Dinamarca",
    label : "Dinamarca"
  },
  {
    id : "Djibuti",
    label : "Djibuti"
  },
  {
    id : "Dominica",
    label : "Dominica"
  },
  {
    id : "Egito",
    label : "Egito"
  },
  {
    id : "El Salvador",
    label : "El Salvador"
  },
  {
    id : "Emirados Árabes Unidos",
    label : "Emirados Árabes Unidos"
  },
  {
    id : "Equador",
    label : "Equador"
  },
  {
    id : "Eritréia",
    label : "Eritréia"
  },
  {
    id : "Eslováquia",
    label : "Eslováquia"
  },
  {
    id : "Eslovênia",
    label : "Eslovênia"
  },
  {
    id : "Espanha",
    label : "Espanha"
  },
  {
    id : "Estados Unidos",
    label : "Estados Unidos"
  },
  {
    id : "Estônia",
    label : "Estônia"
  },
  {
    id : "Etiópia",
    label : "Etiópia"
  },
  {
    id : "Fiji",
    label : "Fiji"
  },
  {
    id : "Filipinas",
    label : "Filipinas"
  },
  {
    id : "Finlândia",
    label : "Finlândia"
  },
  {
    id : "França",
    label : "França"
  },
  {
    id : "Gabão",
    label : "Gabão"
  },
  {
    id : "Gâmbia",
    label : "Gâmbia"
  },
  {
    id : "Gana",
    label : "Gana"
  },
  {
    id : "Geórgia",
    label : "Geórgia"
  },
  {
    id : "Gibraltar",
    label : "Gibraltar"
  },
  {
    id : "Grã-Bretanha (Reino Unido, UK)",
    label : "Grã-Bretanha (Reino Unido, UK)"
  },
  {
    id : "Granada",
    label : "Granada"
  },
  {
    id : "Grécia",
    label : "Grécia"
  },
  {
    id : "Groelândia",
    label : "Groelândia"
  },
  {
    id : "Guadalupe",
    label : "Guadalupe"
  },
  {
    id : "Guam (Território dos Estados Unidos)",
    label : "Guam (Território dos Estados Unidos)"
  },
  {
    id : "Guatemala",
    label : "Guatemala"
  },
  {
    id : "Guernsey",
    label : "Guernsey"
  },
  {
    id : "Guiana",
    label : "Guiana"
  },
  {
    id : "Guiana Francesa",
    label : "Guiana Francesa"
  },
  {
    id : "Guiné",
    label : "Guiné"
  },
  {
    id : "Guiné Equatorial",
    label : "Guiné Equatorial"
  },
  {
    id : "Guiné-Bissau",
    label : "Guiné-Bissau"
  },
  {
    id : "Haiti",
    label : "Haiti"
  },
  {
    id : "Holanda",
    label : "Holanda"
  },
  {
    id : "Honduras",
    label : "Honduras"
  },
  {
    id : "Hong Kong",
    label : "Hong Kong"
  },
  {
    id : "Hungria",
    label : "Hungria"
  },
  {
    id : "Iêmen",
    label : "Iêmen"
  },
  {
    id : "Ilha Bouvet",
    label : "Ilha Bouvet"
  },
  {
    id : "Ilha de Ascensão",
    label : "Ilha de Ascensão"
  },
  {
    id : "Ilha de Clipperton",
    label : "Ilha de Clipperton"
  },
  {
    id : "Ilha de Man",
    label : "Ilha de Man"
  },
  {
    id : "Ilha Natal",
    label : "Ilha Natal"
  },
  {
    id : "Ilha Pitcairn",
    label : "Ilha Pitcairn"
  },
  {
    id : "Ilha Reunião",
    label : "Ilha Reunião"
  },
  {
    id : "Ilhas Aland",
    label : "Ilhas Aland"
  },
  {
    id : "Ilhas Cayman",
    label : "Ilhas Cayman"
  },
  {
    id : "Ilhas Cocos",
    label : "Ilhas Cocos"
  },
  {
    id : "Ilhas Cook",
    label : "Ilhas Cook"
  },
  {
    id : "Ilhas Faroes",
    label : "Ilhas Faroes"
  },
  {
    id : "Ilhas Geórgia do Sul e Sandwich do Sul",
    label : "Ilhas Geórgia do Sul e Sandwich do Sul"
  },
  {
    id : "Ilhas Heard e McDonald (Território da Austrália)",
    label : "Ilhas Heard e McDonald (Território da Austrália)"
  },
  {
    id : "Ilhas Malvinas",
    label : "Ilhas Malvinas"
  },
  {
    id : "Ilhas Marianas do Norte",
    label : "Ilhas Marianas do Norte"
  },
  {
    id : "Ilhas Marshall",
    label : "Ilhas Marshall"
  },
  {
    id : "Ilhas Menores dos Estados Unidos",
    label : "Ilhas Menores dos Estados Unidos"
  },
  {
    id : "Ilhas Norfolk",
    label : "Ilhas Norfolk"
  },
  {
    id : "Ilhas Salomão",
    label : "Ilhas Salomão"
  },
  {
    id : "Ilhas Seychelles",
    label : "Ilhas Seychelles"
  },
  {
    id : "Ilhas Tokelau",
    label : "Ilhas Tokelau"
  },
  {
    id : "Ilhas Turks e Caicos",
    label : "Ilhas Turks e Caicos"
  },
  {
    id : "Ilhas Virgens (Estados Unidos)",
    label : "Ilhas Virgens (Estados Unidos)"
  },
  {
    id : "Ilhas Virgens (Inglaterra)",
    label : "Ilhas Virgens (Inglaterra)"
  },
  {
    id : "Índia",
    label : "Índia"
  },
  {
    id : "Indonésia",
    label : "Indonésia"
  },
  {
    id : "Irã",
    label : "Irã"
  },
  {
    id : "Iraque",
    label : "Iraque"
  },
  {
    id : "Irlanda",
    label : "Irlanda"
  },
  {
    id : "Islândia",
    label : "Islândia"
  },
  {
    id : "Israel",
    label : "Israel"
  },
  {
    id : "Itália",
    label : "Itália"
  },
  {
    id : "Jamaica",
    label : "Jamaica"
  },
  {
    id : "Japão",
    label : "Japão"
  },
  {
    id : "Jersey",
    label : "Jersey"
  },
  {
    id : "Jordânia",
    label : "Jordânia"
  },
  {
    id : "Kiribati",
    label : "Kiribati"
  },
  {
    id : "Kosovo",
    label : "Kosovo"
  },
  {
    id : "Kuait",
    label : "Kuait"
  },
  {
    id : "Laos",
    label : "Laos"
  },
  {
    id : "Lesoto",
    label : "Lesoto"
  },
  {
    id : "Letônia",
    label : "Letônia"
  },
  {
    id : "Líbano",
    label : "Líbano"
  },
  {
    id : "Libéria",
    label : "Libéria"
  },
  {
    id : "Líbia",
    label : "Líbia"
  },
  {
    id : "Liechtenstein",
    label : "Liechtenstein"
  },
  {
    id : "Lituânia",
    label : "Lituânia"
  },
  {
    id : "Luxemburgo",
    label : "Luxemburgo"
  },
  {
    id : "Macau",
    label : "Macau"
  },
  {
    id : "Macedônia (República Yugoslava)",
    label : "Macedônia (República Yugoslava)"
  },
  {
    id : "Madagascar",
    label : "Madagascar"
  },
  {
    id : "Malásia",
    label : "Malásia"
  },
  {
    id : "Malaui",
    label : "Malaui"
  },
  {
    id : "Maldivas",
    label : "Maldivas"
  },
  {
    id : "Mali",
    label : "Mali"
  },
  {
    id : "Malta",
    label : "Malta"
  },
  {
    id : "Marrocos",
    label : "Marrocos"
  },
  {
    id : "Martinica",
    label : "Martinica"
  },
  {
    id : "Maurício",
    label : "Maurício"
  },
  {
    id : "Mauritânia",
    label : "Mauritânia"
  },
  {
    id : "Mayotte",
    label : "Mayotte"
  },
  {
    id : "México",
    label : "México"
  },
  {
    id : "Micronésia",
    label : "Micronésia"
  },
  {
    id : "Moçambique",
    label : "Moçambique"
  },
  {
    id : "Moldova",
    label : "Moldova"
  },
  {
    id : "Mônaco",
    label : "Mônaco"
  },
  {
    id : "Mongólia",
    label : "Mongólia"
  },
  {
    id : "Montenegro",
    label : "Montenegro"
  },
  {
    id : "Montserrat",
    label : "Montserrat"
  },
  {
    id : "Myanma",
    label : "Myanma"
  },
  {
    id : "Namíbia",
    label : "Namíbia"
  },
  {
    id : "Nauru",
    label : "Nauru"
  },
  {
    id : "Nepal",
    label : "Nepal"
  },
  {
    id : "Nicarágua",
    label : "Nicarágua"
  },
  {
    id : "Níger",
    label : "Níger"
  },
  {
    id : "Nigéria",
    label : "Nigéria"
  },
  {
    id : "Niue",
    label : "Niue"
  },
  {
    id : "Noruega",
    label : "Noruega"
  },
  {
    id : "Nova Caledônia",
    label : "Nova Caledônia"
  },
  {
    id : "Nova Zelândia",
    label : "Nova Zelândia"
  },
  {
    id : "Omã",
    label : "Omã"
  },
  {
    id : "Países Baixos Caribenhos",
    label : "Países Baixos Caribenhos"
  },
  {
    id : "Palau",
    label : "Palau"
  },
  {
    id : "Palestina",
    label : "Palestina"
  },
  {
    id : "Panamá",
    label : "Panamá"
  },
  {
    id : "Papua-Nova Guiné",
    label : "Papua-Nova Guiné"
  },
  {
    id : "Paquistão",
    label : "Paquistão"
  },
  {
    id : "Paraguai",
    label : "Paraguai"
  },
  {
    id : "Peru",
    label : "Peru"
  },
  {
    id : "Polinésia Francesa",
    label : "Polinésia Francesa"
  },
  {
    id : "Polônia",
    label : "Polônia"
  },
  {
    id : "Porto Rico",
    label : "Porto Rico"
  },
  {
    id : "Portugal",
    label : "Portugal"
  },
  {
    id : "Qatar",
    label : "Qatar"
  },
  {
    id : "Quênia",
    label : "Quênia"
  },
  {
    id : "Quirguistão",
    label : "Quirguistão"
  },
  {
    id : "República Centro-Africana",
    label : "República Centro-Africana"
  },
  {
    id : "República Democrática do Congo",
    label : "República Democrática do Congo"
  },
  {
    id : "República Dominicana",
    label : "República Dominicana"
  },
  {
    id : "República Tcheca",
    label : "República Tcheca"
  },
  {
    id : "Romênia",
    label : "Romênia"
  },
  {
    id : "Ruanda",
    label : "Ruanda"
  },
  {
    id : "Rússia (antiga URSS) - Federação Russa",
    label : "Rússia (antiga URSS) - Federação Russa"
  },
  {
    id : "Saara Ocidental",
    label : "Saara Ocidental"
  },
  {
    id : "Saint-Pierre e Miquelon",
    label : "Saint-Pierre e Miquelon"
  },
  {
    id : "Samoa Americana",
    label : "Samoa Americana"
  },
  {
    id : "Samoa Ocidental",
    label : "Samoa Ocidental"
  },
  {
    id : "San Marino",
    label : "San Marino"
  },
  {
    id : "Santa Helena",
    label : "Santa Helena"
  },
  {
    id : "Santa Lúcia",
    label : "Santa Lúcia"
  },
  {
    id : "São Bartolomeu",
    label : "São Bartolomeu"
  },
  {
    id : "São Cristóvão e Névis",
    label : "São Cristóvão e Névis"
  },
  {
    id : "São Martim",
    label : "São Martim"
  },
  {
    id : "São Martinho",
    label : "São Martinho"
  },
  {
    id : "São Tomé e Príncipe",
    label : "São Tomé e Príncipe"
  },
  {
    id : "São Vicente e Granadinas",
    label : "São Vicente e Granadinas"
  },
  {
    id : "Senegal",
    label : "Senegal"
  },
  {
    id : "Serra Leoa",
    label : "Serra Leoa"
  },
  {
    id : "Sérvia",
    label : "Sérvia"
  },
  {
    id : "Síria",
    label : "Síria"
  },
  {
    id : "Somália",
    label : "Somália"
  },
  {
    id : "Sri Lanka",
    label : "Sri Lanka"
  },
  {
    id : "Suazilândia",
    label : "Suazilândia"
  },
  {
    id : "Sudão",
    label : "Sudão"
  },
  {
    id : "Sudão do Sul",
    label : "Sudão do Sul"
  },
  {
    id : "Suécia",
    label : "Suécia"
  },
  {
    id : "Suíça",
    label : "Suíça"
  },
  {
    id : "Suriname",
    label : "Suriname"
  },
  {
    id : "Svalbard",
    label : "Svalbard"
  },
  {
    id : "Tadjiquistão",
    label : "Tadjiquistão"
  },
  {
    id : "Tailândia",
    label : "Tailândia"
  },
  {
    id : "Taiwan",
    label : "Taiwan"
  },
  {
    id : "Tanzânia",
    label : "Tanzânia"
  },
  {
    id : "Território Britânico do Oceano índico",
    label : "Território Britânico do Oceano índico"
  },
  {
    id : "Territórios do Sul da França",
    label : "Territórios do Sul da França"
  },
  {
    id : "Timor-Leste",
    label : "Timor-Leste"
  },
  {
    id : "Togo",
    label : "Togo"
  },
  {
    id : "Tonga",
    label : "Tonga"
  },
  {
    id : "Trinidad e Tobago",
    label : "Trinidad e Tobago"
  },
  {
    id : "Tristão da Cunha",
    label : "Tristão da Cunha"
  },
  {
    id : "Tunísia",
    label : "Tunísia"
  },
  {
    id : "Turcomenistão",
    label : "Turcomenistão"
  },
  {
    id : "Turquia",
    label : "Turquia"
  },
  {
    id : "Tuvalu",
    label : "Tuvalu"
  },
  {
    id : "Ucrânia",
    label : "Ucrânia"
  },
  {
    id : "Uganda",
    label : "Uganda"
  },
  {
    id : "Uruguai",
    label : "Uruguai"
  },
  {
    id : "Uzbequistão",
    label : "Uzbequistão"
  },
  {
    id : "Vanuatu",
    label : "Vanuatu"
  },
  {
    id : "Vaticano",
    label : "Vaticano"
  },
  {
    id : "Venezuela",
    label : "Venezuela"
  },
  {
    id : "Vietnã",
    label : "Vietnã"
  },
  {
    id : "Wallis e Futuna",
    label : "Wallis e Futuna"
  },
  {
    id : "Zâmbia",
    label : "Zâmbia"
  },
  {
    id : "Zimbábue",
    label : "Zimbábue"
  }
];

export const Gender = [
  { id: "FEMALE", label: 'Feminino' },
  { id: "MALE", label: 'Masculino' }
];

export const MaritalStatus = [
  { id: "MARRIED", label: 'Casado' },
  { id: "SINGLE", label: 'Solteiro' },
  { id: "WIDOW", label : 'Viuva'},
  { id: "WIDOWER", label: 'Viúvo' }
];

export const Schooling = [
  { id: "PRE_SCHOOL", label: 'Educação infantil' },
  { id: "ELEMENTARY_SCHOOL", label: 'Ensino Fundamental' },
  { id: "HIGH_SCHOOL", label: 'Ensino Médio' },
  { id: "GRADUATE", label: 'Ensino Superior' },
  { id: "POST_GRADUATE", label: 'Pós-graduação' },
  { id: "MASTER_DEGREE", label: 'Mestrado' },
  { id: "DOCTORATE_DEGREE", label: 'Doutorado' }
];

export const Religion = [
  { id: "ATHEIST", label: 'Ateu'},
  { id: "CATHOLIC", label: 'Católica'},
  { id: "SPIRITIST", label: 'Espírita'},
  { id: "EVANGELICAL", label: 'Evangélica'},
  { id: "JEWISH", label: 'Judaica'},
  { id: "NO_RELIGION", label: 'Não tem religião'},
  { id: "UMBANDA", label: 'Umbanda'},
  { id: "OTHER", label: 'Outras'}
];

export const DegreesOfKinship = [
  {id: "FATHER", label: "Pai"},
  {id: "MOTHER", label: "Mãe"},
  {id: "GRANDFATHER", label: "Avô"},
  {id: "GRANDMOTHER", label: "Avó"},
  {id: "SON", label: "Filho"},
  {id: "DAUGHTER", label: "Filha"},
  {id: "BROTHER", label: "Irmão"},
  {id: "SISTER", label: "Irmã"},
  {id: "UNCLE", label: "Tio"},
  {id: "AUNT", label: "Tia"}
];

export const RacePerson = [
  {id: "YELLOW", label: "Amarela"},
  {id: "WHITE", label: "Branca"},
  {id: "INDIGENOUS", label: "Indígena"},
  {id: "BROWN", label: "Parda"},
  {id: "BLACK", label: "Preta"}
];

export const States = [
  {id: "Acre", label: "Acre", sigla: "AC"},
  {id: "Alagoas", label: "Alagoas", sigla: "AL"},
  {id: "Amapá", label: "Amapá", sigla: "AP"},
  {id: "Amazonas", label: "Amazonas", sigla: "AM"},
  {id: "Bahia", label: "Bahia", sigla: "BA"},
  {id: "Ceará", label: "Ceará", sigla: "CE"},
  {id: "Distrito Federal", label: "Distrito Federal", sigla: "DF"},
  {id: "Espírito Santo", label: "Espírito Santo", sigla: "ES"},
  {id: "Goiás", label: "Goiás", sigla: "GO"},
  {id: "Maranhão", label: "Maranhão", sigla: "MA"},
  {id: "Mato Grosso", label: "Mato Grosso", sigla: "MT"},
  {id: "Mato Grosso", label: "Mato Grosso do Sul", sigla: "MS"},
  {id: "Minas Gerais", label: "Minas Gerais", sigla: "MG"},
  {id: "Pará", label: "Pará", sigla: "PA"},
  {id: "Paraíba", label: "Paraíba", sigla: "PB"},
  {id: "Paraná", label: "Paraná", sigla: "PR"},
  {id: "Pernambuco", label: "Pernambuco", sigla: "PE"},
  {id: "Piauí", label: "Piauí", sigla: "PI"},
  {id: "Rio de Janeiro", label: "Rio de Janeiro", sigla: "RJ"},
  {id: "Rio Grande", label: "Rio Grande do Norte", sigla: "RN"},
  {id: "Rio Grande", label: "Rio Grande do Sul", sigla: "RS"},
  {id: "Rondônia", label: "Rondônia", sigla: "RO"},
  {id: "Roraima", label: "Roraima", sigla: "RR"},
  {id: "Santa Catarina", label: "Santa Catarina", sigla: "SC"},
  {id: "São Paulo", label: "São Paulo", sigla: "SP"},
  {id: "Sergipe", label: "Sergipe", sigla: "SE"},
  {id: "Tocantins", label: "Tocantins", sigla: "TO"}
];

export const PaymentMethod = [
  { id: "BOLETO", label: "Boleto" },
  { id: "EMAIL", label: "E-mail" },
  { id: "CARTAO_CREDITO", label: "Cartão de Credito" },
  { id: "DDA", label: "DDA" },
  { id: "CARNE", label: "Carnê" },
  { id: "WHATSAPP", label: "WhatsApp" }
];

export const InactivationReasonList = [
  { id: "CANCELED", label: "Cancelado" },
  { id: "SUSPENSION", label: "Suspensão" },
  { id: "EXCLUSION", label: "Exclusão" }
];

export const ContractStatus = [
  { id: "ATIVO", label: "Ativo" },
  { id: "INATIVO", label: "Inativo" }
];

export const ContractStatusGraveyard = [
  {id: "ACTIVE", label: "Ativo"},
  { id: "INACTIVE", label: "Inativo" }
];

export const InvoicingCycle = [
  { id: "INVOICING_CYCLE", label: "Ciclo de Faturamento" }
];

export const ContractShipping = [
  { id: "SHIPPING", label: "Tipo de entrega" }
];
