/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.scss";
import { withRouter } from "react-router-dom";
import InputForm from "../../components/InputForm";
import { getCompanies } from "../../store/ducks/company.duck";
import * as companyDuck from "../../store/ducks/company.duck";
import store from "../../store/store";



const CompaniesDropdown = (props) => {
  const { companies, user, idCompany, history } = props;

  const [company, setCompany] = useState("")
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (
      history?.location?.pathname.includes('/editar') ||
      history?.location?.pathname.includes('/adicionar')
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [history.location.pathname])

  useEffect(() => {
    (async () => {
      if (user && user?.profile?.name.includes('Administrador')) {
        const companies = await getCompanies();
        const company = companies.find(company => company.value === idCompany)
        store.dispatch(companyDuck.actions.setCompanies(companies));

        if (!!company) {
          setCompany(company)
        } else {
          store.dispatch(companyDuck.actions.selectCompany(undefined));
          setCompany("")
        }
      }
    })()
  }, [idCompany, user])

  const handleCompanySelect = (e) => {
    setCompany(e || "")
    store.dispatch(companyDuck.actions.selectCompany(e?.value))
  }

  return (
    <div className='dropdown-companies'>
      <InputForm
        name="idCompany"
        placeholder="Selecione a empresa"
        value={company}
        error={""}
        onChange={handleCompanySelect}
        role={""}
        isDisabled={disabled}
        label=""
        options={companies}
        isSelect
      />
    </div>

  );
}

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany,
  user: auth.user
});

export default withRouter(
  connect(mapStateToProps)(CompaniesDropdown)
);
