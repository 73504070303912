/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import * as moment from "moment";
import { capitalize, debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
// import ImagemDefault from "../../assets/images/map-default.svg";
import { fetchDetails } from "../utils/fetchDetails";
import InputForm from "../../../../components/InputForm";
import AdminTitle from "../../../../pages/admin/components/AdminTitle";
import HeaderButton from "../../../../pages/admin/components/headerButton";
import RefreshButton from "../../../../pages/admin/components/RefreshButton";
import Loading from "../../../../pages/home/components/Loading";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { AppAPIService } from "../../../../services/AppAPIService";
import "moment/locale/pt-br";
import {
  ImageContainer,
  OpenContainerButton,
  TableWrapper,
  StyledTableCell,
  StyledTableContent,
  StyledTableRow,
  StyledTableHeader,
  StyledTableHeaderAnalisys
} from "./styles";
import "./styles.css";
import { FormatMoney } from "../../../../utils/FormatMoney";
import { useQuery, useQueryClient } from "react-query";

moment.locale("pt-br");

const EnhancedTableHead = props => {
  const { headRows } = props;

  let component = StyledTableCell;

  if (props.customHeader) {
    component = StyledTableHeader;
  } else if (props.customAnalisysHeader) {
    component = StyledTableHeaderAnalisys;
  }

  const generateRow = headRows => (
    <TableRow>
      {(headRows || []).map((row, i) => (
        <TableCell
          key={row.column || i}
          align={row.align ? row.align : "left"}
          padding={row.disablePadding ? "none" : "default"}
          size={row.size || null}
          width={row.width || undefined}
          variant="head"
          component={component}
        >
          {row.label}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <TableHead>
      {Array.isArray(headRows[0]) &&
        headRows.map(row => {
          return generateRow(row);
        })}
      {!Array.isArray(headRows[0]) && generateRow(headRows)}
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired
};

const api = AppAPIService.getInstance();

const Datatable = props => {
  const { widthTable, idCompany, user, checkRemember } = props;
  const useStyles = makeStyles(() => {
    return {
      root: {
        //tabela e pesquisa
        maxHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "4px"
      },
      paper: {
        width: "100%",
        flex: "1"
      },
      table: {
        minWidth: widthTable ? widthTable : 400,
        overflowX: "scroll",
        background: "#F9F7F6"
      },
      tableWrapper: {
        // TODO: remover esse overflow
        //overflow: "hidden !important",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        overflowX: "auto",
        borderRadius: "4px",
        boxShadow: props.removeShadow
          ? "none"
          : "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      },
      tableContainer: {
        padding: "0 16px 16px 16px"
      },
      search: {
        padding: 16
        // display: "flex",
        // flexWrap: "wrap"
      },
      rows: {
        padding: "0 !important",
        margin: "0 !important"
      },
      addBtn: {
        padding: "16px 0",
        marginLeft: "16px"
      }
    };
  });

  const classes = useStyles();
  const currentState = loadPaginationState();

  const [rows, setRows] = useState(props.rows);

  // TODO: Remover depois da integração com o backend
  // const [rows, setRows] = useState(mock.data);

  const [order, setOrder] = useState(currentState.order || "desc");
  const [orderBy, setOrderBy] = useState(
    currentState.orderBy || props.orderBy || props.headRows[0].column || "id"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage || currentState.perPage || 10
  );
  const [count, setCount] = useState(rows?.length);
  const [inputSearch, setInputSearch] = useState(props.search || "");
  const [isLoading, setIsLoading] = useState("");
  const [isLoadingExport, setIsLoadingExport] = useState("");
  const [reload, setReload] = useState(false);
  const pageCurrentState = currentState.page;
  const [isOpen, setIsOpen] = useState(false);

  const monthOptions = [
    { label: "Janeiro", value: 0 },
    { label: "Fevereiro", value: 1 },
    { label: "Março", value: 2 },
    { label: "Abril", value: 3 },
    { label: "Maio", value: 4 },
    { label: "Junho", value: 5 },
    { label: "Julho", value: 6 },
    { label: "Agosto", value: 7 },
    { label: "Setembro", value: 8 },
    { label: "Outubro", value: 9 },
    { label: "Novembro", value: 10 },
    { label: "Dezembro", value: 11 }
  ];

  const [month, setMonth] = useState({
    label: capitalize(moment().format("MMMM")),
    value: moment().month()
  });

  const defaultYear = {
    label: moment().year(),
    value: moment().year()
  }

  const [year, setYear] = useState(defaultYear);

  const [status, setStatus] = useState();
  const statusOptions = [
    { label: 'Realizado', value: 'realizado' },
    { label: 'Pendente', value: 'pendente' },
  ]

  const [classification, setclassification] = useState();
  const classificationOptions = [
    { label: 'Receita', value: 'receita' },
    { label: 'Despesa', value: 'despesa' },
    { label: 'Investimento', value: 'investimento' },
  ]

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalAggregation, setTotalAggregation] = useState(0);


  const [details, setDetails] = useState([]);
  const [detail, setDetail] = useState();

  const { data: categories } = useQuery(
    "categories",
    async () => {
      const result = await fetchDetails(
        `categories?idCompany=${user.idCompany ?? idCompany}`,
        setIsLoading
      );

      return result;
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("categories");
  }, [idCompany])

  const defaultYearsValue = [{
    value: moment().year(),
    label: moment().year(),
  }];

  const [years, setYears] = useState(defaultYearsValue)

  useEffect(() => {
    (async () => {
      setYear(defaultYear)
      if (!user.idCompany && !idCompany) {
        setYears(defaultYearsValue);
        return
      }

      const result = await fetchDetails(
        `finances/${user.idCompany ?? idCompany}/years`,
        setIsLoading
      );

      if (!result?.length) {
        setYears(defaultYearsValue)
        return
      }

      setYears(
        result
          .sort((a, b) => { return Number(b) - Number(a) })
          .map((year) => {
            return {
              value: year,
              label: year,
            }
          })
      )
    })()
  }, [idCompany])

  useEffect(() => {
    if (props.filterType.value === 'advanced') {
      setDetails(() => {
        const currentDetails = [];
        const added = [];

        (categories || []).map(category => {
          category.subcategories.map(subcategory => {
            if (subcategory.name.includes("custos financeiros")) return null
            subcategory.groups.map((group) => {
              if (!added.includes(String(group).toLocaleLowerCase())) {
                added.push(String(group).toLocaleLowerCase())

                currentDetails.push({
                  label: `${capitalize(group)}`,
                  value: String(group).toLocaleLowerCase()
                })
              }
            })
          })
        })

        return currentDetails.sort()
      })
    }

  }, [categories, props.filterType.value])

  const onSearch = useRef(
    debounce((e, searchLoad) => {
      if (e.length >= 3 || e.length === 0) {
        searchLoad(e);
      }
    }, 1500)
  ).current;

  function searchLoad(e) {
    loadRecords({
      page: 0,
      search: e
    });
  }

  function search(e, select) {
    props.localSearch || select
      ? onSearch(e.target.value, e => props.localFilter(select, e, setCount))
      : onSearch(e.target.value, e => searchLoad(e));
  }

  const showMap = () => setIsOpen(!isOpen);

  async function exportRecords(config = {}) {
    setIsLoadingExport(true);

    let params = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      search: config.search,
      where: config.status ? config.status.join(", ") : ""
    };

    await api.download(
      {
        url: config.isEndpointFull
          ? config.endpoint || ""
          : props.endpoint + (config.endpoint || ""),
        params
      },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    setIsLoadingExport(false);
  }
  async function loadRecords(config = {}) {
    setIsLoading(true);
    const initialParms = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      orderBy: config.orderBy || orderBy,
      orderByDirection: config.orderByDirection || order,
      search: config.search,
      idCompany,
      ...(props.filterType.value === 'default' && {
        month: month.value,
        year: year.value
      }),
      ...(props.filterType.value === 'advanced' && {
        classification: classification?.value ?? '',
        detail: detail?.value ?? '',
        status: status?.value ?? '',
        startDate: startDate ?? '',
        endDate: endDate ?? ''
      })
    };

    let params = initialParms;

    try {
      let response = {};

      response = await api.makeHttpRequest({
        url: props.endpoint,
        params
      });

      if (props.filterType.value === 'advanced') {
        const totalAggregation = await api.makeHttpRequest({
          url: '/finances/aggregate',
          params
        });

        setTotalAggregation(totalAggregation)
      }

      if (props?.aggregator) {
        response.data = props.aggregator(response.data);
      }

      setRows(response?.data || response || []);
      setCount(response?.meta?.total || response.length || 0);

      setPage(
        params.page < response?.meta?.lastPage
          ? response?.meta?.currentPage - 1 > 0
            ? response?.meta?.currentPage - 1
            : 0
          : response?.meta?.lastPage - 1 > 0
            ? response?.meta?.lastPage - 1
            : 0
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        "Erro ao carregar os dados, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setIsLoading(false);
      setReload(false);
    }
  }

  useEffect(() => {
    if (rows && checkRemember) {
      rows.map(row => {
        checkRemember(row);
      });
    }
  }, [rows]);

  useEffect(() => {
    if (!props.cancelReload) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, props.reload]);

  useEffect(() => {
    if (Boolean(props.dateRange)) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange]);

  useEffect(() => {
    loadRecords();
  }, [idCompany]);

  useEffect(() => {
    if (props.filterType.value === 'default') {
      setclassification('')
      setStartDate('')
      setEndDate('')
      setStatus('')
      setDetail('')
    }

    loadRecords({
      page: 0
    })

  }, [props.filterType]);


  useEffect(() => {
    if (props.filterType) loadRecords();
  }, [month, year]);

  useEffect(() => {
    if (props.filterType) loadRecords();
  }, [startDate, endDate, classification, status, detail]);

  function loadPaginationState() {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (paginationState[props.endpoint]) {
      return paginationState[props.endpoint];
    }

    return {};
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
    loadRecords({ page: newPage, search: inputSearch });
  }

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(+event.target.value);
    loadRecords({
      perPage: event.target.value,
      page: 0,
      search: inputSearch
    });
  }

  function refreshAction() {
    loadRecords({
      page: pageCurrentState || page,
      search: inputSearch
    });
  }

  if (reload) {
    refreshAction();
  }

  const generateMobileButtons = () => {
    return (
      <>
        {!props.hiddenReload && (
          <li className="kt-nav__item">
            <a href={void 0} className="kt-nav__link" onClick={refreshAction}>
              <i className="kt-nav__link-icon flaticon2-reload"></i>
              <span className="kt-nav__link-text">Atualizar</span>
            </a>
          </li>
        )}
        {props.buttons.length > 0
          ? props.buttons.map((btn, i) => {
            const btnOnClick = btn.endpoint
              ? () =>
                exportRecords({
                  orderByDirection: order,
                  orderBy: orderBy,
                  search: inputSearch,
                  endpoint: btn.endpoint,
                  isEndpointFull: btn.isEndpointFull
                })
              : btn.onClick;

            return btn.path ? (
              <Link to={btn.path} className="kt-nav__item" key={i}>
                <span className="kt-nav__link" onClick={btnOnClick}>
                  <i
                    className={`kt-nav__link-icon flaticon-${btn.icone}`}
                  ></i>
                  <span className="kt-nav__link-text">{btn.label}</span>
                </span>
              </Link>
            ) : (
              <li className="kt-nav__item" key={i}>
                <a
                  href={void 0}
                  className="kt-nav__link"
                  onClick={btnOnClick}
                >
                  <i
                    className={`kt-nav__link-icon flaticon-${btn.icone}`}
                  ></i>
                  <span className="kt-nav__link-text">{btn.label}</span>
                </a>
              </li>
            );
          })
          : null}
      </>
    );
  };

  return (
    <div
      className={`col-xl-12 ${props.className}`}
      style={{
        minHeight: "max-content",
        marginTop: "0px",
        padding: props.addButtonOpenDiv ? "0 16px 0 0" : "0",

        display: "flex",
        justifyContent: "center"
      }}
    >
      <StyledTableContent style={{ maxHeight: "fit-content" }}>
        <Loading isLoading={isLoading || isLoadingExport} />
        <Portlet
          fluidHeight={true}
          style={{ backgroundColor: "#F9F7F6", boxShadow: "none" }}
        >
          <div>
            {props.title && props.icon ? (
              <AdminTitle
                icon={props.icon}
                title={props.title}
                disableHeader={props.disableHeader}
                width={props.width}
                mobile={props.mobile}
                actions={props.actions}
                refreshAction={props.refreshAction}
                generateMobileButtons={generateMobileButtons}
                addButtonUrl={props.addButtonUrl}
                tooltip={props.tooltip}
                titleButon={props.titleButon}
                filterType={props.filterType}
                filterOptions={props.filterOptions}
                onChangeFilter={props.onChangeFilter}
              />
            ) : null}
          </div>

          <PortletBody fit={true} fluid={true}>
            <div className={classes.root}>
              {props.formView ? props.formView : null}

              {props.placeholderSearch ? (
                <div className={classes.search}>
                  <Form.Row className={classes.rows}>
                    <Form.Group
                      as={Col}
                      lg={(props.filterType.value === 'default') ? "9" : "4"}
                      xs={(props.filterType.value === 'default') ? "9" : "4"}
                      className={
                        props.tombstoneInputs ||
                          props.personInputs ||
                          props.contractInputFilter
                          ? "rmv-all-props row-group-padding row-group-margin-bottom row-grop-margin-bottom-mobile icon-search input-custom"
                          : "rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile icon-search input-custom"
                      }
                    >
                      <Form.Control
                        type="text"
                        name="search"
                        onChange={e => {
                          search(e);
                          setInputSearch(e.target.value);
                        }}
                        value={inputSearch}
                        className="input-custom"
                        placeholder={props.placeholderSearch}
                      />
                      <button type="button">
                        <RiSearchLine size={20} />
                      </button>
                    </Form.Group>

                    {(props.filterType.value === 'default') && (
                      <>
                        <Form.Group
                          as={Col}
                          lg="3"
                          xs="3"
                          className="rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile d-flex"
                        >
                          <InputForm
                            id="year"
                            name="year"
                            value={year}
                            options={years}
                            onChange={setYear}
                            placeholder="Ano"
                            isClearable={false}
                            role="ok"
                            isSelect
                            isHeaderButton
                          />

                          <InputForm
                            id="mouth"
                            name="mouth"
                            value={month}
                            options={monthOptions}
                            isSelect
                            onChange={setMonth}
                            placeholder="Mês"
                            isClearable={false}
                            isHeaderButton
                            role="ok"
                          />
                        </Form.Group>
                      </>
                    )}

                    {(props.filterType.value === 'advanced') && (
                      <>
                        <Form.Group
                          as={Col}
                          lg="8"
                          xs="8"
                          className="rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile d-flex"
                        >
                          <InputForm
                            id="classification"
                            name="classification"
                            value={classification}
                            options={classificationOptions}
                            isSelect
                            onChange={setclassification}
                            placeholder="Classificação"
                            isClearable={true}
                            isHeaderButton
                            role="ok"
                          />

                          <InputForm
                            id="detail"
                            name="detail"
                            value={detail}
                            options={!idCompany && !user.idCompany ? [] : details}
                            isSelect
                            onChange={setDetail}
                            placeholder="Detalhamento"
                            isClearable={true}
                            isHeaderButton
                            role="ok"
                          />

                          <InputForm
                            id="status"
                            name="status"
                            value={status}
                            options={statusOptions}
                            onChange={setStatus}
                            placeholder="Status"
                            isClearable={true}
                            role="ok"
                            isSelect
                            isHeaderButton
                          />

                          <InputForm
                            id="startDate"
                            name="startDate"
                            label="Data Inicial"
                            value={startDate}
                            type="date"
                            onChange={setStartDate}
                            role="ok"
                            isHeaderButton
                          />

                          <InputForm
                            id="endDate"
                            name="endDate"
                            label="Data Final"
                            value={endDate}
                            type="date"
                            onChange={setEndDate}
                            role="ok"
                            isHeaderButton
                          />
                        </Form.Group>
                      </>
                    )}
                  </Form.Row>

                  {props.width >= 768 || !props.mobile ? (
                    props.buttons && props.buttons.length > 0 ? (
                      <>
                        {props.buttons.map((item, index) => (
                          <div className={classes.addBtn} key={index}>
                            <HeaderButton
                              path={item.path}
                              label={item.label}
                              onClick={
                                item.endpoint
                                  ? () =>
                                    exportRecords({
                                      orderByDirection: order,
                                      orderBy: orderBy,
                                      search: inputSearch,
                                      endpoint: item.endpoint,
                                      isEndpointFull: item.isEndpointFull
                                    })
                                  : item.onClick
                              }
                              icone={item.icone}
                              disabled={item.disabled}
                            />
                          </div>
                        ))}
                        {!props.hiddenReload && (
                          <RefreshButton refreshAction={refreshAction} />
                        )}
                      </>
                    ) : null
                  ) : null}
                </div>
              ) : null}

              {props.placeholderSearchYear ? (
                <div className={classes.search}>
                  {props.width >= 768 || !props.mobile ? (
                    props.buttons && props.buttons.length > 0 ? (
                      <>
                        {props.buttons.map((item, index) => (
                          <div className={classes.addBtn} key={index}>
                            <HeaderButton
                              path={item.path}
                              label={item.label}
                              onClick={
                                item.endpoint
                                  ? () =>
                                    exportRecords({
                                      orderByDirection: order,
                                      orderBy: orderBy,
                                      search: inputSearch,
                                      endpoint: item.endpoint,
                                      isEndpointFull: item.isEndpointFull
                                    })
                                  : item.onClick
                              }
                              icone={item.icone}
                              disabled={item.disabled}
                            />
                          </div>
                        ))}
                        {!props.hiddenReload && (
                          <RefreshButton refreshAction={refreshAction} />
                        )}
                      </>
                    ) : null
                  ) : null}
                </div>
              ) : null}

              <div className={classes.tableContainer}>
                <Paper className={classes.paper}>
                  <TableWrapper removeShadow={props.removeShadow}>
                    <Table
                      className={classes.table}
                      style={{ borderRadius: "4px 4px 0px 0px" }}
                    >
                      <EnhancedTableHead
                        rowCount={rows?.length}
                        headRows={props.headRows}
                        styledTableCell={props.styledTableCell}
                        customHeader={props.customHeader}
                        customAnalisysHeader={props.customAnalisysHeader}
                      />
                      <TableBody style={{ height: "32px" }}>
                        {isLoading ? (
                          <TableRow component={StyledTableRow}>
                            <TableCell
                              colSpan={props.headRows?.length}
                              className="text-center"
                              component={StyledTableCell}
                            >
                              Aguarde...
                            </TableCell>
                          </TableRow>
                        ) : count > 0 ? (
                          rows.map(row => {
                            return props.formatRow(row);
                          })
                        ) : (
                          <TableRow component={StyledTableRow}>
                            <TableCell
                              colSpan={props.headRows.length}
                              className="text-center"
                              component={StyledTableCell}
                            >
                              {!user.idCompany && !idCompany
                                ? "Nenhum registro encontrado, selecione uma empresa para visualisar."
                                : "Nenhum registro encontrado"}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </Paper>

                {props.alert && (
                  <div className="d-flex justify-content-center align-items-center alert-warning py-2 px-5 mt-4 border rounded">
                    <p className="p-0 m-0">{props.alert}</p>
                  </div>
                )}

                {props.paginator !== false && (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        {props.filterType.value === 'advanced' && (
                          <div className="d-flex align-itens-center py-3" style={{ gap: '10px' }}>
                            <p className="p-0 m-0 text-dark font-weight-bold">Total líquido: </p>
                            <p className="p-0 m-0 text-dark">{FormatMoney(totalAggregation.toFixed(2))}</p>
                          </div>
                        )}
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          colSpan={10}
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          id="MuiTable-root-custom"
                          labelRowsPerPage="Registros por página"
                          backIconButtonProps={{
                            "aria-label": "Anterior"
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima"
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </StyledTableContent>

      {props.addButtonOpenDiv ? (
        <ImageContainer showMap={isOpen}>
          <OpenContainerButton onClick={showMap}>
            {isOpen ? (
              <MdKeyboardArrowLeft className="arrow" />
            ) : (
              <MdKeyboardArrowRight className="arrow" />
            )}
          </OpenContainerButton>
        </ImageContainer>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  idCompany: company.idCompany,
  user: auth.user
});

export default withRouter(connect(mapStateToProps)(Datatable));
