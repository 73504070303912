import { pt } from "yup-locale-pt";
import * as yup from 'yup';

yup.setLocale(pt)

export const selectSchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required()
});


export const schema = yup.object().shape({
  client: selectSchema
    .required()
    .typeError("Cliente é obrigatório"),
  licensePlate: yup.string()
    .label('Placa')
    .required(),
  brand: selectSchema
    .required()
    .typeError("Marca do veículo é obrigatória"),
  model: selectSchema
    .required()
    .typeError("Modelo é obrigatório"),
  color: yup.string()
    .label('Cor')
    .required(),
  fuelType: selectSchema
    .required()
    .typeError("Tipo de combustível é obrigatório"),
  year: yup.string()
    .label("Ano")
    .required()
    .min(4)
    .matches(/^[0-9]+$/, "O ano deve conter apenas números"),
  milage: yup.string()
    .optional(),
  soldAt: yup.string()
    .label("Data da venda")
    .required(),
  nextOilChangeDate: yup.string()
    .optional(),
  licenseExpiresAt: yup.string()
    .optional(),
  obs: yup.string()
    .label("Observações")
    .optional()
});
