import React, { useEffect, useState } from "react";
import Loading from "../../../home/components/Loading";
import { AppAPIService } from "../../../../services/AppAPIService";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";

import { ReactComponent as IconCircleRight } from '../../../../../app/assets/icons/icon_circle-chevron-right-fill.svg';
import { ReactComponent as IconCircleLeft } from '../../../../../app/assets/icons/icon_circle-chevron-left-fill.svg';
import { FormatMoney, FormatMoneyToNumber } from "../../../../utils/FormatMoney";
import { Container, Button, StyledInput, ButtonForm, StyledModal } from "./style";
import { connect } from "react-redux";
import { InputField, NoStyleInputField } from "../../components/InputField";

const RequestFlowInitialValue = ({ user, idCompany, setReload, flow, show, setShow, reload, year }) => {
  const api = AppAPIService.getInstance();

  const [value, setValue] = useState(FormatMoney(Number(flow.initialValue).toFixed(2) ?? 0.00));
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState();

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ].map((month, index) => {
    return {
      label: month,
      value: month.slice(0, 3).toLowerCase()
    }
  })

  useEffect(() => {
    const currentMonth = months.find(m => m.value === flow['initialValueMonth'])
    setMonth(currentMonth ?? { label: 'Janeiro', value: 'jan' })

    let initialValue = flow['initialValue']
    if (initialValue) initialValue = Number(initialValue)
  }, [flow])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await api.makeHttpRequest({
        url: "financialflow/initialValue",
        method: "PUT",
        data: {
          initialFlowYear: year,
          initialValueMonth: month.value,
          initialValue: FormatMoneyToNumber(value),
          idCompany: user.idCompany ?? idCompany
        }
      });
      setLoading(false)
      Swal.fire(
        "Sucesso",
        "Saldo inicial atualizado com sucesso",
        "success"
      ).then(() => {
        if (setReload) setReload(!reload);
        setShow(false)
      })
    } catch (e) {
      setLoading(false)
      Swal.fire(
        "Erro!",
        e?.response?.data?.messages
          ? e.response?.data?.messages[0]
          : "Falha ao adicionar o saldo inicial.",
        "error"
      )
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <StyledModal
        show={show}
        onHide={() => setShow(false)}
        centered
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          <Container>
            <h1 className="forgot-title">Saldo inicial</h1>
            <p className="forgot-subtitle mx-3">
              Insira o saldo inicial para este ano
            </p>

            <NoStyleInputField
              type="text"
              name="referentialMonth"
              placeholder="Selecione o mês..."
              value={month}
              options={months}
              closeMenuOnSelect
              onChange={(e) => {
                setMonth(e)
              }}
              isSelect
            />

            <StyledInput
              placeholder="Saldo inicial"
              value={value}
              onChange={(event) => {
                setValue(FormatMoney(event.target.value ?? 0.00))
              }}
            />

            <ButtonForm >
              <Button
                type="submit"
                color="secondary"
                disabled={FormatMoneyToNumber(value) < 0}
                onClick={() => setShow(false)}
              >
                <IconCircleLeft /> Cancelar
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={FormatMoneyToNumber(value) < 0}
                onClick={handleSubmit}
              >
                Enviar <IconCircleRight />
              </Button>
            </ButtonForm>
          </Container>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(RequestFlowInitialValue);
