export const isNotNull = (value) => {
  if (Array.isArray(value)) {

    if (value.length === 0) return false;

    return value.every((item) => {
      return item !== null && item !== '' && item !== undefined;
    });
  }

  return value !== null && value !== undefined && value !== '';
}

export const validateIfHasAnyNull = (valuesInput) => {
  return valuesInput.some(
    (value) => value === ""
      || value === undefined
      || value === null
      || (Array.isArray(value) && value.length === 0));
}
