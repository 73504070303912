import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import FormActions from "../../components/FormActions";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import categoryIcon from "../../../../assets/icons/category.svg";
import { connect } from "react-redux";
import { Formik } from "formik";
import { addCategorySchema } from './validator';
import { FormikInput } from "../../../../components/FormikInput/Input";
import { createCategory, updateCategory } from "../../../../services/modules/CategoryApiService";
import { FinancialMoveType } from "../Finances/enums";
import { useQuery } from "react-query";
import { fetchDetails } from "../utils/fetchDetails";
import * as categoryDuck from "../../../../store/ducks/category.duck";
import store from "../../../../store/store";

const CategoryFormComponent = ({
  user,
  idCompany,
  setLoading,
  handleSubmit,
  setFieldValue,
  category,
  isValid,
  rootPage,
  values
}) => {
  const isEdit = !!category?.type
  const [currentSubcategories, setCurrentSubcategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);

  const history = useHistory();

  const { data: categories } = useQuery(
    "categories",
    async () => {
      const result = await fetchDetails(
        `/categories?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      );

      return result;
    }
  );

  const onSelectClassification = (event, resetCategory = true) => {
    const localCategories = [];

    if (resetCategory) setFieldValue("category", "")
    setCurrentCategories([])

    if (resetCategory) setFieldValue("subcategory", "")
    setCurrentSubcategories([])

    if (event) {
      (categories || []).map(category => {
        if (category.type.toLowerCase().includes(event.label.toLowerCase())) {
          localCategories.push({
            label: category.name,
            value: category.name
          });
        }
      })
    }

    setCurrentCategories(localCategories)
  }


  const onSelectCategory = (event, resetSubcategory = true) => {
    const localSubcategories = [];

    if (resetSubcategory) setFieldValue("subcategory", "")
    setCurrentSubcategories([])

    if (event.value) {
      (categories ?? []).map(category => {
        if (category.name === event.value) {
          category.subcategories.map(sub => {
            if (sub.name.includes("custos financeiros")) return null
            localSubcategories.push({
              label: sub.name,
              value: sub.name.toUpperCase()
            })
          })

        }
      })
    }

    setCurrentSubcategories(localSubcategories)
  }

  const types = Object.entries(FinancialMoveType).map(([key, value]) => {
    return {
      label: value.toLowerCase(),
      value: key
    };
  });

  useEffect(() => {
    if (values.classification)
      onSelectClassification(values.classification, false)
  }, [values.classification, categories])

  useEffect(() => {
    if (values.category)
      onSelectCategory(values.category, false)
  }, [values.category, categories])

  return (
    <>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className={styles.containerInputs}>
          <FormikInput
            type="text"
            name="classification"
            placeholder="Selecione..."
            setFieldValue={setFieldValue}
            options={types}
            label="Classificação"
            isSelect
            required
            isDisabled={isEdit}
          />

          <FormikInput
            type="text"
            name="category"
            placeholder="Selecione ou digite..."
            setFieldValue={setFieldValue}
            options={currentCategories}
            label="Categoria"
            onChange={(e) => {
              console.log(e)
            }}
            newOption="Criar categoria"
            isCreatable
            required
          />
        </div>

        <div className={styles.containerInputs}>
          <FormikInput
            type="text"
            name="subcategory"
            placeholder="Selecione ou digite..."
            setFieldValue={setFieldValue}
            options={currentSubcategories}
            label="Subcategoria"
            newOption="Criar subcategoria"
            onChange={(event) => {
              console.log(event)
            }}
            isCreatable
            required
          />

          <FormikInput
            type="text"
            name="detail"
            placeholder="Digite o nome..."
            setFieldValue={setFieldValue}
            label="Detalhamento"
            formatter={(e) => {
              return String(e ?? "").toLowerCase()
            }}
            required
          />
        </div>

        <div className="d-flex justify-content-center align-items-center alert-warning py-2">
          <p className="p-0 m-0">Preencha todos os campos para que o botão <b>"SALVAR"</b> abaixo será liberado</p>
        </div>

        <FormActions
          formIsValid={isValid}
          onSubmit={handleSubmit}
          backButton={() => {
            store.dispatch(categoryDuck.actions.setCategory({}))
            history.push(rootPage)
          }}
        />
      </form>
    </>
  );
};


const CategoryForm = ({ idCompany, user, category }) => {
  const rootPage = "/admin/categorias";

  window.setPageTitle(
    `${!!category ? "Cadastro" : "Edição"} do plano de contas`
  );

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    category: undefined,
    subcategory: undefined,
    classification: undefined,
    detail: undefined,
  });

  const handleSubmit = async values => {
    setLoading(true);

    let payload = {}

    if (Object.keys(category).length) {
      payload = {
        current: {
          category: category.category.toLowerCase(),
          subcategory: category.subcategory.toLowerCase(),
          classification: category.type.toLowerCase(),
          detail: category.description.toLowerCase(),
          idCompany: user.idCompany ?? idCompany
        },
        new: {
          category: values.category.value.toLowerCase(),
          subcategory: values.subcategory.value.toLowerCase(),
          classification: values.classification.label.toLowerCase(),
          detail: values.detail.toLowerCase()
        }
      }
    } else {
      payload = {
        category: values.category.value.toLowerCase(),
        subcategory: values.subcategory.value.toLowerCase(),
        classification: values.classification.label.toLowerCase(),
        detail: values.detail.toLowerCase(),
        idCompany: user.idCompany ?? idCompany
      }
    }

    try {
      if (Object.keys(category).length) {
        await updateCategory(payload);
      } else {
        await createCategory(payload);
      }

      setLoading(false);
      await Swal.fire({
        title: "Sucesso",
        text: `Plano de contas ${!!category ? "atualizado" : "criado"} com sucesso`,
        icon: "success"
      });

      store.dispatch(categoryDuck.actions.setCategory({}));
      history.push(rootPage);
    } catch (err) {
      setLoading(false)
      if (err.response) {
        const { data } = err.response;
        Swal.fire({
          title: "Erro",
          text: data.messages[0],
          icon: "error"
        });
        return;
      }

      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  const types = Object.entries(FinancialMoveType).map(([key, value]) => {
    return {
      label: value.toLowerCase(),
      value: key
    };
  });

  useEffect(() => {
    if (Object.keys(category).length) {

      setInitialValues({
        classification: {
          label: category?.type,
          value: types.find(type => type.label === category.type).value
        },
        category: {
          label: category?.category,
          value: category?.category
        },
        subcategory: {
          label: category?.subcategory,
          value: category?.subcategory
        },
        detail: category?.description
      })
    }
  }, [category])

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={categoryIcon} alt={"Plano de contas"} width={18} height={18} />
        Plano de contas
      </h3>

      <Formik
        validationSchema={addCategorySchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isInitialValid={!!category}
        enableReinitialize
        validateOnChange={true}
      >
        {({
          setFieldValue,
          handleSubmit,
          isValid,
          values
        }) => (
          <CategoryFormComponent
            setInitialValues={setInitialValues}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            setLoading={setLoading}
            idCompany={idCompany}
            category={category}
            rootPage={rootPage}
            isValid={isValid}
            values={values}
            user={user}
          />
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = ({ category, company, auth }) => ({
  category: category.category,
  setCategory: category.setCategory,
  idCompany: company.idCompany,
  user: auth.user
});

export default connect(mapStateToProps)(CategoryForm);
