import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import InputForm from "../../../../components/InputForm";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import verifyCpfCnpj, {
  formatCPF
} from "../../../../utils/validators/IsValidCpfCnpj";
import formatTel from "../../../../utils/FormatTel";
import FormActions from "../../components/FormActions";
import { IsValidPassword } from "../../../../utils/validators/IsValidPassword";
import { AppAPIService } from "../../../../services/AppAPIService";
import Swal from "sweetalert2";
import store from "../../../../store/store";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import IsValidName from "../../../../utils/validators/IsValidName";
import { useQuery } from "react-query";
import { fetchDetails } from "../utils/fetchDetails";
import users from "../../../../assets/icons/users.svg";
import message from "../../../../assets/icons/message.svg";
import formatCEP from "../../../../utils/FormatCep";
import axios from "axios";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import validateCep from "../../../../utils/validators/IsValidCep";
import { connect } from "react-redux";
import * as companyDuck from "../../../../store/ducks/company.duck";
import { FormAlert } from "../../../../components/Alerts/alert";
import { applyMask } from "../../../../utils/applyMask";


const UserForm = ({ companies, selectCompany, idCompany }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.USERS_WRITE.ENGLISH);

  const { clientId } = useParams();

  let api = AppAPIService.getInstance();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailRole, setEmailRole] = useState("");

  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [fullnameRole, setFullnameRole] = useState("");

  const [profile, setProfile] = useState("");
  const [profileError, setProfileError] = useState("");
  const [profileRole, setProfileRole] = useState("");

  const [mobiles, setMobiles] = useState([]);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [mobileRole, setMobileRole] = useState("");


  const [phones, setPhones] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneRole, setPhoneRole] = useState("");

  const [cpfOrCnpj, setCpfOrCnpj] = useState("");
  const [cpfOrCnpjError, setCpfOrCnpjError] = useState("");
  const [cpfOrCnpjRole, setCpfOrCnpjRole] = useState("");

  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [companyRole, setCompanyRole] = useState("");

  const [streetRole, setStreetRole] = useState("");
  const [streetError, setStreetError] = useState("");
  const [street, setStreet] = useState("");

  const [districtRole, setDistrictRole] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [district, setDistrict] = useState("");

  const [addressComplementRole, setAddressComplementRole] = useState("");
  const [addressComplementError, setAddressComplementError] = useState("");
  const [addressComplement, setAddressComplement] = useState("");

  const [postalCodeRole, setPostalCodeRole] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [cityRole, setCityRole] = useState("");
  const [cityError, setCityError] = useState("");
  const [city, setCity] = useState("");

  const [stateRole, setStateRole] = useState("");
  const [stateError, setStateError] = useState("");
  const [state, setState] = useState("");

  const [addressNumberRole, setAddressNumberRole] = useState("");
  const [addressNumberError, setAddressNumberError] = useState("");
  const [addressNumber, setAddressNumber] = useState("");

  const [obs, setObs] = useState("");

  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);

  const [userInfo, setUserInfo] = useState({});


  const { data: listProfiles } = useQuery(
    "listProfiles",
    () => fetchDetails("/profile", setLoading),
    {
      select: listProfiles =>
        listProfiles.data?.map(i => ({ value: i.id, label: i.name }))
    }
  );

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberError("");
      setAddressNumberRole("ok");
    } else {
      setAddressNumberError("Campo obrigatório.");
      setAddressNumberRole("notOk");
    }
  };

  useEffect(() => {
    if (profile?.label?.includes("Administrador")) {
      store.dispatch(companyDuck.actions.selectCompany(undefined));
      setCompany("")
      setCompanyRole("ok")

      setPostalCode("")
      setPostalCodeRole("ok")

      setState("")
      setStateRole("ok")

      setDistrict("")
      setDistrictRole("ok")

      setCity("")
      setCityRole("ok")

      setAddressComplement("")
      setAddressComplementRole("ok")

      setStreet("")
      setStreetRole("ok")

    }
  }, [profile])

  useEffect(() => {
    if (idCompany && companies.length) {
      const company = companies.find(c => c.value === idCompany)
      setCompany(company)
      setCompanyRole("ok")
    }
  }, [idCompany, companies])

  const handleInputAddressNumber = e => {
    if (e.length > 6) return;

    if (e !== "" && !Number(e)) {
      return;
    }

    setAddressNumber(e);
    handleValidationAddressNumber(e);
  };

  const handleValidationAddressComplement = value => {
    if (value.length < 2 && value.length > 0) {
      setAddressComplementError("Complemento deve ter pelo menos 2 caracteres");
      setAddressComplementRole("notOk");
    } else if (value.length >= 2) {
      setAddressComplementRole("ok");
      setAddressComplementError("");
    } else {
      setAddressComplementError("");
      setAddressComplementRole("");
    }
  };

  const handleInputAddressComplement = e => {
    if (e.length > 20) return;

    let value = e;
    setAddressComplement(value);
    handleValidationAddressComplement(value);
  };

  const handleNeighborhoodValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setDistrictError("Bairro deve ter pelo menos 2 caracteres");
      setDistrictRole("notOk");
    } else if (value.length >= 2) {
      setDistrictRole("ok");
      setDistrictError("");
    } else {
      setDistrictError("");
      setDistrictRole("");
    }
  };

  const handleInputAddressNeighborhood = e => {
    handleNeighborhoodValidation(e);
    setDistrict(e);
  };

  const handleAddressValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setStreetError("Endereço deve ter pelo menos 2 caracteres");
      setStreetRole("notOk");
    } else if (value.length >= 2) {
      setStreetRole("ok");
      setStreetError("");
    } else {
      setStreetError("");
      setStreetRole("");
    }
  };

  const handleInputAddress = e => {
    if (e.length > 50) return;
    setStreet(e);
    handleAddressValidation(e);
  };

  const handleFetchUfs = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.sigla,
        label: resp.nome
      }));
      setUfs(ufOpts);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleFetchCities = useCallback(async () => {
    try {
      const ufSelected = ufs.find(ufOpt => ufOpt.value === state?.value);
      const response = await (
        await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected?.value}/municipios`
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.nome,
        label: resp.nome
      }));
      setCities(ufOpts);
    } catch (error) {
      console.log(error);
    }
  }, [state.value, ufs]);

  const handleSetUf = e => {
    if (e === null) {
      setStateError("Campo obrigatório.");
      setStateRole("notOk");
      setState("");
    } else {
      setState(e ? e : {});
      setStateRole("ok");
      setStateError("");
    }
  };

  const handleSetCity = e => {
    if (e === null) {
      setCityError("Campo obrigatório.");
      setCityRole("notOk");
      setCity("");
    } else {
      setCity(e ? e : {});
      setCityRole("ok");
      setCityError("");
    }
  };

  useEffect(() => {
    if (postalCode === "") {
      setState("");
      setCity("");
      setStateError("");
      setStateRole("");
      setCityRole("");
      setCityError("");
      setStreet("");
      setStreetRole("");
      setStreetError("");
      setDistrictRole("");
      setDistrictError("");
      setDistrict("");
      setAddressNumber("");
      setAddressNumberRole("");
      setAddressNumberError("");
      setAddressComplement("");
      setAddressComplementRole("");
      setAddressComplementError("");
    }
  }, [postalCode]);

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await (
        await axios.get(`https://viacep.com.br/ws/${cep}/json`, {
          headers: {}
        })
      ).data;

      if (response?.erro) {
        setPostalCodeRole("ok");
        setPostalCodeError("");

        setAddressNumber("");
        setAddressNumberRole("notOk");
        setAddressNumberError("Campo obrigatório.");

        setStreet("");
        setStreetRole("notOk");
        setStreetError("Campo obrigatório.");

        setDistrict("");
        setDistrictRole("notOk");
        setDistrictError("Campo obrigatório.");

        setAddressComplement("");
        setAddressComplementRole("");
        setAddressComplementError("");

        setState("");
        setStateRole("notOk");
        setStateError("Campo obrigatório.");

        setCity("");
        setCityRole("notOk");
        setCityError("Campo obrigatório.");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setPostalCodeRole("ok");
      setPostalCodeError("");

      setStreet(response.logradouro);
      setStreetRole("ok");
      setStreetError("");

      if (!response.logradouro) {
        setStreet("");
        setStreetRole("notOk");
      }

      if (!response.bairro) {
        setDistrictRole("notOk");
        setDistrictError("Bairro não encontrado");
      } else {
        setDistrictError("");
        setDistrict(response.bairro);
        setDistrictRole("ok");
      }

      setState({
        value: response.uf,
        label: response.uf
      });
      setStateRole("ok");
      setStateError("");

      setCity({
        value: response.localidade,
        label: response.localidade
      });
      setCityRole("ok");
      setCityError("");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido."
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleInputCep = useCallback(
    async zipcode => {
      if (zipcode.length > 11) {
        return;
      }

      const { error, cepFormat, cep, role } = validateCep(zipcode);

      if (zipcode.length < 1) {
        setPostalCode(zipcode);
        setPostalCodeError("");
        setPostalCodeRole("");
        return;
      }

      if (cep === "") {
        setPostalCodeError("Apenas números.");
        setPostalCodeRole("notOk");
        return;
      }

      if (zipcode.length > 10) {
        return;
      }

      setPostalCode(cepFormat);
      setPostalCodeError(error);
      setPostalCodeRole(role);

      if (cep.length === 8) {
        await handleCepConsult(cep);
      }
    },
    [handleCepConsult]
  );

  useEffect(() => {
    handleFetchUfs();
  }, [handleFetchUfs]);

  useEffect(() => {
    handleFetchCities();
  }, [ufs, state.value, handleFetchCities]);

  const handleInputEmail = async e => {
    if (e.length > 50) {
      return;
    }
    if (e.length < 1) {
      setEmail(e);
      setEmailError("Campo obrigatório");
      setEmailRole("notOk");
    } else if (!IsValidEmail(e)) {
      setEmail(e);
      setEmailError("Não é um E-mail válido.");
      setEmailRole("notOk");
    } else {
      setEmail(e);
      setEmailError("");
      setEmailRole("ok");
    }
  };

  const checkEmail = async e => {
    if (e !== "" && e !== userInfo?.email) {
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: "auth/email/avaiability",
          data: {
            email: e
          }
        });
        setEmailError("");
        setEmailRole("ok");
      } catch (e) {
        setEmailError("Este email já está em uso");
        setEmailRole("notOk");
        return;
      }
    }
  };

  const handleInputFullname = e => {
    if (e.length > 250) {
      return;
    }
    if (e.length < 3) {
      setFullname(e);
      setFullnameError("Campo obrigatório");
      setFullnameRole("notOk");
    } else if (!IsValidName(e)) {
      setFullname(e);
      setFullnameError("Mínimo de 3 caracteres.");
      setFullnameRole("notOk");
    } else {
      setFullname(e);
      setFullnameError("");
      setFullnameRole("ok");
    }
  };

  const handleInputCompanyName = e => {
    if (e === null) {
      setCompany("notOk");
      setCompanyError("Campo obrigatório.");
      setCompanyRole("");
      store.dispatch(companyDuck.actions.selectCompany(undefined));
      return;
    }

    if (e.label.length > 250) {
      return;
    }

    if (e.label.length < 3) {
      setCompany(e);
      setCompanyError("o Nome deve ser maior que 3 caracteres");
      setCompanyRole("notOk");
    } else {
      if (Number(e.value)) {
        store.dispatch(companyDuck.actions.selectCompany(Number(e.value)));
      }
      setCompany(e);
      setCompanyError("");
      setCompanyRole("ok");
    }
  };

  const handleInputProfile = e => {
    if (e === null) {
      setProfile("");
      setProfileError("Campo obrigatório");
      setProfileRole("notOk");
    } else {
      setProfile(e);
      setProfileError("");
      setProfileRole("ok");

      if (e.label.includes("Administrador")) {
        setCompanyError("")
        setCompanyRole("ok")
      }
    }
  };

  const handleInputMobileChange = e => {
    setMobiles(e || []);
  };

  const handleInputPhoneChange = e => {
    setPhones(e || []);
  };

  const handleInputMobile = e => {
    if (mobiles.length === 2) return;

    if (e === "") {
      setMobile("")
      return;
    }

    let value = e.slice(0, 11);

    const mobileNumbers = value.replace(/\D/g, "");

    if (value !== "" && !Number(mobileNumbers)) {
      setMobileError("Apenas números");
      setMobileRole("notOk");
      return;
    }

    if (!IsValidPhone(mobileNumbers)) {
      setMobileError("Não é um telefone válido.");
      setMobileRole("notOk");
    } else if (IsValidPhone(mobileNumbers) === "") {
      setMobileError("");
      setMobileRole("");
    } else {
      if (mobileNumbers.length === 11) {
        setMobiles(prev => {
          setMobile("");
          return [...prev, { label: formatTel(e), value: formatTel(e) }];
        });
        return;
      }
      setMobileError("");
      setMobileRole("ok");
    }

    setMobile(mobileNumbers);
  };

  const handleInputPhone = e => {
    if (phones.length === 2) return;

    if (e === "") {
      setPhone("")
      return;
    }

    let value = e.slice(0, 10);

    const pn = value.replace(/\D/g, "");

    if (value !== "" && !Number(pn)) {
      setPhoneError("Apenas números");
      setPhoneRole("notOk");
      return;
    }

    if (!IsValidPhone(pn)) {
      setPhoneError("Não é um telefone válido.");
      setPhoneRole("notOk");
    } else if (IsValidPhone(pn) === "") {
      setPhoneError("");
      setPhoneRole("ok");
    } else {
      if (pn.length === 10) {
        setPhones(prev => {
          setPhone("");
          return [...prev, { label: applyMask(e), value: applyMask(e) }];
        });
        setPhoneError("");
        setPhoneRole("ok");
        return;
      }
      setPhoneError("");
      setPhoneRole("ok");
    }

    setPhone(pn);
  };

  const handleInputCpfOrCnpj = doc => {
    const docNumbers = doc.replace(/\D/g, "");

    const { isValid, error, cpfCnpjFormat, cpfCnpj } = verifyCpfCnpj(
      docNumbers
    );

    if (doc.length < 1) {
      setCpfOrCnpj(doc);
      setCpfOrCnpjError("");
      setCpfOrCnpjRole("");
      return;
    }

    if (cpfCnpjFormat === "") {
      setCpfOrCnpjError("Apenas números.");
      setCpfOrCnpjRole("notOk");
      return;
    }

    if (doc.length > 18) {
      return;
    }

    setCpfOrCnpj(cpfCnpjFormat);
    setCpfOrCnpjError(error);
    setCpfOrCnpjRole(isValid ? "ok" : "notOk");
  };

  const submit = useCallback(
    async e => {
      e.preventDefault();

      try {
        setLoading(true);

        const method = clientId ? "PUT" : "POST"

        const response = await api.makeHttpRequest({
          url: clientId ? `/user/${clientId}` : "/auth/register/account",
          method,
          data: {
            idProfile: profile.value,
            fullname,
            email,
            document: cpfOrCnpj,
            obs,
            phones: [...mobiles.map(p => `${p.value}`), ...phones.map(p => `${p.value}`)],
            ...(company?.__isNew__ && {
              companyName: company.value
            }),
            ...(!company?.__isNew__ && {
              idCompany: company?.value
            }),
            ...((company === "") && {
              idCompany: undefined
            }),
            address: {
              complement: addressComplement ?? "",
              number: parseInt(addressNumber),
              state: state.label,
              city: city.label,
              postalCode,
              district,
              street
            }
          }
        });

        store.dispatch(companyDuck.actions.setCompanies(await companyDuck.getCompanies()));
        store.dispatch(companyDuck.actions.selectCompany(response.idCompany));

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Cliente ${clientId ? "editado" : "cadastrado"} com sucesso.`
        }).then(() => {
          history.push("/admin/usuarios");
        });
      } catch (error) {
        if (
          error.response?.data?.errors
            ?.toLowerCase()
            .includes("cliente já existe no sistema")
        ) {
          setEmailError("E-mail já cadastrado em nosso banco de dados");
          setEmailRole("notOk");

          Swal.fire({
            title: "Erro!",
            text: "E-mail já cadastrado em nosso banco de dados",
            icon: "error"
          });
        } else {
          Swal.fire({
            title: "Erro!",
            text:
              "Erro tentar cadastrar novo usuário, tente novamente mais tarde!",
            icon: "error"
          }).then(() => {
            history.push("/admin/usuarios");
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      api,
      profile.value,
      mobiles,
      phones,
      obs,
      company,
      clientId,
      fullname,
      email,
      cpfOrCnpj,
      history,
      addressComplement,
      addressNumber,
      postalCode,
      district,
      street,
      state,
      city
    ]
  );

  const formIsValid = useCallback(() => {
    let isValid = false;

    if (profile?.label?.includes("Administrador")) {
      isValid =
        profileRole === "ok" &&
        fullname !== "" &&
        fullnameRole === "ok" &&
        email !== "" &&
        emailRole === "ok" &&
        mobiles.length > 0 &&
        cpfOrCnpj !== "" &&
        cpfOrCnpjRole === "ok"
    }

    if (!profile?.label?.includes("Administrador")) {
      isValid =
        profileRole === "ok" &&
        fullname !== "" &&
        fullnameRole === "ok" &&
        company.label !== "" &&
        companyRole === "ok" &&
        email !== "" &&
        emailRole === "ok" &&
        mobiles.length > 0 &&
        companyRole === "ok" &&
        cpfOrCnpj !== "" &&
        cpfOrCnpjRole === "ok" &&
        addressNumber !== "" &&
        addressNumberRole === "ok" &&
        postalCode !== "" &&
        postalCodeRole === "ok" &&
        district !== "" &&
        districtRole === "ok" &&
        street !== "" &&
        streetRole === "ok" &&
        state.label !== "" &&
        stateRole === "ok" &&
        city.label !== "" &&
        cityRole === "ok";
    }

    return isValid;
  }, [
    companyRole,
    company.label,
    email,
    emailRole,
    fullname,
    fullnameRole,
    mobiles,
    profile.label,
    profileRole,
    cpfOrCnpjRole,
    cpfOrCnpj,
    addressNumber,
    addressNumberRole,
    postalCode,
    postalCodeRole,
    district,
    districtRole,
    street,
    streetRole,
    state,
    stateRole,
    city,
    cityRole
  ]);

  const onCleanForm = async () => {
    setFullname("");
    setProfile("");
    setEmail("");
    setMobile("");
    setCompany("");
    setCpfOrCnpj("");
  };

  const fetchUserInfo = useCallback(
    async id => {
      try {
        setLoading(true);

        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/user/details/${id}`
        });

        setUserInfo(response);

        setFullname(response.fullname);
        setFullnameRole("ok");

        setEmail(response.email);
        setEmailRole("ok");

        if (response.company) {
          setCompany({
            label: response.company.name,
            value: response.company.id
          });
        }
        setCompanyRole("ok");

        setProfile({
          value: response.profile.id,
          label: response.profile.name
        });
        setProfileRole("ok");

        if (response.document) {
          setCpfOrCnpj(formatCPF(response.document.replace(/\D/g, "")));
          setCpfOrCnpjRole("ok");
        }

        if (response.phone) {
          let phones = [];
          let mobiles = [];

          response.phone.map(p => {
            const phoneNumber = p.number.replace(/\D/g, "");
            const masked = applyMask(phoneNumber);

            if (phoneNumber.length === 10)
              phones.push({
                label: masked,
                value: masked
              });

            if (phoneNumber.length === 11)
              mobiles.push({
                label: masked,
                value: masked
              });
          });

          setMobiles(mobiles);
          setPhones(phones);
        }

        if (response.company.address.postalCode) {
          setPostalCode(formatCEP(response.company.address.postalCode));
          setPostalCodeRole("ok");
        }

        if (response.company.address.street) {
          setStreet(response.company.address.street);
          setStreetRole("ok");
        }

        if (response.company.address.district) {
          setDistrict(response.company.address.district);
          setDistrictRole("ok");
        }

        if (response.company.address.number) {
          setAddressNumber(response.company.address.number);
          setAddressNumberRole("ok");
        }

        if (response.company.address.complement) {
          setAddressComplement(response.company.address.complement);
          setAddressComplementRole("ok");
        }

        if (response.company.address.state) {
          setState({
            label: response.company.address.state,
            value: response.company.address.state
          });
          setStateRole("ok");
        }

        if (response.company.address.city) {
          setCity({
            label: response.company.address.city,
            value: response.company.address.city
          });
          setCityRole("ok");
        }

        setObs(response.obs || "");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  const handlePhoneKeyDown = event => {
    if (!phone) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setPhones(prev => {
          let p = phone;
          setPhone("");
          return [...prev, { label: applyMask(p), value: applyMask(p) }];
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleMobileKeyDown = event => {
    if (!mobile) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setMobiles(prev => {
          let p = phone;
          setMobile("");
          return [...prev, { label: applyMask(p), value: applyMask(p) }];
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!clientId) {
      return;
    }

    fetchUserInfo(clientId);
  }, [fetchUserInfo, clientId]);

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        {" "}
        <img src={message} alt={"Usuários"} />
        Usuários
      </h3>

      <form className={styles.form} autoComplete="off">
        <div className={styles.containerInputs}>
          <InputForm
            name="profile"
            placeholder="Informe o perfil"
            value={profile}
            error={profileError}
            onChange={handleInputProfile}
            role={profileRole}
            label="Perfil"
            required
            isSelect
            options={listProfiles}
          />

          <InputForm
            type="text"
            name="name"
            placeholder="Digite o nome"
            value={fullname}
            error={fullnameError}
            onChange={handleInputFullname}
            role={fullnameRole}
            label="Nome"
            required
          />

          <InputForm
            type="text"
            name="cpfOrCnpj"
            placeholder="000.000.000-00"
            value={cpfOrCnpj}
            error={cpfOrCnpjError}
            onChange={handleInputCpfOrCnpj}
            role={cpfOrCnpjRole}
            label={"CPF/CNPJ"}
            required
          />
        </div>

        <div className={styles.containerInputs}>
          <InputForm
            name="companyName"
            placeholder="Informe o nome da empresa"
            value={company}
            error={companyError}
            onChange={handleInputCompanyName}
            role={companyRole}
            label="Nome da empresa"
            required={!profile?.label?.includes("Administrador")}
            isDisabled={!!profile?.label?.includes("Administrador")}
            onInputChange={() => {}}
            onKeyDown={() => {}}
            options={companies}
            isCreateable
            addProductSelect
            newOption="Criar empresa"
          />

          <InputForm
            type="text"
            name="sold"
            value={0}
            error={""}
            role={"ok"}
            label="Total Vendido"
            isDisabled
          />

          <InputForm
            type="text"
            name="email"
            placeholder="Digite o e-mail"
            value={email}
            error={emailError}
            onBlur={() => checkEmail(email)}
            onChange={handleInputEmail}
            role={emailRole}
            label="E-mail"
            required
          />
        </div>

        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            name="mobile"
            placeholder="(00) 00000-0000"
            value={mobile}
            error={mobileError}
            components={{
              DropdownIndicator: null
            }}
            onChange={handleInputMobileChange}
            role={mobileRole}
            label="Telefone celular"
            onKeyDown={handleMobileKeyDown}
            onInputChange={handleInputMobile}
            menuIsOpen={false}
            isClearable
            isMulti
            isCreateable
            options={mobiles}
            required
          />

          <InputForm
            type="text"
            name="phone"
            placeholder="(00) 0000-0000"
            value={phone}
            error={phoneError}
            components={{
              DropdownIndicator: null
            }}
            onChange={handleInputPhoneChange}
            role={phoneRole}
            label="Telefone fixo"
            onKeyDown={handlePhoneKeyDown}
            onInputChange={handleInputPhone}
            menuIsOpen={false}
            isClearable
            isMulti
            isCreateable
            options={phones}
          />

          <div className={styles.cepContainer}>
            <InputForm
              type="tel"
              placeholder="Digite o CEP"
              onChange={handleInputCep}
              value={postalCode}
              name="cep"
              error={postalCodeError}
              required={!profile?.label?.includes("Administrador")}
              label="CEP"
              isDisabled={!!profile?.label?.includes("Administrador")}
              role={postalCodeRole}
            />
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.cep}
            >
              Não sei meu CEP
              <AiOutlineQuestionCircle fontSize={"20px"} />
            </a>
          </div>


        </div>
        <div className={styles.containerInputs}>
          <InputForm
            isSelect
            placeholder="Estado"
            label="Estado"
            role={stateRole}
            value={state}
            options={ufs}
            onChange={handleSetUf}
            error={stateError}
            isDisabled={!!profile?.label?.includes("Administrador")}
            required={!profile?.label?.includes("Administrador")}
          />
          <InputForm
            type="text"
            placeholder="Digite o bairro"
            onChange={handleInputAddressNeighborhood}
            value={district}
            name="neighborhood"
            error={districtError}
            label="Bairro"
            isDisabled={!!profile?.label?.includes("Administrador")}
            role={districtRole}
            required={!profile?.label?.includes("Administrador")}
          />
          <InputForm
            isSelect
            placeholder="Cidade"
            label="Cidade"
            role={cityRole}
            value={city}
            options={cities}
            onChange={handleSetCity}
            isDisabled={!!profile?.label?.includes("Administrador")}
            error={cityError}
            required={!profile?.label?.includes("Administrador")}
          />
        </div>
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            placeholder="Digite o nome da rua"
            onChange={handleInputAddress}
            value={street}
            name="street"
            isDisabled={!!profile?.label?.includes("Administrador")}
            error={streetError}
            label="Logradouro"
            role={streetRole}
            required={!profile?.label?.includes("Administrador")}
          />
          <InputForm
            type="text"
            placeholder="Digite o número"
            onChange={handleInputAddressNumber}
            value={addressNumber}
            name="address"
            error={addressNumberError}
            isDisabled={!!profile?.label?.includes("Administrador")}
            label="Número"
            role={addressNumberRole}
            required={!profile?.label?.includes("Administrador")}
          />

          <InputForm
            type="text"
            placeholder="Digite o complemento"
            onChange={handleInputAddressComplement}
            value={addressComplement}
            isDisabled={!!profile?.label?.includes("Administrador")}
            name="complement"
            error={addressComplementError}
            label="Complemento"
            role={addressComplementRole}
          />
        </div>
        <div className={styles.containerInputs}>
          <InputForm
            type="text"
            placeholder="Observações"
            onChange={setObs}
            value={obs}
            name="Observações"
            label="Observações"
          />
        </div>
        <FormAlert />
      </form>

      <FormActions
        module={"usuarios"}
        formIsValid={formIsValid()}
        onCleanForm={onCleanForm}
        onSubmit={submit}
        hideCancel={true}
        messagem
      />
    </div>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  companies: company.companies,
  selectCompany: company.selectCompany,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(UserForm);
