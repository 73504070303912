import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import globalColors from "../../../../_metronic/_assets/sass/custom/globalColors.scss";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    width: fit-content;
    max-width: 90%;
  }

  .modal-body {
    width: fit-content;
    max-width: 100%;
    padding: 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  margin: 0 auto;

  background: transparent;
  border-radius: 15px;

  span {
    display: block;
  }

  .forgot-title {
    text-align: center;
    font-family: "Cera Pro Regular";
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }

  .forgot-subtitle {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${globalColors.textColor1};

    width: fit-content;
  }
`;

export const defaultInnerWidth = 90;

export const StyledInput = styled(TextField)`
  width: ${defaultInnerWidth + "%"};
  margin: 20px 0;

  input {
    font-size: 18px;
    padding: 7px 5px;
  }
`;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin-top: 20px;
  width: ${defaultInnerWidth + "%"};
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 145px;
  margin: 0 !important;
  gap: 5px;
  border-radius: 4px;
  border: 1px solid ${globalColors.primaryColor1};
  background: ${({ color }) =>
    color === "primary" ? globalColors.primaryColor1 : "transparent"};

  font-family: "Cera Pro Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ color }) =>
    color === "primary" ? "#fff" : globalColors.primaryColor1};

  box-shadow: ${({ color }) =>
    color === "primary"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const PatternButton = `
  outline: none;
  border: none;
  margin: none;
  padding: 15px 25px;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: all 0.2s linear;
`;

export const CancelButton = styled.button`
  ${PatternButton}
  background: #555;
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;

  &:hover {
    background: ${props => (props.disabled ? "#807E80" : "#474747")};
    color: white;
  }
`;

export const SubmitButton = styled.button`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : "#1c0c3f"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;

export const LinkButton = styled(Link)`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : "#1c0c3f"};
  cursor: ${props => props.disabled && "not-allowed"};
  color: white;
`;
