import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import logo from "../../assets/logo/logo__login.svg";
import Login from "./Login";
import Password from "./Password";
import PassActions from "./Password/enum/Actions";
import Content from "../home/components/Content/Content";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";

export default function AuthPage() {
  return (

    <div
      className="kt-grid kt-grid--ver kt-grid--root"
      style={{
        height: "100%",
        background: globalColors.primaryColor1,
        overflow: 'hidden'
      }}
    >
      <div
        id="kt_login"
        className=" kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              width: "43.89%"
            }}
          >
            <div className="kt-grid__item">
              <Content loading={!logo}>
                <Link
                  to="/"
                  className="kt-login__logo justify-content-center"
                >
                  <img alt="Logo" src={logo} size={'250px'} />
                </Link>
              </Content>
            </div>
          </div>

          <div
            style={{ padding: 0 }}
            className="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
          >
            <Switch>
              <Route
                path="/auth/ativar-conta"
                component={() => <Password action={PassActions.CriarConta} />}
              />
              <Route
                path="/auth/recuperar-senha"
                component={() => (
                  <Password action={PassActions.RecuperarSenha} />
                )}
              />
              <Route
                path="/auth/cadastrar-senha"
                component={() => (
                  <Password action={PassActions.CadastrarSenha} />
                )}
              />

              <Route path="/auth/login" component={Login} />
              <Redirect from="/auth" exact={true} to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
        </div>
      </div>
    </div>

  )
}
