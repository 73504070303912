import styled from "styled-components";

export const TooltipText = styled.div`
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  //left: -140px;
  visibility: hidden;
  min-width: fit-content;
  padding: 16px;

  word-wrap: break-word;
  white-space: nowrap;

  z-index: 1;

  background: #ffffff;
  border: 0.5px solid #00aeac;

  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #00aeac;

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);

  border-radius: 12px;

  div {
    display: flex;
    align-items: flex-start;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      margin-left: 12px;
    }

    & + div {
      margin-top: 16px;
    }
  }
`;

export const TooltipCard = styled.div`
  position: relative;

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;
