import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { AppAPIService } from "../../../../services/AppAPIService";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import briefCase from "../../../../assets/icons/briefcase.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { FormatMoney } from "../../../../utils/FormatMoney";

const UserListing = props => {
  let api = AppAPIService.getInstance();

  UseProtectedPage.accessToFormOrListing(USER_TYPES.WORKERS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.WORKERS_WRITE.ENGLISH
  );

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  window.setPageTitle("Listagem de profissionais - Admin");

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        method: "DELETE",
        url: `/worker/${id}`
      });

      setReload(!reload);

      Swal.fire({
        title: "Sucesso!",
        text: "Profissional deletado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar profissional.", "error");
    }

    setLoading(false);
  };

  const handleConfirmChangeStatus = async obj => {
    try {
      await api.makeHttpRequest({
        method: "PATCH",
        url: `/worker/${obj.id}/state`,
        data: {
          status: !obj.active
        }
      });

      setReload(!reload);

      const workerStatus = !obj.active ? "ativado" : "desativado";

      Swal.fire({
        title: "Sucesso!",
        text: `Profissional ${workerStatus} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    }
  };

  const headRows = [
    { column: "fullname", label: "Nome" },
    { column: "occupation", label: "Especialidade" },
    { column: "phone", label: "Telefone" },
    { column: "email", label: "E-mail" },
    { column: "salary", label: "Salário / Valor Hora" },
    { column: "comission", label: "Comissão" },
    { column: "workload", label: "Carga horária" },
    { column: "obs", label: "Observações" }
  ];

  if (userHasAccess) {
    headRows.unshift({ label: "Ações" });
  }

  const handleResendEmail = async (user) => {
    try {
      await api.makeHttpRequest({
        method: 'POST',
        url: '/auth/resend',
        data: {
          email: user.email
        }
      })
    } catch (e) {
      console.log("Erro ao reenviar o email");
    }
  }

  const formatRow = r => {
    let crudActions = ["delete", "edit", "toggle"];

    return (
      <StyledTableRow key={r.id} hover tabIndex={-1}>
        {userHasAccess ? (
          <StyledTableCell
            scope="row"
            style={{ padding: "0px 10px", width: "48px" }}
          >
            <CrudActions
              actions={crudActions}
              module="worker"
              path="profissionais"
              onConfirmChangeStatus={handleConfirmChangeStatus}
              onConfirmDelete={handleConfirmDelete}
              onConfirmResendEmail={handleResendEmail}
              id={r.id}
              obj={r}
              titleMessageDelete={"Excluir Profissional"}
              addMessage={"Você tem certeza que deseja excluir esse profissional? Essa ação não poderá ser desfeita"}
            />
          </StyledTableCell>
        ) : null}
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.fullname}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.occupation ?? "-"}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r?.phone?.replace("+55 ", "") ?? "-"}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.email ?? "-"}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {`${FormatMoney(parseFloat(r.salary).toFixed(2))} / ${FormatMoney((r.salary / r.workload).toFixed(2))}`}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.comission ? `${parseInt(Number(r.comission) * 100)}%` : '0%'}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {`${r.workload ? `${r.workload}H` : '-'}`}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 20px 0 0", maxWidth: 150 }}>
          {r.obs || '--'}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        title="Profissionais"
        icon={<img src={briefCase} alt={"profissional"} />}
        endpoint="/worker"
        className="h-100"
        headRows={headRows}
        formatRow={formatRow}
        rows={[]}
        reload={reload}
        placeholderSearch="Busque por nome, e-mail, especialidade ou telefone"
        width={props.width}
        tooltip="Cadastrar novo profissional"
        hiddenReload
        addButtonUrl={userHasAccess ? "/admin/profissionais/adicionar" : ""}
        hiddenKanbanContent
        titleButon={"Novo cadastro"}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(UserListing);
