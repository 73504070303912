import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  FormatMoney,
  FormatMoneyToNumber
} from "../../../../utils/FormatMoney";
import styles from "./styles.module.scss";
import money from "../../../../assets/icons/money.svg";

import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import FormActions from "../../components/FormActions";

import { Formik } from "formik";

import { schema } from "./validator";
import { FormikInput } from "../../../../components/FormikInput/Input";
import Swal from "sweetalert2";

import Loading from "../../../home/components/Loading";

import {
  getFinancialMovementById,
  createFinancialMovement,
  updateFinancialMovement
} from "../../../../services/modules/FinancialMovementApiService";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import { fetchDetails } from "../utils/fetchDetails";
import moment from "moment";
import { FormAlert } from "../../../../components/Alerts/alert";
import {
  FinancialMoveStatus,
  FinancialMoveType,
  FinancialMoveTo
} from "./enums";

import { getEffectiveValue } from "../../../../utils/getEffectiveValue";
import { getEffectivePercentual } from "../../../../utils/getEffectivePercentual";
import { capitalize } from "lodash";
import { enumerateCategories, enumerateFinancialFlow } from "../Categories/CategoryListing";
import { getFinancialFlowAnalysis } from "../../../../services/modules/CashFlowAnalysisApiService";

const rootPage = "/admin/lancamentos";

const status = Object.entries(FinancialMoveStatus).map(([key, value]) => {
  return {
    label: value,
    value: key
  };
});

const types = Object.entries(FinancialMoveType).map(([key, value]) => {
  return {
    label: value,
    value: key
  };
});

const FinancesForm = ({ idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.FINANCES_WRITE.ENGLISH);
  const rootPage = "/admin/lancamentos";
  const { movementId } = useParams();
  const history = useHistory();

  window.setPageTitle(`${!!movementId ? "Cadastro" : "Edição"} de lançamentos`);

  const [currentCategories, setCurrentCategories] = useState([]);
  const [historiesDiscount, setHistoriesDiscount] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false)

  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    status: undefined,
    date: undefined,
    type: undefined,
    receiver: undefined,
    moveTo: undefined,
    liquidValue: FormatMoney(0.00),
    value: undefined,
    discounts: [],
    fees: [],
    detail: "",
    obs: "",
    worker: undefined,
    receiptWay: undefined
  });

  const { data: clients } = useQuery(
    "clients",
    () =>
      fetchDetails(
        `/client/all?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      ),
    {
      select: clients => clients.map(i => ({ value: i.id, label: i.fullname })).sort(
        (c1, c2) =>
          c1.label.localeCompare(c2.label, undefined, { sensitivity: 'accent' })
      )
    }
  );

  const { data: receiptWays } = useQuery(
    "receiptway",
    () =>
      fetchDetails(
        `/receiptway/all?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      ),
    {
      select: methods => methods.map(i => ({ value: i.id, label: i.name }))
    }
  );

  const { data: suppliers } = useQuery(
    "suppliers",
    async () => {
      const result = await fetchDetails(
        `/supplier/all?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      );
      setHistoriesDiscount(result);
      return result;
    },
    {
      select: suppliers => suppliers.map(i => ({ value: i.id, label: i.name, supplier: i })).sort(
        (s1, s2) =>
          s1.label.localeCompare(s2.label, undefined, { sensitivity: 'accent' })
      )
    }
  );

  const { data: workers } = useQuery(
    "workers",
    () => fetchDetails(`/worker/all?idCompany=${user.idCompany ?? idCompany}`, setLoading),
    {
      select: workers =>
        workers.map(i => ({ value: i.id, label: i.fullname }))
    }
  );

  const [discounts, setDiscounts] = useState([])

  const { data: rawDiscounts } = useQuery(
    "discounts",
    () =>
      fetchDetails(
        `discount/all?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      ),
    {
      select: discounts =>
        discounts?.map(discount => ({
          value: discount.id,
          label: `${discount.description} (${getEffectiveValue(
            discount.alterationHistoric
          )})`,
          discount
        }))
    }
  );

  useEffect(() => {
    setDiscounts(rawDiscounts)
  }, [rawDiscounts])

  const [fees, setFees] = useState([])

  const updateFeesAndDiscounts = (values, setFieldValue) => {
    setDiscounts(prevState => {
      return (prevState ?? []).map(discountDetails => {
        return {
          value: discountDetails.discount.id,
          discount: discountDetails.discount,
          label: `${discountDetails.discount.description} (${getEffectiveValue(
            discountDetails.discount.alterationHistoric, values.date
          )})`,
        }
      })
    })

    setFees(prevState => {
      return (prevState ?? []).map(feeDetails => {
        return {
          value: feeDetails.fee.id,
          fee: feeDetails.fee,
          label: makeFeesLabel(
            values.receiptWay?.label,
            feeDetails.fee.paymentWay?.name,
            feeDetails.fee.alterationHistoric,
            values.date
          ),
        }
      })
    })

    if (setFieldValue) {
      setFieldValue('discounts', (values.discounts ?? []).map(selectedDiscount => {
        return {
          value: selectedDiscount.value,
          label: `${selectedDiscount.discount.description} (${getEffectiveValue(
            selectedDiscount.discount.alterationHistoric, values.date
          )})`,
          discount: selectedDiscount.discount
        }
      }))

      setFieldValue('fees', (values.fees ?? []).map(selectedFee => {
        return {
          value: selectedFee.value,
          label: makeFeesLabel(
            values.receiptWay?.label,
            selectedFee.fee.paymentWay?.name,
            selectedFee.fee.alterationHistoric,
            values.date
          ),
          fee: selectedFee.fee
        }
      }))
    }
  }

  const get = async id => {
    try {
      const financialmovement = await getFinancialMovementById(id);
      let receiver = undefined;
      let moveTo = undefined;

      if (financialmovement.idClient) {
        moveTo = { label: "Cliente", value: "cliente" };
        receiver = (clients ?? []).find(
          client => client.value === financialmovement.idClient
        );
      }

      if (financialmovement.idSupplier) {
        moveTo = { label: "Fornecedor", value: "fornecedor" };
        receiver = (suppliers ?? []).find(
          supplier => supplier.value === financialmovement.idSupplier
        );
      }

      if (financialmovement.idFavoredWorker) {
        moveTo = { label: "Funcionário", value: "funcionario" };
        receiver = (workers ?? []).find(
          worker => worker.value === financialmovement.idFavoredWorker
        );
      }

      if (!moveTo) {
        moveTo = {
          label: "Outros",
          value: "outros"
        };
        receiver = moveTo;
      }

      const [statusKey, statusLabel] = Object.entries(FinancialMoveStatus).find(
        ([key, value]) => key === financialmovement.status
      );
      const [typeKey, typeLabel] = Object.entries(FinancialMoveType).find(
        ([key, value]) => key === financialmovement.type
      );
      const date = moment.utc(financialmovement.date).format("YYYY-MM-DD")
      updateFeesAndDiscounts({ date })

      const initialValues = {
        ...financialmovement,
        status: { label: statusLabel, value: statusKey },
        date,
        type: { label: typeLabel, value: typeKey },
        receiver,
        moveTo,
        detail: {
          label: capitalize(financialmovement.detail),
          value: financialmovement.detail,
          category: financialmovement.category,
          subcategory: financialmovement.subcategory
        },
        value: FormatMoney(Number(financialmovement?.total ?? 0).toFixed(2)),
        liquidValue: 0,
        obs: financialmovement.obs,
        discounts: financialmovement.discounts.map(obj => {
          return {
            label: `${obj.discount.description} (${getEffectiveValue(obj.discount.alterationHistoric, date)})`,
            value: obj.discount.id,
            discount: obj.discount
          };
        }),
        fees: financialmovement.fees.map(obj => {
          return {
            label: makeFeesLabel(
              financialmovement.receiptWay?.name,
              obj.fee.paymentWay?.name,
              obj.fee.alterationHistoric,
              date
            ),
            value: obj.fee.id,
            fee: obj.fee
          };
        }),
        ...(financialmovement.worker && (
          {
            worker: {
              label: financialmovement.worker.fullname,
              value: financialmovement.worker.id
            }
          })
        ),
        ...(financialmovement.receiptWay && (
          {
            receiptWay: {
              label: financialmovement.receiptWay.name,
              value: financialmovement.receiptWay.id
            }
          })
        ),

      }

      const liquidValue = calculate(initialValues);
      initialValues.liquidValue = FormatMoney(liquidValue.toFixed(2));

      setInitialValues(initialValues);

    } catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  useEffect(() => {
    if (movementId) get(movementId);
  }, [movementId, clients, suppliers, workers]);

  const handleSubmit = async values => {
    const payload = {
      status: values.status.value,
      date: moment.utc(values.date).toISOString(),
      type: values.type.value,
      detail: values.detail.value,
      category: values.detail.category,
      subcategory: values.detail.subcategory,
      ...(values.moveTo.value === FinancialMoveTo.CLIENT.toLowerCase() && {
        idClient: values.receiver.value
      }),
      ...(values.moveTo.value === FinancialMoveTo.SUPPLIER.toLowerCase() && {
        idSupplier: values.receiver.value
      }),
      ...(values.moveTo.value === FinancialMoveTo.WORKER.toLowerCase() && {
        idFavoredWorker: values.receiver.value
      }),
      total: FormatMoneyToNumber(values.value),
      liquidValue: FormatMoneyToNumber(values.liquidValue),
      obs: values.obs,
      discounts: values.discounts.map(obj => obj.discount.id),
      fees: values.fees.map(obj => obj.fee.id),
      idCompany: user.idCompany ?? idCompany,
      isSelf: true,
      idWorker: values?.worker?.value,
      idReceiptWay: values?.receiptWay?.value,
    };

    setLoading(true);

    try {
      if (!!movementId) {
        await updateFinancialMovement(movementId, payload);
      } else {
        await createFinancialMovement(payload);
      }

      setLoading(false)

      await Swal.fire({
        title: "Sucesso",
        text: `Movimentação ${!!movementId ? "atualizada" : "criada"
          } com sucesso`,
        icon: "success"
      });

      history.push(rootPage);
    } catch (err) {
      setLoading(false)
      if (err.response) {
        const { data } = err.response;
        Swal.fire({
          title: "Erro",
          text: data.messages[0],
          icon: "error"
        });
        return;
      }

      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error"
      });
    }
  };

  const { data: categories } = useQuery(
    "categories",
    async () => {
      const result = await fetchDetails(
        `categories?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      );

      return result;
    }
  );

  const selectDetails = ({ label }) => {
    if (label) {
      let groups = [];
      const categoriesEnumeration = enumerateFinancialFlow(categories);
      (categories || []).map(category => {
        if (category?.type?.toLowerCase().includes(label.toLowerCase())) {
          category.subcategories.map(subcategory => {
            if (subcategory.name.includes("custos financeiros")) return null

            subcategory.groups.map((group) => {
              groups.push({
                subcategory: subcategory.name,
                label: `${categoriesEnumeration[`${category.name}.${subcategory.name}.${group}`]} ${capitalize(group)}`,
                category: category.name,
                value: group
              })
            })
          })
        }
      })
      setCurrentCategories(groups)
      return
    }

    setCurrentCategories([])
  }

  useEffect(() => {
    if (initialValues?.type && categories) {
      selectDetails(initialValues.type)
    };
  }, [initialValues, categories]);


  const removeSupplierDiscount = (setFieldValue, values) => {
    setFieldValue("discounts", (values.discounts ?? []).filter(({ discount }) => {
      return !discount?.description.toLowerCase().includes("desconto do fornecedor")
    }))

    setDiscounts(prevState => {
      const filteredDiscounts = prevState.filter(({ discount }) => {
        return !discount?.description.toLowerCase().includes("desconto do fornecedor")
      })
      return [...filteredDiscounts]
    })
  }

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <img src={money} alt={"Lançamentos"} />
        Lançamentos
      </h3>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        isInitialValid={!!movementId}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ setFieldValue, handleSubmit, validateField, touched, errors, isValid, values }) => (
          <>
            <form
              className={styles.form}
              autoComplete="off"
              onSubmit={handleSubmit}
            >

              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="type"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  options={types}
                  label="Classificação"
                  onChange={(type) => {
                    if (!type || (type && values.type !== type)) {
                      values.detail = ""
                    }
                    selectDetails(type)
                  }}
                  isSelect
                  required
                />
                <FormikInput
                  type="text"
                  name="status"
                  placeholder="Selecione o status"
                  setFieldValue={setFieldValue}
                  options={status}
                  label="Status"
                  isSelect
                  required
                />
                <FormikInput
                  type="date"
                  name="date"
                  placeholder="Data da movimentação"
                  onChange={(event) => {
                    calculate({ ...values, date: event.target.value }, setFieldValue);
                    updateFeesAndDiscounts({ ...values, date: event.target.value }, setFieldValue);
                  }}
                  label="Data"
                  required
                />
                <FormikInput
                  type="text"
                  name="worker"
                  placeholder="Selecione o profissional"
                  setFieldValue={setFieldValue}
                  options={workers}
                  label="Profissional"
                  isSelect
                />
              </div>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="detail"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  options={currentCategories}
                  label="Detalhamento"
                  isSelect
                  required
                />

                <FormikInput
                  type="text"
                  name="moveTo"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  onChange={event => {
                    if (!event) {
                      removeSupplierDiscount(setFieldValue, values)
                      setFieldValue("receiver", "");
                      return
                    };

                    if (event.value === FinancialMoveTo.OTHERS.toLowerCase()) {
                      removeSupplierDiscount(setFieldValue, values)
                      setFieldValue("receiver", {
                        label: "Outros",
                        value: "outros"
                      });
                    } else {
                      setFieldValue("receiver", "");
                      if (event.value !== FinancialMoveTo.SUPPLIER.toLowerCase()) {
                        removeSupplierDiscount(setFieldValue, values)
                      }
                    }
                  }}
                  options={[
                    { label: "Outros", value: "outros" },
                    { label: "Cliente", value: "cliente" },
                    { label: "Fornecedor", value: "fornecedor" },
                    { label: "Funcionário", value: "funcionario" }
                  ]}
                  label="Movimentação para"
                  isSelect
                  required
                />
                <FormikInput
                  type="text"
                  name="receiver"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  isDisabled={
                    !values?.moveTo ||
                    values?.moveTo?.value === FinancialMoveTo.OTHERS.toLowerCase()
                  }
                  required={values?.moveTo?.value !== FinancialMoveTo.OTHERS.toLowerCase()}
                  options={
                    selectReceivedListByMoveTo(
                      values?.moveTo?.value,
                      clients,
                      suppliers,
                      workers
                    )
                  }
                  onChange={event => {
                    values.receiver = event;

                    if (!event) {
                      removeSupplierDiscount(setFieldValue, values)
                      calculate(values, setFieldValue);
                      return
                    }

                    if (values.moveTo.value === FinancialMoveTo.SUPPLIER.toLowerCase()) {
                      if (discounts.find(d => d.value === event.supplier.discount.id)) return;

                      const supplierDiscount = {
                        value: event.supplier.discount.id,
                        label: `${event.supplier.discount.description} (${getEffectiveValue(event.supplier.discount.alterationHistoric, values?.date ?? null)})`,
                        discount: event.supplier.discount
                      }

                      setDiscounts([
                        ...discounts.filter(({ discount }) => !discount?.description.toLowerCase().includes("desconto do fornecedor")),
                        supplierDiscount
                      ])

                      setFieldValue("discounts", [
                        ...values.discounts.filter(({ label }) => !label.toLowerCase().includes("desconto do fornecedor")),
                        supplierDiscount
                      ])
                    } else {
                      removeSupplierDiscount(setFieldValue, values)
                    }
                    calculate(values, setFieldValue);
                  }}
                  onBlur={() => {
                    calculate(values, setFieldValue);
                  }}
                  label="Recebedor da movimentação"
                  isSelect
                />
              </div>

              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="receiptWay"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  label="Forma de recebimento/pagamento"
                  options={receiptWays}
                  onChange={(receiptWay) => {
                    if (!receiptWay || (receiptWay && values.receiptWay !== receiptWay)) {
                      values.fees = []
                      setFees([])
                      calculate(values, setFieldValue)
                    }
                  }}
                  isSelect
                />
                <FormikInput
                  type="text"
                  name="value"
                  placeholder="Digite o valor"
                  setFieldValue={setFieldValue}
                  onChange={(event) => {
                    values.value = event.target.value
                    calculate(values, setFieldValue);
                  }}
                  label="Valor Total"
                  formatter={(value) => {
                    return FormatMoney(value.replace("-", "") ?? 0)
                  }}
                  required
                />

                <FormikInput
                  type="text"
                  name="liquidValue"
                  placeholder="Valor Liquido"
                  setFieldValue={setFieldValue}
                  label="Valor Liquido"
                  isDisabled
                />
              </div>

              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="discounts"
                  placeholder="Selecione..."
                  setFieldValue={setFieldValue}
                  label="Descontos"
                  options={discounts}
                  onChange={(discounts) => {
                    values.discounts = discounts ?? [];
                    calculate(values, setFieldValue);
                  }}
                  onBlur={() => !values.discounts && setFieldValue("discounts", [])}
                  isSelect
                  isMulti
                />
                {(values?.receiptWay?.value) ?
                  (
                    <FeesFieldWithData
                      setLoading={setLoading}
                      idCompany={idCompany}
                      user={user}
                      values={values}
                      setFieldValue={setFieldValue}
                      fees={fees}
                      setFees={setFees}
                    />
                  ) :
                  (<FeesField
                    values={values}
                    setFieldValue={setFieldValue}
                    fees={fees}
                    isDisabled={false}
                  />)
                }
              </div>

              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="obs"
                  placeholder="Digite as observações"
                  setFieldValue={setFieldValue}
                  label="Observações"
                />
              </div>
              <FormAlert />
              <FormActions
                module={"lancamentos"}
                formIsValid={isValid && values.receiver}
                onSubmit={handleSubmit}
                hideCancel={true}
                messagem
              />
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export const calculate = (values, setFieldValue) => {
  let discountPercentualTotal = 0;
  let feePercentualTotal = 0;

  let liquidValue = 0;

  if (values.value) {
    let value = FormatMoneyToNumber(values.value);

    (values.discounts || []).map(({ discount }) => {
      discountPercentualTotal += getEffectivePercentual(discount.alterationHistoric, values?.date)
    });

    (values.fees || []).map(({ fee }) => {
      feePercentualTotal += getEffectivePercentual(fee.alterationHistoric, values?.date)
    });

    discountPercentualTotal /= 100;
    feePercentualTotal /= 100;

    liquidValue = value - (value * feePercentualTotal) - (value * discountPercentualTotal);

  }

  if (setFieldValue) {
    setFieldValue("liquidValue", FormatMoney(liquidValue.toFixed(2)));
  }

  return liquidValue;
}

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

function FeesFieldWithData({ setLoading, idCompany, user, values, setFieldValue, fees, setFees }) {

  const { data: rawFees, refetch } = useQuery(
    "fees",
    async () => {
      const result = await fetchDetails(
        `fee/receiptway/${values.receiptWay?.value}?idCompany=${user.idCompany ?? idCompany}`,
        setLoading
      );
      return result;
    },
    {
      select: fees =>
        fees?.map(fee => ({
          value: fee.id,
          label: makeFeesLabel(values.receiptWay?.label, fee.paymentWay?.name, fee.alterationHistoric, values.date),
          fee
        }))
    }
  );

  useEffect(() => {
    refetch()
  }, [fees])

  useEffect(() => {
    if (!values.fees) refetch()
  }, [values.fees])


  useEffect(() => {
    setFees(rawFees)
  }, [rawFees])

  return (
    <FeesField
      values={values}
      setFieldValue={setFieldValue}
      fees={fees}
      isDisabled={false}
    />
  )
}


function FeesField({ values, setFieldValue, fees, isDisabled }) {
  return (
    <FormikInput
      type="text"
      name="fees"
      placeholder="Selecione..."
      setFieldValue={setFieldValue}
      label="Taxas"
      options={fees}
      onChange={(fees) => {
        values.fees = fees ?? [];
        calculate(values, setFieldValue);
      }}
      onBlur={() => !values.fees && setFieldValue("fees", [])}
      isSelect
      isMulti
      isDisabled={isDisabled}
    />
  );
}

function makeFeesLabel(receiptWayName, paymentName, alterationHistoric, date) {
  return `${receiptWayName} - ${paymentName} (${getEffectiveValue(alterationHistoric, date)})`;
}

function selectReceivedListByMoveTo(moveToSelected, clients, suppliers, workers) {
  if (!moveToSelected) return;
  moveToSelected = moveToSelected.toLowerCase();
  const elements = {};
  elements[FinancialMoveTo.CLIENT.toLowerCase()] = clients;
  elements[FinancialMoveTo.SUPPLIER.toLowerCase()] = suppliers;
  elements[FinancialMoveTo.WORKER.toLowerCase()] = workers;
  if (elements.hasOwnProperty(moveToSelected)) {
    return elements[moveToSelected];
  }
  return;
}

export default connect(mapStateToProps)(FinancesForm);
