export function FormatMoney(value) {
  if(value === 'NaN') {
    return "-"
  }

  if (typeof value !== 'string') {
    value = value.toString();
  }

  let valueWithoutLetters = 0;

  if (value.includes('-')) {
    valueWithoutLetters = value
      .replace('.', "")
      .replace(',', ".")
      .replace(/[^0-9.-]/g, "")
  } else {
    valueWithoutLetters = value.replace(/\D/g, '')
  }

  if (!valueWithoutLetters || Number(valueWithoutLetters) === 0) {
    return "R$ 0,00";
  }

  const valueDecimal = Number(valueWithoutLetters) / 100;
  const valueMoneyMask = valueDecimal.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return valueMoneyMask
}

export function FormatMoneyToNumber(value) {
  const real = value
    .replace('.', "")
    .replace(',', ".")
    .replace(/[^0-9.-]/g, "")

  return Number(real)
}
