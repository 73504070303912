import axios from "axios";
import { AppAPIService } from "../services/AppAPIService";

export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot/password";
const api = AppAPIService.getInstance();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: '/auth/login',
    method: 'POST',
    data: {
      email,
      password
    }
  });
}

export async function resendEmail(email) {
  return api.makeHttpRequest({
    url: '/auth/resend/confirmation',
    method: 'POST',
    data: {
      email
    }
  });
}

export async function loginGoogle(email) {
  return api.makeHttpRequest({
    url: `/login/login-with-social?login=${email}`,
    method: 'GET'
  });
}

export async function verificaEmail(email) {
  return api.makeHttpRequest({
    url: `/user/verificar-email-login?login=${email}`,
    method: 'GET',
  });
}

export async function updatePassword({
  password,
  newPassword,
  token
}) {

  const response = await api.makeHttpRequest({
    url: `auth/register/password`,
    method: 'POST',
    data: {
      token,
      password,
      newPassword
    },
  });
  return response;
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function refreshUserToken(token) {
  return api.makeHttpRequest({
    url: 'auth/refresh',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export function getUser() {
  return api.makeHttpRequest({
    url: '/auth/me'
  });
};
