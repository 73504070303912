import { createAction, createReducer } from '@reduxjs/toolkit'

export const actionTypes = {
  setCategory: "[SetCategory] Action"
};

const initialState = {
  category: {}
};

const setCategory = createAction("[SetCategory] Action")

export const reducer = createReducer(initialState,
  (builder) => {
    builder.addCase(setCategory, (state, action) => {
      return { category: action.payload }
    })
  }
);

export const actions = {
  setCategory: (categories) => ({
    type: actionTypes.setCategory,
    payload: categories
  })
};

