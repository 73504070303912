import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import InputForm from "../../../../components/InputForm";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import formatTel from "../../../../utils/FormatTel";
import FormActions from "../../components/FormActions";
import { AppAPIService } from "../../../../services/AppAPIService";
import Swal from "sweetalert2";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import IsValidName from "../../../../utils/validators/IsValidName";
import briefCase from "../../../../assets/icons/briefcase.svg";
import {
  FormatMoney,
  FormatMoneyToNumber
} from "../../../../utils/FormatMoney";
import validateCep from "../../../../utils/validators/IsValidCep";
import axios from "axios";
import formatCEP from "../../../../utils/FormatCep";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { FormAlert } from "../../../../components/Alerts/alert";
import { isNotNull } from "../../../../utils/validators/IsNotNull";

const WorkerForm = ({ user, idCompany }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.WORKERS_WRITE.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.WORKERS_WRITE.ENGLISH
  );

  const { workerId } = useParams();

  let api = AppAPIService.getInstance();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [fullnameRole, setFullnameRole] = useState("");

  const [occupation, setOccupation] = useState("");
  const [occupationError, setOccupationError] = useState("");
  const [occupationRole, setOccupationRole] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailRole, setEmailRole] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneRole, setPhoneRole] = useState("");

  const [salary, setSalary] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [salaryRole, setSalaryRole] = useState("");

  const [comission, setComission] = useState("");
  const [comissionError, _] = useState("");
  const [comissionRole, setComissionRole] = useState("");

  const [workloadRole, setWorkloadRole] = useState("");
  const [workloadError, setWorkloadError] = useState("");
  const [workload, setWorkload] = useState("");

  const [salaryHour, setSalaryHour] = useState("");
  const [obs, setObs] = useState("");

  const [streetRole, setStreetRole] = useState("");
  const [streetError, setStreetError] = useState("");
  const [street, setStreet] = useState("");

  const [districtRole, setDistrictRole] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [district, setDistrict] = useState("");

  const [addressComplementRole, setAddressComplementRole] = useState("");
  const [addressComplementError, setAddressComplementError] = useState("");
  const [addressComplement, setAddressComplement] = useState("");

  const [postalCodeRole, setPostalCodeRole] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [cityRole, setCityRole] = useState("");
  const [cityError, setCityError] = useState("");
  const [city, setCity] = useState("");

  const [stateRole, setStateRole] = useState("");
  const [stateError, setStateError] = useState("");
  const [state, setState] = useState("");

  const [addressNumberRole, setAddressNumberRole] = useState("");
  const [addressNumberError, setAddressNumberError] = useState("");
  const [addressNumber, setAddressNumber] = useState("");

  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);

  const [workerInfo, setWorkerInfo] = useState({});

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberError("");
      setAddressNumberRole("ok");
    } else {
      setAddressNumberError("Campo obrigatório.");
      setAddressNumberRole("notOk");
    }
  };

  const handleInputAddressNumber = e => {
    if (e.length > 6) return;

    if (e !== "" && !Number(e)) {
      return;
    }

    setAddressNumber(e);
    handleValidationAddressNumber(e);
  };

  const handleValidationAddressComplement = value => {
    if (value.length < 2 && value.length > 0) {
      setAddressComplementError("Complemento deve ter pelo menos 2 caracteres");
      setAddressComplementRole("notOk");
    } else if (value.length >= 2) {
      console.log("maior que 2");
      setAddressComplementRole("ok");
      setAddressComplementError("");
    } else {
      setAddressComplementError("");
      setAddressComplementRole("");
    }
  };

  const handleInputAddressComplement = e => {
    if (e.length > 20) return;

    let value = e;
    setAddressComplement(value);
    handleValidationAddressComplement(value);
  };

  const handleNeighborhoodValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setDistrictError("Bairro deve ter pelo menos 2 caracteres");
      setDistrictRole("notOk");
    } else if (value.length >= 2) {
      setDistrictRole("ok");
      setDistrictError("");
    } else {
      setDistrictError("");
      setDistrictRole("");
    }
  };

  const handleInputAddressNeighborhood = e => {
    let value = e;
    setDistrictError(value);
    handleNeighborhoodValidation(value);
  };

  const handleAddressValidation = value => {
    if (value.length < 2 && value.length > 0) {
      setStreetError("Endereço deve ter pelo menos 2 caracteres");
      setStreetRole("notOk");
    } else if (value.length >= 2) {
      setStreetRole("ok");
      setStreetError("");
    } else {
      setStreetError("");
      setStreetRole("");
    }
  };

  const handleInputAddress = e => {
    if (e.length > 50) return;
    setStreet(e);
    handleAddressValidation(e);
  };

  const handleFetchUfs = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.sigla,
        label: resp.nome
      }));
      setUfs(ufOpts);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleFetchCities = useCallback(async () => {
    try {
      const ufSelected = ufs.find(ufOpt => ufOpt.value === state?.value);
      const response = await (
        await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected?.value}/municipios`
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.nome,
        label: resp.nome
      }));
      setCities(ufOpts);
    } catch (error) {
      console.log(error);
    }
  }, [state.value, ufs]);

  const handleSetUf = useCallback(
    e => {
      if (e === null && postalCode) {
        setPostalCodeRole("notOk");
        setPostalCodeError("Campo obrigatório.");
        setCityError("Campo obrigatório.");
        setState("");
        setCityRole("notOk");
        setCity("");
      } else {
        setPostalCode("");
        setPostalCodeRole("ok");
        setPostalCodeError("");
        setCity(undefined);
        setCityRole("ok");
        setCityError();
      }
    },
    [postalCode]
  );

  const handleSetCity = e => {
    if (e === null) {
      setCityError("Campo obrigatório.");
      setCityRole("notOk");
      setCity("");
    } else {
      setCity(e ? e : {});
      setCityRole("ok");
      setCityError("");
    }
  };

  useEffect(() => {
    if (postalCode === "") {
      setState("");
      setCity("");
      setStateError("");
      setStateRole("");
      setCityRole("");
      setCityError("");
      setStreet("");
      setStreetRole("");
      setStreetError("");
      setDistrictRole("");
      setDistrictError("");
      setDistrict("");
      setAddressNumber("");
      setAddressNumberRole("");
      setAddressNumberError("");
      setAddressComplement("");
      setAddressComplementRole("");
      setAddressComplementError("");
    }
  }, [postalCode]);

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await (
        await axios.get(`https://viacep.com.br/ws/${cep}/json`, {
          headers: {}
        })
      ).data;

      if (response?.erro) {
        setPostalCodeRole("ok");
        setPostalCodeError("");

        setAddressNumber("");
        setAddressNumberRole("notOk");
        setAddressNumberError("Campo obrigatório.");

        setStreet("");
        setStreetRole("notOk");
        setStreetError("Campo obrigatório.");

        setDistrictError("");
        setDistrictRole("notOk");
        setDistrictError("Campo obrigatório.");

        setAddressComplement("");
        setAddressComplementRole("");
        setAddressComplementError("");

        setState("");
        setStateRole("notOk");
        setStateError("Campo obrigatório.");

        setCity("");
        setCityRole("notOk");
        setCityError("Campo obrigatório.");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setPostalCodeRole("ok");
      setPostalCodeError("");

      setStreet(response.logradouro);
      setStreetRole("ok");
      setStreetError("");

      if (!response.logradouro) {
        setStreet("");
        setStreetRole("notOk");
      }

      if (!response.bairro) {
        setDistrictRole("notOk");
        setDistrictError("Bairro não encontrado");
      } else {
        setDistrictError("");
        setDistrict(response.bairro);
        setDistrictRole("ok");
      }

      setState({
        value: response.uf,
        label: response.uf
      });
      setStateRole("ok");
      setStateError("");

      setCity({
        value: response.localidade,
        label: response.localidade
      });
      setCityRole("ok");
      setCityError("");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido."
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleInputCep = useCallback(
    async zipcode => {
      if (zipcode.length > 11) {
        return;
      }

      const { error, cepFormat, cep, role } = validateCep(zipcode);

      if (zipcode.length < 1) {
        setPostalCode(zipcode);
        setPostalCodeError("");
        setPostalCodeRole("");
        return;
      }

      if (cep === "") {
        setPostalCodeError("Apenas números.");
        setPostalCodeRole("notOk");
        return;
      }

      if (zipcode.length > 10) {
        return;
      }

      setPostalCode(cepFormat);
      setPostalCodeError(error);
      setPostalCodeRole(role);

      if (cep.length === 8) {
        await handleCepConsult(cep);
      }
    },
    [handleCepConsult]
  );

  useEffect(() => {
    handleFetchUfs();
  }, [handleFetchUfs]);

  useEffect(() => {
    handleFetchCities();
  }, [ufs, state.value, handleFetchCities]);

  const handleValidationOccupation = value => {
    if (value !== "") {
      setOccupationError("");
      setOccupationRole("ok");
    } else {
      setOccupationError("Campo obrigatório.");
      setOccupationRole("notOk");
    }
  };

  const handleInputOccupation = e => {
    setOccupation(e);
    handleValidationOccupation(e);
  };

  const handleValidationSalary = value => {
    if (value !== "") {
      setSalaryError("");
      setSalaryRole("ok");
    } else {
      setSalaryError("Campo obrigatório.");
      setSalaryRole("notOk");
    }
  };

  const handleInputSalary = e => {
    const value = FormatMoney(e);
    setSalary(value);
    handleValidationSalary(value);
  };

  const handleInputComission = e => {
    let value = e.replace(/\D/g, "");
    setComission(`${value}%`);
  };

  const handleValidationWorkload = value => {
    if (value !== "") {
      setWorkloadError("");
      setWorkloadRole("ok");
    } else {
      setWorkloadError("Campo obrigatório.");
      setWorkloadRole("notOk");
    }
  };

  const handleInputWorkload = e => {
    setWorkload(e.replace(/[^0-9.]/g, ""));
    handleValidationWorkload(e.replace(/[^0-9.]/g, ""));
  };

  const handleInputObs = e => {
    setObs(e);
  };

  const handleInputEmail = async e => {
    if (e.length > 50) {
      return;
    }
    if (e.length < 1) {
      setEmail(e);
      setEmailError("Campo obrigatório");
      setEmailRole("notOk");
    } else if (!IsValidEmail(e)) {
      setEmail(e);
      setEmailError("Não é um E-mail válido.");
      setEmailRole("notOk");
    } else {
      setEmail(e);
      setEmailError("");
      setEmailRole("ok");
    }
  };

  const checkEmail = async e => {
    if (e !== "" && e !== workerInfo?.email) {
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: "auth/email/avaiability",
          data: {
            email: e
          }
        });
        setEmailError("");
        setEmailRole("ok");
      } catch (e) {
        setEmailError("Este email já está em uso");
        setEmailRole("notOk");
        return;
      }
    }
  };

  const handleInputFullname = e => {
    if (e.length > 250) {
      return;
    }
    if (e.length < 3) {
      setFullname(e);
      setFullnameError("Campo obrigatório");
      setFullnameRole("notOk");
    } else if (!IsValidName(e)) {
      setFullname(e);
      setFullnameError("Mínimo de 3 caracteres.");
      setFullnameRole("notOk");
    } else {
      setFullname(e);
      setFullnameError("");
      setFullnameRole("ok");
    }
  };

  const handleInputPhone = e => {
    if (e.length > 15) {
      return;
    }

    const phoneNumbers = e.replace(/\D/g, "");

    if (e !== "" && !Number(phoneNumbers)) {
      setPhoneError("Apenas números");
      setPhoneRole("notOk");
      return;
    }

    if (!IsValidPhone(phoneNumbers)) {
      setPhone(phoneNumbers);
      setPhoneError("Não é um Celular válido.");
      setPhoneRole("notOk");
    } else if (IsValidPhone(phoneNumbers) === "") {
      setPhone();
      setPhoneError("");
      setPhoneRole("");
    } else {
      setPhone(formatTel(phoneNumbers));
      setPhoneError("");
      setPhoneRole("ok");
    }
  };

  const submit = useCallback(
    async e => {
      e.preventDefault();

      try {
        setLoading(true);

        const comissionDecimal =
          Number(comission.replace(/\D/g, "") || 0) / 100;

        await api.makeHttpRequest({
          url: workerId ? `/worker/${workerId}` : "/worker",
          method: workerId ? "PUT" : "POST",
          data: {
            fullname: isNotNull(fullname) ? fullname : null,
            email: isNotNull(email) ? email: null,
            phone: isNotNull(phone) ? `+55 ${phone}` : null,
            occupation: isNotNull(occupation) ? occupation : null,
            workload: isNotNull(workload) ? parseInt(workload) : null,
            comission: isNotNull(comissionDecimal) ? comissionDecimal : null,
            salary: isNotNull(salary) ? FormatMoneyToNumber(salary) : null,
            idCompany: user.idCompany ?? idCompany,
            obs: isNotNull(obs) ? obs : null,
            address: isNotNull(
              [addressComplement, addressNumber, state.label, city.label, postalCode, district, street]
            ) ? {
              complement: addressComplement ?? "",
              number: parseInt(addressNumber),
              state: state.label,
              city: city.label,
              postalCode,
              district,
              street
            } : null,
          }
        });

        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Profissional ${workerId ? "editado" : "cadastrado"
            } com sucesso.`
        }).then(() => {
          history.push("/admin/profissionais");
        });
      } catch (error) {
        if (
          error.response?.data?.errors
            ?.toLowerCase()
            .includes("Profissional já existe no sistema")
        ) {
          setEmailError("E-mail já está em uso");
          setEmailRole("notOk");

          Swal.fire({
            title: "Erro!",
            text: "E-mail já está em uso",
            icon: "error"
          });
        } else {
          Swal.fire({
            title: "Erro!",
            text:
              "Erro tentar cadastrar novo profissional, tente novamente mais tarde!",
            icon: "error"
          }).then(() => {
            history.push("/admin/profissionais");
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [comission, api, workerId, fullname, email, phone, occupation, workload, salary, user.idCompany, idCompany, obs, addressComplement, addressNumber, state.label, city.label, postalCode, district, street, history]
  );

  useEffect(() => {
    if (workload > 0 && FormatMoneyToNumber(salary) > 0) {
      const salaryNumber = FormatMoneyToNumber(salary);
      setSalaryHour(`R$ ${(salaryNumber / workload).toFixed(2)}`);
    } else {
      setSalaryHour("");
    }
  }, [workload, salary]);

  const formIsValid = useCallback(() => {
    let isValid = false;

    isValid =
      fullname !== "" &&
      fullnameRole === "ok";

    return isValid;
  }, [
    fullname,
    fullnameRole
  ]);

  const fetchWorkerInfo = useCallback(
    async id => {
      try {
        setLoading(true);

        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/worker/${id}`
        });

        setWorkerInfo(response);

        setFullname(response.fullname);
        setFullnameRole("ok");

        setEmail(response.email);
        setEmailRole("ok");

        const formatted = formatTel(
          response.phone.replace("+55 ", "").replace(/\D/g, "")
        );
        setPhone(formatted);
        setPhoneRole("ok");

        setOccupation(response.occupation);
        setOccupationRole("ok");

        setWorkload(response.workload);
        setWorkloadRole("ok");

        setSalary(FormatMoney(parseFloat(response.salary).toFixed(2)));
        setSalaryRole("ok");

        setComission(`${parseInt(Number(response.comission) * 100)}%`);
        setComissionRole("ok");

        setObs(response.obs);

        if (response.address.postalCode) {
          setPostalCode(formatCEP(response.address.postalCode));
          setPostalCodeRole("ok");
        }

        if (response.address.street) {
          setStreet(response.address.street);
          setStreetRole("ok");
        }

        if (response.address.district) {
          setDistrict(response.address.district);
          setDistrictRole("ok");
        }

        if (response.address.number) {
          setAddressNumber(response.address.number);
          setAddressNumberRole("ok");
        }

        if (response.address.complement) {
          setAddressComplement(response.address.complement);
          setAddressComplementRole("ok");
        }

        if (response.address.state) {
          setState({
            label: response.address.state,
            value: response.address.state
          });
          setStateRole("ok");
        }

        if (response.address.city) {
          setCity({
            label: response.address.city,
            value: response.address.city
          });
          setCityRole("ok");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  useEffect(() => {
    if (!workerId) {
      return;
    }

    fetchWorkerInfo(workerId);
  }, [fetchWorkerInfo, workerId]);

  const handleKeyDown = event => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      comission.length > 0
    ) {
      setComission(`${comission.slice(0, -2)}%`);
    }
  };

  return (
    userHasAccess && (
      <div className={styles.container}>
        <Loading isLoading={loading} />
        <h3 className={styles.formTitle}>
          {" "}
          <img src={briefCase} alt={"Profissionais"} />
          {workerId ? "Editar profissional" : "Cadastrar novo profissional"}
        </h3>

        <form className={styles.form} autoComplete="off">
          <div className={styles.containerInputs}>
            <InputForm
              type="text"
              name="name"
              placeholder="Digite o nome do profissional"
              value={fullname}
              error={fullnameError}
              onChange={handleInputFullname}
              role={fullnameRole}
              label="Nome"
              required
            />
          </div>

          <div className={styles.containerInputs}>
            <InputForm
              type="email"
              name="email"
              onBlur={() => checkEmail(email)}
              placeholder="Digite o e-mail do profissional"
              value={email}
              error={emailError}
              onChange={handleInputEmail}
              role={emailRole}
              label="E-mail"
            />

            <InputForm
              type="text"
              name="occupation"
              placeholder="Digite a especialidade do profissional"
              value={occupation}
              error={occupationError}
              onChange={handleInputOccupation}
              role={occupationRole}
              label={"Especialidade"}
            />

            <InputForm
              type="tel"
              name="phone"
              placeholder="Digite o telefone do profissional"
              value={phone}
              error={phoneError}
              onChange={handleInputPhone}
              role={phoneRole}
              label="Telefone"
            />
          </div>

          <div className={styles.containerInputs}>
            <InputForm
              type="text"
              name="salary"
              placeholder="Ex: 4.000,00"
              value={salary}
              error={salaryError}
              onChange={handleInputSalary}
              role={salaryRole}
              label={"Salário"}
            />
            <div className={styles.jobValuesContainer}>
              <InputForm
                type="text"
                name="workload"
                placeholder="Digite a carga horária do profissional"
                value={workload}
                error={workloadError}
                onChange={handleInputWorkload}
                role={workloadRole}
                label={"Carga Horária"}
              />
              <InputForm
                type="text"
                name="paidPerHour"
                placeholder="Valor hora"
                value={salaryHour}
                onChange={() => { }}
                label={"Valor Hora"}
                isDisabled={true}
              />
            </div>
            <InputForm
              type="text"
              name="comission"
              placeholder="Digite o valor"
              value={comission}
              error={comissionError}
              onChange={handleInputComission}
              role={comissionRole}
              label={"Comissão"}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className={styles.containerInputs}>
            <div className={styles.cepContainer}>
              <InputForm
                type="tel"
                placeholder="Digite o CEP"
                onChange={handleInputCep}
                value={postalCode}
                name="cep"
                error={postalCodeError}
                label="CEP"
                role={postalCodeRole}
              />
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.cep}
              >
                Não sei meu CEP
                <AiOutlineQuestionCircle fontSize={"20px"} />
              </a>
            </div>
            <InputForm
              isSelect
              placeholder="Estado"
              label="Estado"
              role={stateRole}
              value={state}
              options={ufs}
              onChange={handleSetUf}
              error={stateError}
            />
            <InputForm
              type="text"
              placeholder="Digite o bairro"
              onChange={handleInputAddressNeighborhood}
              value={district}
              name="neighborhood"
              error={districtError}
              label="Bairro"
              role={districtRole}
            />
          </div>

          <div className={styles.containerInputs}>
            <InputForm
              isSelect
              placeholder="Cidade"
              label="Cidade"
              role={cityRole}
              value={city}
              options={cities}
              onChange={handleSetCity}
              error={cityError}
            />

            <InputForm
              type="text"
              placeholder="Digite o nome da rua"
              onChange={handleInputAddress}
              value={street}
              name="street"
              error={streetError}
              label="Logradouro"
              role={streetRole}
            />

            <InputForm
              type="text"
              placeholder="Digite o número"
              onChange={handleInputAddressNumber}
              value={addressNumber}
              name="address"
              error={addressNumberError}
              label="Número"
              role={addressNumberRole}
            />
          </div>
          <div className={styles.containerInputs}>
            <InputForm
              type="text"
              placeholder="Digite o complemento"
              onChange={handleInputAddressComplement}
              value={addressComplement}
              name="complement"
              error={addressComplementError}
              label="Complemento"
              role={addressComplementRole}
            />
            <InputForm
              type="text"
              placeholder="Digite as observações"
              onChange={handleInputObs}
              value={obs}
              name="obs"
              label="Observações"
            />
          </div>
          <FormAlert />
        </form>

        <FormActions
          module={"profissionais"}
          isEdit={!!workerId}
          formIsValid={formIsValid()}
          onSubmit={submit}
          hideCancel={true}
          messagem
        />
      </div>
    )
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(WorkerForm);
