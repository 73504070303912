export const servicesReducer = (state = [], action) => {
  let newState = [];
  switch (action.type) {
    case "push":
      newState = [...state, action.payload]

      return newState

    case "slice":
      newState = state.filter(service => service.id !== action.payload.id);

      return newState;

    default:
      return state;
  }
}
