/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState, useEffect, useCallback } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { refreshUserToken } from "./app/crud/auth.crud";
import { I18nProvider, LayoutSplashScreen } from "./_metronic";
import bsCustomFileInput from "bs-custom-file-input";
import { QueryClient, QueryClientProvider } from "react-query";

bsCustomFileInput.init();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

history.listen(location => {
  const path = buildPath(location);
  ReactGA.set({ page: path }); // Update the user's current page
  ReactGA.pageview(path); // Record a pageview for the given page
});

export default function App({ store, persistor, basename }) {
  const [isLoading, setIsLoading] = useState(true);

  const refreshToken = useCallback((token) => {
    if (token) {
      refreshUserToken(token);
    }
    setIsLoading(false);
  }, []);

  let authData;
  let token
  let rememberMe;

  if (window.localStorage.getItem("persist:auth:ohana")) {
    authData = JSON.parse(window.localStorage.getItem("persist:auth:ohana"))?.authData;
    rememberMe = JSON.parse(window.localStorage.getItem("persist:auth:ohana"))?.rememberMe;
    if (authData && JSON.parse(authData) && rememberMe) {
      token = JSON.parse(authData)?.refreshToken;
    }
  }

  useEffect(() => {
    const path = buildPath(history.location);
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
    refreshToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persistor}>
          <Router basename={basename} history={history}>
            <LastLocationProvider>
              <I18nProvider>{!isLoading && <Routes />}</I18nProvider>
            </LastLocationProvider>
          </Router>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
}
