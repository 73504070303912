import React, { useState } from "react";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { AppAPIService } from "../../../services/AppAPIService";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Container, Button, StyledInput, ButtonForm, StyledModal } from "./style";
import { CreatePassword } from "./component/CreatePassword";
import { useHistory } from "react-router-dom";
import { ReactComponent as IconCircleRight } from '../../../../app/assets/icons/icon_circle-chevron-right-fill.svg';
import { ReactComponent as IconCircleLeft } from '../../../../app/assets/icons/icon_circle-chevron-left-fill.svg';


const ForgotPassword = ({ open, setOpen, token }) => {
  const api = AppAPIService.getInstance();
  const [email, setEmail] = useState("");

  const [emailSent, setEmailSent] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();

  const reloadForm = () => {
    setEmail("");
    setEmailSent(false);
    setError("");
    setDisabledButton(false);
  };

  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (!IsValidEmail(value)) {
      setError("Este email é invalido!");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleEmailSend = async () => {
    if (error !== "") return;

    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "auth/forgot/password",
        method: "POST",
        data: {
          email
        }
      });

      setEmailSent(true);
      Swal.fire(
        "Sucesso",
        "Enviamos um email com o link para concluir esta operação",
        "success"
      ).then(() => {
        setOpen(false)
      })
    } catch (e) {
      console.log(e)
      Swal.fire(
        "Erro!",
        e?.response?.data?.messages
          ? e.response?.data?.messages[0]
          : "Tivemos problemas ao enviar o email de recuperação. Por favor, tente novamente.",
        "error"
      );
    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={open}
        onHide={() => setOpen(false)}
        centered
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          {!token && (
            <Container>
              <h1 className="forgot-title">Esqueceu sua senha?</h1>
              <p className="forgot-subtitle mx-3">
                Digite o  e-mail nos campos abaixo para que possamos enviar um código de redefinição
              </p>

              <StyledInput
                placeholder="Insira seu email"
                value={email}
                error={error !== "" ? true : false}
                onChange={handleEmailChange}
                helperText={error !== "" && error}
              />

              <ButtonForm >
                <Button
                  disabled={disabledButton}
                  onClick={() => setOpen(false)}
                  outline
                >
                  <IconCircleLeft /> Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={disabledButton}
                  onClick={handleEmailSend}
                >
                  Enviar <IconCircleRight />
                </Button>
              </ButtonForm>
            </Container>
          )}

          {token && (
            <CreatePassword
              token={token}
              close={() => setOpen(false)}
              resetAll={reloadForm}
            />
          )}
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ForgotPassword;
