import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import Loading from "../../../home/components/Loading";

import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { USER_TYPES } from "../../../../enum/UserTypes";
import vehicles from "../../../../assets/icons/vehicle.svg";
import FormActions from "../../components/FormActions";

import { Formik } from "formik";

import { schema } from "./validator"
import { FormikInput } from "../../../../components/FormikInput/Input";
import Swal from "sweetalert2";

import { getVehicleById, createVehicle, updateVehicle } from "../../../../services/modules/VehicleApiService";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import { fetchDetails } from "../utils/fetchDetails";
import moment from 'moment';
import { FormAlert } from "../../../../components/Alerts/alert";

const rootPage = "/admin/veiculos";

const VehicleForm = ({ idCompany, user }) => {
  UseProtectedPage.accessToFormOrListing(USER_TYPES.VEHICLES_WRITE.ENGLISH);

  const { vehicleId } = useParams();
  const history = useHistory();

  window.setPageTitle(`${!!vehicleId ? "Cadastro" : "Edição"} de veículos`);

  const [initialValues, setInitialValues] = useState({
    client: undefined,
    licensePlate: "",
    licenseExpiresAt: "",
    brand: undefined,
    model: undefined,
    color: "",
    fuelType: undefined,
    year: "",
    milage: "",
    soldAt: "",
    obs: "",
  });

  const get = async (id) => {
    try {
      const vehicle = await getVehicleById(id);
      setInitialValues({
        ...vehicle,
        milage: vehicle.milage.toLocaleString(),
        soldAt: moment.utc(vehicle?.soldAt).format('YYYY-MM-DD'),
        nextOilChangeDate: moment.utc(vehicle?.nextOilChangeDate).format('YYYY-MM-DD'),
        licenseExpiresAt: moment.utc(vehicle?.licenseExpiresAt).format('YYYY-MM-DD'),
        client: { label: vehicle?.client?.fullname, value: vehicle.client.id },
        fuelType: { label: vehicle?.fuelType, value: vehicle?.fuelType },
        brand: { label: vehicle?.brand, value: vehicle?.brand },
        model: { label: vehicle?.model, value: vehicle?.model },
      });
    }
    catch (err) {
      Swal.fire({
        title: "Erro",
        text: err.message,
        icon: "error",
      })
    }
  }

  const { data: clients } = useQuery(
    "clients",
    () => fetchDetails(`/client/all?idCompany=${user.idCompany ?? idCompany}`, setLoading),
    {
      select: clients =>
        clients.map(i => ({ value: i.id, label: i.fullname }))
    }
  );

  const { data: models } = useQuery(
    "models",
    () => fetchDetails("vehicle/models", setLoading),
    {
      select: models =>
        models?.map(i => ({ value: i, label: i }))
    }
  );

  const { data: brands } = useQuery(
    "brands",
    () => fetchDetails("vehicle/brands", setLoading),
    {
      select: brands =>
        brands?.map(i => ({ value: i, label: i }))
    }
  );

  useEffect(() => {
    if (vehicleId) {
      get(vehicleId)
    }
  }, [vehicleId])

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    const payload = {
      idClient: values.client.value,
      licensePlate: values.licensePlate,
      brand: values.brand.value,
      model: values.model.value,
      fuelType: values.fuelType.value,
      color: values.color,
      obs: values.obs,
      year: parseInt(values.year),
      soldAt: moment.utc(values.soldAt).toISOString(),
      milage: Number(values.milage.replace(/[^\d]/g, '')),
      licenseExpiresAt: moment.utc(values.licenseExpiresAt).toISOString(),
      nextOilChangeDate: moment.utc(values.nextOilChangeDate).toISOString()
    }

    setLoading(true);

    try {
      if (!!vehicleId) {
        await updateVehicle(vehicleId, payload);
      }
      else {
        await createVehicle(payload);
      }

      setLoading(false);
      await Swal.fire({
        title: "Sucesso",
        text: `Veículo ${!!vehicleId ? "atualizado" : "criado"} com sucesso`,
        icon: "success",
      })

      history.push(rootPage)
    }
    catch (err) {
      const message = "Erro ao salvar veículo";

      Swal.fire({
        title: "Erro",
        text: message,
        icon: "error",
      })
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        {" "}
        <img src={vehicles} alt={"Veículos"} />
        Veículos
      </h3>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        isInitialValid={!!vehicleId}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isValid, setFieldValue }) => (
          <>
            <form className={styles.form} autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="client"
                  placeholder="Selecione o cliente"
                  label="Cliente"
                  setFieldValue={setFieldValue}
                  options={clients}
                  isSelect
                  required
                />
                <FormikInput
                  type="text"
                  name="licensePlate"
                  formatter={(value, _) => {
                    return value.toUpperCase()
                  }}
                  placeholder="Digite a placa"
                  label="Placa"
                  required
                />
                <FormikInput
                  type="date"
                  name="licenseExpiresAt"
                  placeholder="Data do vencimento do licenciamento"
                  label="Vencimento do licenciamento"
                />
              </div>
              <div className={styles.containerInputs}>

                <FormikInput
                  type="text"
                  name="brand"
                  placeholder="Selecione a marca"
                  label="Marca"
                  setFieldValue={setFieldValue}
                  options={brands}
                  newOption="Criar marca"
                  addProductSelect
                  isCreatable
                  isClearable
                  required
                />
                <FormikInput
                  type="text"
                  name="model"
                  placeholder="Selecione o modelo"
                  label="Modelo"
                  setFieldValue={setFieldValue}
                  options={models}
                  newOption="Criar modelo"
                  addProductSelect
                  isCreatable
                  isClearable
                  required
                />
                <FormikInput
                  type="text"
                  name="color"
                  placeholder="Digite a cor"
                  label="Cor"
                  required
                />
              </div>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="text"
                  name="year"
                  placeholder="Digite o ano"
                  label="Ano"
                  required
                />
                <FormikInput
                  type="text"
                  name="milage"
                  placeholder="Digite a quilometragem atual"
                  formatter={(value) => {
                    return Number(value.replace(/[^\d]/g, '')).toLocaleString()
                  }}
                  label="Km Atual"
                />
                <FormikInput
                  type="text"
                  name="fuelType"
                  placeholder="Selecione o combustível"
                  label="Combustível"
                  setFieldValue={setFieldValue}
                  options={[
                    { label: 'Elétrico', value: 'Elétrico' },
                    { label: 'Gasolina', value: 'Gasolina' },
                    { label: 'Etanol', value: 'Etanol' },
                    { label: 'Diesel', value: 'Diesel' },
                    { label: 'Flex', value: 'Flex' },
                  ]}
                  isSelect
                  required
                />
              </div>
              <div className={styles.containerInputs}>
                <FormikInput
                  type="date"
                  name="nextOilChangeDate"
                  placeholder="Previsão da próxima troca de óleo"
                  label="Previsão da próxima troca de óleo"
                />
                <FormikInput
                  type="date"
                  name="soldAt"
                  placeholder="Selecione a data da venda"
                  label="Data da venda"
                  required
                />
                <FormikInput
                  type="text"
                  name="obs"
                  placeholder="Digite as observações"
                  label="Observações"
                />
              </div>
              <FormAlert />
            </form>
            <FormActions
              module={"veiculos"}
              formIsValid={isValid}
              onSubmit={handleSubmit}
              hideCancel={true}
              messagem
            />
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(VehicleForm);
