import { pt } from "yup-locale-pt";
import * as yup from "yup";
import { FinancialMoveTo } from "./enums";

yup.setLocale(pt);

export const selectSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string()
});

export const schema = yup.object().shape({
  status: yup.object().typeError("Status é obrigatório"),
  type: yup.object().typeError("Tipo de movimentação é obrigatório"),
  date: yup
    .string()
    .label("Data da movimentação")
    .required(),
  detail: yup.object().typeError("Detalhamento é obrigatório").required(),
  moveTo: yup.object().typeError("Este campo é obrigatório").required(),
  receiver: yup.object().label("Recebedor").typeError("Recebedor da movimentação é obrigatório").required(),
  receiptWay: yup
    .object()
    .nullable()
    .notRequired()
    .label("Forma de recebimento"),
  value: yup
    .string()
    .label("Valor total")
    .required(),
  bruteValue: yup.string().optional(),
  liquidValue: yup.string().optional(),
  obs: yup
    .string()
    .label("Observações")
    .optional()
});
