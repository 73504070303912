import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { AppAPIService } from "../../../../services/AppAPIService";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import clients from "../../../../assets/icons/clientes.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { formatDate } from "../../../../utils/FormatDate";

const UserListing = props => {
  let api = AppAPIService.getInstance();

  UseProtectedPage.accessToFormOrListing(USER_TYPES.USERS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.USERS_WRITE.ENGLISH
  );

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  window.setPageTitle("Listagem de usuários - Admin");

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        method: "DELETE",
        url: `/user/${id}`
      });

      setReload(!reload);

      Swal.fire({
        title: "Sucesso!",
        text: "Usuário deletado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar usuário.", "error");
    }

    setLoading(false);
  };

  const handleConfirmChangeStatus = async obj => {
    try {
      await api.makeHttpRequest({
        method: "PATCH",
        url: `/user/updateStatus/${obj.id}`,
        data: {
          active: !obj.active
        }
      });

      setReload(!reload);

      const userStatus = !obj.active ? "ativado" : "desativado";

      Swal.fire({
        title: "Sucesso!",
        text: `Usuário ${userStatus} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    }
  };

  const headRows = [
    { column: "fullname", label: "Nome" },
    { column: "document", label: "CPF/CNPJ" },
    { column: "phone", label: "Contato" },
    { column: "address", label: "Endereço" },
    { column: "email", label: "Email" },
    { column: "totalSold", label: "Total vendido" },
    { column: "updatedAt", label: "Data de criação" }
  ];

  if (userHasAccess) {
    headRows.unshift({ label: "Ações" });
  }

  const handleResendEmail = async user => {
    try {
      await api.makeHttpRequest({
        method: "POST",
        url: "/auth/resend",
        data: {
          email: user.email
        }
      });
    } catch (e) {
      console.log("Erro ao reenviar o email");
    }
  };

  const formatRow = r => {
    let crudActions = ["delete", "edit", "resend", "toggle"];

    return (
      <StyledTableRow key={r.id} hover tabIndex={-1}>
        {userHasAccess ? (
          <StyledTableCell
            scope="row"
            style={{ padding: "0px 10px", width: "48px" }}
          >
            <CrudActions
              actions={crudActions}
              module="clientes"
              path="usuarios"
              onConfirmChangeStatus={handleConfirmChangeStatus}
              onConfirmDelete={handleConfirmDelete}
              onConfirmResendEmail={handleResendEmail}
              id={r.id}
              obj={r}
              titleMessageDelete={"Excluir Usuário"}
              addMessage={"Você tem certeza que deseja excluir esse usuário? Essa ação não poderá ser desfeita"}
            />
          </StyledTableCell>
        ) : null}
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.fullname}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.document?.length ? r.document : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r?.phone?.map(p => p.number).join("/")}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r?.company?.address?.street && r.company?.address?.number
            ? `${r?.company?.address?.street}, ${r.company?.address?.number}`
            : "----"}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.email}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {r.totalSold}
        </StyledTableCell>
        <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
          {formatDate(r.createdAt)}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        title="Usuários"
        icon={<img src={clients} alt={"Cliente"} />}
        endpoint="/user"
        className="h-100"
        headRows={headRows}
        formatRow={formatRow}
        rows={[]}
        reload={reload}
        placeholderSearch="Busque por nome, e-mail ou CPF/CNPJ"
        width={props.width}
        tooltip="Cadastrar nova pessoa"
        hiddenReload
        addButtonUrl={userHasAccess ? "/admin/usuarios/adicionar" : ""}
        hiddenKanbanContent
        titleButon={"Novo cadastro"}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(UserListing);
