import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getFeeById = async feeId => {
  return await api.makeHttpRequest({
    url: `/fee/${feeId}`,
    method: "GET"
  });
};

export const getFee = async filter => {
  return await api.makeHttpRequest({
    url: "/fee",
    method: "GET",
    params: filter
  });
};

export const createFee = async fee => {
  return await api.makeHttpRequest({
    url: "/fee",
    method: "POST",
    data: fee
  });
};

export const saveManyFees = async fees => {
  return await api.makeHttpRequest({
    url: "/fee/batch",
    method: "POST",
    data: fees
  });
}

export const updateFee = async (id, fee) => {
  return await api.makeHttpRequest({
    url: `/fee/${id}`,
    method: "PUT",
    data: fee
  });
};

export const deleteFee = async feeId => {
  return await api.makeHttpRequest({
    url: `/fee/${feeId}`,
    method: "DELETE"
  });
};
