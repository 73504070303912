import { AppAPIService } from "../../../../services/AppAPIService";

let api = AppAPIService.getInstance();

export const fetchDetails = async (url, setLoading) => {
  try {
    setLoading(true);
    const response = await api.makeHttpRequest({
      url,
      method: "GET"
    });
    return response;
  } catch (err) {
  } finally {
    setLoading(false);
  }
};
