import Loading from "../../../home/components/Loading";
import money from "../../../../assets/icons/money.svg";
import TabsComponent from "../../components/Tabs";
import DiscountForm from "./FinancesDiscounts";
import FeeForm from "./FinancesFee";
import FinancesForm from "./FinancesForm";
import styles from "./styles.module.scss";
import React, { useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const FinancesTabs = () => {
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const history = useHistory()

  return (
    <div className={styles.container}>
      <Loading isLoading={loading} />
      <h3 className={styles.formTitle}>
        <Button className="text-light mr-3" onClick={() => history.push("/admin/lancamentos")}>
          <ArrowBack />
        </Button>
        {" "}
        <img src={money} alt={"Lançamentos"} />
        Lançamentos
      </h3>

      <TabsComponent
        titles={["Lançamentos", "Descontos", "Taxas"]}
        setIndex={setIndex}
        index={index}
      >
        <FinancesForm setLoading={setLoading} />
        <DiscountForm setLoading={setLoading} />
        <FeeForm setLoading={setLoading} />
      </TabsComponent>
    </div>
  );
};

export default FinancesTabs;
