import React from "react";
import CrudActions from "../../../../partials/datatable/CrudActions";
import styles from "./styles.module.scss";
import {
  TableWrapper,
} from "./styles";

const GroupRow = ({ user, group, obj, reload, categoriesEnumeration, setLoading }) => {

  return (
    <li>
      <div className={`${styles.groupTitle} ${styles.listText}`}>
        <div className={styles.groupText}>
          {categoriesEnumeration[`${obj.category}.${obj.subcategory}.${group}`]} {group}
        </div>
        <div className={styles.groupIcon}>
          <CrudActions
            setLoading={setLoading}
            // actions={["editCategory", "deleteCategory"]}
            actions={(user?.idProfile || 0) === 1 ? ["editCategory", "deleteCategory"] : []}
            module="categorias"
            titleMessageDelete={"Excluir item"}
            addMessage={"Você tem certeza que deseja excluir este item? Essa ação não poderá ser desfeita"}
            obj={obj}
            reload={reload}
          />
        </div>
      </div>
    </li>
  )
}

const SubcategoryList = ({ user, subcategory, obj, reload, categoriesEnumeration, setLoading }) => {
  return (
    <>
      <li>
        <div className="d-flex w-100 justify-content-between">
          <div className={`${styles.subcategoryTitle} ${styles.listText}`}>
            {categoriesEnumeration[`${obj.category}.${obj.subcategory}`]} {subcategory.name}
          </div>
          <div className={'align-self-center mr-3'}>
            <CrudActions
              actions={[]}
              module="categorias/subcategoria"
              obj={{ ...obj, subcategoryType: subcategory.type }}
              reload={reload}
            />
          </div>
        </div>

      </li>
      {subcategory.groups.map(group => (
        <GroupRow
          user={user}
          key={`${obj.type}-${obj.category}-${obj.subcategory}-${group}`}
          setLoading={setLoading}
          group={group}
          obj={{ ...obj, subcategoryType: subcategory.type, description: group }}
          reload={reload}
          categoriesEnumeration={categoriesEnumeration}
        />
      ))}
    </>
  )
}

const CategoryList = ({ user, category, obj, reload, categoriesEnumeration, setLoading }) => {
  return (
    <>
      <li>
        <div className={`${styles.categoryTitle} ${styles.listText}`}>
          {categoriesEnumeration[`${obj.category}`]} {category.name}
        </div>
      </li>
      {category.subcategories.map(subcategory => (
        <SubcategoryList
          user={user}
          setLoading={setLoading}
          key={`${obj.type}-${obj.category}-${subcategory.name}`}
          subcategory={subcategory}
          obj={{ ...obj, subcategory: subcategory.name }}
          reload={reload}
          categoriesEnumeration={categoriesEnumeration}
        />
      ))}
    </>
  )
}

const ClassificationList = ({
  user,
  title,
  categories,
  type,
  idCompany,
  reload,
  categoriesEnumeration,
  titleBackgroundColor,
  setLoading
}) => {
  return (
    <div className={styles.listContainer}>
      <TableWrapper>
        <h2
          className={styles.classificationTitle}
          style={{ backgroundColor: titleBackgroundColor }}
        >
          {title}
        </h2>
        <ul>
          {categoriesEnumeration && categories && categories.map(category => (
            <CategoryList
              user={user}
              setLoading={setLoading}
              key={`${type}-${category.name}`}
              category={category}
              obj={{ type, category: category.name, idCompany }}
              reload={reload}
              categoriesEnumeration={categoriesEnumeration}
            />
          ))}
        </ul>
      </TableWrapper>
    </div>
  )
}

export default ClassificationList
