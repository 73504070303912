import { CancelToken } from 'axios';
import Swal from 'sweetalert2';
import store from '../store/store';
import * as authDuck from "../store/ducks/auth.duck";
import * as companyDuck from "../store/ducks/company.duck";

const TIMEOUT = 300000; // 5 minutos

export class HttpService {
  constructor(axiosInstance) {
    if (!axiosInstance) {
      throw new Error('Setup do axios não fornecido');
    }

    this.axios = axiosInstance;
  }

  async makeHttpRequest(config) {
    try {
      const source = await CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, TIMEOUT + 10000);

      config.timeout = config.timeout || TIMEOUT;
      config.cancelToken = source.token;

      const httpResponse = await this.axios.request(config);
      return config.responseType === 'blob'
        ? httpResponse
        : httpResponse.data;
    } catch (e) {

      if (e.response.status === 401 && e.config.url.includes("refresh")) {
        await Swal.fire({
          title: "Oops...",
          text: "Sua sessão expirou! Por favor efetue o login novamente.",
          icon: "warning"
        })
          .then(() => {
            store.dispatch(authDuck.actions.logout())
            store.dispatch(companyDuck.actions.forget())
            window.location.reload()
          });
      }

      if (!e.response) {
        throw e;
      }

      return this.handleRequestError(e, config);
    }
  }

  handleRequestError(e, config) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      const reponsePayload = typeof e.response.data === 'string'
        ? e.response.data
        : JSON.stringify(e.response.data);

      console.error(`
        Error: ${e.message},
        StatusCode: ${e.response.status},
        Request Config: ${JSON.stringify(config)},
        Response Payload: ${reponsePayload}
      `);
    }

    throw e;
  }
}
