import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getClientById = async (clientId) => {
  return await api.makeHttpRequest({
    url: `/client/${clientId}`,
    method: "GET"
  });
}

export const verifyClientDocument = async (document) => {
  return await api.makeHttpRequest({
    url: `/client/document/avaiability`,
    method: "POST",
    data: {
      document
    }
  });
}

export const getClient = async (filter) => {
  return await api.makeHttpRequest({
    url: "/client",
    method: "GET",
    params: filter
  });
}

export const createClient = async (client) => {
  return await api.makeHttpRequest({
    url: "/client",
    method: "POST",
    data: client
  });
}

export const updateClient = async (id, client) => {
  return await api.makeHttpRequest({
    url: `/client/${id}`,
    method: "PUT",
    data: client
  });
}

export const deleteClient = async (clientId) => {
  return await api.makeHttpRequest({
    url: `/client/${clientId}`,
    method: "DELETE"
  });
}

export const toggleClientStatus = async (clientId, status) => {
  return await api.makeHttpRequest({
    url: `/client/${clientId}/state`,
    method: "PATCH",
    data: { status }
  });
}
