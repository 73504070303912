import styled from "styled-components";
import { Form } from "react-bootstrap";
import Select2 from "react-select";
import CreatableSelect2 from "react-select/creatable";
import globalColors from "../../../../../_metronic/_assets/sass/custom/globalColors.scss";

export const InputContainer = styled.div`
  position: relative;
  width: 100%;

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: ${globalColors.textColor1};
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    z-index: 2;

    .required {
      color: #d93438;
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 48px;
    right: 15px;

    color: #00aeac;
  }

  &.fade {
    opacity: 0.4;
  }

  .input-group {
    & > :nth-child(2) {
      border-left: none;

      :focus {
        z-index: 1;
      }
    }
  }

  .input-group-text {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    color: ${globalColors.textColor1};
    background: #f4f4f4;
    padding-top: 12px;

    border: 0.5px solid ${globalColors.textColor1};
    border-right: 1px solid #e2e5ec;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    cursor: default;
  }

  .end-adornment {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 15px;
    z-index: 2;

    cursor: pointer;
  }
`;

export const StyledInput = styled(Form.Control)`
  width: 100%;
  height: ${({ height }) => (!height ? "48px" : "100px")};
  color: ${globalColors.textColor1};
  font-family: "Cera Pro Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: ${({ as }) => (as === "textarea" ? "150%" : "24px")};
  padding: 12px 30px 12px 16px;
  border: 0.5px solid ${globalColors.textColor1};
  border-radius: 4px;
  outline: none;
  background: #fff;

  resize: ${({ resize }) => (resize ? "none" : "")};

  transition: 0.4s;

  &:focus,
  &:focus-visible {
    border-color: #00aeac;
    color: ${globalColors.textColor1};
    text-shadow: none;
  }

  &.is-invalid {
    border-color: #ff7563;
  }

  &:disabled {
    background: #fff;
    border: 1px solid ${globalColors.textColor3};
    opacity: 0.8;

    cursor: not-allowed;
  }

  &::-webkit-input-placeholder {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    color: ${globalColors.textColor3};
    opacity: 0.8;
  }

  &:-ms-input-placeholder {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    color: ${globalColors.textColor3};
    opacity: 0.8;
  }

  &::placeholder {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    color: ${globalColors.textColor3};
    opacity: 0.8;
  }

  ${({ as }) =>
    as === "select" &&
    `
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;

    &.placeholder {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      color: ${globalColors.textColor3};
      opacity: 0.8;
    }

    option {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      color: ${globalColors.textColor1};
    }
  `}
`;

export const ContainerSelect2 = styled.div`
  position: relative;
  width: 100%;

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: ${globalColors.textColor1};
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;

    .required {
      color: red;
    }
  }
`;

export const StyledSelect2 = styled(Select2)`
  height: 48px;
  border: 0.5px solid ${globalColors.textColor2};

  .select__control {
    height: 48px;

    transition: 0.4s;

    &--is-disabled {
      background: #fff;
      border: 1px solid ${globalColors.textColor3};
      opacity: 0.8;

      cursor: not-allowed;
    }

    &--is-focused {
      border: 1px solid ${globalColors.textColor2};
      box-shadow: none;

      &:focus,
      &:hover {
        border: 1px solid ${globalColors.textColor2};
      }
    }

    .select__value-container {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 16px;
      color: ${globalColors.textColor1};

      .select__multi-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f2f2;
        border-radius: 4px;

        .select__multi-value__label {
          color: ${globalColors.textColor1};
          font-family: "Cera Pro Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;

          padding: 6px 3px 6px 6px;
        }

        .select__multi-value__remove {
          background: transparent;
          color: ${globalColors.primaryColor3};
          padding: 6px 6px 5px 3px;

          &:hover {
            background: transparent;
            color: ${globalColors.primaryColor3};
          }

          .border {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            border: 1px solid ${globalColors.primaryColor3} !important;
            border-radius: 2px;
          }
        }
      }
    }

    .select__indicators {
      .select__indicator-separator {
        width: 0;
      }

      .select__dropdown-indicator {
        margin-right: 8px;
        color: ${globalColors.textColor1};
      }

      .select__clear-indicator {
        color: #fff;

        .border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: ${globalColors.primaryColor3};
          border-radius: 3px;
          border: none !important;
        }
      }
    }
  }

  .select__menu {
    margin-top: 0;
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
  }
`;

export const NoStyledCreatableSelect2 = styled(CreatableSelect2)`
  height: 48px;
  width: 100%;


  .select__control {
    height: 48px;
    border: none;
    border-bottom: 1px solid ${globalColors.textColor2};
    border-radius: 0;
    margin: 0 15px;
    padding: 0;

    transition: 0.4s;

    &--is-disabled {
      background: #fff;
      border-bottom: 1px solid ${globalColors.textColor3};
      opacity: 0.8;

      cursor: not-allowed;
    }

    &--is-focused {
      border-bottom: 1px solid ${globalColors.textColor2};
      box-shadow: none;

      &:focus,
      &:hover {
        border-bottom: 1px solid ${globalColors.textColor2};
      }
    }

    .select__value-container {
      font-family: "Cera Pro Regular";
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      padding-left: 0px;

      .select__placeholder {
        font-family: "Cera Pro Regular";
        font-weight: 500;
        color: ${globalColors.textColor3};
        opacity: 0.8;
      }

      .select__multi-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f2f2;
        border-radius: 0;
        color: #000;

        .select__multi-value__label {
          color: ${globalColors.textColor1};
          font-family: "Cera Pro Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;

          padding: 6px 3px 6px 6px;
        }

        .select__multi-value__remove {
          background: transparent;
          color: ${globalColors.primaryColor3};
          padding: 6px 6px 5px 3px;

          &:hover {
            background: transparent;
            color: ${globalColors.primaryColor3};
          }
        }
      }
    }

    .select__indicators {
      .select__indicator-separator {
        width: 0;
      }

      .select__dropdown-indicator {
        margin-right: 8px;
        color: #4d4c4d;
      }

      .select__clear-indicator {
        color: #fff;

        .border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: ${globalColors.primaryColor3};
          border-radius: 3px;
          border: none !important;
        }
      }
    }
  }

  .select__menu {
    margin: 0 15px;
    width: 90%;
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
    color: #000;
  }
`;

export const StyledCreatableSelect2 = styled(CreatableSelect2)`
  height: 48px;

  .select__control {
    height: 48px;
    border: 1px solid ${globalColors.textColor2};

    transition: 0.4s;

    &--is-disabled {
      background: #fff;
      border: 1px solid ${globalColors.textColor3};
      opacity: 0.8;

      cursor: not-allowed;
    }

    &--is-focused {
      border: 1px solid ${globalColors.textColor2};
      box-shadow: none;

      &:focus,
      &:hover {
        border: 1px solid ${globalColors.textColor2};
      }
    }

    .select__value-container {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 16px;

      .select__placeholder {
        font-family: "Cera Pro Regular";
        font-weight: 400;
        color: ${globalColors.textColor3};
        opacity: 0.8;
      }

      .select__multi-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f2f2;
        border-radius: 4px;

        .select__multi-value__label {
          color: ${globalColors.textColor1};
          font-family: "Cera Pro Regular";
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;

          padding: 6px 3px 6px 6px;
        }

        .select__multi-value__remove {
          background: transparent;
          color: ${globalColors.primaryColor3};
          padding: 6px 6px 5px 3px;

          &:hover {
            background: transparent;
            color: ${globalColors.primaryColor3};
          }

          .border {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            border: 1px solid ${globalColors.primaryColor3} !important;
            border-radius: 2px;
          }
        }
      }
    }

    .select__indicators {
      .select__indicator-separator {
        width: 0;
      }

      .select__dropdown-indicator {
        margin-right: 8px;
        color: #4d4c4d;
      }

      .select__clear-indicator {
        color: #fff;

        .border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background: ${globalColors.primaryColor3};
          border-radius: 3px;
          border: none !important;
        }
      }
    }
  }

  .select__menu {
    margin-top: 0;
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
  }
`;

export const DateContainer = styled.div`
  position: relative;
  width: 100%;

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: ${globalColors.textColor1};
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    z-index: 5;

    .required {
      color: #d93438;
    }
  }

  & .input-container *:not(input) {
    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;

    border: none;

    .datetime-popper {
      z-index: 99;
      padding-bottom: 8px;

      & > div .react-datepicker {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
          0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        background: ${globalColors.primaryColor3} !important;
        color: #fff;

        &:hover {
          background: ${globalColors.primaryColor3 + "cc"};
        }
      }
    }
  }

  .date-input {
    transition: all 0.4s;
  }

  .date-input:focus,
  .date-input:focus-visible {
    border-color: #00aeac;
  }

  .date-input:disabled {
    background: #fff;
    border: 1px solid ${globalColors.textColor3};
    opacity: 0.8;

    cursor: not-allowed;
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    height: 48px;
    width: 100%;

    font-family: "Cera Pro Regular";
    font-weight: 400;
    font-size: 14px;
    color: ${globalColors.textColor1};
    cursor: pointer;

    border: 0.5px solid ${globalColors.textColor2};
    border-radius: 4px;
    outline: none;
    text-align: left;
    background: transparent;

    padding-left: 16px;

    &::-webkit-input-placeholder {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      color: ${globalColors.textColor3};
      opacity: 0.8;
    }

    &:-ms-input-placeholder {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      color: ${globalColors.textColor3};
      opacity: 0.8;
    }

    &::placeholder {
      font-family: "Cera Pro Regular";
      font-weight: 400;
      color: ${globalColors.textColor3};
      opacity: 0.8;
    }
  }

  .date-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 16px;
    z-index: 1;

    svg {
      stroke: #00aeac;
    }
  }

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      z-index: 2;

      input {
        width: 100%;
        text-align: left;
        color: #4d4c4d;
      }
    }
  }
`;
