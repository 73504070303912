import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "querystring";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import Loading from "../home/components/Loading";
import { bindActionCreators } from "redux";
import ForgotPassword from "./ForgotPassword";
import ActivateAccount from "./ActivateAccount";
import iconChecked from "../../assets/icons/icon-checked.svg";
import iconNotChecked from "../../assets/icons/icon-not-checked.svg";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { decrypt, encrypt } from "../../services/Crypto";

import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";
import { makeStyles, Checkbox } from "@material-ui/core";
import Swal from "sweetalert2";
import { USER_TYPES } from "../../enum/UserTypes";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& label": {
      color: globalColors.textColor1
    },
    "& label.Mui-focused": {
      color: globalColors.textColor1
    },
    "& input": {
      color: globalColors.textColor1,
      padding: "12px 16px",
      fontSize: "14px"
    },
    "& .MuiOutlinedInput-root": {
      height: "100vh",
      maxHeight: "48px",
      "& fieldset": {
        borderColor: globalColors.textColor2
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${globalColors.primaryColor2}`
      }
    }
  },
  multilineColor: {
    color: globalColors.textColor1,
    fontSize: "14px"
  },
  typography: {
    fontFamily: "Cera Pro Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px"
  },
  svg: {
    color: globalColors.primaryColor2
  }
});

function Login(props) {
  const { intl, history } = props;
  const classes = useStyles();

  const stored = window.localStorage.getItem("remember:ohana:rememberMe");
  const data = JSON.parse(stored);

  const [forgotModal, setForgotModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(undefined);

  const [error, setError] = useState(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialValues] = useState({
    email: data?.email ? data.email : "",
    password: data?.key ? decrypt(data.key) : "",
    rememberMe: data?.rememberMe
  });

  useEffect(() => {
    const params = queryString.parse(window.location.search.replace("?", ""));
    console.log(params)
    if (params?.token) {
      setToken(params.token)
      setForgotModal(true)
    }
  }, [])

  const authData = useSelector(state => state?.auth);

  useEffect(() => {
    if (!authData.user) return;
    if (authData.user?.roles?.some(item => item === USER_TYPES.USERS_READ.ENGLISH)) {
      history.push("/admin/usuarios");
      return;
    }
    if (authData.user?.roles?.some(item => item === USER_TYPES.PRODUCTS_READ.ENGLISH)) {
      history.push("/admin/produtos");
      return;
    }
  }, [history, authData]);

  const enableLoading = () => {
    setIsLoading(true);
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLoginExpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  window.setPageTitle("Login");

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>Faça seu login</h3>
            </div>
            <Formik
              initialValues={initialValues}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                enableLoading();

                await login(values.email, values.password)
                  .then(data => {
                    const { user, ...authData } = data;
                    authData.isLogin = true;
                    authData.rememberMe = values.rememberMe ?? false;
                    disableLoading();
                    props.login(authData);
                  })
                  .catch(e => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      e.response && e.response.data && e.response.data
                        ? e.response.data.message
                        : intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN"
                        })
                    );

                    if (e?.response?.status === 403) {
                      setError(
                        "Seu perfil foi inativado. Contate um Administrador do Sistema para mais informações."
                      );
                      return;
                    }
                    setError("Email ou senha inválidos");
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {(status || isLoginExpired()) && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">
                        {status ||
                          "Sua sessão expirou, faça login para continuar"}
                      </div>
                    </div>
                  )}

                  {error && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{error}</div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "28px"
                    }}
                  >
                    <div className="form-group">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-error-helper-text"
                        label="E-mail"
                        className={`${classes.multilineColor} ${classes.typography} ${classes.root}`}
                        placeholder="Digite seu e-mail"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        fullWidth
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        type={showPassword ? "text" : "password"}
                        InputLabelProps={{ shrink: true }}
                        label="Senha"
                        name="password"
                        className={`${classes.multilineColor} ${classes.typography} ${classes.root}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Digite sua senha"
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ padding: 0 }}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff className={`${classes.svg}`} />
                                ) : (
                                  <Visibility className={`${classes.svg}`} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="kt-login__content">
                    <div className="kt-login__separator">
                      <Checkbox
                        checked={values.rememberMe}
                        name="rememberMe"
                        className="checkbox-rp"
                        onChange={handleChange}
                        icon={
                          <img src={iconNotChecked} alt="Não selecionado" />
                        }
                        checkedIcon={
                          <img src={iconChecked} alt="Selecionado" />
                        }
                        sx={{
                          color: globalColors.primaryColor2,
                          "&.MuiCheckbox-root": {
                            marginLeft: "-9px"
                          },
                          "&.Mui-checked": {
                            color: globalColors.primaryColor2
                          }
                        }}
                      />

                      <span className="kt-remember-me">Lembrar-me</span>
                    </div>
                    <li
                      type="button"
                      onClick={() => setForgotModal(true)}
                      className="justify-content-start forgot-password kt-remember-me kt-login__underline"
                    >
                      Esqueci minha senha
                    </li>

                    <ForgotPassword
                      open={forgotModal}
                      setOpen={setForgotModal}
                      token={token}
                    />
                  </div>

                  <div className="kt-login__actions mb-0 pb-0 ">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-elevate btn-width kt-login__btn-primary justify-content-end ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Entrar
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
      ;
      {/* <ActivateAccount
        email={email}
        open={activateModal}
        setOpen={setActivateModal}
      /> */}
    </>
  );
}

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      login: auth.actions.login
    },
    dispatch
  );

export default injectIntl(connect(null, mapActionsToProps)(Login));
