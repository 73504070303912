import store from "../../app/store/store";
import { USER_TYPES } from "../../app/enum/UserTypes";

export default () => {
  const { auth } = store.getState();

  const roles = auth?.roles || [];

  const menu = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: []
    }
  };

  var idMenuItem = [];

  //  if (idMenuItem.includes(1)) {
  //   menu.aside.items.push({
  //     title: "Dashboard",
  //     root: true,
  //     icon: "dashboard",
  //     bullet: "dot",
  //     page: "admin/dashboard"
  //   });
  // }

  if (roles?.some(item => item === USER_TYPES.USERS_READ.ENGLISH)) {
    idMenuItem.push(1);
    menu.aside.items.push({
      title: "Usuários",
      root: true,
      icon: "clients",
      bullet: "dot",
      page: "admin/usuarios"
    });
  }

  if (roles?.some(item => item === USER_TYPES.WORKERS_READ.ENGLISH)) {
    idMenuItem.push(3);
    menu.aside.items.push({
      title: "Profissionais",
      root: true,
      icon: "briefcase",
      bullet: "dot",
      page: "admin/profissionais"
    });
  }

  if (roles?.some(item => item === USER_TYPES.SUPPLIERS_READ.ENGLISH)) {
    idMenuItem.push(4);
    menu.aside.items.push({
      title: "Fornecedores",
      root: true,
      icon: "truck",
      bullet: "dot",
      page: "admin/fornecedores"
    });
  }

  if (roles?.some(item => item === USER_TYPES.PRODUCTS_READ.ENGLISH)) {
    idMenuItem.push(2);
    menu.aside.items.push({
      title: "Produtos",
      root: true,
      icon: "shoppingCart",
      bullet: "dot",
      page: "admin/produtos"
    });
  }

  if (roles?.some(item => item === USER_TYPES.CLIENTS_READ.ENGLISH)) {
    idMenuItem.push(5);
    menu.aside.items.push({
      title: "Clientes",
      root: true,
      icon: "clienteIcon",
      bullet: "dot",
      page: "admin/clientes"
    });
  }

  if (roles?.some(item => item === USER_TYPES.VEHICLES_READ.ENGLISH)) {
    idMenuItem.push(6);
    menu.aside.items.push({
      title: "Veículos",
      root: true,
      icon: "vehicle",
      bullet: "dot",
      page: "admin/veiculos"
    });
  }

  if (roles?.some(item => item === USER_TYPES.RECEIPTWAY_READ.ENGLISH)) {
    idMenuItem.push(7);
    menu.aside.items.push({
      title: "Formas de recebimento",
      root: true,
      icon: "streamline_money",
      bullet: "dot",
      page: "admin/formas-recebimento"
    });
  }

  if (roles?.some(item => item === USER_TYPES.FEE_READ.ENGLISH)) {
    idMenuItem.push(8);
    menu.aside.items.push({
      title: "Taxas",
      root: true,
      icon: "fee",
      bullet: "dot",
      page: "admin/taxas"
    });
  }

  if (roles?.some(item => item === USER_TYPES.DISCOUNT_READ.ENGLISH)) {
    idMenuItem.push(9);
    menu.aside.items.push({
      title: "Descontos",
      root: true,
      icon: "discount",
      bullet: "dot",
      page: "admin/descontos"
    });
  }

  idMenuItem.push(12);
  menu.aside.items.push({
    title: "Plano de contas",
    root: true,
    icon: "category",
    bullet: "dot",
    page: "admin/categorias"
  });

  if (roles?.some(item => item === USER_TYPES.FINANCES_READ.ENGLISH)) {
    idMenuItem.push(10);
    menu.aside.items.push({
      title: "Lançamentos",
      root: true,
      icon: "money",
      bullet: "dot",
      page: "admin/lancamentos"
    });
  }

  if (roles?.some(item => item === USER_TYPES.FINANCIALFLOW_READ.ENGLISH)) {
    idMenuItem.push(11);
    menu.aside.items.push({
      title: "Relatório de fluxo de caixa",
      root: true,
      icon: "streamline",
      bullet: "dot",
      page: "admin/fluxo-caixa"
    });
  }

  if (roles?.some(item => item === USER_TYPES.FINANCIALFLOWANALYSIS_READ.ENGLISH)) {
    idMenuItem.push(13);
    menu.aside.items.push({
      title: "Análise do Fluxo de Caixa",
      root: true,
      icon: "streamline_money",
      bullet: "dot",
      page: "admin/analise-fluxo-caixa"
    });
  }

  return menu;
};
