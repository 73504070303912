import { withRouter } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import classes from "./styles.module.scss";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import InputForm from "../../../../components/InputForm";

const AdminTitleComponent = ({ history, companies, ...props }) => {
  const Mobile = props.mobile;

  const handleClick = () => {
    if (
      !props?.user?.idCompany &&
      !props?.idCompany &&
      !history.location.pathname.includes("usuarios")
    ) {
      return
    }

    history.push(props.addButtonUrl)
  }

  return (
    <header className={classes.headerWrapper}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <div className={classes.titleIcon}>
            {props.icon ? (
              props.icon
            ) : (
              <FiSettings size="22px" color="#4d4d4d" />
            )}
          </div>
          <h1 className={classes.title}>{props.title}</h1>
        </div>

        <div className='d-flex align-items-center ' style={{ gap: 10 }}>
          <div style={{ width: '200px' }}>
            {history.location.pathname.includes("lancamentos") && (
              <InputForm
                isSelect
                placeholder="Selecione o filtro"
                value={props?.filterType}
                role='ok'
                name="filters"
                options={props?.filterOptions}
                onChange={props?.onChangeFilter}
                isClearable={false}
              />
            )}
          </div>

          {props.addButtonUrl &&
            <p
              onClick={props?.disabled ? () => {} : handleClick}
              className={
                (
                  (!props?.user?.idCompany && !props?.idCompany && !history.location.pathname.includes("usuarios"))) ||
                  props?.disabled ?
                  classes.disabledAddButton
                  :
                  classes.addButtonCustom
              }
            >
              {props.titleButon}
            </p>
          }
        </div>

      </div>
      {props.disableHeader ? null : props.width >= 768 ||
        !props.mobile ? null : (
        <div>
          <Mobile
            actions={props.actions || null}
            width={props.width}
            buttons={props.generateMobileButtons}
            refreshAction={() => props.refreshAction}
          />
        </div>
      )}
      <div className={classes.line} />
    </header>
  );
};

const mapStateToProps = ({ company, auth }) => ({
  idCompany: company.idCompany,
  companies: company.companies,
  user: auth.user
});

export default withRouter(
  connect(mapStateToProps)(AdminTitleComponent)
)
