import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import * as auth from "../../../app/store/ducks/auth.duck";
import Brand from "../brand/Brand";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import { ReactComponent as IconLogout } from "../../../app/assets/icons/icon-logout.svg";
import globalColors from "../../../_metronic/_assets/sass/custom/globalColors.scss";
import { bindActionCreators } from "redux";
class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  render() {
    return (
      <>
        <button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt-aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop width-menu-lateral`}
        >
          <Brand />
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            style={{
              backgroundColor: globalColors.backgroundColor3
            }}
          >
            <Menu />
            <span style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              width: "100%",
              padding: "10px",
              color: globalColors.textColor2
            }}>v{process.env.REACT_APP_PACKAGE_VERSION}</span>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  },
  user: store.auth.user
});

const mapActionsToProps = dispatch =>
  bindActionCreators({ logout: auth.actions.logout }, dispatch);

export default connect(mapStateToProps, mapActionsToProps)(AsideLeft);
