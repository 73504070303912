import React from 'react';
import { Link } from "react-router-dom";
import { Button, Icon } from '@material-ui/core';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'chart.js';
import { CustomTooltip } from '../pages/Vehicles/VehiclesListing';

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);

const HeaderButton = (props) => {
  const { label, onClick, path, icone, disabled, style, noIcon, tooltip } = props;

  return (
    <div>
      <CustomTooltip title={tooltip} arrow placement="right">
        {path ?
          <Link to={path}>
            <Button variant="contained" className="kt-button-adicionar" disabled={disabled} style={{ ...style }} >
              {icone ? icone : noIcon ? null : <Icon className="mr-2 kt-icons">add</Icon>} {label ? label : "Adicionar"}
            </Button>
          </Link>
          :
          <div style={{ marginRight: '5px' }} >
            <Button variant="contained" className="kt-button-adicionar" disabled={disabled} onClick={onClick} style={{ ...style }}>
              {icone ? icone : noIcon ? null : <Icon className="mr-2 kt-icons">add</Icon>} {label ? label : "Adicionar"}
            </Button>
          </div>
        }
      </CustomTooltip>
    </div>

  );
}

export default HeaderButton;