import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "./Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import moment from "moment";
import { USER_TYPES } from "../../../../enum/UserTypes";
import money from "../../../../assets/icons/money.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { styled } from "@material-ui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { deleteFinancialMovement } from "../../../../services/modules/FinancialMovementApiService";
import {
  FinancialMoveStatus,
  FinancialMoveTo,
  FinancialMoveType
} from "./enums";
import { FormatMoney } from "../../../../utils/FormatMoney";
import { capitalize } from "lodash";

const propsDatatable = {
  title: "Lançamentos",
  className: "h-100",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    fontWeight: 700,
    padding: "10px 30px",
    color: "#333333",
    border: "1px solid #e3e3e3",
    lineHeight: "9.5px",
    boxShadow: "rgba(0, 0, 0, .5)",
    fontSize: 10
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFF",
    "&:before": {
      border: "1px solid #e3e3e3"
    }
  }
}));

const VehicleListing = props => {
  window.setPageTitle("Listagem de movimentações financeiras - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.FINANCES_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.FINANCES_WRITE.ENGLISH
  );

  const filterOptions = [
    { label: "Filtro padrão", value: "default" },
    { label: "Filtro avançado", value: "advanced" }
  ]

  const [filterType, setFilterType] = useState(filterOptions[0]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    ...(userHasAccess ? [{ label: "Ações" }] : []),
    { column: "status", label: "Status" },
    { column: "date", label: "Data" },
    { column: "type", label: "Classificação" },
    { column: "details", label: "Detalhamento" },
    { column: "moveTo", label: "Tipo de Recebedor" },
    { column: "receiver", label: "Recebedor" },
    { column: "receiptWay", label: "Forma de recebimento" },
    { column: "value", label: "Valor Líquido" }
  ];

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteFinancialMovement(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Movimentação deletada com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar movimentação.", "error");
    } finally {
      setLoading(false);
    }
  };

  const getEnunValue = (enumeratedObject, keyName) => {
    const [_, value] = Object.entries(enumeratedObject).find(
      ([key, value]) => keyName === key
    );
    return value;
  };

  const formatRow = r => (
    <StyledTableRow
      key={r.id}
      bg={r.status === "PENDING" ? "rgba(245, 238, 53, 0.2)" : "transparent"}
      head={"#026FBB"}
      hover
      tabIndex={-1}
    >
      {userHasAccess ? (
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            actions={["delete", "edit"]}
            module="finances"
            path="lancamentos"
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Movimentação"}
            addMessage={
              "Você tem certeza que deseja excluir essa movimentação? Essa ação não poderá ser desfeita"
            }
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {getEnunValue(FinancialMoveStatus, r.status)}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {moment.utc(r.date).format("DD/MM/YYYY")}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {getEnunValue(FinancialMoveType, r.type)}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {capitalize(r?.detail)}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.idClient
          ? FinancialMoveTo.CLIENT
          : r?.idSupplier
            ? FinancialMoveTo.SUPPLIER
            : r?.idFavoredWorker
              ? "Funcionário"
              : "Outros"}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.idClient
          ? r.client.fullname
          : r?.idSupplier
            ? r?.supplier.name
            : r?.idFavoredWorker
              ? r?.favoredWorker.fullname
              : "N/A"}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {
          r.receiptWay
            ? r.receiptWay.name
              ? r.receiptWay.name
              : "N/A"
            : "N/A"
        }
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {FormatMoney(Number(r?.liquidValue).toFixed(2))}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={money} alt={"Icone Financeiro"} />}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        reload={reload}
        addButtonUrl={userHasAccess ? "/admin/lancamentos/adicionar" : ""}
        filterType={filterType}
        filterOptions={filterOptions}
        onChangeFilter={setFilterType}
        endpoint={filterType?.value === 'advanced' ? "/finances/advanced" : "/finances"}
        placeholderSearch="Busque por status ou tipo ou forma de recebimento"
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(VehicleListing);
