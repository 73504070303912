import { pt } from "yup-locale-pt";
import * as yup from 'yup';

yup.setLocale(pt)

export const schema = yup.object().shape({
  description: yup.string()
    .label("Descrição")
    .required(),
  value: yup.string()
    .label("Valor")
    .test(value => Number(value?.replace("%", '').replace(",", ".")))
    .required(),
});


export const addCategorySchema = yup.object().shape({
  classification: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Classificação para lançamentos")
        .required("A classificação para lançamentos é obrigatória"),
    })
    .typeError("A classificação para lançamentos é obrigatória"),
  category: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Categoria")
        .required("A categoria é obrigatória"),
    }),
  subcategory: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Subcategoria")
        .required("A subcategoria é obrigatória"),
    }),
  detail: yup
    .string()
    .label("Detalhamento")
    .required("O detalhamento é obrigatório"),
});


export const editSubcategorySchema = yup.object().shape({
  classification: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Classificação para lançamentos")
        .required("A classificação para lançamentos é obrigatória"),
    })
    .typeError("A classificação para lançamentos é obrigatória"),
  category: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Categoria")
        .required("A categoria é obrigatória"),
    }),
  subcategory: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Subcategoria")
        .required("A subcategoria é obrigatória"),
    }),
  subcategoryType: yup
    .object()
    .shape({
      label: yup
        .string()
        .label("Classificação")
        .required("A classificação é obrigatória"),
    })
    .typeError("A classificação é obrigatória")
});
