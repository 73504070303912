
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions/';
import DialogContent from '@material-ui/core/DialogContent/';
import DialogContentText from '@material-ui/core/DialogContentText/';
import DialogTitle from '@material-ui/core/DialogTitle/';
import { Button, StyledDialog } from './components/Dialog/style';

export default function ConfirmChangeStatusDialog({ open, closeDialog, confirm, title, hideTitle, message }) {
  return (
    <div>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hideTitle && <DialogTitle id="alert-dialog-title">{title && title !== '' ? title : "Alterar o status?"}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && message !== '' ? message : 'Ao clicar em confirmar você esterá alterando o status do registro.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancelar
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
