import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getDiscountsById = async (discountId) => {
  return await api.makeHttpRequest({
    url: `/discount/${discountId}`,
    method: "GET"
  });
}

export const getDiscount = async (filter) => {
  return await api.makeHttpRequest({
    url: "/discount",
    method: "GET",
    params: filter
  });
}

export const createDiscount = async (discount) => {
  return await api.makeHttpRequest({
    url: "/discount",
    method: "POST",
    data: discount
  });
}

export const updateDiscount = async (id, discount) => {
  return await api.makeHttpRequest({
    url: `/discount/${id}`,
    method: "PUT",
    data: discount
  });
}

export const deleteDiscount = async (discountId) => {
  return await api.makeHttpRequest({
    url: `/discount/${discountId}`,
    method: "DELETE"
  });
}
