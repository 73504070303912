import React from "react";
import { Preloader, Bars } from "react-preloader-icon";
import { AiFillPlusCircle, AiOutlineQuestionCircle } from "react-icons/ai";

import { Container } from "./styles";

const Button = ({
  text,
  opacity,
  disabled,
  cursor,
  type,
  loading,
  marginBottom,
  onClick,
  width,
  height,
  padding,
  background,
  color,
  fontSize,
  borderRadius,
  children
}) => {
  return (
    <Container
      type={type}
      isLoading={loading}
      marginBottom={marginBottom}
      style={{ opacity, cursor }}
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
      padding={padding}
      background={background}
      color={color}
      fontSize={fontSize}
      borderRadius={borderRadius}
    >
      {loading ? (
        <Preloader use={Bars} size={30} strokeWidth={8} duration={1000} />
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </Container>
  );
};

export default Button;
