import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getVehicleById = async (vehicleId) => {
  return await api.makeHttpRequest({
    url: `/vehicle/${vehicleId}`,
    method: "GET"
  });
}

export const getVehicles = async (filter) => {
  return await api.makeHttpRequest({
    url: "/vehicle",
    method: "GET",
    params: filter
  });
}

export const createVehicle = async (vehicle) => {
  return await api.makeHttpRequest({
    url: "/vehicle",
    method: "POST",
    data: vehicle
  });
}

export const updateVehicle = async (id, vehicle) => {
  return await api.makeHttpRequest({
    url: `/vehicle/${id}`,
    method: "PUT",
    data: vehicle
  });
}

export const deleteVehicle = async (vehicleId) => {
  return await api.makeHttpRequest({
    url: `/vehicle/${vehicleId}`,
    method: "DELETE"
  });
}

export const toggleVehicleStatus = async (vehicleId, status) => {
  return await api.makeHttpRequest({
    url: `/vehicle/${vehicleId}/state`,
    method: "PATCH",
    data: { status }
  });
}

export const getVehicleModels = async () => {
  return await api.makeHttpRequest({
    url: `/vehicle/models`,
    method: "GET"
  });
}

export const getVehicleBrands = async () => {
  return await api.makeHttpRequest({
    url: `/vehicle/brands`,
    method: "GET"
  });
}
