
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions/';
import DialogContent from '@material-ui/core/DialogContent/';
import DialogContentText from '@material-ui/core/DialogContentText/';
import DialogTitle from '@material-ui/core/DialogTitle/';
import { Button, StyledDialog } from './components/Dialog/style';

import { ReactComponent as IconCircleLeft } from "../../assets/icons/akar-icons_circle_dark.svg";
import { ReactComponent as IconCircleRight } from "../../assets/icons/icon_circle-chevron-right-fill.svg";




export default function ConfirmDeleteDialog({ open, closeDialog, confirm, title, hideTitle, message }) {
  return (
    <div>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hideTitle && <DialogTitle id="alert-dialog-title">{title ? title : ""}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message ? message : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="">
          <IconCircleLeft />
            Cancelar
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            Confirmar <IconCircleRight />
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
