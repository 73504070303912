import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import truck from "../../../../assets/icons/truck.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { deleteSupplier, toggleSupplierStatus } from "../../../../services/modules/SupplierApiService";


const propsDatatable = {
  title: "Fornecedores",
  endpoint: "/supplier",
  className: "h-100",
  placeholderSearch: "Busque por Empresa ou Responsável",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
}

const SupplierListing = props => {
  window.setPageTitle("Listagem de fornecedores - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.SUPPLIERS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(USER_TYPES.SUPPLIERS_WRITE.ENGLISH);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    ...(userHasAccess ? [{ label: "Ações" }] : []),
    { column: "name", label: "Empresa" },
    { column: "responsable", label: "Responsável" },
    { column: "phone", label: "Telefone" },
    { column: "email", label: "E-mail" },
    { column: "totalBought", label: "Total Comprado" },
    { column: "obs", label: "Observações" },
  ];

  const handleConfirmChangeStatus = async obj => {
    try {
      setLoading(true)
      await toggleSupplierStatus(obj.id, !obj.active)
      setReload(!reload)
      Swal.fire({
        title: "Sucesso!",
        text: `Fornecedor ${!obj.active ? "ativado" : "desativado"} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    }
    finally {
      setLoading(false)
    }
  };

  const handleConfirmDelete = async id => {
    try {
      setLoading(true)
      await deleteSupplier(id)
      setReload(!reload)
      Swal.fire({
        title: "Sucesso!",
        text: `Fornecedor deletado com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar fornecedor.", "error");
    }
    finally {
      setLoading(false)
    }
  };

  const formatRow = r => (
    <StyledTableRow key={r.id} hover tabIndex={-1}>
      {userHasAccess ? (
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            actions={["delete", "edit", "toggle"]}
            module="supplier"
            path="fornecedores"
            onConfirmChangeStatus={handleConfirmChangeStatus}
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Fornecedor"}
            addMessage={"Você tem certeza que deseja excluir este fornecedor? Essa ação não pode ser desfeita"}
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{r.name}</StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{r.responsable}</StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{r.phone ?? "-"}</StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{r.email ?? "-"}</StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{r.totalBought}</StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>{!!r.obs ? r.obs : "-"}</StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={truck} alt={"Icone Fornecedor"} />}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        reload={reload}
        addButtonUrl={userHasAccess ? "/admin/fornecedores/adicionar" : ""}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(SupplierListing);
