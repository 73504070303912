export function applyMask(str) {
  str = str.replace(/\D/g, '');
  let maskedStr = '';

  if (str.length === 10) {
    maskedStr = `(${str.slice(0, 2)}) ${str.slice(2, 6)}-${str.slice(6)}`;
  } else if (str.length === 11) {
    maskedStr = `(${str.slice(0, 2)}) ${str.slice(2, 7)}-${str.slice(7)}`;
  } else {
    maskedStr = str;
  }

  return maskedStr;
};
