import { AppAPIService } from "../AppAPIService";

const api = AppAPIService.getInstance();

export const getSupplierById = async (supplierId) => {
  return await api.makeHttpRequest({
    url: `/supplier/${supplierId}`,
    method: "GET"
  });
}

export const getSuppliers = async (filter) => {
  return await api.makeHttpRequest({
    url: "/supplier",
    method: "GET",
    params: filter
  });
}

export const createSupplier = async (supplier) => {
  return await api.makeHttpRequest({
    url: "/supplier",
    method: "POST",
    data: supplier
  });
}

export const updateSupplier = async (id, supplier) => {
  return await api.makeHttpRequest({
    url: `/supplier/${id}`,
    method: "PUT",
    data: supplier
  });
}

export const deleteSupplier = async (supplierId) => {
  return await api.makeHttpRequest({
    url: `/supplier/${supplierId}`,
    method: "DELETE"
  });
}

export const toggleSupplierStatus = async (supplierId, status) => {
  return await api.makeHttpRequest({
    url: `/supplier/${supplierId}/state`,
    method: "PATCH",
    data: { status }
  });
}
