import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import money from "../../../../assets/icons/money.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { styled } from "@material-ui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { deleteReceiptWay } from "../../../../services/modules/ReceiptWayApiService";
import moment from "moment";

const propsDatatable = {
  title: "Formas de Recebimento",
  endpoint: "/receiptway",
  className: "h-100",
  placeholderSearch: "Busque por forma de recebimento",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    fontWeight: 700,
    padding: "10px 30px",
    color: "#333333",
    border: "1px solid #e3e3e3",
    lineHeight: "9.5px",
    boxShadow: "rgba(0, 0, 0, .5)",
    fontSize: 10
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFF",
    "&:before": {
      border: "1px solid #e3e3e3"
    }
  }
}));

const ReceiptWayListing = props => {
  window.setPageTitle("Listagem de Formas de Recebimento - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.RECEIPTWAY_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.RECEIPTWAY_WRITE.ENGLISH
  );

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const headRows = [
    ...(userHasAccess ? [{ label: "Ações" }] : []),
    { column: "name", label: "Nome" },
    { column: "createdAt", label: "Data de Cadastro" }
  ];

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteReceiptWay(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Forma de recebimento deletada com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data?.messages
          ? e.response?.data?.messages[0]
          : "Erro ao deletar forma de recebimento.",
        "error"
      )
    } finally {
      setLoading(false);
    }
  };

  const formatRow = r => (
    <StyledTableRow
      key={r.id}
      bg={"transparent"}
      head={"#026FBB"}
      hover
      tabIndex={-1}
    >
      {userHasAccess ? (
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            actions={["delete", "edit"]}
            module="receiptway"
            path="formas-recebimento"
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir forma de recebimento"}
            addMessage={
              "Você tem certeza que deseja excluir essa forma de recebimento? Essa ação não poderá ser desfeita"
            }
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.name || "N/A"}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {moment(r?.createdAt).format("DD/MM/YYYY")}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={money} alt={"Icone Financeiro"} />}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        reload={reload}
        addButtonUrl={userHasAccess ? "/admin/formas-recebimento/adicionar" : ""}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(ReceiptWayListing);
