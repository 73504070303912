import { useEffect } from "react";
import Swal from "sweetalert2";
import { USER_TYPES } from "../enum/UserTypes";
import store from "../store/store";

const locations = {
  [USER_TYPES.USERS_READ.ENGLISH]: "/admin/usuarios"
}

export class UseProtectedPage {
  static accessToFormOrListing = (page) => {
    const roles = store.getState().auth.roles || [];
    const firstLocation = roles?.find(role => role.includes("READ"));
    const location = locations[firstLocation] || "/admin/usuarios";
    const userHasAccess = roles?.some(item => (item === page));

    if (!userHasAccess) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Acesso negado',
        showCancelButton: false,
      }).then(() => {
        window.location = location
      })
    }
  }

  static accessToListActions = (acess) => {
    const roles = store.getState().auth.roles;
    return roles?.some(item => (item === acess));
  }
}
