import React from "react";
import { pt } from "date-fns/locale";
import { components } from "react-select";
import DatePicker from "react-datepicker";
import { BsChevronDown } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { FiCalendar } from "react-icons/fi";
import { InputGroup } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Flatpickr from "react-flatpickr";
import {
  ContainerSelect2,
  DateContainer,
  InputContainer,
  NoStyledCreatableSelect2,
  StyledCreatableSelect2,
  StyledInput,
  StyledSelect2
} from "./style";

import iconChecked from "../../../../assets/icons/icon-checked.svg";
import iconNotChecked from "../../../../assets/icons/icon-not-checked.svg";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <BsChevronDown />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <div className="border">
        <IoMdClose size="8px" />
      </div>
    </components.MultiValueRemove>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <div className="border">
        <IoMdClose size="12px" />
      </div>
    </components.ClearIndicator>
  );
};

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      Sem opções
    </components.NoOptionsMessage>
  );
};

const poppupOptions = [
  {
    name: "offset",
    options: {
      offset: [-10, 0]
    }
  }
];

export const NoStyleInputField = props => {

  return (
    <NoStyledCreatableSelect2
      classNamePrefix="select"
      isMulti={props.isMulti ?? false}
      value={props.value}
      placeholder={props.placeholder ?? "Selecione..."}
      closeMenuOnSelect={props.closeMenuOnSelect ?? false}
      name={props.name}
      options={props.options}
      onChange={props.onChange}
      isDisabled={props.disabled}
      formatCreateLabel={newItem => (
        <span className="select__create-option">{`Criar ${newItem}...`}</span>
      )}
      components={{
        DropdownIndicator,
        MultiValueRemove,
        ClearIndicator,
        NoOptionsMessage
      }}
    />
  )
}

export const InputField = props => {
  const input = () => {
    if (props.as === "select2") {
      if (props.creatable) {
        return (
          <ContainerSelect2>
            <StyledCreatableSelect2
              classNamePrefix="select"
              isMulti={props.isMulti ?? false}
              value={props.value}
              placeholder={props.placeholder ?? "Selecione..."}
              closeMenuOnSelect={props.closeMenuOnSelect ?? false}
              name={props.name}
              options={props.options}
              onChange={props.onChange}
              isDisabled={props.disabled}
              formatCreateLabel={newItem => (
                <span className="select__create-option">{`Criar ${newItem}...`}</span>
              )}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
                NoOptionsMessage
              }}
            />
            <span className="label">
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </span>
          </ContainerSelect2>
        );
      } else {
        return (
          <ContainerSelect2>
            <StyledSelect2
              classNamePrefix="select"
              isMulti={props.isMulti ?? false}
              value={props.value}
              placeholder={props.placeholder ?? "Selecione..."}
              closeMenuOnSelect={props.closeMenuOnSelect ?? false}
              name={props.name}
              options={props.options}
              onChange={props.onChange}
              isDisabled={props.disabled}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
                NoOptionsMessage
              }}
            />
            <span className="label">
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </span>
          </ContainerSelect2>
        );
      }
    }

    if (props.type === "date") {
      return (
        <DateContainer>
          <DatePicker locale={pt} />
          {/* {props.label && (
            <span className="label">
              {props.label}{" "}
              {props.required && <span className="required">*</span>}
            </span>
          )} */}
        </DateContainer>
      );
    }

    if (props.type === "checkbox") {
      return (
        <InputContainer labelRed={props.labelRed}>
          <FormControlLabel
            control={
              <Checkbox
                name={props.name}
                checked={props.value}
                icon={<img src={iconNotChecked} alt="Não selecionado" />}
                checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                onChange={props.onChange}
              />
            }
            label={props.label}
          />
        </InputContainer>
      );
    }

    if ((props.as === undefined || props.as === null) && props.prefix) {
      return (
        <InputContainer className={props.fade ? "fade" : ""}>
          <InputGroup>
            <InputGroup.Text>{props.prefix}</InputGroup.Text>
            <StyledInput
              type={props.type ? props.type : "text"}
              autoComplete="off"
              name={props.name}
              placeholder={props.placeholder ?? "Digite os dados"}
              maxLength={props.maxLength}
              style={props.style}
              value={props.value}
              isInvalid={props.isInvalid}
              onChange={props.onChange}
              required={props.required}
              disabled={props.disabled}
              readOnly={props.readOnly ?? false}
            />
            {props.label && (
              <span className="label">
                {props.label}{" "}
                {props.required && <span className="required">*</span>}
              </span>
            )}
            {props.as === "select" && (
              <div className="chevron">{<BsChevronDown />}</div>
            )}
            {props.endAdornment !== undefined && (
              <div className="end-adornment">{props.endAdornment}</div>
            )}
          </InputGroup>
        </InputContainer>
      );
    }

    return (
      <InputContainer className={props.fade ? "fade" : ""}>
        <StyledInput
          type={props.type ?? "text"}
          as={props.as}
          autoComplete="off"
          name={props.name}
          placeholder={props.placeholder ?? "Digite os dados"}
          maxLength={props.maxLength}
          style={props.style}
          value={props.value}
          rows={props.rows}
          isInvalid={props.isInvalid}
          onChange={props.onChange}
          required={props.required}
          disabled={props.disabled}
          readOnly={props.readOnly ?? false}
          resize={props.resize}
          height={props.height}
          className={
            props.as === "select" && props.value === ""
              ? "placeholder"
              : props.as === "textarea" && props.isInvalid
                ? "is-invalid"
                : ""
          }
        >
          {props.children}
        </StyledInput>

        {props.label && (
          <span className="label">
            {props.label}{" "}
            {props.required && <span className="required">*</span>}
          </span>
        )}

        {props.as === "select" && (
          <div className="chevron">{<BsChevronDown />}</div>
        )}

        {props.endAdornment !== undefined && (
          <div className="end-adornment">{props.endAdornment}</div>
        )}
      </InputContainer>
    );
  };

  return input();
};
