/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import { DateTimePicker } from "@material-ui/pickers";
import * as moment from "moment";
import { camelCase, capitalize, debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiSearchLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
// import ImagemDefault from "../../assets/images/map-default.svg";
import DatePicker from "../../../../components/InputDatePicker";
import InputForm from "../../../../components/InputForm";
import AdminTitle from "../../../../pages/admin/components/AdminTitle";
import HeaderButton from "../../../../pages/admin/components/headerButton";
import RefreshButton from "../../../../pages/admin/components/RefreshButton";
import Loading from "../../../../pages/home/components/Loading";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { AppAPIService } from "../../../../services/AppAPIService";
import "moment/locale/pt-br";
import {
  ImageContainer,
  OpenContainerButton,
  StyledTableCell,
  StyledTableContent,
  StyledTableRow,
  StyledTableHeader,
  StyledTableHeaderAnalisys
} from "../../../../partials/datatable/styles";
import "../../../../partials/datatable/styles.css";
import { fetchDetails } from "../../../../pages/admin/pages/utils/fetchDetails";
import "./custom-styles.css";

moment.locale("pt-br");

const EnhancedTableHead = props => {
  const { headRows } = props;

  let component = StyledTableCell;

  if (props.customHeader) {
    component = StyledTableHeader;
  } else if (props.customAnalisysHeader) {
    component = StyledTableHeaderAnalisys;
  }

  const generateRow = headRows => (
    <TableRow>
      {(headRows || []).map((row, i) => (
        <TableCell
          key={row.column || i}
          align={row.align ? row.align : "left"}
          padding={row.disablePadding ? "none" : "default"}
          size={row.size || null}
          width={row.width || undefined}
          variant="head"
          component={component}
          style={i !== (headRows || []).length - 1 ? { borderRight: '1px solid #CCC' } : {}}
        >
          {row.label}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <TableHead>
      {Array.isArray(headRows[0]) &&
        headRows.map(row => {
          return generateRow(row);
        })}
      {!Array.isArray(headRows[0]) && generateRow(headRows)}
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired
};

const api = AppAPIService.getInstance();

const CustomDatatable = props => {
  const { widthTable, idCompany, user, endpoint, checkRemember } = props;
  const useStyles = makeStyles(() => {
    return {
      root: {
        //tabela e pesquisa
        maxHeight: "fit-content",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        borderRadius: "4px"
      },
      paper: {
        width: "100%",
        flex: "1"
      },
      table: {
        minWidth: widthTable ? widthTable : 400,
        overflowX: "scroll",
        background: "#F9F7F6"
      },
      tableWrapper: {
        // TODO: remover esse overflow
        //overflow: "hidden !important",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        overflowX: "auto",
        borderRadius: "4px",
        boxShadow: props.removeShadow
          ? "none"
          : "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      },
      tableContainer: {
        padding: "0 16px 16px 16px"
      },
      search: {
        padding: 16
        // display: "flex",
        // flexWrap: "wrap"
      },
      rows: {
        padding: "0 !important",
        margin: "0 !important"
      },
      addBtn: {
        padding: "16px 0",
        marginLeft: "16px"
      }
    };
  });

  const classes = useStyles();
  const currentState = loadPaginationState();

  const [rows, setRows] = useState(props.rows);

  // TODO: Remover depois da integração com o backend
  // const [rows, setRows] = useState(mock.data);

  const [order, setOrder] = useState(currentState.order || "desc");
  const [orderBy, setOrderBy] = useState(
    currentState.orderBy || props.orderBy || props.headRows[0].column || "id"
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage || currentState.perPage || 10
  );
  const [count, setCount] = useState(rows?.length);
  const [inputSearch, setInputSearch] = useState(props.search || "");
  const [isLoading, setIsLoading] = useState("");
  const [isLoadingExport, setIsLoadingExport] = useState("");
  const [reload, setReload] = useState(false);
  const pageCurrentState = currentState.page;
  const [isOpen, setIsOpen] = useState(false);

  const monthOptions = [
    { label: "Janeiro", value: 0 },
    { label: "Fevereiro", value: 1 },
    { label: "Março", value: 2 },
    { label: "Abril", value: 3 },
    { label: "Maio", value: 4 },
    { label: "Junho", value: 5 },
    { label: "Julho", value: 6 },
    { label: "Agosto", value: 7 },
    { label: "Setembro", value: 8 },
    { label: "Outubro", value: 9 },
    { label: "Novembro", value: 10 },
    { label: "Dezembro", value: 11 }
  ];

  const [month, setMonth] = useState({
    label: capitalize(moment().format("MMMM")),
    value: moment().month()
  });

  const defaultYear = {
    label: moment().year(),
    value: moment().year()
  }

  const [year, setYear] = useState(defaultYear);

  const defaultYearsValue = [{
    value: moment().year(),
    label: moment().year(),
  }];

  const [years, setYears] = useState(defaultYearsValue)

  useEffect(() => {
    (async () => {
      setYear(defaultYear)

      if (!user.idCompany && !idCompany) {
        setYears(defaultYearsValue);
        return
      }

      const result = await fetchDetails(
        `${endpoint}/${user.idCompany ?? idCompany}/years`,
        setIsLoading
      );

      console.log(result)

      if (!result?.length) {
        setYears(defaultYearsValue)
        return
      }

      setYears(
        result
          .sort((a, b) => { return Number(b) - Number(a) })
          .map((year) => {
            return {
              value: year,
              label: year,
            }
          })
      )
    })()
  }, [idCompany])

  const onSearch = useRef(
    debounce((e, searchLoad) => {
      if (e.length >= 3 || e.length === 0) {
        searchLoad(e);
      }
    }, 1500)
  ).current;

  function searchLoad(e) {
    loadRecords({
      page: 0,
      search: e
    });
  }

  function search(e, select) {
    props.localSearch || select
      ? onSearch(e.target.value, e => props.localFilter(select, e, setCount))
      : onSearch(e.target.value, e => searchLoad(e));
  }

  const showMap = () => setIsOpen(!isOpen);

  async function exportRecords(config = {}) {
    setIsLoadingExport(true);

    let params = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      search: config.search,
      where: config.status ? config.status.join(", ") : ""
    };

    await api.download(
      {
        url: config.isEndpointFull
          ? config.endpoint || ""
          : props.endpoint + (config.endpoint || ""),
        params
      },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    setIsLoadingExport(false);
  }
  async function loadRecords(config = {}) {
    setIsLoading(true);
    const initialParms = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      orderBy: config.orderBy || orderBy,
      orderByDirection: config.orderByDirection || order,
      search: config.search,
      idCompany,
      ...(props.dateFilter && {
        month: month.value,
        year: year.value
      })
    };

    let params = initialParms;

    try {
      let response = {};

      response = await api.makeHttpRequest({
        url: props.endpoint,
        params
      });

      if (props?.aggregator) {
        response.data = props.aggregator(response.data);
      }
      setRows(response?.data || response || []);
      setCount(response?.meta?.total || response.length || 0);

      setPage(
        params.page < response?.meta?.lastPage
          ? response?.meta?.currentPage - 1 > 0
            ? response?.meta?.currentPage - 1
            : 0
          : response?.meta?.lastPage - 1 > 0
            ? response?.meta?.lastPage - 1
            : 0
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        "Erro ao carregar os dados, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setIsLoading(false);
      setReload(false);
    }
  }

  useEffect(() => {
    if (rows && checkRemember) {
      rows.map(row => {
        checkRemember(row);
      });
    }
  }, [rows]);

  useEffect(() => {
    if (!props.cancelReload) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, props.reload]);

  useEffect(() => {
    if (Boolean(props.dateRange)) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange]);

  useEffect(() => {
    loadRecords();
  }, [idCompany]);

  useEffect(() => {
    if (props.dateFilter) loadRecords();
  }, [month, year]);

  function loadPaginationState() {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (paginationState[props.endpoint]) {
      return paginationState[props.endpoint];
    }

    return {};
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
    loadRecords({ page: newPage, search: inputSearch });
  }

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(+event.target.value);
    loadRecords({
      perPage: event.target.value,
      page: 0,
      search: inputSearch
    });
  }

  function refreshAction() {
    loadRecords({
      page: pageCurrentState || page,
      search: inputSearch
    });
  }

  if (reload) {
    refreshAction();
  }

  const generateMobileButtons = () => {
    return (
      <>
        {!props.hiddenReload && (
          <li className="kt-nav__item">
            <a href={void 0} className="kt-nav__link" onClick={refreshAction}>
              <i className="kt-nav__link-icon flaticon2-reload"></i>
              <span className="kt-nav__link-text">Atualizar</span>
            </a>
          </li>
        )}
        {props.buttons.length > 0
          ? props.buttons.map((btn, i) => {
            const btnOnClick = btn.endpoint
              ? () =>
                exportRecords({
                  orderByDirection: order,
                  orderBy: orderBy,
                  search: inputSearch,
                  endpoint: btn.endpoint,
                  isEndpointFull: btn.isEndpointFull
                })
              : btn.onClick;

            return btn.path ? (
              <Link to={btn.path} className="kt-nav__item" key={i}>
                <span className="kt-nav__link" onClick={btnOnClick}>
                  <i
                    className={`kt-nav__link-icon flaticon-${btn.icone}`}
                  ></i>
                  <span className="kt-nav__link-text">{btn.label}</span>
                </span>
              </Link>
            ) : (
              <li className="kt-nav__item" key={i}>
                <a
                  href={void 0}
                  className="kt-nav__link"
                  onClick={btnOnClick}
                >
                  <i
                    className={`kt-nav__link-icon flaticon-${btn.icone}`}
                  ></i>
                  <span className="kt-nav__link-text">{btn.label}</span>
                </a>
              </li>
            );
          })
          : null}
      </>
    );
  };

  return (
    <div
      className={`col-xl-12 ${props.className}`}
      style={{
        minHeight: "max-content",
        marginTop: "0px",
        padding: props.addButtonOpenDiv ? "0 16px 0 0" : "0",
        display: "flex",
        justifyContent: "center"
      }}
    >
      <StyledTableContent style={{ maxHeight: "fit-content" }}>
        <Loading isLoading={isLoading || isLoadingExport} />
        <Portlet
          fluidHeight={true}
          style={{ backgroundColor: "#F9F7F6", boxShadow: "none" }}
        >
          <div>
            {props.title && props.icon ? (
              <AdminTitle
                icon={props.icon}
                title={props.title}
                disableHeader={props.disableHeader}
                width={props.width}
                mobile={props.mobile}
                actions={props.actions}
                refreshAction={props.refreshAction}
                generateMobileButtons={generateMobileButtons}
                addButtonUrl={props.addButtonUrl}
                tooltip={props.tooltip}
                titleButon={props.titleButon}
              />
            ) : null}
          </div>

          <PortletBody fit={true} fluid={true}>
            <div className={classes.root}>
              {props.formView ? props.formView : null}

              {props.placeholderSearch ? (
                <div className={classes.search}>
                  <Form.Row className={classes.rows}>
                    <Form.Group
                      as={Col}
                      lg={props.dateFilter ? "9" : "12"}
                      xs={props.dateFilter ? "9" : "12"}
                      className={
                        props.tombstoneInputs ||
                          props.personInputs ||
                          props.contractInputFilter
                          ? "rmv-all-props row-group-padding row-group-margin-bottom row-grop-margin-bottom-mobile icon-search input-custom"
                          : "rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile icon-search input-custom"
                      }
                    >
                      <Form.Control
                        type="text"
                        name="search"
                        onChange={e => {
                          search(e);
                          setInputSearch(e.target.value);
                        }}
                        value={inputSearch}
                        className="input-custom"
                        placeholder={props.placeholderSearch}
                      />
                      <button type="button">
                        <RiSearchLine size={20} />
                      </button>
                    </Form.Group>

                    {props.dateFilter && (
                      <>
                        <Form.Group
                          as={Col}
                          lg="3"
                          xs="3"
                          className="rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile d-flex"
                        >
                          <InputForm
                            id="year"
                            name="year"
                            value={year}
                            options={years}
                            onChange={setYear}
                            placeholder="Ano"
                            isClearable={false}
                            role="ok"
                            isSelect
                            isHeaderButton
                          />

                          <InputForm
                            id="mouth"
                            name="mouth"
                            value={month}
                            options={monthOptions}
                            isSelect
                            onChange={setMonth}
                            placeholder="Mês"
                            isClearable={false}
                            isHeaderButton
                            role="ok"
                          />
                        </Form.Group>
                      </>
                    )}
                  </Form.Row>

                  {props.width >= 768 || !props.mobile ? (
                    props.buttons && props.buttons.length > 0 ? (
                      <>
                        {props.buttons.map((item, index) => (
                          <div className={classes.addBtn} key={index}>
                            <HeaderButton
                              path={item.path}
                              label={item.label}
                              onClick={
                                item.endpoint
                                  ? () =>
                                    exportRecords({
                                      orderByDirection: order,
                                      orderBy: orderBy,
                                      search: inputSearch,
                                      endpoint: item.endpoint,
                                      isEndpointFull: item.isEndpointFull
                                    })
                                  : item.onClick
                              }
                              icone={item.icone}
                              disabled={item.disabled}
                            />
                          </div>
                        ))}
                        {!props.hiddenReload && (
                          <RefreshButton refreshAction={refreshAction} />
                        )}
                      </>
                    ) : null
                  ) : null}
                </div>
              ) : null}

              {props.placeholderSearchYear ? (
                <div className={classes.search}>
                  <Form.Row>
                    {props.dateFilter && (
                      <>
                        <Form.Group
                          as={Col}
                          lg="2"
                          xs="2"
                          className="rmv-all-props row-group-margin-bottom row-grop-margin-bottom-mobile d-flex"
                          style={{ zIndex: 100 }}
                        >
                          <InputForm
                            id="year"
                            name="year"
                            value={year}
                            options={years}
                            onChange={setYear}
                            placeholder="Selecione o ano"
                            isClearable={false}
                            role="ok"
                            isSelect
                            isHeaderButton
                          />
                        </Form.Group>
                      </>
                    )}
                  </Form.Row>

                  {props.width >= 768 || !props.mobile ? (
                    props.buttons && props.buttons.length > 0 ? (
                      <>
                        {props.buttons.map((item, index) => (
                          <div className={classes.addBtn} key={index}>
                            <HeaderButton
                              path={item.path}
                              label={item.label}
                              onClick={
                                item.endpoint
                                  ? () =>
                                    exportRecords({
                                      orderByDirection: order,
                                      orderBy: orderBy,
                                      search: inputSearch,
                                      endpoint: item.endpoint,
                                      isEndpointFull: item.isEndpointFull
                                    })
                                  : item.onClick
                              }
                              icone={item.icone}
                              disabled={item.disabled}
                            />
                          </div>
                        ))}
                        {!props.hiddenReload && (
                          <RefreshButton refreshAction={refreshAction} />
                        )}
                      </>
                    ) : null
                  ) : null}
                </div>
              ) : null}

              <div className={classes.tableContainer}>
                {
                  Boolean(!isLoading && count) ?
                    <Paper className={classes.paper}>
                      <div class="container-roll">
                        <StyledTableRow style={{
                          borderTopLeftRadius: "4px",
                          position: "fixed",
                          zIndex: 3
                        }}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              fontWeight: "500",
                              color: "#FFFFFF",
                              background: "#026fbb",
                              textTransform: "capitalize",
                              borderTopLeftRadius: "4px",
                              textAlign: "center",
                              height: "41px",
                              width: "316px",
                              padding: "0"
                            }}
                          >
                            CATEGORIA
                          </StyledTableCell>
                        </StyledTableRow>


                        <div class="fixed-div">
                          <EnhancedTableHead
                            rowCount={rows?.length}
                            headRows={props.headRows}
                            styledTableCell={props.styledTableCell}
                            customHeader={props.customHeader}
                            customAnalisysHeader={props.customAnalisysHeader}
                          />
                        </div>
                        <div class="scrollable-content">
                          <div style={{ display: "flex", position: 'relative' }}>
                            <div className="fixed-side">
                              {
                                (rows ?? []).map(row => (
                                  <TableBody style={{ height: "32px" }}>
                                    <Categories data={row} />
                                  </TableBody>

                                ))
                              }
                            </div>
                            <Table
                              className={classes.table}
                              style={{ borderRadius: "4px 4px 0px 0px" }}
                            >
                              <TableBody style={{ height: "32px" }}>
                                {isLoading ? (
                                  <TableRow component={StyledTableRow}>
                                    <TableCell
                                      colSpan={props.headRows?.length}
                                      className="text-center"
                                      component={StyledTableCell}
                                    >
                                      Aguarde...
                                    </TableCell>
                                  </TableRow>
                                ) : count > 0 ? (
                                  rows.map(row => {
                                    return props.formatRow(row);
                                  })
                                ) : (
                                  <TableRow component={StyledTableRow}>
                                    <TableCell
                                      colSpan={props.headRows.length}
                                      className="text-center"
                                      component={StyledTableCell}
                                    >
                                      {!user.idCompany && !idCompany
                                        ? "Nenhum registro encontrado, selecione uma empresa para visualisar."
                                        : "Nenhum registro encontrado. Realize lançamentos para gerar dados"}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </div>

                        </div>
                      </div>
                    </Paper>
                    :
                    <Paper style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",

                      padding: "5px 0"
                    }}>
                      <p style={{ color: "#777", fontWeight: "400", margin: 0 }}>
                        Nenhum registro encontrado. Realize lançamentos para gerar dados
                      </p>
                    </Paper>
                }


                {props.paginator !== false && (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          colSpan={10}
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          id="MuiTable-root-custom"
                          labelRowsPerPage="Registros por página"
                          backIconButtonProps={{
                            "aria-label": "Anterior"
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima"
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </StyledTableContent>

      {props.addButtonOpenDiv ? (
        <ImageContainer showMap={isOpen}>
          <OpenContainerButton onClick={showMap}>
            {isOpen ? (
              <MdKeyboardArrowLeft className="arrow" />
            ) : (
              <MdKeyboardArrowRight className="arrow" />
            )}
          </OpenContainerButton>

          {/* <img src={ImagemDefault} alt="Mapa do cemitério" /> */}
        </ImageContainer>
      ) : null}
    </div>
  );
};

export const dotJoin = (items, name) => {
  const joined = items.map((i) => (!isNaN(i) ? ++i : '')).join('.');
  return `${joined} ${name.toUpperCase()}`;
};

const Categories = (props) => {
  return (
    <>
      {
        props?.data?.categories?.map((category, categoryIndex) => (
          <>
            <StyledTableRow key={category.id} tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
              <StyledTableCell
                scope="row"
                style={{
                  fontWeight: "500",
                  color: "#FFFFFF",
                  background: "#2992DB",
                  textTransform: "capitalize",
                  textAlign: "initial",
                  display: "flex",
                  width: "100%",
                }}
              >
                {dotJoin([categoryIndex], category.name)}
              </StyledTableCell>
            </StyledTableRow>

            {category.subcategories?.map((sub, subIndex) => (
              <>
                <StyledTableRow
                  key={sub.id}
                  bg={getBackgroudRow(sub.name)}
                  tabIndex={-1}
                  style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}
                >
                  <StyledTableCell
                    scope="row"
                    style={{
                      fontWeight: "600",
                      background: "#f9f7f6",
                      color: "#026FBB",
                      textAlign: "initial",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    {dotJoin([categoryIndex, subIndex], sub.name)}
                  </StyledTableCell>
                </StyledTableRow>

                {sub.groups?.map((group, groupIndex) => (
                  <>
                    <StyledTableRow key={group.id} hover tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                      <StyledTableCell
                        scope="row"
                        style={{
                          fontWeight: "normal",
                          color: "#333333",
                          textTransform: "capitalize",
                          background: "#FFF",
                          textAlign: "initial",
                          display: "flex",
                          width: "100%",
                          borderRight: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        <span
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "316px"
                          }}
                        >
                          {dotJoin([categoryIndex, subIndex, groupIndex], group.name)}
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                ))}

                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <StyledTableCell
                    style={{
                      textTransform: "capitalize",
                      textAlign: "initial",
                      fontWeight: "600",
                      color: "#333333",
                      background: "#FFFDBD",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    TOTAL
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ))}

            {category.name.toUpperCase().includes("CUSTOS VARIÁVEIS") ? (
              <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                <StyledTableCell
                  scope="row"
                  style={{
                    textTransform: "capitalize",
                    textAlign: "initial",
                    fontWeight: "600",
                    color: "#333333",
                    background: "#F1F388",
                    display: "flex",
                    width: "100%",
                    borderRight: "1px solid rgba(224, 224, 224, 1)"
                  }}
                >
                  Margem de Contribuição (Resultado)
                </StyledTableCell>
              </StyledTableRow>
            ) : null}

            {category.name.toUpperCase().includes("DESPESAS FIXAS") ? (
              <>
                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <StyledTableCell
                    scope="row"
                    style={{
                      textTransform: "capitalize",
                      textAlign: "initial",
                      fontWeight: "600",
                      color: "#333333",
                      background: "#F1F37F",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    Lucro Operacional (Antes dos Investimentos)
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                  <StyledTableCell
                    scope="row"
                    style={{
                      textTransform: "capitalize",
                      textAlign: "initial",
                      fontWeight: "600",
                      color: "#333333",
                      background: "#F9D2B1",
                      display: "flex",
                      width: "100%",
                      borderRight: "1px solid rgba(224, 224, 224, 1)"
                    }}
                  >
                    Lucro Operacional
                  </StyledTableCell>
                </StyledTableRow>
              </>
            ) : null}

            {categoryIndex + 1 === props?.data?.categories.length ? (
              <StyledTableRow tabIndex={-1} style={{ height: "32px", width: "100%", display: "flex", flexDirection: "row" }}>
                <StyledTableCell
                  scope="row"
                  style={{
                    textTransform: "capitalize",
                    textAlign: "initial",
                    fontWeight: "600",
                    color: "#333333",
                    background: "#E8EA76",
                    display: "flex",
                    width: "100%",
                    borderRight: "1px solid rgba(224, 224, 224, 1)"
                  }}
                >
                  Resultado Líquido (Resultado)
                </StyledTableCell>
              </StyledTableRow>
            ) : null}
          </>
        ))
      }
    </>
  )
}

const getBackgroudRow = value => {
  let color;

  const code = value.split(" ");
  const validCode = new RegExp("^[0-9].$").test(code[0]);

  if (validCode) {
    return "#2992DB";
  }

  switch (String(value).toLowerCase()) {
    case "total":
      color = "#FFFDBD";
      break;

    default:
      color = "transparent";
      break;
  }

  return color;
};

const mapStateToProps = ({ company, auth }) => ({
  idCompany: company.idCompany,
  user: auth.user
});

export default withRouter(connect(mapStateToProps)(CustomDatatable));
