
import React, { useState, useEffect } from 'react';
import DialogActions from '@material-ui/core/DialogActions/';
import DialogContent from '@material-ui/core/DialogContent/';
import DialogContentText from '@material-ui/core/DialogContentText/';
import DialogTitle from '@material-ui/core/DialogTitle/';
import Swal from "sweetalert2";
import { InputField } from "../../pages/admin/components/InputField";
import { AppAPIService } from "../../services/AppAPIService";
import { Button, StyledDialog } from './components/Dialog/style';
import {
  DegreesOfKinship
} from "../../utils/lists/ListSelectsPeople";

export default function ConfirmChangeKinship({ open, closeDialog, title, hideTitle, data, setReload }) {

  const api = AppAPIService.getInstance();
  const [ kinship, setKinship ] = useState("");
  const id = data.id;

  useEffect(() => {
    if (data) {
      setKinship(data.kinship);
    }
  }, [data]);

  async function handleChangeKinship(id) {

    try {
      await api.makeHttpRequest({
        method: "PATCH",
        url: `/person-relative/update/${id}`,
        data: {
          kinship: kinship ?? ""
        }
      });

      closeDialog(false);

      Swal.fire({
        title: "Sucesso!",
        text: "Parentesco alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });

    } catch (e) {
      closeDialog(false);
      Swal.fire("Erro!", "Erro ao alterar parentesco.", "error");
    } finally {
      setReload(true);
    }
  }

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!hideTitle && <DialogTitle id="alert-dialog-title">{title && title !== '' ? title : "Deseja editar parentesco?"}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <InputField
              name="kinship"
              as="select"
              label='Parentesco'
              value={kinship}
              onChange={(e) => setKinship(e.target.value)}
              style={{ width: 288 }}
            >
              <option value="">Selecione o parentesco</option>
              {DegreesOfKinship.map((i, index) => (
                <option key={index} value={i.id}>
                  {i.label}
                </option>
              ))}
            </InputField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>
            Cancelar
          </Button>
          <Button onClick={() => handleChangeKinship(id)} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
