import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as company from "./ducks/company.duck";
import * as category from "./ducks/category.duck";
import { metronic } from "../../_metronic";
import { servicesReducer } from "./ducks/services.duck";

export const rootReducer = combineReducers({
  company: company.reducer,
  category: category.reducer,
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  services: servicesReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
