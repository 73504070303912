import { pt } from "yup-locale-pt";
import * as yup from 'yup';
import { string } from "prop-types";

yup.setLocale(pt)

export const schema = yup.object().shape({
  name: yup.string()
    .label("Nome")
    .required(),
  paymentWays: yup.array()
    .label("Tipos de pagamento"),
  tempPaymentName: yup.string()
    .optional()
});
