import moment from 'moment-timezone';

export const sortHistoricByDate = (dateA, dateB) => {
  return new Date(dateA) - new Date(dateB);
}

export const getLastAlteration = (historic) => {
  historic.sort(sortHistoricByDate);
  return [...historic].slice(-1)[0].effectiveDate;
}

export const getCreationDate = (historic) => {
  historic.sort(sortHistoricByDate);
  return [...historic].shift().effectiveDate;
}


export const getEffectivePercentual = (historic, effectiveDate) => {

  if (effectiveDate) {
    effectiveDate = moment.utc(effectiveDate).parseZone();
  } else {
    effectiveDate = moment.utc().parseZone();
  }

  const endOfEffectiveDate = effectiveDate.endOf('day');
  historic.sort(sortHistoricByDate);

  const effectiveAlterations = [];

  historic.map((alteration) => {
    const alterationEffectiveDate = moment.utc(alteration.effectiveDate).parseZone();
    if (alterationEffectiveDate.isSameOrBefore(endOfEffectiveDate)) {
      effectiveAlterations.push(alteration);
    }
  })

  return effectiveAlterations.length ? effectiveAlterations[0].value : 0;
}
