export const USER_TYPES = {
  USERS_READ: {
    ENGLISH: "USERS_READ",
    PORTUGUESE: "Leitura - Usuários"
  },

  USERS_WRITE: {
    ENGLISH: "USERS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Usuarios"
  },

  PROFILES_READ: {
    ENGLISH: "PROFILES_READ",
    PORTUGUESE: "Leitura - Perfis"
  },

  PROFILES_WRITE: {
    ENGLISH: "PROFILES_WRITE",
    PORTUGUESE: "Adicionar/Editar - Perfis"
  },

  PRODUCTS_READ: {
    ENGLISH: "PRODUCTS_READ",
    PORTUGUESE: "Adicionar/Editar - Produtos"
  },

  PRODUCTS_WRITE: {
    ENGLISH: "PRODUCTS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Produtos"
  },

  WORKERS_WRITE: {
    ENGLISH: "WORKERS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Funcionários"
  },

  WORKERS_READ: {
    ENGLISH: "WORKERS_READ",
    PORTUGUESE: "Leitura - Funcionários"
  },

  SUPPLIERS_READ: {
    ENGLISH: "SUPPLIERS_READ",
    PORTUGUESE: "Leitura - Fornecedores"
  },

  SUPPLIERS_WRITE: {
    ENGLISH: "SUPPLIERS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Fornecedores"
  },

  CLIENTS_READ: {
    ENGLISH: "CLIENTS_READ",
    PORTUGUESE: "Leitura - Clientes"
  },

  CLIENTS_WRITE: {
    ENGLISH: "CLIENTS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Clientes"
  },

  VEHICLES_WRITE: {
    ENGLISH: "VEHICLES_WRITE",
    PORTUGUESE: "Adicionar/Editar - Veículos"
  },

  VEHICLES_READ: {
    ENGLISH: "VEHICLES_READ",
    PORTUGUESE: "Leitura - Veículos"
  },

  FINANCES_WRITE: {
    ENGLISH: "FINANCES_WRITE",
    PORTUGUESE: "Adicionar/Editar - Movimentações Financeiras"
  },

  FINANCES_READ: {
    ENGLISH: "FINANCES_READ",
    PORTUGUESE: "Leitura - Movimentações Financeiras"
  },

  DISCOUNT_WRITE: {
    ENGLISH: "DISCOUNT_WRITE",
    PORTUGUESE: "Adicionar/Editar - Descontos"
  },

  DISCOUNT_READ: {
    ENGLISH: "DISCOUNT_READ",
    PORTUGUESE: "Leitura - Descontos"
  },

  FEE_WRITE: {
    ENGLISH: "FEE_WRITE",
    PORTUGUESE: "Adicionar/Editar - Taxas"
  },

  FEE_READ: {
    ENGLISH: "FEE_READ",
    PORTUGUESE: "Leitura - Taxas"
  },

  FINANCIALFLOW_WRITE: {
    ENGLISH: "FINANCIALFLOW_WRITE",
    PORTUGUESE: "Adicionar/Editar - Fluxo de Caixa"
  },

  FINANCIALFLOW_READ: {
    ENGLISH: "FINANCIALFLOW_READ",
    PORTUGUESE: "Leitura - Fluxo de Caixa"
  },

  FINANCIALFLOWANALYSIS_WRITE: {
    ENGLISH: "FINANCIALFLOWANALYSIS_WRITE",
    PORTUGUESE: "Adicionar/Editar - Analises de fluxo de Caixa"
  },

  FINANCIALFLOWANALYSIS_READ: {
    ENGLISH: "FINANCIALFLOWANALYSIS_READ",
    PORTUGUESE: "Leitura - Analises de fluxo de Caixa"
  },

  CATEGORIES_READ: {
    ENGLISH: "CATEGORIES_READ",
    PORTUGUESE: "Ler itens do plano de conta"
  },

  CATEGORIES_WRITE: {
    ENGLISH: "CATEGORIES_WRITE",
    PORTUGUESE: "Criar/Editar/Deletar itens do plano de contas"
  },

  RECEIPTWAY_WRITE: {
    ENGLISH: "RECEIPTWAY_WRITE",
    PORTUGUESE: "Adicionar/Editar - Formas de recebimento"
  },

  RECEIPTWAY_READ: {
    ENGLISH: "RECEIPTWAY_READ",
    PORTUGUESE: "Leitura - Formas de recebimento"
  },
};
