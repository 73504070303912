import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../partials/datatable/styles";
import Datatable from "../../../../partials/datatable/Datatable";

import Loading from "../../../home/components/Loading";
import { USER_TYPES } from "../../../../enum/UserTypes";
import streamline from "../../../../assets/icons/streamline.svg";
import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import { FormatMoney } from "../../../../utils/FormatMoney";
import RequestFlowInitialValue from "./FlowInitialValue";

const propsDatatable = {
  title: "Fluxo de Caixa",
  endpoint: "/financialflow",
  className: "h-100",
  placeholderSearchYear: "Selecione o ano",
  customHeader: true
};

class CategoryFlowTypes {
  static RECEIPTS = 'receitas';
  static EXPENSES = 'despesas';
  static INVESTIMENTS = 'investimentos';
  static ACCUMULATED = 'acumulado';
  static INITIAL_VALUES = 'saldo inicial';
  static RESULT = 'resultado';
}

const FlowListing = props => {
  window.setPageTitle("Fluxo de Caixa - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.FINANCIALFLOW_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.FINANCIALFLOW_READ.ENGLISH
  );

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [hasInitial, setHasInitial] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasFlow, setHasFlow] = useState(false);

  const months = [
    { label: "JANEIRO", value: "jan", align: 'left' },
    { label: "FEVEREIRO", value: "fev", align: 'left' },
    { label: "MARÇO", value: "mar", align: 'left' },
    { label: "ABRIL", value: "abr", align: 'left' },
    { label: "MAIO", value: "mai", align: 'left' },
    { label: "JUNHO", value: "jun", align: 'left' },
    { label: "JULHO", value: "jul", align: 'left' },
    { label: "AGOSTO", value: "ago", align: 'left' },
    { label: "SETEMBRO", value: "set", align: 'left' },
    { label: "OUTUBRO", value: "out", align: 'left' },
    { label: "NOVEMBRO", value: "nov", align: 'left' },
    { label: "DEZEMBRO", value: "dez", align: 'left' }
  ]

  const headRows = [
    ...(userHasAccess ? [{ label: "FLUXO DE CAIXA" }] : []),
    ...months,
    { label: "TOTAL", align: 'left' }
  ];

  useEffect(() => {
    setHasFlow(false)
  }, [props.idCompany])

  const getBackgroudRow = value => {
    let color;

    switch (String(value).toLowerCase()) {
      case "receitas":
        color = "rgba(0, 255, 86, 0.25)";
        break;
      case "despesas":
        color = "rgba(255, 184, 192, 0.81)";
        break;
      case "investimentos":
        color = "#CEEBFF";
        break;
      case "resultado":
        color = "#FFFDBD";
        break;
      case "acumulado":
        color = "#FDFF89";
        break;

      default:
        color = "transparent";
        break;
    }

    return color;
  };

  const getTransformLabelRow = value => {
    switch (String(value).toLowerCase()) {
      case CategoryFlowTypes.RESULT:
        return "uppercase";

      case CategoryFlowTypes.ACCUMULATED:
        return "uppercase";

      default:
        return "capitalize";
    }
  };

  const toDecimal = (value) => {
    return Number(value).toFixed(2)
  }

  const getAggregationTotal = (aggregation) => {
    let total = 0.00
    months.map((month) => {
      total += Number(aggregation[month.value])
    })
    return Number(total.toFixed(2))
  }

  const calculateTotal = (financialFlows) => {
    for (const financialFlow of financialFlows) {
      [
        CategoryFlowTypes.INITIAL_VALUES,
        CategoryFlowTypes.RECEIPTS,
        CategoryFlowTypes.EXPENSES,
        CategoryFlowTypes.INVESTIMENTS,
        CategoryFlowTypes.RESULT,
        CategoryFlowTypes.ACCUMULATED,
      ].map(aggregation => {
        switch (aggregation) {
          case CategoryFlowTypes.INITIAL_VALUES:
            let lastInitialValue = 0.00
            months.map((month) => {
              if (financialFlow[CategoryFlowTypes.INITIAL_VALUES][month.value]) {
                lastInitialValue = financialFlow[CategoryFlowTypes.INITIAL_VALUES][month.value]
              }
            })
            financialFlow[CategoryFlowTypes.INITIAL_VALUES].total = lastInitialValue
            break;

          case CategoryFlowTypes.RESULT:
            financialFlow[CategoryFlowTypes.RESULT].total = getAggregationTotal(financialFlow[CategoryFlowTypes.RESULT])
            break;

          case CategoryFlowTypes.ACCUMULATED:
            let lastAcumulatedValue = 0.00
            months.map((month) => {
              if (financialFlow[CategoryFlowTypes.ACCUMULATED][month.value]) {
                lastAcumulatedValue = financialFlow[CategoryFlowTypes.ACCUMULATED][month.value]
              }
            })
            financialFlow[CategoryFlowTypes.ACCUMULATED].total = lastAcumulatedValue
            break;

          case CategoryFlowTypes.RECEIPTS:
            financialFlow[CategoryFlowTypes.RECEIPTS].total = getAggregationTotal(financialFlow[CategoryFlowTypes.RECEIPTS])
            break;

          case CategoryFlowTypes.EXPENSES:
            financialFlow[CategoryFlowTypes.EXPENSES].total = getAggregationTotal(financialFlow[CategoryFlowTypes.EXPENSES])
            break;

          case CategoryFlowTypes.INVESTIMENTS:
            financialFlow[CategoryFlowTypes.INVESTIMENTS].total = getAggregationTotal(financialFlow[CategoryFlowTypes.INVESTIMENTS])
            break;

          default:
            return null;
        }
      })
    }

    return financialFlows;
  }

  const getOrderedGroups = (financialFlow) => {
    const groups = [];
    [
      CategoryFlowTypes.INITIAL_VALUES,
      CategoryFlowTypes.RECEIPTS,
      CategoryFlowTypes.EXPENSES,
      CategoryFlowTypes.INVESTIMENTS,
      CategoryFlowTypes.RESULT,
      CategoryFlowTypes.ACCUMULATED,
    ].map((groupName) => {
      groups.push(Object.values(financialFlow).find(group => group?.label === groupName))
    })

    return groups;
  }

  const formatRow = r => {
    setHasInitial(!!(Number(r.initialValue) > 0))
    setHasFlow(true)

    return (
      <>
        <RequestFlowInitialValue
          setShow={setShowModal}
          setReload={setReload}
          show={showModal}
          reload={reload}
          year={r.referencialYear}
          flow={r}
        />
        {getOrderedGroups(r).map(group => (
          <>
            <StyledTableRow
              key={group.label}
              bg={getBackgroudRow(group.label)}
              hover
              tabIndex={-1}
            >
              <StyledTableCell
                scope="row"
                style={{
                  padding: "0px 10px !important",
                  width: "88px !important",
                  height: "32px !important",
                  fontSize: "12px !important",
                  color: "#333333 !important",
                  textTransform: getTransformLabelRow(group.label)
                }}
              >
                {group.label}
              </StyledTableCell>
              {months.map(month => (
                <StyledTableCell
                  scope="row"
                  style={{
                    padding: "0px 10px !important",
                    width: "48px !important",
                    fontSize: "12px !important",
                    color: "#333333 !important"
                  }}
                >
                  {FormatMoney(toDecimal(group[month.value]))}
                </StyledTableCell>
              ))}

              <StyledTableCell
                scope="row"
                style={{
                  padding: "0px 10px !important",
                  width: "48px !important",
                  fontSize: "12px !important",
                  color: "#333333 !important"
                }}
              >
                {FormatMoney(toDecimal(group.total))}
              </StyledTableCell>
            </StyledTableRow>
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Loading isLoading={loading} />
      <Datatable
        {...propsDatatable}
        icon={<img src={streamline} alt={"Icone Fluxo de Caixa"} />}
        aggregator={calculateTotal}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        hasInitial={hasInitial}
        hasFlow={hasFlow}
        showInitialValueModal={setShowModal}
        paginator={false}
        reload={reload}
        dateFilter
      />
    </>
  );
};

const mapStateToProps = ({ auth, company }) => ({
  user: auth.user,
  idCompany: company.idCompany
});

export default connect(mapStateToProps)(FlowListing);
