import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Datatable from "../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Loading from "../../../home/components/Loading";
import moment from "moment";
import { USER_TYPES } from "../../../../enum/UserTypes";

import clienteIcon from "../../../../assets/icons/message.svg";
import aniversario from "../../../../assets/icons/aniversario.svg";
import documentWarning from "../../../../assets/icons/documentWarning.svg";
import documentDanger from "../../../../assets/icons/documentDanger.svg";

import { UseProtectedPage } from "../../../../utils/UseProtectedPage";
import {
  deleteClient,
  toggleClientStatus
} from "../../../../services/modules/ClienteApiService";
import { CustomTooltip } from "../Vehicles/VehiclesListing";
import RememberDialog from "../../../../partials/datatable/RememberDialog";

const propsDatatable = {
  title: "Clientes",
  endpoint: "/client",
  className: "h-100",
  placeholderSearch: "Busque por nome, e-mail ou CPF/CNPJ",
  tooltip: "Novo cadastro",
  titleButon: "Novo cadastro"
};

const ClientesListing = props => {
  window.setPageTitle("Listagem de Clientes - Admin");

  UseProtectedPage.accessToFormOrListing(USER_TYPES.CLIENTS_READ.ENGLISH);
  const userHasAccess = UseProtectedPage.accessToListActions(
    USER_TYPES.CLIENTS_WRITE.ENGLISH
  );
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showRemember, setShowRemember] = useState(false);

  const headRows = [
    ...(userHasAccess ? [{ label: "Ações" }] : []),
    { column: "lembretes", label: "Lembretes" },
    { column: "name", label: "Nome" },
    { column: "cpfCnpj", label: "CPF/CNPF" },
    { column: "dataNascimento", label: "Data de nascimento" },
    { column: "vencimentoCnh", label: "Vencimento CNH" },
    { column: "totalVendido", label: "Total Vendido" },
    { column: "observacao", label: "Observações" }
  ];

  const handleConfirmChangeStatus = async obj => {
    try {
      setLoading(true);
      await toggleClientStatus(obj.id, !obj.active);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Cliente ${!obj.active ? "ativado" : "desativado"} com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async id => {
    try {
      setLoading(true);
      await deleteClient(id);
      setReload(!reload);
      Swal.fire({
        title: "Sucesso!",
        text: `Cliente deletado com sucesso!`,
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar cliente.", "error");
    } finally {
      setLoading(false);
    }
  };

  const hasRemember = (r) => {
    return !!(
      (moment(r?.birthDate).format("MM-DD") === moment().format("MM-DD")) ||
      moment(r?.drivePermissionExpirationDate).isBefore(moment()) ||
      moment(r?.drivePermissionExpirationDate).isBetween(moment(), moment().add(5, "days"))
    )
  }

  const checkRemember = r => {
    if (hasRemember(r)) {
      if (!showRemember) setShowRemember(true);
      return true;
    }
    return false;
  };

  const formatRow = r => (
    <StyledTableRow key={r.id} bg={hasRemember(r) ? "rgba(245, 238, 53, 0.2)" : undefined} hover tabIndex={-1}>
      {userHasAccess ? (
        <StyledTableCell
          scope="row"
          style={{ padding: "0px 10px", width: "48px" }}
        >
          <CrudActions
            actions={["delete", "edit", "toggle"]}
            module="clientes"
            path="clientes"
            onConfirmChangeStatus={handleConfirmChangeStatus}
            onConfirmDelete={handleConfirmDelete}
            id={r.id}
            obj={r}
            titleMessageDelete={"Excluir Cliente"}
            addMessage={
              "Você tem certeza que deseja excluir este cliente? Essa ação não poderá ser desfeita"
            }
          />
        </StyledTableCell>
      ) : null}
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {(moment.utc(r?.birthDate).format("MM-DD") === moment().format("MM-DD")) && (
          <CustomTooltip title="Aniversário" arrow placement="top">
            <img
              style={{ margin: "0 5px" }}
              src={aniversario}
              alt="Aniversário"
            />
          </CustomTooltip>
        )}

        {moment(r?.drivePermissionExpirationDate).isBetween(
          moment(),
          moment().add(5, "days")
        ) && (
            <CustomTooltip
              title="CNH próxima ao vencimento"
              arrow
              placement="top"
            >
              <img src={documentWarning} alt="CNH próxima ao vencimento" />
            </CustomTooltip>
          )}

        {moment(r?.drivePermissionExpirationDate).isBefore(moment()) && (
          <CustomTooltip title="CNH vencida" arrow placement="top">
            <img src={documentDanger} alt="CNH vencida" />
          </CustomTooltip>
        )}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r.fullname}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r.document}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.birthDate ? moment.utc(r?.birthDate).format("DD/MM/YYYY") : "-"}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r?.drivePermissionExpirationDate ? moment.utc(r?.drivePermissionExpirationDate).format("DD/MM/YYYY") : "-"}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {r.totalSold}
      </StyledTableCell>
      <StyledTableCell scope="row" style={{ padding: "4px 0px" }}>
        {!!r.obs ? r.obs : "-"}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <RememberDialog
        open={showRemember}
        closeDialog={() => setShowRemember(false)}
      />
      <Datatable
        {...propsDatatable}
        icon={<img src={clienteIcon} alt={"Icone Cliente"} />}
        width={props.width}
        headRows={headRows}
        formatRow={formatRow}
        reload={reload}
        addButtonUrl={userHasAccess ? "/admin/clientes/adicionar" : ""}
        checkRemember={checkRemember}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(ClientesListing);
